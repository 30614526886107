import * as React from "react"
import { useCallback } from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { CUSTOMERS_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../shared/models/ICustomer"
import CustomerForm from "./components/CustomerForm"
import { useApiRead } from "../../shared/hooks/useApiRead"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import { dateAsIso } from "../../shared/utilities/format_utility"
import moment from "moment"

const repository = new RestRepository<ICustomer>(CUSTOMER_ENDPOINT)
const redirectView = CUSTOMERS_VIEW_URL

/**
 * This page will allow editing of a customer.
 *
 * @returns {React.FunctionComponent} the customer edit page.
 */
const EditPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setError, formState, setValue, clearErrors } = useForm()
  const { data: customer, error, loading } = useApiRead<ICustomer>({ apiFunction: repository.read })

  const props: IUseApiEditProps<ICustomer> = {
    apiFunction: repository.edit,
    setError,
    redirectView,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<ICustomer>(props)

  const handleSave = useCallback(async (customer1: ICustomer) => {
    customer1.executive = customer1.executive === undefined ? null : customer1.executive
    customer1.invoice_contact = customer1.invoice_contact === undefined ? null : customer1.invoice_contact
    customer1.technical_admin = customer1.technical_admin === undefined ? null : customer1.technical_admin
    customer1.service_instructions_change_date = dateAsIso(customer1.service_instructions_change_date as moment.Moment)
    await handleEdit(customer1)
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Edit Customer" loading={saving || loading} errors={formState.errors} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <CustomerForm customer={customer} control={control} setValue={setValue} clearErrors={clearErrors} isEdit />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
