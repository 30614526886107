import * as React from "react"
import { type ChangeEvent, useCallback, useEffect, useState } from "react"
import Moment from "react-moment"
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
  Typography
} from "@mui/material"
import { Link as BrowserLink, useNavigate, useParams } from "@reach/router"

import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import ViewProperty from "../../shared/components/ViewProperty"

import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import {
  ACCOUNTS_EDIT_URL,
  CUSTOMERS_URL,
  CUSTOMERS_VIEW_URL,
  LOCATIONS_ADD_URL,
  LOCATIONS_EDIT_URL,
  LOCATIONS_VIEW_URL,
  WORK_ASSIGNMENT_ADD_URL,
  WORK_ASSIGNMENT_VIEW_URL,
} from "../../config/urls"
import { DATE_TIME_FORMAT } from "../../config/config"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../shared/models/IAccount"
import { type ILocation, LOCATION_ENDPOINT, locationToString } from "../../shared/models/ILocation"
import LocationMap from "../../shared/components/LocationMap"
import { type IPagedResults } from "../../shared/models/IPagedResults"
import { type IFilter } from "../../shared/models/IFilter"
import { type IPaging } from "../../shared/models/IPaging"
import { CONNECTION_ERROR, type IConnectionError } from "../../shared/models/IConnectionError"
import TablePaging from "../../shared/components/TablePaging"
import ContactCard from "../../shared/components/ContactCard"
import FilesEditor from "../../shared/components/FilesEditor"
import { type IFile } from "../../shared/models/IFile"
import { ACCOUNT_FILE_ENDPOINT } from "../../shared/models/IAccountFile"
import Goto from "../../shared/components/Goto"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { type IWorkAssignment } from "../../shared/models/IWorkAssignment"
import WorkAssignmentProgress from "../../shared/components/WorkAssignmentProgress"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { type DashboardStore } from "../../store"
import type IAccountsViewState from "../../store/settings/models/IAccountsViewState"
import { operations } from "../../store/settings"
import { type ICustomer } from "../../shared/models/ICustomer"
import { type IListItem } from "../../shared/models/component/IListItem"
import UserCard from "../../shared/components/UserCard"
import type IUser from "../../shared/models/IUser"
import TruncateText from "../../shared/components/TruncateText"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import TableLoadingFull from "../../shared/components/TableLoadingFull"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"

const accountRepository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const locationRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)
const fileRepository = new RestRepository<IFile>(ACCOUNT_FILE_ENDPOINT)

const limit = 10

/**
 * This component renders the account view page.
 * @returns {React.FC} the account view page.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: account, error, loading, call: refreshAccount } = useApiRead<IAccount>({ apiFunction: accountRepository.read })

  const { id } = useParams()
  const viewState: IAccountsViewState = useSelector((state: DashboardStore) => state.settings.accountsView, shallowEqual)

  const [locations, setLocations] = useState<IPagedResults<ILocation> | undefined>()
  const [locationsLoading, setLocationsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [page, setPage] = useState(viewState?.id === id ? viewState?.page : 1)

  const { tab, handleTabChange } = useTabPanel()
  const { tab: tab1, handleTabChange: handleTabChange1 } = useTabPanel(viewState?.id === id ? { initialTab: viewState?.tab1 } : undefined)
  const [location, setLocation] = useState<ILocation | null>(viewState?.id === id ? viewState?.location : null)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    if (location !== null) {
      const viewState: IAccountsViewState = {
        id,
        page,
        tab1,
        location,
      }
      dispatch(operations.setAccountsView(viewState))
    }
  }, [id, page, tab1, location])

  const handleSelected = useCallback((l: ILocation) => {
    setLocation(location => {
      return location?.id === l.id ? null : l
    })
  }, [])

  const handleNavigateWa = useCallback(
    (wa: IWorkAssignment) => async () => {
      await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${wa.id}`)
    },
    []
  )

  const handlePaging = useCallback((_event: ChangeEvent<unknown> | null, p: number) => {
    setPage(p)
    setLocations(undefined)
  }, [])

  const loadLocations = useCallback(async () => {
    if (account !== undefined) {
      try {
        setLocationsLoading(true)
        setErrorMessage(null)
        const filters = [
          {
            field: "accounts",
            value: account.id,
          },
        ] as IFilter[]
        const paging: IPaging = {
          filters,
          limit,
          offset: limit * (page - 1),
        }
        const results = await locationRepository.findAll(paging)
        setLocations(results as IPagedResults<ILocation>)
        const updatedLocation = results.results.filter(l => l.id === location?.id)
        if (updatedLocation.length > 0) {
          setLocation(updatedLocation[0] as ILocation)
        }
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setErrorMessage(reason.response as IConnectionError)
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
      setLocationsLoading(false)
    }
  }, [account, page])

  const handleDataRefresh = useCallback(async () => {
    try {
      setErrorMessage(null)
      await loadLocations()
      refreshAccount()
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage(reason.response as IConnectionError)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
  }, [loadLocations])

  const getGotoLocationFilter = useCallback((account: IAccount): IFilter[] => {
    return [
      {
        field: "accounts",
        value: account.id,
      },
    ]
  }, [])

  useEffect(() => {
    if (account !== undefined && locations === undefined) {
      void (async () => {
        await loadLocations()
      })()
    }
  }, [account, locations, loadLocations])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading || locationsLoading} />
        <ErrorMessage error={error} />
        <ErrorMessage error={errorMessage} />
        {account !== undefined && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h4" component="h2" onClick={handleDataRefresh} sx={{ cursor: "pointer" }}>
                    <Grid container>
                      <Grid item>
                        <TruncateText text={account.name} />
                      </Grid>
                      <Grid item>
                        <Box component="small" sx={styles.consultant.identifier}>
                          {account.identifier}
                        </Box>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item xs>
                  <ViewLoading loading={loading} message="Updating..." />
                </Grid>
                <Grid item>
                  {account.service_instructions_url !== "" && (
                    <Button component={Link} href={account.service_instructions_url} target="_blank">
                      Service Instructions
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button startIcon={<EditIcon />} component={BrowserLink} to={`${ACCOUNTS_EDIT_URL}/${account.id}`}>
                    Account
                  </Button>
                </Grid>
                <Grid item>
                  <HelpDocsDrawer showButton showButtonLabel={false} pageId={527} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link component={BrowserLink} to={CUSTOMERS_URL}>
                  Customers
                </Link>
                {account.customer !== null ? (
                  <Link component={BrowserLink} to={`${CUSTOMERS_VIEW_URL}/${(account.customer as ICustomer).id}`}>
                    {(account.customer as ICustomer).name}
                  </Link>
                ) : (
                  <em>Customer Missing</em>
                )}
              </Breadcrumbs>
            </Grid>
            {account.contacts.map(contact => (
              <Grid item key={contact.id} lg={3} md={5} sm={12}>
                <ContactCard contact={contact} />
              </Grid>
            ))}
            {account.invoice_contact !== null && (
              <Grid item lg={3} md={5} xs={12}>
                <ContactCard contact={account.invoice_contact} prefixLabel="Invoice" />
              </Grid>
            )}

            {account.executive !== undefined && account.executive !== null && (
              <Grid item lg={3} md={5} xs={12}>
                <UserCard user={account.executive as IUser} label="AE" />
              </Grid>
            )}

            {account.technical_admin !== undefined && account.technical_admin !== null && (
              <Grid item lg={3} md={5} xs={12}>
                <UserCard user={account.technical_admin as IUser} label="TA" />
              </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={styles.tabsBox}>
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Locations" />
                  <Tab label="Files" />
                  <Tab label="Additional Info" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} md={4} lg={3}>
                    <Goto repository={locationRepository} filters={getGotoLocationFilter(account)} url={LOCATIONS_VIEW_URL} />
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Button
                      startIcon={<AddIcon />}
                      component={BrowserLink}
                      to={`${LOCATIONS_ADD_URL}`}
                      state={{
                        account: {
                          id: account.id,
                          name: account.name,
                        },
                      }}
                    >
                      Location
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={location !== null ? 6 : 12} lg={location !== null ? 8 : 12}>
                    <Box sx={{ mb: 2 }}>
                      <TableContainer>
                        <Table stickyHeader size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell># WA</TableCell>
                              <TableCell>Specialty</TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>City</TableCell>
                              <TableCell>State</TableCell>
                              <TableCell>Country</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {locations !== undefined &&
                              locations.count > 0 &&
                              !locationsLoading &&
                              locations.results.map(l => (
                                <TableRowSelect key={l.id} item={l} onSelected={handleSelected} selected={l.id === location?.id}>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>L-{l.id}</TableCell>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    <TruncateText text={l.name} />
                                  </TableCell>
                                  <TableCell>{l.work_assignments.length}</TableCell>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>{l.specialty?.name}</TableCell>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    <TruncateText text={l.address} />
                                  </TableCell>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>{l.city}</TableCell>
                                  <TableCell>{l.state_region}</TableCell>
                                  <TableCell>{l.country}</TableCell>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    <Toolbar variant="dense" disableGutters sx={{minHeight: 0}}>
                                      <Button
                                        startIcon={<AddIcon />}
                                        size="small"
                                        component={BrowserLink}
                                        state={{
                                          customer: {
                                            id: (account.customer as ICustomer).id,
                                            name: (account.customer as ICustomer).name,
                                          },
                                          account: {
                                            id: account.id,
                                            name: account.name,
                                          },
                                          location: l,
                                        }}
                                        to={`${WORK_ASSIGNMENT_ADD_URL}`}
                                      >
                                        WA
                                      </Button>
                                      <Button size="small" component={BrowserLink} to={`${LOCATIONS_EDIT_URL}/${l.id}`}>
                                        Edit
                                      </Button>
                                      <Button size="small" component={BrowserLink} to={`${LOCATIONS_VIEW_URL}/${l.id}`}>
                                        View
                                      </Button>
                                    </Toolbar>
                                  </TableCell>
                                </TableRowSelect>
                              ))}
                          </TableBody>
                        </Table>
                        {!locationsLoading && (locations === undefined || locations.results.length === 0) && (
                          <Alert color="info" sx={{ m: 1 }}>
                            No locations found.
                          </Alert>
                        )}
                        <TableLoadingFull loading={locationsLoading} rows={limit} />
                      </TableContainer>
                    </Box>
                    {locations !== undefined && (
                      <TablePaging count={Math.ceil(locations.count / limit)} total={locations.count} page={page} onPaging={handlePaging} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={location !== null ? 6 : 12} lg={location !== null ? 4 : 12}>
                    {location !== null && (
                      <>
                        <Tabs value={tab1} onChange={handleTabChange1}>
                          <Tab label="Location" />
                          <Tab label="Work Assignments" />
                        </Tabs>
                        <TabPanel value={tab1} index={0}>
                          <Grid container>
                            <Grid item xs>
                              <Typography variant="h4" component="h4">
                                L-{location.id} | {location.name}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box sx={{ mb: 1 }}>
                            <Typography>{locationToString(location)}</Typography>
                          </Box>
                          <LocationMap primaryLocation={location} />
                        </TabPanel>
                        <TabPanel value={tab1} index={1}>
                          <List>
                            {location.work_assignments.length === 0 && (
                              <ListItem>
                                <ListItemText primary="No work assignments for this location." />
                              </ListItem>
                            )}
                            {(location.work_assignments as IWorkAssignment[])?.map(wa => (
                              <ListItemButton key={wa.id} onClick={handleNavigateWa(wa)}>
                                <ListItemText
                                  primary={
                                    <Grid container alignItems="center">
                                      <Grid item xs>
                                        {wa.identifier}
                                      </Grid>
                                      <Grid item>
                                        <WorkAssignmentProgress workAssignment={wa} />
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        </TabPanel>
                      </>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <FilesEditor parentId={account.id} fileRepository={fileRepository} fieldName="account" />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ViewProperty label="Notes" vertical>
                      <Box dangerouslySetInnerHTML={{ __html: account.notes }}></Box>
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={3}>
                    <ViewProperty label="Created">
                      <Moment format={DATE_TIME_FORMAT}>{account.created}</Moment>
                    </ViewProperty>
                    <ViewProperty label="Updated">
                      <Moment format={DATE_TIME_FORMAT}>{account.updated}</Moment>
                    </ViewProperty>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
