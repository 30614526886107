import * as React from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import { Box, Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper, Popover } from "@mui/material"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../../shared/models/IExpenseReport"
import StorageFileDownloadButton from "../../../shared/components/StorageFileDownloadButton"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { LoadingButton } from "@mui/lab"
import BuildIcon from "@mui/icons-material/Build"
import FormatDate from "../../../shared/components/format/FormatDate"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import FileViewerDialog from "../../../shared/components/files/FileViewerDialog"
import { IMinimalFile } from "../../../shared/models/IFile"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)

interface IProps {
  expenseReport: IExpenseReport
  onChange: () => void
}

/**
 * Generates and provides a download link for an expense report.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the component to generate an expense report.
 */
const GenerateExpenseReportDocument: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { expenseReport, onChange } = props
  const [generatingFile, setGeneratingFile] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)

  const fileAnchorRef = useRef<HTMLDivElement>(null)
  const [openFileMenu, setOpenFileMenu] = useState(false)

  const handleGenerateFile = useCallback(async () => {
    if (expenseReport !== null) {
      setGeneratingFile(true)
      setOpenFileMenu(false)
      try {
        await repository.action(expenseReport.id, "generate_file")
      } catch (e) {}
      setGeneratingFile(false)
      onChange()
    }
  }, [repository, expenseReport])

  const expenseReportFile: IMinimalFile | null = useMemo(() => {
    if (expenseReport.file?.exists !== undefined && expenseReport.file.exists !== false && expenseReport.pay_period !== null) {
      return {
        name: `ER-${expenseReport.id}-${expenseReport.consultant.name}-${expenseReport.pay_period}`,
        ext: "pdf",
        file: expenseReport.file,
      }
    }
    return null
  }, [expenseReport])

  return (
    <>
      {expenseReportFile !== null && (
        <FileViewerDialog
          open={previewOpen}
          files={[expenseReportFile]}
          selectedFile={expenseReportFile}
          onClose={() => setPreviewOpen(false)}
        />
      )}
      {expenseReport.file?.exists !== undefined && expenseReport.file.exists !== false && !generatingFile ? (
        <>
          <ButtonGroup ref={fileAnchorRef} variant="text">
            <StorageFileDownloadButton file={expenseReport.file} title="Download" />
            <Button size="small" onClick={() => setOpenFileMenu(o => !o)}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <ButtonGroup ref={fileAnchorRef} variant="text">
            <LoadingButton
              loadingPosition="start"
              loading={generatingFile}
              onClick={handleGenerateFile}
              startIcon={<BuildIcon />}
              disabled={generatingFile}
            >
              {generatingFile ? "Generating..." : "Generate"}
            </LoadingButton>
          </ButtonGroup>
        </>
      )}

      <Popover
        open={openFileMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setOpenFileMenu(false)}
        anchorEl={fileAnchorRef.current}
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setOpenFileMenu(false)}>
            <MenuList autoFocusItem>
              {expenseReportFile !== null && (
                <MenuItem onClick={() => setPreviewOpen(true)} sx={{ display: "block" }}>
                  Preview
                </MenuItem>
              )}
              {expenseReport?.file?.exists !== undefined && expenseReport.file.exists !== false && (
                <MenuItem onClick={handleGenerateFile} sx={{ display: "block" }}>
                  <Box component="div">
                    <Box component="span" sx={{ pr: 1 }}>
                      Regenerate
                    </Box>
                    <FormatDate value={expenseReport.file.exists as string} />
                  </Box>
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  )
}

export default GenerateExpenseReportDocument
