import { ListItem, ListItemText, Skeleton } from '@mui/material'
import * as React from 'react'
import { useMemo } from 'react'

interface IProps {
  loading: boolean
  rows: number
}

/**
 * Loading indicator for a list.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ListLoading: React.FunctionComponent<IProps> = (props: IProps) => {
  const { loading, rows } = props

  const rowIndex = useMemo(() => {
    return Array.from({ length: rows }, (x, i) => i)
  }, [])

  return loading
    ? (
      <>
        {rowIndex.map(i => (
          <ListItem key={i}>
            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          </ListItem>
        ))}
      </>
      )
    : (<></>)
}

export default ListLoading
