import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Control } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import { UseFormSetValue } from "react-hook-form/dist/types/form"
import FhMuiIdField from "../../../shared/components/form/FhMuiIdField"
import { IExpenseReport } from "../../../shared/models/IExpenseReport"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IListItem } from "../../../shared/models/component/IListItem"
import { CONSULTANTS_ENDPOINT, IConsultant } from "../../../shared/models/main/IConsultant"
import { useLocation } from "@reach/router"
import FhMuiDateField from "../../../shared/components/form/FhMuiDateField"
import { convertDateToPayPeriod, isoAsDate } from "../../../shared/utilities/format_utility"
import moment from "moment"
import FormatDate from "../../../shared/components/format/FormatDate"
import CheckForExpenseReportItems from "./CheckForExpenseReportItems"
import { IPayPeriod, PAY_PERIODS_ENDPOINT } from "../../../shared/models/main/IPayPeriod"

const consultantRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const payPeriodRepository = new RestRepository<IListItem>(PAY_PERIODS_ENDPOINT)

interface ILocationState {
  consultant: IConsultant
}

interface IProps {
  control: Control<any>
  expenseReport?: IExpenseReport | null
  isEdit?: boolean
  setValue: UseFormSetValue<any>
}

/**
 * Renders the form for editing or creating an expenseReport.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the expenseReport form component.
 */
const ExpenseReportForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { expenseReport = null, isEdit = false, control, setValue } = props
  const [hasData, setHasData] = useState(false)
  const [consultant, setConsultant] = useState<IConsultant | null>(null)
  const [adpPayPeriod, setApdPeriod] = useState<IPayPeriod | null>(null)
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<moment.Moment | null>(null)
  const browserLocation = useLocation()

  const handleConsultant = useCallback((consultant1?: IListItem | null) => {
    setValue("consultant", consultant1 === null || consultant1 === undefined ? null : consultant1.id)
    setConsultant(consultant1 !== undefined ? (consultant1 as IConsultant) : null)
  }, [])

  const handleAdpPayPeriod = useCallback((adpPayPeriod1?: IListItem | null) => {
    setValue("adp_pay_period", adpPayPeriod1 === null || adpPayPeriod1 === undefined ? null : adpPayPeriod1.id)
    setApdPeriod(adpPayPeriod1 !== undefined ? (adpPayPeriod1 as IPayPeriod) : null)
  }, [])

  const handlePayPeriodChange = useCallback((payPeriod1: moment.Moment | null) => {
    const adjustedPayPeriod = convertDateToPayPeriod(payPeriod1)
    setSelectedPayPeriod(adjustedPayPeriod)
    setValue("pay_period", adjustedPayPeriod !== null ? adjustedPayPeriod.clone() : null)
  }, [])

  useEffect(() => {
    if (expenseReport !== null && isEdit && !hasData) {
      setHasData(true)
      setValue("id", expenseReport.id)
      handleConsultant(expenseReport.consultant)
      handleAdpPayPeriod(expenseReport.adp_pay_period)
      handlePayPeriodChange(isoAsDate(expenseReport.pay_period))
      setValue("pay_period", isoAsDate(expenseReport.pay_period))
    }
    if (expenseReport !== null && !hasData) {
      setHasData(true)
    }
  }, [expenseReport, hasData, setValue, isEdit, handleConsultant])

  useEffect(() => {
    if (browserLocation?.state !== undefined && browserLocation.state !== null && !isEdit) {
      const { consultant } = browserLocation.state as ILocationState
      if (consultant !== null && consultant !== undefined) {
        handleConsultant(consultant)
      }
    }
  }, [browserLocation.state])

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        {isEdit && <FhMuiIdField control={control} />}
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
          <Grid item xs={12} md={3}>
            <SelectFilteredSingle
              name="consultant"
              label="Consultant"
              defaultValue={consultant as IListItem}
              repository={consultantRepository}
              onChange={handleConsultant}
            />
          </Grid>
          <Grid item>
            <FhMuiDateField control={control} label="Pay Period" name="pay_period" onChange={handlePayPeriodChange} />
          </Grid>
          <Grid item>
            <FormatDate value={selectedPayPeriod} />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectFilteredSingle
              name="adp_pay_period"
              label="ADP Pay Period"
              defaultValue={adpPayPeriod as IListItem}
              repository={payPeriodRepository}
              onChange={handleAdpPayPeriod}
            />
          </Grid>
        </Grid>
      </Grid>
      {!isEdit && (
        <Grid item xs={12}>
          <CheckForExpenseReportItems consultant={consultant} />
        </Grid>
      )}
    </Grid>
  )
}

export default ExpenseReportForm
