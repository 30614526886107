import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import useAuth from "../../../shared/hooks/useAuth"
import { Box, Grid, List, ListItem, Typography } from "@mui/material"
import ViewProperty from "../../../shared/components/ViewProperty"
import { LookupsRepository } from "../../../shared/repositories/LookupsRepository"
import { BASE_URL, BASE_URL_RELEASE } from "../../../shared/utilities/request_utility"
import { useLocalStorage } from "beautiful-react-hooks"
import { IMaintenance } from "../../../shared/models/IMaintenance"
import { MAINTENANCE_LS_KEY } from "../../../config/config"

const lookupsRepository = new LookupsRepository()

interface IDateTimeZone {
  date: string
  time: string
  timezone: string
}
interface IServerInfo {
  date: string
  time: string
  timezone: string
  version: string
}

/**
 * Displays helpful user profile information.
 *
 * @returns {React.FC} the user profile component.
 */
const UserProfile: React.FC = (): React.ReactElement => {
  const { currentUser } = useAuth()

  const [serverInfo, setServerInfo] = useState<IServerInfo | null>(null)
  const [browserDateTimeZone, setBrowserDateTimeZone] = useState<IDateTimeZone | null>(null)
  const [initial, setInitial] = useState(false)
  const [maintenance] = useLocalStorage<IMaintenance | null>(MAINTENANCE_LS_KEY, null)

  // Handle fetching the date, time, and timezone
  const getBrowserDateTimeZone = useCallback(() => {
    const now = new Date()
    const date = now.toDateString()
    const time = now.toLocaleTimeString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return {
      date,
      time,
      timezone,
    }
  }, [])

  useEffect(() => {
    if (!initial) {
      setInitial(true)
      setBrowserDateTimeZone(getBrowserDateTimeZone())
      void (async function getInvoice() {
        try {
          const serverInfo1 = await lookupsRepository.lookup<IServerInfo>("server_datetime")
          setServerInfo(serverInfo1)
        } catch (error) {
          console.error("Failed to get server datetime", error)
        }
      })()
    }
  }, [serverInfo])

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5">{currentUser?.user.name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <ViewProperty label="Username">{currentUser?.user.username}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Email">{currentUser?.user.email}</ViewProperty>
            </Grid>
            {serverInfo !== null && (
              <>
                <Grid item xs={6}>
                  <ViewProperty vertical label="Server Time">
                    <ul>
                      <li>{serverInfo.date}</li>
                      <li>{serverInfo.time}</li>
                      <li>{serverInfo.timezone}</li>
                    </ul>
                  </ViewProperty>
                </Grid>
                <Grid item xs={6}>
                  <ViewProperty vertical label="Local Time">
                    <ul>
                      <li>{browserDateTimeZone?.date}</li>
                      <li>{browserDateTimeZone?.time}</li>
                      <li>{browserDateTimeZone?.timezone}</li>
                    </ul>
                  </ViewProperty>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <ViewProperty vertical label={<>API {maintenance?.useRelease === true && <small>(Release)</small>} {serverInfo?.version}</>}>
                <ul>
                  {maintenance?.useRelease === true ? (
                    <>
                      <li>{BASE_URL}</li>
                      <li>
                        <strong>{BASE_URL_RELEASE}</strong> <small>(Release)</small>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <strong>{BASE_URL}</strong>
                      </li>
                      <li>{BASE_URL_RELEASE} <small>(Release)</small></li>
                    </>
                  )}
                </ul>
              </ViewProperty>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <ViewProperty vertical label="Access Groups">
                <List sx={{ pt: 0 }}>
                  {currentUser?.user.groups.map(group => {
                    return <ListItem key={group.id}>{group.name}</ListItem>
                  })}
                </List>
              </ViewProperty>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserProfile
