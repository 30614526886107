import * as React from "react"
import { type MouseEvent, useCallback, useState } from "react"
import { Box, Button, Grid, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material"
import ViewLoading from "./ViewLoading"
import { type FieldErrors } from "react-hook-form/dist/types/errors"
import ErrorIcon from "@mui/icons-material/Error"
import HelpDocsDrawer from "./help/HelpDocsDrawer"

interface IProps {
  title: string
  loading?: boolean
  errors?: FieldErrors
  helpDocsId?: number
}

/**
 * Use this as a default header for a form. Has errors built in.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the form header.
 */
const HeaderPanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, loading = false, errors, helpDocsId } = props
  const errorFields = errors !== undefined ? Object.keys(errors) : []
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpenErrors = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const open = Boolean(anchorEl)

  const handleFocusItem = useCallback(
    (field: string) => () => {
      handleClose()
      setTimeout(() => {
        if ((errors as any)[field]?.ref !== undefined) {
          ;(errors as any)[field].ref.focus()
        }
      }, 500)
    },
    [errors],
  )

  return (
    <Grid
      container
      spacing={2}
      alignItems="baseline"
      sx={{
        ml: 0,
        mb: 2,
      }}
    >
      <Grid item>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid item xs>
        <Box sx={{ pl: 8 }}>
          <ViewLoading loading={loading} />
        </Box>
      </Grid>
      {helpDocsId !== undefined && (
        <Grid item>
          <HelpDocsDrawer showButton showButtonLabel={false} pageId={helpDocsId} />
        </Grid>
      )}
      <Grid item>
        <Box sx={{ mr: 4 }}>
          {errorFields.length > 0 && (
            <>
              <Button onClick={handleOpenErrors} variant="contained" color="error">
                Errors Found ({errorFields.length})
              </Button>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <List>
                  {errorFields.map(field => {
                    return (
                      <ListItemButton key={field} onClick={handleFocusItem(field)}>
                        <ListItemIcon>
                          <ErrorIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              <Box component="span" sx={{ textTransform: "capitalize" }}>
                                {field.replace("_", " ")}
                              </Box>{" "}
                              - {(errors as any)[field].message as string}
                            </>
                          }
                        />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Popover>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default HeaderPanel
