import * as React from "react"
import { IColumn } from "../models/component/IColumn"
import { TableCell, Theme } from "@mui/material"
import { TableCellProps } from "@mui/material/TableCell/TableCell"
import { SxProps } from "@mui/system"

interface IProps extends TableCellProps {
  field: string
  columns: IColumn[]
  children: any
  sx?: SxProps<Theme>
}

/**
 * Show or hide a table column.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the column or nothing.
 */
const TableCellShow: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    field,
    columns,
    children,
    sx
  } = props

  if (!columns.some(c => c.field === field)) {
    return <></>
  }

  return <TableCell sx={sx}>{children}</TableCell>
}

export default TableCellShow
