import * as React from "react"
import { useCallback, useState } from "react"
import { axiosInstance, urlSearchParams } from "../utilities/request_utility"
import { IPaging } from "../models/IPaging"
import { saveAs } from "file-saver"
import { IFilter } from "../models/IFilter"
import { LoadingButton } from "@mui/lab"
import DownloadIcon from "@mui/icons-material/Download"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import ViewError from "./ViewError"
import { Alert, Snackbar } from "@mui/material"

interface IProps {
  paging?: IPaging | null
  endpoint: string
  asyncEndpoint?: string
  filename: string
  limit?: number
}

/**
 * This component will download the Excel document for the current paging results.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the download button.
 */
const ExcelDownloadButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { asyncEndpoint, limit = 10 } = props

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleDownload = useCallback(async () => {
    setLoading(true)
    try {
      const { endpoint, paging, filename, limit = 10 } = props
      const theFilters = [
        ...(paging?.filters !== undefined ? paging.filters : []),
        {
          field: "format",
          value: "xlsx",
        },
      ] as IFilter[]
      const thePaging: IPaging = paging !== undefined && paging !== null ? paging : {}
      const queryString = urlSearchParams({
        ...thePaging,
        limit,
        filters: theFilters,
      })
      const url = `/${endpoint}/${queryString}`

      const response = await axiosInstance.get(url, { responseType: "blob" })
      saveAs(response.data, filename)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage({
          data: {
            message: reason.response.statusText,
          },
        })
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [props])

  const handleAsyncDownload = useCallback(async () => {
    if (asyncEndpoint !== undefined) {
      setLoading(true)
      setDisabled(true)
      try {
        const { paging } = props
        const theFilters = [...(paging?.filters !== undefined ? paging.filters : [])] as IFilter[]
        const thePaging: IPaging = paging !== undefined && paging !== null ? paging : {}
        const queryString = urlSearchParams({
          ...thePaging,
          limit,
          filters: theFilters,
        })
        await axiosInstance.get(`${asyncEndpoint}/${queryString}`)
        setOpen(true)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setErrorMessage({
            data: {
              message: reason.response.statusText,
            },
          })
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
      setLoading(false)
      setTimeout(() => {
        setDisabled(false)
      }, 4000)
    }
  }, [props])

  return (
    <>
      <ViewError error={errorMessage} />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        sx={{ mt: 8 }}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert severity="info" elevation={8}>
          Your excel document is being generated. Check your email for a download link.
        </Alert>
      </Snackbar>

      <LoadingButton
        loading={loading}
        startIcon={<DownloadIcon />}
        disabled={disabled}
        onClick={limit > 50 && asyncEndpoint !== undefined ? handleAsyncDownload : handleDownload}
        title="Download all the results to an excel file."
      >
        Excel
      </LoadingButton>
    </>
  )
}

export default ExcelDownloadButton
