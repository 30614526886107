import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { type IConsultant } from "../../../shared/models/main/IConsultant"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import {
  EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT,
  type IWorkAssignmentConsultant,
} from "../../../shared/models/IWorkAssignmentConsultant"
import {
  EXPENSE_REPORT_WORK_ASSIGNMENT_ADMIN_REVIEW_ENDPOINT,
  EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT,
  type IWorkAssignment,
} from "../../../shared/models/IWorkAssignment"
import { type IPagedResults } from "../../../shared/models/IPagedResults"
import { type IListItem } from "../../../shared/models/component/IListItem"
import { type IPaging } from "../../../shared/models/IPaging"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { Alert, Grid } from "@mui/material"
import AddTechReview from "./AddTechReview"
import AddAdminReview from "./AddAdminReview"
import AddWorkAssignment from "./AddWorkAssignment"
import ViewLoading from "../../../shared/components/ViewLoading"

const wacRepository = new RestRepository<IWorkAssignmentConsultant>(EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)
const waAdminReviewRepository = new RestRepository<IWorkAssignment>(EXPENSE_REPORT_WORK_ASSIGNMENT_ADMIN_REVIEW_ENDPOINT)
const waTechReviewRepository = new RestRepository<IWorkAssignment>(EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT)

interface IProps {
  consultant: IConsultant | null
}

/**
 * Check to see if the consultant has any expense report items like:
 * - Inspections
 * - Tech Reviews
 * - Admin Reviews
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the expense report check info.
 */
const CheckForExpenseReportItems: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { consultant } = props
  const [error, setError] = useState<IConnectionError | undefined>()
  const [loading, setLoading] = useState(false)

  const [workAssignments, setWorkAssignments] = useState<IPagedResults<IWorkAssignmentConsultant> | null>(null)
  const [techReviews, setTechReviews] = useState<IPagedResults<IWorkAssignment> | null>(null)
  const [adminReviews, setAdminReviews] = useState<IPagedResults<IWorkAssignment> | null>(null)

  const getPaging = useCallback((consultant1: IListItem): IPaging => {
    return {
      limit: 100,
      filters: [
        {
          field: "can_be_expense_reported",
          value: consultant1.id,
          display: "Yes",
          title: "Can Be Expense Reported?",
        },
      ],
    }
  }, [])

  const handleConsultant = useCallback(async (consultant1?: IListItem | null) => {
    setLoading(true)
    try {
      if (consultant1 !== undefined && consultant1 !== null) {
        const paging = getPaging(consultant1)
        const waResults = await wacRepository.findAll(paging)
        setWorkAssignments(waResults)

        const waAdminReviewResults = await waAdminReviewRepository.findAll(paging)
        setAdminReviews(waAdminReviewResults)

        const waTechReviewResults = await waTechReviewRepository.findAll(paging)
        setTechReviews(waTechReviewResults)
      }
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response as IConnectionError)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    void (async () => {
      await handleConsultant(consultant)
    })()
  }, [consultant])

  return (
    <>
      <ErrorMessage error={error} />
      <ViewLoading inline loading={loading} message="Checking for items that can be expensed." />
      {!loading && consultant !== null && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {workAssignments !== null && workAssignments.count > 0 ? (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <Alert severity={"info"} action={<AddWorkAssignment consultant={consultant} />}>
                    There are {workAssignments.count} work assignments available for an expense report.
                  </Alert>
                </Grid>
              </Grid>
            ) : (
              <Alert severity="warning">
                <>No work assignments are available for expense reporting.</>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            {adminReviews !== null && adminReviews.count > 0 ? (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <Alert severity={"info"} action={<AddAdminReview consultant={consultant} />}>
                    There are {adminReviews.count} admin reviews available for an expense report.
                  </Alert>
                </Grid>
              </Grid>
            ) : (
              <Alert severity="warning">
                <>No admin reviews are available for expense reporting.</>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            {techReviews !== null && techReviews.count > 0 ? (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <Alert severity={"info"} action={<AddTechReview consultant={consultant} />}>
                    There are {techReviews.count} tech reviews available for an expense report.
                  </Alert>
                </Grid>
              </Grid>
            ) : (
              <Alert severity="warning">
                <>No admin tech are available for expense reporting.</>
              </Alert>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default CheckForExpenseReportItems
