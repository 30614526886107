import { Box, Button, CircularProgress, Container, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import * as React from "react"
import { FormEvent, useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { INVOICES_URL, SETTINGS_URL, WORK_ASSIGNMENTS_URL } from "../../config/urls"
import useAuth from "../../shared/hooks/useAuth"
import { operations } from "../../store/settings"
import { useDispatch } from "react-redux"
import { useWindowResize } from "beautiful-react-hooks"
import { grey } from "@mui/material/colors"
import { useLocation } from "@reach/router"
import { CONNECTION_ERROR, IConnectionError } from "../../shared/models/IConnectionError"
import ErrorMessage from "../../shared/components/ErrorMessage"
import Maintenance from "../../shared/components/Maintenance"
import { ACCESS_AUDIT_GROUP, ACCESS_INVOICES_GROUP, ACCESS_WORK_ASSIGNMENT_GROUP } from "../../config/permissions"
import { GoogleLogin } from "@react-oauth/google"
import IToken from "../../shared/models/IToken"

const GATSBY_GOOGLE_AUTH_TOKEN: string | undefined = process.env.GATSBY_GOOGLE_AUTH_TOKEN
const GATSBY_SHOW_USERNAME_PASSWORD_FORM: boolean = process.env.GATSBY_SHOW_USERNAME_PASSWORD_FORM === "true"

const GATSBY_DEV = process.env.GATSBY_DEV === "true"

interface IProps {
  data: any
}

interface ILocationState {
  refer?: string
}

/**
 * This component allows the user to log in to the site.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the login page.
 */
const LoginPage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { data } = props
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [loading, setLoading] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const dispatch = useDispatch()
  const location = useLocation()

  const isLarge = useMediaQuery(useTheme().breakpoints.up("lg"))

  const { loginWithToken, loginWithCredentials, loadUserWithToken, isUserInGroups } = useAuth()

  const [windowHeight, setWindowHeight] = useState(typeof window !== "undefined" ? window.innerHeight : 600)

  useWindowResize(() => {
    setWindowHeight(window.innerHeight)
  })

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      if (loginWithCredentials !== null) {
        event.preventDefault()
        setLoading(true)
        setError(false)
        try {
          setErrorMessage(null)
          await loginWithCredentials({
            username,
            password,
          })
          const refer = (location.state as ILocationState)?.refer
          await navigate(refer !== undefined ? refer : WORK_ASSIGNMENTS_URL)
        } catch (reason: any) {
          setError(true)
          if (reason?.response !== undefined) {
            setErrorMessage(reason.response)
          } else {
            setErrorMessage(CONNECTION_ERROR)
          }
        }
        setLoading(false)
      }
    },
    [loginWithCredentials, username, password]
  )

  const responseGoogleSuccess = useCallback(
    async (response: any) => {
      if (loginWithToken !== null) {
        setLoading(true)
        setError(false)
        try {
          setErrorMessage(null)
          const user = await loginWithToken(response.credential)
          const refer = (location.state as ILocationState)?.refer
          if (isUserInGroups?.([ACCESS_WORK_ASSIGNMENT_GROUP], user) === true) {
            await navigate(refer !== undefined ? refer : WORK_ASSIGNMENTS_URL)
          } else if (isUserInGroups?.([ACCESS_INVOICES_GROUP, ACCESS_AUDIT_GROUP], user) === true) {
            await navigate(refer !== undefined ? refer : INVOICES_URL)
          } else {
            await navigate(refer !== undefined ? refer : SETTINGS_URL)
          }
        } catch (reason: any) {
          setError(true)
          if (reason?.response !== undefined) {
            setErrorMessage(reason.response)
          } else {
            setErrorMessage(CONNECTION_ERROR)
          }
        }
        setLoading(false)
      }
    },
    [location, loginWithToken]
  )

  const responseGoogleFailure = useCallback(async () => {
    const errorMessage1: IConnectionError = {
      objType: "GoogleError",
      data: { message: ["Google login error."] },
      code: "None",
    }
    setErrorMessage(errorMessage1)
    setError(true)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
      dispatch(operations.clearCurrentUser())
    }
  }, [initialLoad])

  useEffect(() => {
    // On dev site allow for logins using access and refresh tokens as url params.
    if (GATSBY_DEV) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const access = urlParams.get("access_token")
      const refresh = urlParams.get("refresh_token")
      if (access !== null && refresh !== null && loadUserWithToken !== null) {
        void (async () => {
          setLoading(true)
          const token: IToken = {
            refresh,
            access,
          }
          await loadUserWithToken(token)
          await navigate(WORK_ASSIGNMENTS_URL)
        })()
      }
    }
  }, [location, GATSBY_DEV])

  return (
    <Box
      sx={{
        backgroundColor: isLarge ? grey[50] : "#fff",
        height: windowHeight,
      }}
    >
      <Container sx={isLarge ? { width: 450 } : {}}>
        <Box sx={isLarge ? { p: 10 } : { pt: 2 }} />
        <Paper elevation={isLarge ? 2 : 0} variant="elevation" sx={{ p: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              color: "primary.main",
              fontWeight: 600,
            }}
          >
            RLI Workflow{GATSBY_DEV && " - DEV"}
          </Typography>
          <Maintenance asButton={false}>
            <Box>
              {loading && (
                <Grid
                  container
                  spacing={3}
                  sx={{
                    minHeight: 240,
                    pt: 10,
                    pb: 10,
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      <Grid item>
                        <CircularProgress size="1rem" />
                      </Grid>
                      <Grid item>Logging in...</Grid>
                      <Grid item xs={12} />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {GATSBY_GOOGLE_AUTH_TOKEN !== undefined && !loading && (
                <>
                  {error && (
                    <Grid item xs={12}>
                      <ErrorMessage error={errorMessage} />
                    </Grid>
                  )}
                  <Grid
                    container
                    justifyContent="center"
                    spacing={3}
                    sx={{
                      minHeight: 200,
                      pt: 10,
                      pb: 10,
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <GoogleLogin
                            onSuccess={responseGoogleSuccess}
                            onError={responseGoogleFailure}
                            size="large"
                            logo_alignment="center"
                            width="280px"
                            theme="outline"
                            text="signin"
                            shape="rectangular"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                </>
              )}
              <form method="post" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {(GATSBY_SHOW_USERNAME_PASSWORD_FORM || GATSBY_GOOGLE_AUTH_TOKEN === undefined) && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Username"
                          variant="outlined"
                          name="username"
                          value={username}
                          fullWidth
                          onChange={e => setUsername(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          type="password"
                          variant="outlined"
                          name="password"
                          value={password}
                          fullWidth
                          onChange={e => setPassword(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Button color="secondary" size="large" variant="contained" type="submit">
                          Login
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Box>
          </Maintenance>
          <Typography>
            © {data.site.siteMetadata.company} {new Date().getFullYear()}
            <Box component="small" sx={{ ml: 1 }}>
              v{data.site.siteMetadata.version}
            </Box>
          </Typography>
        </Paper>
      </Container>
    </Box>
  )
}

export default LoginPage
