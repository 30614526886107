import IUser from "./IUser"
import { IExtensionType } from "./main/IExtensionType"
import { IWorkAssignment } from "./IWorkAssignment"

export const WORK_ASSIGNMENT_EXTENSION_ENDPOINT = "work_assignment_extensions"

export interface IWorkAssignmentExtension {
  id?: number
  notes: string
  new_consultant_due_date: string | null
  new_due_date: string | null

  sign_off?: string
  sign_off_by?: IUser

  extension_type: IExtensionType | number
  work_assignment: IWorkAssignment | number

  created?: string
}
