import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import AddIcon from "@mui/icons-material/Add"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CONSULTANTS_AND_SPECIALTIES_ENDPOINT, IConsultant } from "../../../shared/models/main/IConsultant"
import { ILocation } from "../../../shared/models/ILocation"
import Filtering from "../../../shared/components/Filtering"
import { IFilter } from "../../../shared/models/IFilter"
import ViewLoading from "../../../shared/components/ViewLoading"
import { IPagedResults } from "../../../shared/models/IPagedResults"
import { IPaging } from "../../../shared/models/IPaging"
import WorkAssignmentMap from "../../../shared/components/WorkAssignmentMap"
import WorkAssignmentLocation from "../../../shared/components/WorkAssignmentLocation"
import { IWorkAssignmentConsultant, WORK_ASSIGNMENT_CONSULTANT_ENDPOINT } from "../../../shared/models/IWorkAssignmentConsultant"
import WorkAssignmentConsultants from "../../../shared/components/WorkAssignmentConsultants"
import ConsultantSpecialties from "../../../shared/components/ConsultantSpecialties"
import { IConsultantSpecialty } from "../../../shared/models/IConsultantSpecialty"
import { ISpecialty } from "../../../shared/models/ISpecialty"
import TablePaging from "../../../shared/components/TablePaging"
import ListLoading from "../../../shared/components/ListLoading"
import AddConsultantToWorkAssignment from "./AddConsultantToWorkAssignment"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { DashboardStore } from "../../../store"
import { operations } from "../../../store/settings"
import { CONSULTANT_ACTIVE_FILTER, CONSULTANT_FILTERS, FILTERS } from "../../../config/config"
import ConsultantRating from "../../../shared/components/consultants/ConsultantRating"

interface IProps {
  wa: IWorkAssignment
  onChange: (wa: IWorkAssignment) => void
}

const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_AND_SPECIALTIES_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)
const waConsultantRepository = new RestRepository<IWorkAssignmentConsultant>(WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)

const availableFilters = [...CONSULTANT_FILTERS, FILTERS.IN_TRAVEL_RADIUS] as IFilter[]

const limit = 10

/**
 * Edit the consultants on a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultants editor.
 */
const ConsultantsEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wa, onChange } = props

  const [initialLoad, setInitialLoad] = useState(false)
  const [canLoad, setCanLoad] = useState(false)
  const [filters, setFilters] = useState<IFilter[]>([])
  const [page, setPage] = useState<number>(1)
  const [offset, setOffset] = useState<number>(0)
  const [consultants, setConsultants] = useState<IPagedResults<IConsultant> | null>(null)
  const [consultant, setConsultant] = useState<IConsultant | null>(null)
  const [waConsultant, setWaConsultant] = useState<IWorkAssignmentConsultant | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectConsultant, setSelectConsultant] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)

  const [openAdd, setOpenAdd] = useState(false)

  const dispatch = useDispatch()
  const viewState = useSelector((state: DashboardStore) => state.settings.overviewView, shallowEqual)

  const handleTravelRadius = useCallback(
    (e: any) => {
      dispatch(
        operations.setOverviewView({
          ...viewState,
          showTravelRadius: e.target.checked,
        })
      )
    },
    [viewState]
  )

  const handleWaRefresh = useCallback(async () => {
    setOpenAdd(false)
    const wa1 = await waRepository.read(wa.id)
    onChange(wa1)
    setConsultants(null)
    setSelectConsultant(false)
  }, [wa])

  const handleFilter = useCallback((fs: IFilter[]) => {
    setFilters(fs)
    setPage(1)
    setOffset(0)
    setConsultants(null)
  }, [])

  const handlePaging = useCallback(async (_e: ChangeEvent<unknown> | null, p: number) => {
    setPage(p)
    setOffset((p - 1) * limit)
    setConsultants(null)
  }, [])

  const handleOpenAdd = useCallback(
    (c: IConsultant) => () => {
      setConsultant(c)
      setSelectConsultant(false)
      setOpenAdd(true)
    },
    []
  )

  const handleDelete = useCallback(async (wacId: number) => {
    try {
      setErrorMessage(null)
      setLoading(true)
      await waConsultantRepository.delete(wacId)
      const wa1 = await waRepository.read(wa.id)
      onChange(wa1)
      setConsultants(null)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage(reason.response)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [])

  const handleAction = useCallback(
    action => async (wacId: number) => {
      try {
        setErrorMessage(null)
        setLoading(true)
        await waConsultantRepository.action(wacId, action)
        const wa1 = await waRepository.read(wa.id)
        onChange(wa1)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setErrorMessage(reason.response)
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    },
    []
  )

  const count = useMemo(() => {
    return consultants?.count !== undefined ? Math.ceil(consultants.count / limit) : 0
  }, [consultants, limit])

  useEffect(() => {
    if (consultants == null && canLoad) {
      void (async () => {
        setLoading(true)
        const paging: IPaging = {
          page,
          offset,
          limit,
          filters: [
            ...filters,
            {
              field: "not_ids",
              value: wa.work_assignment_consultants.map(wac => (wac.consultant as IConsultant).id).join(","),
            },
          ],
        }
        const results = await consultantRepository.findAll(paging)
        setConsultants(results)
        setLoading(false)
      })()
    }
  }, [consultants, wa, filters, page, offset, canLoad])

  const specialtyFilter = useCallback(
    (consultantSpecialty1: IConsultantSpecialty) => {
      return (
        wa.location?.specialty !== undefined &&
        wa.location?.specialty !== null &&
        consultantSpecialty1.specialty !== null &&
        wa.location.specialty.id === (consultantSpecialty1.specialty as ISpecialty).id
      )
    },
    [wa]
  )

  const addLocationFilter = useCallback((location: ILocation) => {
    setFilters(fs => [
      ...fs,
      {
        ...FILTERS.IN_TRAVEL_RADIUS,
        value: location.id,
        display: location.name,
      },
    ])
  }, [])

  const handleAddConsultantSelect = useCallback(() => {
    setSelectConsultant(true)
    setConsultant(null)
    setOpenAdd(true)
  }, [])

  useEffect(() => {
    // Set initial location.
    if (wa.location != null && !initialLoad) {
      addLocationFilter(wa.location)
      setFilters(fs => [...fs, CONSULTANT_ACTIVE_FILTER])
      setCanLoad(true)
      setInitialLoad(true)
    }
  }, [wa.location, addLocationFilter])

  const [locationMenuAnchorEl, setLocationMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleOpenLMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setLocationMenuAnchorEl(event.currentTarget)
  }, [])

  const handleCloseLMenu = useCallback(() => setLocationMenuAnchorEl(null), [])

  const handleFilterLocation = useCallback(
    (location: ILocation) => () => {
      addLocationFilter(location)
      setLocationMenuAnchorEl(null)
      setPage(1)
      setOffset(0)
      setConsultants(null)
    },
    []
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Grid container sx={{ mt: 1 }} alignItems="center">
          <Grid item>
            <IconButton color="primary" onClick={handleAddConsultantSelect}>
              <PersonAddIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Filtering availableFilters={availableFilters} filters={filters} onFilter={handleFilter} disabled={loading} />
          </Grid>
          <Grid item>
            <ViewLoading loading={loading} />
          </Grid>
        </Grid>
        <Box>
          <List>
            <ListLoading loading={loading} rows={limit} />
            {!loading && consultants?.count === 0 && (
              <ListItem>
                <Alert severity="info" sx={{ width: "100%" }}>
                  No consultants found.
                </Alert>
              </ListItem>
            )}
            {!loading &&
              consultants?.results.map(c => (
                <React.Fragment key={c.id}>
                  <ListItem
                    disablePadding
                    secondaryAction={
                      <IconButton edge="end" sx={{ mr: 1 }} onClick={handleOpenAdd(c)}>
                        <AddIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton dense selected={c.id === consultant?.id} onClick={() => setConsultant(c)}>
                      <ListItemText
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <>
                            {c.name} |{" "}
                            <small>
                              {c.address}, {c.city}, {c.state_region} {c.country}
                            </small>
                          </>
                        }
                        secondary={
                          <Box sx={{ mt: 1 }}>
                            <Box sx={{ pr: 1 }} component="span">
                              <ConsultantRating rating={c.rating} />
                            </Box>
                            <ConsultantSpecialties size="small" specialties={c?.specialties?.filter(specialtyFilter)} />
                          </Box>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
          </List>
        </Box>

        <TablePaging count={count} total={consultants?.count} page={page} onPaging={handlePaging} />
        <AddConsultantToWorkAssignment
          wa={wa}
          consultant={consultant}
          onAdd={handleWaRefresh}
          selectConsultant={selectConsultant}
          onCancel={() => setOpenAdd(false)}
          open={openAdd}
        />
      </Grid>
      <Grid item xs={7}>
        <ErrorMessage error={errorMessage} />
        <WorkAssignmentConsultants
          wa={wa}
          waConsultant={waConsultant}
          onDelete={handleDelete}
          onAccept={handleAction("accept")}
          onDecline={handleAction("decline")}
          onClear={handleAction("clear")}
          onChange={handleWaRefresh}
          onSelect={setWaConsultant}
        />

        {wa.location !== null && (
          <>
            <WorkAssignmentLocation
              wa={wa}
              menu={(location: ILocation) => (
                <>
                  <IconButton onClick={handleOpenLMenu}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu anchorEl={locationMenuAnchorEl} open={Boolean(locationMenuAnchorEl)} onClose={handleCloseLMenu}>
                    <MenuItem onClick={handleFilterLocation(location)}>Filter by {location.name}</MenuItem>
                  </Menu>
                </>
              )}
            />
            <WorkAssignmentMap
              location={wa.location}
              centerLocation={wa.location}
              consultant={consultant}
              waConsultants={wa.work_assignment_consultants}
              consultants={consultants?.results}
              zoom={2}
              useZoom
              height="500px"
              showTravelRadius={viewState?.showTravelRadius}
              onConsultantSelect={setConsultant}
            />
          </>
        )}
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={viewState?.showTravelRadius} onChange={handleTravelRadius} />}
              label="Show Travel Radius"
            />
          </FormGroup>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ConsultantsEditor
