import { axiosInstance } from "../utilities/request_utility";

/**
 * Get Power BI report
 *
 * @returns {Promise<any>} the site stats.
 */
export const powerBiReport = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/reports/power_bi_report/`)
  return data
}
