import * as React from "react"
import FormatDate from "../../../shared/components/format/FormatDate"
import { IPayPeriod } from "../../../shared/models/main/IPayPeriod"

interface IProps {
  adpPayPeriod: IPayPeriod | null
}


/**
 * Displays the ADP pay period on the UI.
 *
 * @param {object} props - The component props.
 * @param {object} props.adpPayPeriod - The ADP pay period details.
 * @returns {React.ReactElement} - The rendered React component.
 */
const AdpPayPeriodDisplay: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { adpPayPeriod } = props

  return adpPayPeriod !== null ? (
    <>
      <FormatDate value={adpPayPeriod.start_date} /> <em>to</em> <FormatDate value={adpPayPeriod.expense_report_due_date} />
    </>
  ) : (
    <>Not Set</>
  )
}

export default AdpPayPeriodDisplay
