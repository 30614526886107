import * as React from "react"
import { useCallback } from "react"
import { Alert, Box, Divider, Grid, Table, TableBody, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import TablePaging from "../../shared/components/TablePaging"
import { CONTACTS_VIEW_URL } from "../../config/urls"
import { navigate } from "gatsby"
import { styles } from "../../shared/styling/general"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { RestRepository } from "../../shared/repositories/RestRepository"
import TableOrdering from "../../shared/components/TableOrdering"
import { type IFilter } from "../../shared/models/IFilter"
import Filtering from "../../shared/components/Filtering"
import { FILTERS } from "../../config/config"
import { CONTACT_ENDPOINT, getContactPhone, type IContact } from "../../shared/models/IContact"
import Goto from "../../shared/components/Goto"
import TableColumnShow, { useTableColumnShow } from "../../shared/components/TableColumnShow"
import TableCellShow from "../../shared/components/TableCellShow"
import { type IColumn } from "../../shared/models/component/IColumn"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useContentHeight from "../../shared/hooks/useContentHeight"
import { type IListItem } from "../../shared/models/component/IListItem"
import ContactVerified from "../../shared/components/ContactVerified"
import useFocus from "../../shared/hooks/useFocus"
import ExcelDownloadButton from "../../shared/components/ExcelDownloadButton"
import { ACCESS_EXCEL_DOWNLOAD_GROUP } from "../../config/permissions"
import PrivateComponent from "../../shared/components/PrivateComponent"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"
import TableLoadingFull from "../../shared/components/TableLoadingFull"

const repository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)

const availableFilters = [FILTERS.CUSTOMERS] as IFilter[]

const columns = [
  {
    field: "first_name",
    title: "First Name",
  },
  {
    field: "last_name",
    title: "Last Name",
  },
  {
    field: "business_name",
    title: "Business Name",
  },
  {
    field: "verified",
    title: "Verified",
  },
  {
    field: "phone",
    title: "Phone",
  },
  {
    field: "email",
    title: "Email",
  },
] as IColumn[]

/**
 * Index page for displaying and filtering contacts.
 * @returns {React.FC} the index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { focused, handleFocus, handleBlur } = useFocus()
  const { selectedColumns } = useTableColumnShow({ columns })
  const height = useContentHeight(-14)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const props: IUseApiPagedResultsProps<IContact | IListItem> = { apiFunction: repository.findAll }
  const { data, count, paging, error, loading, handlePaging, handleOrdering, handleFilter, handleLimit } = useApiPaged<
    IContact | IListItem
  >(props)

  const handleSelected = useCallback(async (consultant: IContact) => {
    await navigate(`${CONTACTS_VIEW_URL}/${consultant.id}`)
  }, [])

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={focused ? 6 : 3} md={focused ? 4 : 2} lg={focused ? 3 : 1}>
            <Goto repository={repository} onFocus={handleFocus} onBlur={handleBlur} url={CONTACTS_VIEW_URL} />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Filtering availableFilters={availableFilters} filters={paging?.filters} onFilter={handleFilter} disabled={loading} />
          </Grid>
          <Grid item>
            <PrivateComponent
              component={<ExcelDownloadButton endpoint={CONTACT_ENDPOINT} filename="contacts.xlsx" limit={data?.count} paging={paging} />}
              groupNames={[ACCESS_EXCEL_DOWNLOAD_GROUP]}
            />
          </Grid>
          <Grid item>
            <TableColumnShow columns={columns} />
          </Grid>
          <Grid item>
            <HelpDocsDrawer showButton showButtonLabel={false} pageId={237} />
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCellShow columns={selectedColumns} field="first_name">
                  <TableOrdering ordering={paging?.ordering} field="first_name" title="First Name" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="last_name">
                  <TableOrdering ordering={paging?.ordering} field="last_name" title="Last Name" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="business_name">
                  <TableOrdering ordering={paging?.ordering} field="business_name" title="Business Name" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="verified">
                  <TableOrdering ordering={paging?.ordering} field="verified" title="Verified" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="phone">
                  Phone
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="email">
                  <TableOrdering ordering={paging?.ordering} field="email" title="Email" onOrdering={handleOrdering} />
                </TableCellShow>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                (data?.results as IContact[])?.map(contact => (
                  <TableRowSelect key={contact.id} item={contact} onSelected={handleSelected}>
                    <TableCellShow columns={selectedColumns} field="first_name">
                      {contact.first_name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="last_name">
                      {contact.last_name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="business_name">
                      {contact.business_name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="verified">
                      <ContactVerified contact={contact} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="phone">
                      {getContactPhone(contact)}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="email">
                      {contact.email}
                    </TableCellShow>
                  </TableRowSelect>
                ))}
            </TableBody>
          </Table>
          {!loading && (data === undefined || data.results.length === 0) && (
            <Alert color="info" sx={{ m: 1 }}>
              No contacts found.
            </Alert>
          )}
          <TableLoadingFull loading={loading} rows={paging?.limit} />
        </TableContainer>
      </Box>
      <Divider />
      <TablePaging
        count={count}
        total={data?.count}
        page={paging?.page}
        limit={paging?.limit}
        size={isSmall ? "small" : "medium"}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default IndexPage
