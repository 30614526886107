import { Box, Grid, IconButton, Pagination, TextField } from "@mui/material"
import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight"

interface IProps {
  count: number
  page?: number
  total?: number
  limit?: number
  onPaging: (_e: ChangeEvent<unknown> | null, page: number) => void
  onLimit?: (limit: number) => void
  size?: "small" | "medium" | "large"
}

const numFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

/**
 * A general purpose component for paging data.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the table paging component
 */
const TablePaging: React.FunctionComponent<IProps> = (props: IProps) => {
  const { count, page, total, limit, onPaging, size = "medium", onLimit } = props
  const [localPage, setLocalPage] = useState<number | undefined>(page)
  const [localLimit, setLocalLimit] = useState<number | undefined>(limit)

  useEffect(() => {
    if (page === undefined) {
      // setting page to 1 if undefined.
      onPaging({} as any, 1)
    } else {
      setLocalPage(page)
    }
  }, [page])
  useEffect(() => setLocalLimit(limit), [limit])

  const handleLimit = useCallback(() => {
    if (onLimit !== undefined && localLimit !== undefined) {
      onLimit(localLimit)
    }
  }, [localLimit])

  const handleLimitChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = Number(e.target.value)
    if (newLimit === 0) {
      setLocalLimit(undefined)
    } else {
      if (newLimit > 200) {
        setLocalLimit(200)
      } else {
        setLocalLimit(newLimit)
      }
    }
  }, [])

  const handlePagingChange = useCallback(() => {
    if (localPage !== undefined) {
      onPaging(null, localPage)
    }
  }, [onPaging, localPage])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={size === "small"}>
        <Box sx={{ p: 2 }}>
          <Pagination
            count={count}
            page={page === undefined ? 0 : page}
            siblingCount={size === "small" ? 0 : undefined}
            boundaryCount={size === "small" ? 1 : undefined}
            onChange={onPaging}
            size={size}
          />
        </Box>
      </Grid>
      {size !== "small" && (
        <>
          <Grid item>
            <TextField
              label="Page"
              value={localPage}
              size="small"
              sx={{ width: 100 }}
              onChange={e => setLocalPage(Number(e.target.value))}
            />
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={handlePagingChange}>
              <ArrowCircleRightIcon />
            </IconButton>
          </Grid>
          {onLimit !== undefined ? (
            <>
              <Grid item>
                <TextField label="Limit" value={localLimit} size="small" sx={{ width: 100 }} onChange={handleLimitChange} />
              </Grid>
              <Grid item xs>
                <IconButton size="small" onClick={handleLimit}>
                  <ArrowCircleRightIcon />
                </IconButton>
              </Grid>
            </>
          ) : (
            <Grid item xs />
          )}
        </>
      )}
      <Grid item>
        <Box
          sx={{
            p: 2,
            mr: 1,
          }}
        >
          {total !== undefined && (
            <>
              {size !== "small" && <>Results: </>}
              {total.toLocaleString(undefined, numFormatOptions)}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default TablePaging
