import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Due To Client In 7 Days or Less"
const description = "Work assignments due to the client in 7 days or less."
const baseFilter: IFilter = {
  field: "due_to_client_in_7_days_or_less",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "due_to_client_in_7_days_or_less.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * Work assignments due to the client in 7 days or less.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const DueToClientInSevenDaysOrLessReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default DueToClientInSevenDaysOrLessReport
