import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Control } from "react-hook-form/dist/types"
import { Alert, Box, Button, Grid, IconButton } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import EqualsIcon from "@mui/icons-material/DoubleArrow"
import { UseFormClearErrors, UseFormGetValues, UseFormSetValue } from "react-hook-form/dist/types/form"

import { ILocation, IMapPosition, LOCATION_ENDPOINT } from "../../../shared/models/ILocation"
import LocationMap from "../../../shared/components/LocationMap"
import { IMapLocation } from "../../../shared/models/component/IMapLocation"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CONTACT_ENDPOINT, IContact } from "../../../shared/models/IContact"
import { ACCOUNT_ENDPOINT, IAccount } from "../../../shared/models/IAccount"
import SelectFilteredMultiple from "../../../shared/components/SelectFilteredMultiple"
import { GpsFixed as GpsFixedIcon, Restore as RestoreIcon } from "@mui/icons-material"
import { CONNECTION_ERROR, IConnectionError, isConnectionError } from "../../../shared/models/IConnectionError"
import { ISpecialty, SPECIALTY_ENDPOINT } from "../../../shared/models/ISpecialty"
import useFormLocateAddress from "../../../shared/hooks/useFormLocateAddress"
import { IListItem } from "../../../shared/models/component/IListItem"
import AddContact from "../../../shared/components/AddContact"
import FormatNumberAndError from "../../../shared/components/format/FormatNumberAndError"
import FhMuiIdField from "../../../shared/components/form/FhMuiIdField"
import FhMuiTextField from "../../../shared/components/form/FhMuiTextField"
import { requiredRule } from "../../../shared/utilities/form_utility"
import FhMuiMdField from "../../../shared/components/form/FhMuiMdField"
import FormatAreaAndError from "../../../shared/components/format/FormatAreaAndError"
import FhMuiSelectField from "../../../shared/components/form/FhMuiSelectField"
import { countriesList, DEFAULT_COUNTRY_CODE } from "../../../config/countries"
import { IOccupancy, OCCUPANCY_ENDPOINT } from "../../../shared/models/IOccupancy"
import { IPaging } from "../../../shared/models/IPaging"
import { IPagedResults } from "../../../shared/models/IPagedResults"
import SimilarLocations from "./SimilarLocations"
import ViewLoading from "../../../shared/components/ViewLoading"
import ViewError from "../../../shared/components/ViewError"
import AddContactCheckMessage from "../../../shared/components/AddContactCheckMessage"

const contactRepository = new RestRepository<IListItem>(CONTACT_ENDPOINT)
const accountRepository = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)
const specialtyRepository = new RestRepository<ISpecialty | IListItem>(SPECIALTY_ENDPOINT)
const occupancyRepository = new RestRepository<IListItem>(OCCUPANCY_ENDPOINT)
const locationRepository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

const COUNTRIES_LIST = countriesList()

interface IProps {
  control: Control<any>
  location?: ILocation | null
  isEdit?: boolean
  setValue: UseFormSetValue<any>
  getValues: UseFormGetValues<any>
  clearErrors: UseFormClearErrors<any>
  minimal?: boolean
  parentAccount?: IAccount | null | undefined
  onLocationSelected?: (location: ILocation) => void
}

/**
 * This is a general purpose for form adding and editing locations.
 *
 * @param {IProps} props See IProps or details.
 * @returns {React.FunctionComponent} the location form
 */
const LocationForm: React.FunctionComponent<IProps> = (props: IProps) => {
  const { location, isEdit = false, control, setValue, getValues, clearErrors, minimal = false, parentAccount, onLocationSelected } = props
  const [hasData, setHasData] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocation | null>(null)
  const { locateAddress } = useFormLocateAddress({ getValues })
  const [contacts, setContacts] = useState<IListItem[]>([])
  const [specialty, setSpecialty] = useState<ISpecialty | null>(null)
  const [occupancy, setOccupancy] = useState<IOccupancy | null>(null)

  const [showTivToMil, setShowTivToMil] = useState(false)

  const [similarLocationsOpen, setSimilarLocationsOpen] = useState(false)
  const [similarLocations, setSimilarLocations] = useState<IPagedResults<ILocation> | null>(null)
  const [mapPosition, setMapPosition] = useState<IMapPosition | null>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<IConnectionError | undefined>()

  const handleContacts = useCallback((contacts1?: IListItem[]) => {
    clearErrors("contacts")
    if (contacts1 !== undefined) {
      setValue(
        "contacts",
        contacts1.map(c => c.id)
      )
      setContacts(contacts1)
    }
  }, [])

  const handleAddContact = useCallback((contact1: IContact) => {
    clearErrors("contacts")
    setContacts(contacts => {
      const cs2 = [...contacts, contact1]
      setValue(
        "contacts",
        cs2.map(c => c.id)
      )
      return cs2
    })
  }, [])

  const handleSpecialty = useCallback((specialty1?: IListItem | null) => {
    setValue("specialty", specialty1 === null || specialty1 === undefined ? null : specialty1.id)
    setSpecialty(specialty1 !== undefined ? (specialty1 as ISpecialty) : null)
  }, [])

  const handleOccupancy = useCallback((occupancy1?: IListItem | null) => {
    setValue("occupancy", occupancy1 === null || occupancy1 === undefined ? null : occupancy1.id)
    setOccupancy(occupancy1 !== undefined ? (occupancy1 as IOccupancy) : null)
  }, [])

  const handleAccounts = useCallback((accounts: IListItem[]) => {
    setValue(
      "accounts",
      accounts.map(a => a.id)
    )
  }, [])

  const handleAliases = useCallback((aliases: IListItem[]) => {
    setValue(
      "aliases",
      aliases.map(alias => alias.id)
    )
  }, [])

  const handlePositionChange = useCallback((mapPosition: IMapPosition) => {
    setValue("map_position", mapPosition)
    setMapPosition(mapPosition)
  }, [])

  const updateMapLocation = useCallback((location: IMapLocation | ILocation) => {
    const { longitude, latitude } = location
    clearErrors(["longitude", "latitude"])
    setValue("longitude", longitude)
    setValue("latitude", latitude)
    setMapLocation(location)
  }, [])

  const handleLocateAddress = useCallback(async () => {
    setLoading(true)
    setLoadingError(undefined)
    try {
      const location = await locateAddress()
      if (!isConnectionError(location)) {
        updateMapLocation(location)
        const longitude = getValues("longitude") as string
        const latitude = getValues("latitude") as string
        const paging: IPaging = {
          limit: 100,
          filters: [
            {
              field: "close_by",
              value: `${longitude},${latitude}`,
            },
          ],
        }
        const results = await locationRepository.findAll(paging)
        setSimilarLocations(results)
        setSimilarLocationsOpen(true)
      }
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setLoadingError(reason.response)
      } else {
        setLoadingError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [updateMapLocation])

  const handleTiv = useCallback(() => {
    const propertyDamage = Number(getValues("property_damage"))
    const businessInterruption = Number(getValues("business_interruption"))
    clearErrors("total_insured_value")
    setValue("total_insured_value", propertyDamage + businessInterruption)
  }, [])

  const handleTivChange = useCallback(() => {
    const tiv = Number(getValues("total_insured_value"))
    setShowTivToMil(tiv < 10000)
  }, [])

  const handleMultiplyValue = useCallback(
    (name: string) => () => {
      const value = Number(getValues(name))
      setValue(name, Math.round(value * 1_000_000))
      handleTivChange()
    },
    []
  )

  const handleUpdateMap = useCallback(() => {
    const longitude = Number(getValues("longitude"))
    const latitude = Number(getValues("latitude"))
    setMapLocation({
      longitude,
      latitude,
    })
    setValue("map_position", null)
    setMapPosition(null)
  }, [getValues, setMapLocation, setMapPosition])

  const aliases = useMemo(() => {
    return location?.aliases?.map(alias => ({
      id: alias,
      name: alias,
    }))
  }, [location])

  const hasMapPositionChanged = useMemo(() => {
    return JSON.stringify(mapPosition) !== JSON.stringify(location?.map_position)
  }, [location, mapPosition])

  useEffect(() => {
    if (location !== undefined && location !== null && isEdit && !hasData) {
      setHasData(true)
      for (const key of Object.keys(location)) {
        const value: any = (location as any)[key]
        if (value !== undefined) {
          setValue(key, value)
        }
      }
      handleContacts(location.contacts)
      handleSpecialty(location.specialty)
      handleOccupancy(location.occupancy)
      handleAccounts(location.accounts as IAccount[])
      updateMapLocation(location)
    }
    if (location !== undefined && location !== null && !hasData) {
      setHasData(true)
      handleAccounts(location.accounts as IAccount[])
    }
  }, [location, hasData, setValue, isEdit])

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={!minimal && 6}>
        <Grid item xs={12}>
          {isEdit && <FhMuiIdField control={control} />}
          <ViewLoading loading={loading} />
          <ViewError error={loadingError} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FhMuiTextField control={control} label="Name" name="name" />
          </Grid>
          {!minimal && (
            <>
              <Grid item xs={4}>
                <SelectFilteredMultiple name="aliases" label="Aliases" defaultValue={aliases} onChange={handleAliases} />
              </Grid>
              <Grid item xs={4}>
                <SelectFilteredSingle
                  name="specialty"
                  label="Specialty"
                  defaultValue={specialty as IListItem}
                  repository={specialtyRepository}
                  onChange={handleSpecialty}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectFilteredSingle
                  name="occupancy"
                  label="Occupancy"
                  defaultValue={occupancy as IListItem}
                  repository={occupancyRepository}
                  onChange={handleOccupancy}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <SelectFilteredMultiple
                  name="contacts"
                  label="Contacts"
                  defaultValue={contacts}
                  repository={contactRepository}
                  onChange={handleContacts}
                />
                <AddContactCheckMessage />
              </Grid>
              <Grid item>
                <Box sx={{ mt: -2 }}>
                  <AddContact onAddContact={handleAddContact} />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {!minimal && (
            <>
              <Grid item xs={12}>
                <SelectFilteredMultiple
                  name="accounts"
                  label="Accounts"
                  defaultValue={location?.accounts as IAccount[]}
                  repository={accountRepository}
                  onChange={handleAccounts}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={5} md={!minimal && 3} lg={3}>
                <FhMuiTextField
                  control={control}
                  type="number"
                  defaultValue={0}
                  label="Property Damage"
                  name="property_damage"
                  formatter={FormatNumberAndError}
                />
              </Grid>
              <Grid item xs={2} md={!minimal && 1} lg={1} sx={{ textAlign: "center" }}>
                <AddIcon sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={5} md={!minimal && 3} lg={3}>
                <FhMuiTextField
                  control={control}
                  type="number"
                  defaultValue={0}
                  label="Business Interruption"
                  name="business_interruption"
                  formatter={FormatNumberAndError}
                />
              </Grid>
              <Grid item xs={2} md={!minimal && 1} lg={1} sx={{ textAlign: "center" }}>
                <IconButton color="primary" component="span" onClick={handleTiv} sx={{ mb: 2 }}>
                  <EqualsIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} md={!minimal && 3} lg={3}>
                <FhMuiTextField
                  control={control}
                  type="number"
                  defaultValue={0}
                  label="Total Insurable Value"
                  name="total_insured_value"
                  rules={requiredRule()}
                  onChange={handleTivChange}
                  formatter={FormatNumberAndError}
                />
                {showTivToMil && (
                  <Button size="small" onClick={handleMultiplyValue("total_insured_value")}>
                    x by 1 mil
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          {!minimal && (
            <>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <FhMuiTextField control={control} type="number" label="Area" name="area" formatter={FormatAreaAndError} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FhMuiMdField control={control} label="Notes" name="notes" />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={!minimal && 6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <FhMuiTextField control={control} label="Address" name="address" rules={requiredRule()} />
          </Grid>
          <Grid item xs={12}>
            <FhMuiTextField control={control} label="Address 2" name="address_2" />
          </Grid>
          <Grid item xs={8} md={5}>
            <FhMuiTextField control={control} label="City" name="city" rules={requiredRule()} />
          </Grid>
          <Grid item xs={4} md={2}>
            <FhMuiTextField control={control} label="State/Region" name="state_region" />
          </Grid>
          <Grid item xs={6} md={2}>
            <FhMuiSelectField control={control} items={COUNTRIES_LIST} defaultValue={DEFAULT_COUNTRY_CODE} label="Country" name="country" />
          </Grid>
          <Grid item xs={6} md={3}>
            <FhMuiTextField control={control} label="Postal Code" name="postal_code" />
          </Grid>
          <Grid item xs={5} md>
            <FhMuiTextField control={control} label="Longitude" name="longitude" type="number" rules={requiredRule()} />
          </Grid>
          <Grid item xs={5} md>
            <FhMuiTextField control={control} label="Latitude" name="latitude" type="number" rules={requiredRule()} />
          </Grid>
          <Grid item xs={2} md={undefined}>
            <IconButton color="primary" component="span" onClick={handleLocateAddress}>
              <GpsFixedIcon />
            </IconButton>
            <SimilarLocations
              locations={similarLocations}
              mapLocation={mapLocation}
              open={similarLocationsOpen}
              onClose={() => setSimilarLocationsOpen(false)}
              parentAccount={parentAccount}
              onLocationSelected={onLocationSelected}
            />
          </Grid>
          {!minimal && (
            <>
              <Grid item>
                <IconButton color="primary" component="span" onClick={handleUpdateMap}>
                  <RestoreIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {hasMapPositionChanged && (
                  <Box
                    sx={{
                      position: "absolute",
                      mt: 0.8,
                      ml: 24,
                      zIndex: 100,
                    }}
                  >
                    <Alert severity="warning" variant="filled">
                      Position and zoom changed.
                    </Alert>
                  </Box>
                )}
                <LocationMap
                  primaryLocation={mapLocation}
                  initialMapPosition={location?.map_position}
                  onPositionChange={handlePositionChange}
                  showCenter
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LocationForm
