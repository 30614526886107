import * as React from "react"
import { useCallback, useState } from "react"
import { Box, IconButton, List, ListItem, ListItemText, ListSubheader, Popover } from "@mui/material"
import InfoRoundedIcon from "@mui/icons-material/InfoOutlined"

interface IKeyEntry {
  color: string
  description: string
}

interface IProps {
  entries: IKeyEntry[]
}

/**
 * Displays a highlighted key that might be used on a map, table or chart.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the highlight key.
 */
const HighlightedKey: React.FunctionComponent<IProps> = (props: IProps) => {
  const { entries } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton onClick={handleClick}>
        <InfoRoundedIcon />
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box
          sx={{
            p: 2,
            width: 400,
          }}
        >
          <List subheader={<ListSubheader component="div">Highlighted Key</ListSubheader>}>
            {entries.map(({ color, description }, index) => (
              <ListItem key={index} sx={{ background: color }}>
                <ListItemText primary={description} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  )
}

export default HighlightedKey
