import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { CONTACT_ENDPOINT, IContact } from "../../shared/models/IContact"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import SelectFilteredMultiple from "../../shared/components/SelectFilteredMultiple"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../shared/models/ICustomer"
import { ACCOUNT_ENDPOINT, IAccount } from "../../shared/models/IAccount"
import { ILocation, LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import ContactForm from "./components/ContactForm"
import FhMuiMdField from "../../shared/components/form/FhMuiMdField"
import useApiAdd, { IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { IListItem } from "../../shared/models/component/IListItem"

const contactRepository = new RestRepository<IContact>(CONTACT_ENDPOINT)
const customerRepository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)
const accountsRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const locationsRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)

/**
 * This page will add a contact.
 *
 * @returns {React.FunctionComponent} the contact add page.
 */
const AddPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setError, formState, setValue } = useForm()
  const [initial, setInitial] = useState(false)

  const props: IUseApiAddProps<IContact> = {
    apiFunction: contactRepository.add,
    setError,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<IContact>(props)

  // noinspection DuplicatedCode
  const handleCustomers = useCallback((customers: IListItem[]) => {
    setValue(
      "customers",
      customers.map(c => c.id)
    )
  }, [])

  const handleAccounts = useCallback((accounts: IListItem[]) => {
    setValue(
      "accounts",
      accounts.map(a => a.id)
    )
  }, [])

  const handleLocations = useCallback((locations: IListItem[]) => {
    setValue(
      "locations",
      locations.map(l => l.id)
    )
  }, [])

  useEffect(() => {
    if (!initial) {
      setValue("customers", [])
      setValue("accounts", [])
      setValue("locations", [])
      setInitial(true)
    }
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Add Contact" loading={saving} errors={formState.errors} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleAdd as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={5}>
              <ContactForm control={control} />
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiMdField control={control} label="Notes" name="notes" />
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredMultiple name="customers" label="Customers" repository={customerRepository} onChange={handleCustomers} />
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredMultiple name="accounts" label="Accounts" repository={accountsRepository} onChange={handleAccounts} />
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredMultiple name="locations" label="Locations" repository={locationsRepository} onChange={handleLocations} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
