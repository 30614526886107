import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { Box, Grid, TableContainer, Typography } from "@mui/material"
import ExcelDownloadButton from "../../../shared/components/ExcelDownloadButton"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import TableColumnShow from "../../../shared/components/TableColumnShow"
import WorkAssignmentsTable, { workAssignmentColumns } from "../../../shared/components/WorkAssignmentsTable"
import { WORK_ASSIGNMENTS_URL } from "../../../config/urls"
import TablePaging from "../../../shared/components/TablePaging"
import { IPaging } from "../../../shared/models/IPaging"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IFilter } from "../../../shared/models/IFilter"
import { IConsultant } from "../../../shared/models/main/IConsultant"
import { IPagedResults } from "../../../shared/models/IPagedResults"
import { ACCESS_EXCEL_DOWNLOAD_GROUP } from "../../../config/permissions"
import PrivateComponent from "../../../shared/components/PrivateComponent"
import { BASE_URL } from "../../../shared/utilities/request_utility"
import CopyWorkAssignmentsToClipboard from "../../work_assignments/components/CopyWorkAssignmentsToClipboard"

const workAssignmentRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

const limit = 10

interface IProps {
  consultant: IConsultant
  page: number
  setPage: (page: number) => void
}

/**
 * Displays the currently active work assignments for a consultant.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} a panel for the active work assignments for a consultant.
 */
const ConsultantWorkAssignmentsPanel: React.FunctionComponent<IProps> = (props: IProps) => {
  const { consultant, page, setPage } = props
  const [activeWorkAssignmentsLoading, setActiveWorkAssignmentsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [activeWorkAssignments, setActiveWorkAssignments] = useState<IPagedResults<IWorkAssignment> | null>(null)

  const filters = useMemo(() => {
    if (consultant?.id === undefined) {
      return []
    }
    return [
      {
        field: "consultant_was",
        value: consultant.id,
      },
      {
        field: "columns",
        value: "consultant",
      },
    ] as IFilter[]
  }, [consultant?.id])

  const handlePaging = useCallback((_e: ChangeEvent<unknown> | null, page1: number) => {
    setPage(page1)
    setActiveWorkAssignments(null)
  }, [])

  const loadWorkAssignments = useCallback(async () => {
    try {
      setErrorMessage(null)
      setActiveWorkAssignmentsLoading(true)
      const paging: IPaging = {
        filters,
        limit,
        offset: limit * (page - 1),
      }
      const results = await workAssignmentRepository.findAll(paging)
      setActiveWorkAssignments(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage(reason.response)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
    setActiveWorkAssignmentsLoading(false)
  }, [filters, page])

  useEffect(() => {
    if (activeWorkAssignments === null) {
      void (async () => {
        await loadWorkAssignments()
      })()
    }
  }, [activeWorkAssignments, loadWorkAssignments])

  return (
    <>
      <ErrorMessage error={errorMessage} />
      {(activeWorkAssignments !== null && activeWorkAssignments.count > 0) || activeWorkAssignmentsLoading ? (
        <>
          <Grid container sx={{ mt: 1 }} spacing={1} alignItems="center">
            <Grid item xs>
              <Typography variant="h6">Open Work Assignments: {activeWorkAssignments?.count}</Typography>
            </Grid>
            <Grid item>
              <PrivateComponent
                component={
                  <ExcelDownloadButton
                    endpoint={WORK_ASSIGNMENT_ENDPOINT}
                    asyncEndpoint={`${BASE_URL}/lookups/work_assignments_async_file`}
                    filename={`${consultant.first_name.toLowerCase()}_${consultant.last_name.toLowerCase()}_open_work_assignments.xlsx`}
                    limit={activeWorkAssignments !== null ? activeWorkAssignments.count : 0}
                    paging={{ filters }}
                  />
                }
                groupNames={[ACCESS_EXCEL_DOWNLOAD_GROUP]}
              />
            </Grid>
            {activeWorkAssignments?.results !== undefined && (
              <Grid item>
                <CopyWorkAssignmentsToClipboard workAssignments={activeWorkAssignments.results} />
              </Grid>
            )}
            <Grid item>
              <TableColumnShow columns={workAssignmentColumns} pathname={WORK_ASSIGNMENTS_URL} />
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <TableContainer>
              <WorkAssignmentsTable workAssignments={activeWorkAssignments?.results} loading={activeWorkAssignmentsLoading} />
            </TableContainer>
          </Box>
          {activeWorkAssignments !== null && (
            <TablePaging
              count={Math.ceil(activeWorkAssignments.count / limit)}
              total={activeWorkAssignments.count}
              page={page}
              onPaging={handlePaging}
            />
          )}
        </>
      ) : (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs>
            <Typography variant="h6">No open work assignments.</Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ConsultantWorkAssignmentsPanel
