import * as React from "react"
import { useCallback } from "react"
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { CUSTOMER_ENDPOINT, customerTypeName, type ICustomer } from "../../shared/models/ICustomer"
import TablePaging from "../../shared/components/TablePaging"
import { Link } from "@reach/router"
import { CUSTOMERS_ADD_URL, CUSTOMERS_VIEW_URL } from "../../config/urls"
import { navigate } from "gatsby"
import Moment from "react-moment"
import { CUSTOMER_FILTERS, DATE_FORMAT } from "../../config/config"
import { styles } from "../../shared/styling/general"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { RestRepository } from "../../shared/repositories/RestRepository"
import Filtering from "../../shared/components/Filtering"
import TableOrdering from "../../shared/components/TableOrdering"
import Goto from "../../shared/components/Goto"
import TableColumnShow, { useTableColumnShow } from "../../shared/components/TableColumnShow"
import { type IColumn } from "../../shared/models/component/IColumn"
import TableCellShow from "../../shared/components/TableCellShow"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useContentHeight from "../../shared/hooks/useContentHeight"
import AddIcon from "@mui/icons-material/Add"
import useFocus from "../../shared/hooks/useFocus"
import { type IListItem } from "../../shared/models/component/IListItem"
import TableLoadingFull from "../../shared/components/TableLoadingFull"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"

const repository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)

const columns: IColumn[] = [
  {
    field: "identifier",
    title: "ID",
  },
  {
    field: "name",
    title: "Name",
  },
  {
    field: "customer_type",
    title: "Type",
  },
  {
    field: "account_count",
    title: "# Accounts",
  },
  {
    field: "since",
    title: "Since",
  },
  {
    field: "executive",
    title: "AE",
  },
  {
    field: "technical_admin",
    title: "TA",
  },
  {
    field: "notes",
    title: "Notes",
  },
]

/**
 * Index page for displaying and filtering customers.
 * @returns {React.FC} the customers index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { focused, handleFocus, handleBlur } = useFocus()
  const { selectedColumns } = useTableColumnShow({ columns })
  const height = useContentHeight(-14)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const props: IUseApiPagedResultsProps<ICustomer | IListItem> = { apiFunction: repository.findAll }
  const { data, count, paging, error, loading, handlePaging, handleFilter, handleOrdering, handleLimit, call } = useApiPaged<
    ICustomer | IListItem
  >(props)

  const handleSelected = useCallback(async (customer: ICustomer) => {
    await navigate(`${CUSTOMERS_VIEW_URL}/${customer.id}`)
  }, [])

  const handleCustomerChange = useCallback(async () => {
    call()
  }, [call])

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={focused ? 6 : 3} md={focused ? 4 : 2} lg={focused ? 3 : 1}>
            <Goto repository={repository} onFocus={handleFocus} onBlur={handleBlur} url={CUSTOMERS_VIEW_URL} />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Filtering availableFilters={CUSTOMER_FILTERS} filters={paging?.filters} onFilter={handleFilter} disabled={loading} />
          </Grid>
          <Grid item>
            <Button component={Link} to={CUSTOMERS_ADD_URL} startIcon={<AddIcon />}>
              Customer
            </Button>
          </Grid>
          <Grid item>
            <TableColumnShow columns={columns} />
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCellShow columns={selectedColumns} field="identifier">
                  <TableOrdering ordering={paging?.ordering} field="id" title="ID" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="name">
                  <TableOrdering ordering={paging?.ordering} field="name" title="Name" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="customer_type">
                  <TableOrdering ordering={paging?.ordering} field="customer_type" title="Type" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="account_count">
                  # Accounts
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="since">
                  <TableOrdering ordering={paging?.ordering} field="since" title="Since" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="executive">
                  <TableOrdering ordering={paging?.ordering} field="executive" title="AE" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="technical_admin">
                  <TableOrdering ordering={paging?.ordering} field="technical_admin" title="TA" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="notes">
                  Notes
                </TableCellShow>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                (data?.results as ICustomer[])?.map(customer => (
                  <TableRowSelect key={customer.id} item={customer} onSelected={handleSelected}>
                    <TableCellShow columns={selectedColumns} field="identifier">
                      {customer.identifier}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="name">
                      {customer.name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="customer_type">
                      {customerTypeName(customer)}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="account_count">
                      {customer.accounts_count}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="since">
                      <Moment format={DATE_FORMAT}>{customer.since}</Moment>
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="executive">
                      {customer.executive?.name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="technical_admin">
                      {customer.technical_admin?.name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="notes">
                      <ChangeHtmlField
                        modelId={customer.id}
                        item={customer}
                        field="notes"
                        title="Notes"
                        onChange={handleCustomerChange}
                        value={customer.notes}
                        repository={repository}
                      />
                    </TableCellShow>
                  </TableRowSelect>
                ))}
            </TableBody>
          </Table>
          {!loading && (data === undefined || data.results.length === 0) && (
            <Alert color="info" sx={{ m: 1 }}>
              No customers found.
            </Alert>
          )}
          <TableLoadingFull loading={loading} rows={paging?.limit} />
        </TableContainer>
      </Box>
      <Divider />
      <TablePaging
        count={count}
        total={data?.count}
        page={paging?.page}
        limit={paging?.limit}
        size={isSmall ? "small" : "medium"}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default IndexPage
