import * as React from "react"
import { useCallback } from "react"
import { Box, Button, Grid, Link, List, ListItemButton, Typography } from "@mui/material"
import { Link as RouterLink } from "@reach/router"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { useApiRead } from "../../shared/hooks/useApiRead"
import ViewLoading from "../../shared/components/ViewLoading"
import { ACCOUNTS_VIEW_URL, CONTACTS_EDIT_URL, CUSTOMERS_VIEW_URL, LOCATIONS_VIEW_URL } from "../../config/urls"
import { styles } from "../../shared/styling/general"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { CONTACT_ENDPOINT, getContactPhone, getContactPhone2, type IContact } from "../../shared/models/IContact"
import ViewProperty from "../../shared/components/ViewProperty"
import { type ICustomer } from "../../shared/models/ICustomer"
import { navigate } from "gatsby"
import { type ILocation } from "../../shared/models/ILocation"
import { type IAccount } from "../../shared/models/IAccount"
import ContactVerified from "../../shared/components/ContactVerified"

const contactRepository = new RestRepository<IContact>(CONTACT_ENDPOINT)

/**
 * This page displays a site contact and their information.
 *
 * @returns {React.FC} the contact page.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: contact, loading } = useApiRead<IContact>({ apiFunction: contactRepository.read })

  const handleCustomer = useCallback(
    (customer: ICustomer) => async () => {
      await navigate(`${CUSTOMERS_VIEW_URL}/${customer.id}`)
    },
    []
  )

  const handleAccount = useCallback(
    (account: IAccount) => async () => {
      await navigate(`${ACCOUNTS_VIEW_URL}/${account.id}`)
    },
    []
  )

  const handleLocation = useCallback(
    (location: ILocation) => async () => {
      await navigate(`${LOCATIONS_VIEW_URL}/${location.id}`)
    },
    []
  )

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading} />
        {contact !== undefined && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h3" component="h2">
                    {contact.first_name} {contact.last_name}
                  </Typography>
                  <Typography variant="subtitle1" component="h3">
                    {contact.job_title}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <ContactVerified contact={contact} />
                </Grid>
                <Grid item>
                  <Button component={RouterLink} to={`${CONTACTS_EDIT_URL}/${contact.id}`} variant="contained">
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ViewProperty label={"Business Name"}>
                    {contact.business_name !== null && contact.business_name !== "" ? contact.business_name : "Not Set"}
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={"Phone"}>
                    <Link href={`tel:${getContactPhone(contact)}`}>{getContactPhone(contact)}</Link>
                  </ViewProperty>
                  <ViewProperty label={"Phone 2"}>
                    <Link href={`tel:${getContactPhone2(contact)}`}>{getContactPhone2(contact)}</Link>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={"Email"}>
                    <Link href={`mailto:${contact.first_name} ${contact.last_name}<${contact.email}>`}>{contact.email}</Link>
                  </ViewProperty>
                  <ViewProperty label={"Email 2"}>
                    <Link href={`mailto:${contact.first_name} ${contact.last_name}<${contact.email_2}>`}>{contact.email_2}</Link>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={"Notes"} vertical>
                    <Box dangerouslySetInnerHTML={{ __html: contact.notes }}></Box>
                  </ViewProperty>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <ViewProperty label={"Customers"} vertical show={contact.customers.length > 0}>
                <List>
                  {contact.customers.map(customer => (
                    <ListItemButton key={customer.id} onClick={handleCustomer(customer)}>
                      {customer.name}
                    </ListItemButton>
                  ))}
                </List>
              </ViewProperty>
              <ViewProperty label={"Accounts"} vertical show={contact.accounts.length > 0}>
                <List>
                  {contact.accounts.map(account => (
                    <ListItemButton key={account.id} onClick={handleAccount(account)}>
                      {account.name}
                    </ListItemButton>
                  ))}
                </List>
              </ViewProperty>
              <ViewProperty label={"Locations"} vertical show={contact.locations.length > 0}>
                <List>
                  {contact.locations.map(location => (
                    <ListItemButton key={location.id} onClick={handleLocation(location)}>
                      {location.name}
                    </ListItemButton>
                  ))}
                </List>
              </ViewProperty>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
