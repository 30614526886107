import * as React from "react"
import { useCallback, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../models/IWorkAssignment"
import { RestRepository } from "../repositories/RestRepository"
import IUser, { STAFF_ENDPOINT } from "../models/IUser"
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import UserCard from "./UserCard"
import ErrorMessage from "./ErrorMessage"
import ViewLoading from "./ViewLoading"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { IListItem } from "../models/component/IListItem"
import SelectFilteredSingle from "./SelectFilteredSingle"

interface IPatch {
  assignee: number | null
}

interface IProps {
  wa: IWorkAssignment
  onChange: (wa: IWorkAssignment) => void
  asButton?: boolean
}

const userRepository = new RestRepository<IListItem>(STAFF_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment | IPatch>(WORK_ASSIGNMENT_ENDPOINT)

/**
 * This component manages assignee for the work assignment.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} The component.
 */
const AssigneeEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wa, onChange, asButton = false } = props
  const [open, setOpen] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)
  const [user, setUser] = useState<IListItem | null>(null)

  const handleOpen = useCallback(() => {
    setUser(wa.assignee as IUser)
    setOpen(true)
  }, [wa.assignee])

  const handleClose = useCallback(() => setOpen(false), [])

  /**
   * Makes call to save assignee using a patch. Handles all errors.
   */
  const handleAssign = useCallback(async () => {
    setSaving(true)
    setSavingError(null)
    try {
      const assignee = user?.id !== undefined ? Number(user?.id) : null
      const patch: IPatch = { assignee }
      await waRepository.patch(patch, wa.id)
      onChange({
        ...wa,
        assignee: user as IUser,
      })
      setOpen(false)
      setSaving(false)
    } catch (reason: any) {
      setSaving(false)
      if (reason.response !== undefined && reason.response !== null) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [wa, user])

  /**
   * Get the changes when the assignee list is updated.
   */
  const handleSelectionChange = useCallback((item?: IListItem | null) => {
    setUser(item !== undefined ? item : null)
  }, [])

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Edit Assignee</DialogTitle>
        <DialogContent>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <ErrorMessage error={savingError} />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="assignee"
                label="Assignee"
                defaultValue={user as IListItem}
                repository={userRepository}
                onChange={handleSelectionChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            sx={{
              m: 2,
              mt: 0,
            }}
          >
            <Grid item xs>
              <ViewLoading loading={saving} message="Saving..." />
            </Grid>
            <Grid item>
              <Button disabled={saving} onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={saving} onClick={handleAssign}>
                Assign
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {asButton ? (
        <>
          <Button size="small" onClick={handleOpen}>
            {wa.assignee !== null ? (wa.assignee as IUser).name : "Not Set"}
          </Button>
        </>
      ) : (
        <Grid container sx={{ mt: 2 }} alignItems="center">
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography fontWeight={600}>Assignee</Typography>
          </Grid>
          <Grid item xs>
            {wa.assignee === null ? (
              <Alert severity="warning">No Assignee selected.</Alert>
            ) : (
              <UserCard user={wa.assignee as IUser} label="Assignee" />
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={handleOpen}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AssigneeEditor
