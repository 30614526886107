import * as React from "react"
import { Box } from "@mui/material"
import { isTruncated, truncateString } from "../utilities/format_utility"
import HtmlTooltip from "./HtmlToolTip"
import { useCallback } from "react"

interface IProps {
  text?: string | null
  altText?: string
  num?: number
  middle?: boolean
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
}

/**
 * This component will truncate text and show a tooltip if needed.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The truncated text with a tooltip.
 */
const TruncateText: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { text = "", altText, placement = "right", num = 30, middle = false } = props
  const textUpdated = text ?? ""

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  return (
    <HtmlTooltip title={<Box onClick={handleClick} dangerouslySetInnerHTML={{ __html: altText ?? textUpdated }} />} placement={placement}>
      {isTruncated(textUpdated, num) || altText !== undefined ? (
        <Box component="span">{truncateString(textUpdated, num, middle)}</Box>
      ) : (
        <>{textUpdated}</>
      )}
    </HtmlTooltip>
  )
}

export default TruncateText
