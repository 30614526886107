import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material"
import { IWorkAssignmentConsultant } from "../models/IWorkAssignmentConsultant"
import { IConsultant } from "../models/main/IConsultant"
import ConsultantSpecialties from "./ConsultantSpecialties"
import { IConsultantSpecialty } from "../models/IConsultantSpecialty"
import { ISpecialty } from "../models/ISpecialty"
import { IWorkAssignment } from "../models/IWorkAssignment"
import MoreVertIcon from "@mui/icons-material/More"
import FormatNumber from "./format/FormatNumber"
import WorkAssignmentConsultantEdit from "../../pages/work_assignments/components/WorkAssignmentConsultantEdit"
import ConsultantRating from "./consultants/ConsultantRating"

interface IProps {
  wa: IWorkAssignment
  /**
   * Currently selected work assignment consultant.
   */
  waConsultant?: IWorkAssignmentConsultant | null | undefined
  onSelect: (waConsultant: IWorkAssignmentConsultant) => void
  onDelete: (id: number) => void
  onAccept: (id: number) => void
  onDecline: (id: number) => void
  onClear: (id: number) => void
  onChange: () => void
}

/**
 * This component will manage all the work assignment consultants for a work assignment.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const WorkAssignmentConsultants: React.FunctionComponent<IProps> = (props: IProps) => {
  const { waConsultant, wa, onSelect, onDelete, onAccept, onDecline, onClear, onChange } = props
  const [wac, setWac] = useState<IWorkAssignmentConsultant | null>(null)

  const [open, setOpen] = useState<boolean>(false)

  const specialtyFilter = useCallback(
    (s: IConsultantSpecialty) => {
      return wa.location?.specialty != null && s.specialty !== null && wa.location.specialty.id === (s.specialty as ISpecialty).id
    },
    [wa]
  )

  const handleOpenDialog = useCallback(
    (c: IWorkAssignmentConsultant) => (_event: React.MouseEvent<HTMLElement>) => {
      setWac(c)
      setOpen(true)
    },
    []
  )

  const handleCloseDialog = useCallback(() => setOpen(false), [])

  const handleAccept = useCallback(() => {
    if (wac !== null) {
      onAccept(wac.id)
    }
  }, [wac])

  const handleDecline = useCallback(() => {
    if (wac !== null) {
      onDecline(wac.id)
    }
  }, [wac])

  const handleClear = useCallback(() => {
    if (wac !== null) {
      onClear(wac.id)
    }
  }, [wac])

  const handleDelete = useCallback(
    wacId => () => {
      setOpen(false)
      setWac(null)
      onDelete(wacId)
    },
    []
  )

  const handleOpenConsultantEdit = useCallback(() => {
    onChange()
  }, [])

  useEffect(() => {
    const currentWac = wa.work_assignment_consultants?.filter(wac1 => wac?.id === wac1.id)
    if (currentWac.length > 0) {
      setWac(currentWac[0])
    }
  }, [wa, wac])

  return wa.work_assignment_consultants.length === 0 ? (
    <Alert severity="warning" sx={{ mb: 1 }}>
      No consultants available yet.
    </Alert>
  ) : (
    <>
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Consultant Request for {(wac?.consultant as IConsultant)?.name}</DialogTitle>
        <DialogContent>
          <Grid container sx={{ mt: 1 }} spacing={2} alignItems="center">
            <Grid item xs>
              <strong>Status</strong>
            </Grid>
            <Grid item>
              {wac?.accepted === true && (
                <Grid item>
                  <Chip color="success" label="Accepted" />
                </Grid>
              )}
              {wac?.declined === true && (
                <Grid item>
                  <Chip color="error" label="Declined" />
                </Grid>
              )}
            </Grid>
            <Grid item xs>
              <strong>Amount</strong>
            </Grid>
            <Grid item>
              {wac !== null && <WorkAssignmentConsultantEdit wac={wac} onChange={handleOpenConsultantEdit} useButtonWithAmount />}
            </Grid>
            <Grid item xs={12}>
              {(wac?.consultant as IConsultant)?.address}, {(wac?.consultant as IConsultant)?.city},
              {(wac?.consultant as IConsultant)?.state_region} {(wac?.consultant as IConsultant)?.country}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Consultant Comment
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {wac?.consultant_comment}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            {(wac?.accepted === true || wac?.declined === null) && (
              <Grid item>
                <Button onClick={handleDecline}>Decline</Button>
              </Grid>
            )}
            {(wac?.declined === true || wac?.accepted === null) && (
              <Grid item>
                <Button onClick={handleAccept}>Accept</Button>
              </Grid>
            )}
            {(wac?.accepted !== null || wac?.declined !== null) && (
              <Grid item>
                <Button onClick={handleClear}>Clear Response</Button>
              </Grid>
            )}
            <Grid item xs>
              <Button onClick={handleDelete(wac?.id)}>Remove Consultant</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCloseDialog} variant="contained">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Paper
        sx={{
          p: 1,
          mb: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            p: 2,
            pb: 0,
          }}
        >
          Consultants
        </Typography>
        <List dense>
          {wa.work_assignment_consultants.map(c => {
            const consultant = c.consultant as IConsultant
            return (
              <ListItem
                key={c.id}
                disablePadding
                secondaryAction={
                  <IconButton onClick={handleOpenDialog(c)}>
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <ListItemButton dense selected={c.id === waConsultant?.id} onClick={() => onSelect(c)}>
                  <ListItemText
                    secondaryTypographyProps={{ component: "div" }}
                    primary={
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>{consultant.name}</Grid>
                        <Grid item xs>
                          <small>
                            {consultant.address}, {consultant.city}, {consultant.state_region} {consultant.country}
                          </small>
                        </Grid>
                        {c.accepted && (
                          <Grid item>
                            <Chip sx={{ ml: 1 }} size="small" color="success" label="Accepted" />
                          </Grid>
                        )}
                        {c.declined && (
                          <Grid item>
                            <Chip sx={{ ml: 1 }} size="small" color="error" label="Declined" />
                          </Grid>
                        )}
                        <Grid item>
                          <Chip
                            sx={{
                              ml: 1,
                              mr: 1,
                            }}
                            size="small"
                            color="info"
                            label={<FormatNumber value={c.amount} suffixUnits={c.hourly_rate ? "/hr" : ""} />}
                          />
                        </Grid>
                      </Grid>
                    }
                    secondary={
                      <Box sx={{ mt: 1 }}>
                        <Box sx={{ pr: 1 }} component="span">
                          <ConsultantRating rating={consultant.rating} />
                        </Box>
                        <ConsultantSpecialties size="small" specialties={consultant.specialties.filter(specialtyFilter)} />
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Paper>
    </>
  )
}

export default WorkAssignmentConsultants
