import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import * as React from "react"
import { useCallback, useState } from "react"
import { IFile } from "../models/IFile"
import FormatDate from "./format/FormatDate"
import FileDownloadButton from "./files/FileDownloadButton"
import prettyBytes from "pretty-bytes"
import FileAddEdit from "./files/FileAddEdit"
import { RestRepository } from "../repositories/RestRepository"
import FileViewerDialog from "./files/FileViewerDialog"
import ViewLoading from "./ViewLoading"
import ErrorMessage from "./ErrorMessage"
import { IUseApiPagedResultsProps, useApiPagedLocal } from "../hooks/useApiPagedLocal"
import { IListItem } from "../models/component/IListItem"
import { useInterval } from "beautiful-react-hooks"
import HtmlToolTip from "./HtmlToolTip"
import PreviewIcon from "@mui/icons-material/Preview"
import FileDelete from "./files/FileDelete"
import TablePaging from "./TablePaging"

interface IProps {
  parentId: number
  fieldName: string
  fileRepository: RestRepository<IFile>
}

type TPagedProps = IUseApiPagedResultsProps<IFile | IListItem>

/**
 * A reusable file editor panel.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} The file editor panel.
 */
const FilesEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { fileRepository, parentId, fieldName } = props
  const [openPreview, setPreviewOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<IFile | null>(null)

  const initialFilters = [
    {
      field: fieldName,
      value: parentId,
    },
  ]
  const propsApi: TPagedProps = {
    apiFunction: fileRepository.findAll,
    initialFilters,
  }
  const apiPaging = useApiPagedLocal<IFile | IListItem>(propsApi)
  const { data, call: handleRefresh, count, paging, error, loading, handlePaging } = apiPaging

  const handleOpenPreview = useCallback(
    (file: IFile) => () => {
      setSelectedFile(file)
      setPreviewOpen(true)
    },
    []
  )

  useInterval(() => {
    handleRefresh()
  }, 3.6e6) // 1 hour

  // noinspection DuplicatedCode
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <FileAddEdit
            parentId={parentId}
            fieldName={fieldName}
            onChange={handleRefresh}
            repository={fileRepository}
            hideWaPermissions
            useDropzone
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ViewLoading loading={loading} message="Loading work assignment files." />
          <ErrorMessage error={error} />
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Ext</TableCell>
                  <TableCell>Exists</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Size</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(data?.results as IFile[])?.map(file => (
                  <TableRow key={file.id}>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{file.name}</TableCell>
                    <TableCell>{file.ext}</TableCell>
                    <TableCell>{file?.file?.exists !== undefined ? "Yes" : "No"}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {file.file?.size !== undefined && prettyBytes(file.file.size)}
                    </TableCell>
                    <TableCell>
                      <FormatDate value={file.updated} />
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        textAlign: "right",
                      }}
                    >
                      <FileDownloadButton file={file} />
                      <FileAddEdit
                        parentId={parentId}
                        fieldName={fieldName}
                        selectedFile={file}
                        onChange={handleRefresh}
                        repository={fileRepository}
                        useEdit
                        hideWaPermissions
                      />
                      <HtmlToolTip title={`Preview file.`}>
                        <IconButton color="primary" onClick={handleOpenPreview(file)}>
                          <PreviewIcon />
                        </IconButton>
                      </HtmlToolTip>
                      <FileDelete repository={fileRepository} file={file} onChange={handleRefresh} />
                    </TableCell>
                  </TableRow>
                ))}

                {data?.results.length !== undefined && data.results.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={9}>No files found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {data?.results.length !== undefined && data.results.length > 0 && count > 1 && (
            <Box>
              <TablePaging count={count} total={data.count} page={paging?.page} onPaging={handlePaging} />
            </Box>
          )}
          <FileViewerDialog
            open={openPreview}
            files={data?.results as IFile[]}
            selectedFile={selectedFile}
            onClose={() => setPreviewOpen(false)}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FilesEditor
