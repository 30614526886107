import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { useCallback } from "react"
import { RestRepository } from "../repositories/RestRepository"

interface IUseApiPatchResponse<T> {
  handlePatch: (patch: T, itemId: number) => Promise<T | undefined>
}

export interface IUseApiPatchProps<T> {
  repository: RestRepository<T>
  setSaving: (saving: boolean) => void
  setSavingError: (saving: IConnectionError | undefined) => void
}

/**
 * Hook for sending a patch to REST objects.
 *
 * @param {IUseApiPatchResponse} props See IProps for details.
 * @returns {IUseApiPatchProps} See IUseApiPatchProps for details.
 */
const useApiPatch = <T>(props: IUseApiPatchProps<T>): IUseApiPatchResponse<T> => {
  const { repository, setSaving, setSavingError } = props

  const handlePatch = useCallback(
    async (patch: T, itemId: number): Promise<T | undefined> => {
      setSaving(true)
      setSavingError(undefined)
      try {
        const item = await repository.patch(patch, itemId)
        setSaving(false)
        return item
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    },
    [repository, setSaving, setSavingError]
  )

  return {
    handlePatch,
  }
}

export default useApiPatch
