import { Controller, FieldValues } from "react-hook-form"
import { MenuItem, TextField } from "@mui/material"
import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"
import { ISelectItem } from "../../models/component/ISelectItem"

interface IProps {
  control: Control<FieldValues, any>
  items: ISelectItem[]
  defaultValue?: string | number
  label: string
  name: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

/**
 * Form wrapper for a MUI selection field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the wrapped select field.
 */
const FhMuiSelectField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { control, defaultValue, items, name, label, onChange } = props

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            label={label}
            select
            helperText={fieldState.error?.message}
            onBlur={field.onBlur}
            onChange={event => {
              field.onChange(event)
              onChange?.(event)
            }}
            value={field.value}
            inputRef={field.ref}
          >
            {items.map(item => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </>
  )
}

export default FhMuiSelectField
