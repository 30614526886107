import * as React from "react"
import { ControllerFieldState } from "react-hook-form"
import { ControllerRenderProps } from "react-hook-form/dist/types/controller"
import FormatNumber from "./FormatNumber"
import { Box } from "@mui/material"

interface IProps {
  field: ControllerRenderProps<any, any>
  fieldState: ControllerFieldState
}

/**
 * Formats areas and error messages for text fields.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps> } formatted area and error messages.
 */
const FormatAreaAndError: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, fieldState } = props

  return (
    <>
      <FormatNumber
        value={field.value}
        prefixUnits=""
        suffixUnits={
          <Box component="span" sx={{ pl: 1 }}>
            ft<sup>2</sup>
          </Box>
        }
      />
      {fieldState.error !== undefined ? fieldState.error.message : ""}
    </>
  )
}

export default FormatAreaAndError
