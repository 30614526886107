import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { IWorkAssignment, workAssignmentProgress } from "../models/IWorkAssignment"
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import WorkAssignmentProgressTable from "./WorkAssignmentProgressTable"

interface IProps {
  workAssignment: IWorkAssignment
  onChange?: (wa: IWorkAssignment) => void
}

/**
 * Show the work assignment progress is a compact form.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the work assignment progress.
 */
const WorkAssignmentProgress: React.FunctionComponent<IProps> = (props: IProps) => {
  const { workAssignment, onChange } = props
  const [open, setOpen] = useState(false)
  const progress = useMemo(() => workAssignmentProgress(workAssignment), [workAssignment])

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleWorkAssignmentChange = useCallback((wa: IWorkAssignment) => onChange?.(wa), [onChange])

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Chip label={progress.getPercent()} />
        {onChange !== undefined ? (
          <>
            <Button size="small" onClick={handleOpen}>
              {progress.getCurrentName() !== "" ? progress.getCurrentName() : "Update"}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
              <DialogTitle>Change Progress for {workAssignment.identifier}</DialogTitle>
              <DialogContent>
                <WorkAssignmentProgressTable workAssignment={workAssignment} progress={progress} onChange={handleWorkAssignmentChange} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <Box
            sx={{
              ml: 1,
              display: "inline",
            }}
          >
            {progress.getCurrentName()}
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default WorkAssignmentProgress
