import * as React from "react"
import { useMemo } from "react"
import { ILocation, locationToString } from "../models/ILocation"
import { Grid, Link, Paper, Typography } from "@mui/material"
import GoogleMapsGeoLink from "./GoogleMapsGeoLink"
import { Link as BrowserLink } from "@reach/router"
import { LOCATIONS_VIEW_URL } from "../../config/urls"
import LocationEditor from "../../pages/work_assignments/components/LocationEditor"
import { IWorkAssignment } from "../models/IWorkAssignment"
import OccupancyEditor from "../../pages/work_assignments/components/OccupancyEditor"
import TruncateText from "./TruncateText"

interface IProps {
  wa?: IWorkAssignment
  location?: ILocation
  menu?: (location: ILocation) => React.ReactNode
  onChange?: () => void
}

/**
 * Displays information about the work assignment location.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the work assignment locations.
 */
const WorkAssignmentLocation: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { wa, location, menu, onChange } = props

  const location1: ILocation | null = useMemo(() => {
    if (location !== undefined) {
      return location
    }
    if (wa !== undefined) {
      return wa.location
    }
    return null
  }, [])

  return (
    <Paper
      sx={{
        mb: 1,
        p: 1,
        pl: 2,
      }}
      variant="outlined"
    >
      {location1 !== null && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg>
              <Grid container alignItems="center">
                <Grid item>
                  <LocationEditor currentLocation={location1} onChange={onChange} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h5">
                    <TruncateText text={location1.name} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {wa !== undefined && (
              <Grid item>
                <OccupancyEditor wa={wa} onChange={onChange} />
              </Grid>
            )}
            {menu !== undefined && <Grid item>{menu(location1)}</Grid>}
          </Grid>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 0.2 }}>
            <Grid item xs={12} lg>
              <Link component={BrowserLink} to={`${LOCATIONS_VIEW_URL}/${location1.id}`}>
                <TruncateText text={locationToString(location1)} num={50} />
              </Link>
            </Grid>
            <Grid item>
              <GoogleMapsGeoLink latitude={location1.latitude} longitude={location1.longitude} />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  )
}

export default WorkAssignmentLocation
