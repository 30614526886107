import useAuth from "../../../shared/hooks/useAuth"
import { useMemo } from "react"
import { ACCESS_AUDIT_GROUP, ACCESS_INVOICES_GROUP, ACCESS_WORK_ASSIGNMENT_GROUP } from "../../../config/permissions"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IInvoice, INVOICE_AUDIT_ENDPOINT, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import { IListItem } from "../../../shared/models/component/IListItem"

interface IResponse {
  isInAuditGroup: boolean
  isInWorkAssignmentGroup: boolean
  isInInvoicesGroup: boolean
  repository: RestRepository<IInvoice | IListItem>
}

/**
 * This hook checks if the user is in the invoice or audit group and returns the correct repo.
 *
 * @returns {IResponse} see description.
 */
const useInvoicesAuditsGroup = (): IResponse => {
  const { currentUser, isUserInGroups } = useAuth()

  // Check to see if the user is in the audit group.
  const isInAuditGroup = useMemo(() => {
    return isUserInGroups?.([ACCESS_AUDIT_GROUP]) === true
  }, [isUserInGroups, currentUser])

  // Check to see if the user is in the invoices group.
  const isInInvoicesGroup = useMemo(() => {
    return isUserInGroups?.([ACCESS_INVOICES_GROUP]) === true
  }, [isUserInGroups, currentUser])

  // Check to see if the user is in the work assignment group.
  const isInWorkAssignmentGroup = useMemo(() => {
    return isUserInGroups?.([ACCESS_WORK_ASSIGNMENT_GROUP]) === true
  }, [isUserInGroups, currentUser])

  const repository = useMemo(() => {
    if (isInInvoicesGroup) {
      return new RestRepository<IInvoice | IListItem>(INVOICE_ENDPOINT)
    }
    return new RestRepository<IInvoice | IListItem>(INVOICE_AUDIT_ENDPOINT)
  }, [isInAuditGroup, isInInvoicesGroup])

  return {
    isInAuditGroup,
    isInWorkAssignmentGroup,
    isInInvoicesGroup,
    repository,
  }
}

export default useInvoicesAuditsGroup
