import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { styles } from "../../shared/styling/general"
import TableColumnShow, { useTableColumnShow } from "../../shared/components/TableColumnShow"
import useContentHeight from "../../shared/hooks/useContentHeight"
import { type IColumn } from "../../shared/models/component/IColumn"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { type IListItem } from "../../shared/models/component/IListItem"
import { EXPENSE_REPORTS_ENDPOINT, type IExpenseReport } from "../../shared/models/IExpenseReport"
import useFocus from "../../shared/hooks/useFocus"
import { type IPagedResults } from "../../shared/models/IPagedResults"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { navigate } from "gatsby"
import { EXPENSE_REPORTS_ADD_URL, EXPENSE_REPORTS_VIEW_URL } from "../../config/urls"
import Goto from "../../shared/components/Goto"
import Filtering from "../../shared/components/Filtering"
import FilterBookmarksDialog from "../../shared/components/FilterBookmarksDialog"
import FilterSharing from "../../shared/components/FilterSharing"
import { type IFilter } from "../../shared/models/IFilter"
import { Link } from "@reach/router"
import AddIcon from "@mui/icons-material/Add"
import ErrorMessage from "../../shared/components/ErrorMessage"
import TableCellShow from "../../shared/components/TableCellShow"
import TableOrdering from "../../shared/components/TableOrdering"
import TableRowSelect from "../../shared/components/TableRowSelect"
import FormatDate from "../../shared/components/format/FormatDate"
import TablePaging from "../../shared/components/TablePaging"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"
import FormatNumber from "../../shared/components/format/FormatNumber"
import { FILTERS } from "../../config/config"
import SignOffButton from "./components/SignOffButton"
import { useUpdateResults } from "../../shared/hooks/useUpdateResults"
import PayPeriodDialog from "./components/PayPeriodDialog"
import TableLoadingFull from "../../shared/components/TableLoadingFull"
import PayPeriodDisplay from "./components/PayPeriodDisplay"
import AdpPayPeriodDisplay from "./components/AdpPayPeriodDisplay"
import CurrentPayPeriods from "./components/CurrentPayPeriods"

const repository = new RestRepository<IExpenseReport | IListItem>(EXPENSE_REPORTS_ENDPOINT)

const columns = [
  {
    field: "id",
    title: "ID",
  },
  {
    field: "consultant",
    title: "Consultant",
  },
  {
    field: "expense_report_payment_type",
    title: "Payment Type",
  },
  {
    field: "report_ready",
    title: "Report Ready",
  },
  {
    field: "received",
    title: "Received",
  },
  {
    field: "paid",
    title: "Paid",
  },
  {
    field: "confirmed",
    title: "Confirmed",
  },
  {
    field: "pay_period",
    title: "Pay Period",
  },
  {
    field: "adp_pay_period",
    title: "ADP Pay Period",
  },
  {
    field: "total_amount",
    title: "Amount",
  },
  {
    field: "total_admin_hours",
    title: "Admin Hours",
  },
  {
    field: "notes",
    title: "Notes",
  },
  {
    field: "created",
    title: "Created",
  },
  {
    field: "updated",
    title: "Updated",
  },
] as IColumn[]

/**
 * Index page for viewing all expense reports.
 * @returns {React.FC} the expense report index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { focused, handleFocus, handleBlur } = useFocus()
  const { selectedColumns } = useTableColumnShow({ columns })
  const height = useContentHeight(-14)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const [results, setResults] = useState<IPagedResults<IExpenseReport> | null>(null)

  const handleExpenseReportChange = useUpdateResults<IExpenseReport>({ setResults })

  const props: IUseApiPagedResultsProps<IExpenseReport | IListItem> = { apiFunction: repository.findAll }
  const {
    data,
    count,
    paging,
    error,
    loading,
    handlePaging,
    handleOrdering,
    handleFilter,
    handleLimit,
    call: refreshExpenseReports,
  } = useApiPaged<IExpenseReport | IListItem>(props)

  const handleSelected = useCallback(async (expenseReport: IExpenseReport) => {
    await navigate(`${EXPENSE_REPORTS_VIEW_URL}/${expenseReport.id}`)
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      setResults(data as IPagedResults<IExpenseReport>)
    }
  }, [data])

  const AVAILABLE_FILTERS: IFilter[] = useMemo(() => {
    if (
      paging?.filters !== undefined &&
      paging.filters.some(
        filter => filter.field === FILTERS.EXPENSE_REPORT_PAY_PERIOD.field || filter.field === FILTERS.ADP_PAY_PERIOD.field
      )
    ) {
      return [FILTERS.EXPENSE_REPORT_PAYMENT_TYPE, FILTERS.CONSULTANT_WA]
    }
    return [FILTERS.EXPENSE_REPORT_PAY_PERIOD, FILTERS.ADP_PAY_PERIOD, FILTERS.EXPENSE_REPORT_PAYMENT_TYPE, FILTERS.CONSULTANT_WA]
  }, [paging])

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={focused ? 6 : 3} md={focused ? 4 : 2} lg={focused ? 3 : 1}>
            <Goto repository={repository} url={EXPENSE_REPORTS_VIEW_URL} onFocus={handleFocus} onBlur={handleBlur} />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Filtering availableFilters={AVAILABLE_FILTERS} filters={paging?.filters} onFilter={handleFilter} disabled={loading} />
              </Grid>
              <Grid item>
                <FilterBookmarksDialog bookmarkType="expense_reports" filters={paging?.filters} onFilter={handleFilter} />
              </Grid>
              <Grid item>
                <FilterSharing onFilter={handleFilter} filters={paging?.filters} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <CurrentPayPeriods />
          </Grid>
          <Grid item>
            <PayPeriodDialog filters={paging?.filters} onChange={refreshExpenseReports} />
            <Button component={Link} to={EXPENSE_REPORTS_ADD_URL} startIcon={<AddIcon />}>
              Expense Report
            </Button>
          </Grid>
          <Grid item>
            <TableColumnShow columns={columns} />
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCellShow columns={selectedColumns} field="identifier">
                  <TableOrdering ordering={paging?.ordering} field="id" title="ID" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="consultant" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering ordering={paging?.ordering} field="consultant" title="Consultant" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow
                  columns={selectedColumns}
                  field="expense_report_payment_type"
                  sx={{
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                >
                  Payment Type
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="pay_period" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering ordering={paging?.ordering} field="pay_period" title="Paid Period" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="adp_pay_period" sx={{ whiteSpace: "nowrap" }}>
                  ADP Paid Period
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="report_ready">
                  <TableOrdering ordering={paging?.ordering} field="report_ready" title="Report Ready" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="received">
                  <TableOrdering ordering={paging?.ordering} field="received" title="Received" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="paid">
                  <TableOrdering ordering={paging?.ordering} field="paid" title="Paid" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="confirmed">
                  <TableOrdering ordering={paging?.ordering} field="confirmed" title="Confirmed" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="total_amount" sx={{ whiteSpace: "nowrap" }}>
                  Amount
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="total_admin_hours" sx={{ whiteSpace: "nowrap" }}>
                  Admin Hours
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="notes">
                  <TableOrdering ordering={paging?.ordering} field="notes" title="Notes" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="created">
                  <TableOrdering ordering={paging?.ordering} field="created" title="Created" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="updated">
                  <TableOrdering ordering={paging?.ordering} field="updated" title="Updated" onOrdering={handleOrdering} />
                </TableCellShow>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                results?.results.map(expenseReport => {
                  const rowStyle = styles.expenseReportRowStyle(expenseReport)
                  return (
                    <TableRowSelect key={expenseReport.id} item={expenseReport} onSelected={handleSelected}>
                      <TableCellShow
                        columns={selectedColumns}
                        field="consultant"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {expenseReport.consultant.name}
                        <Box>
                          <small>{expenseReport.consultant.legal_entity_name}</small>
                        </Box>
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="expense_report_payment_type"
                        sx={{
                          ...rowStyle,
                          textAlign: "center",
                        }}
                      >
                        {expenseReport.consultant.expense_report_payment_type}
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="pay_period"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <PayPeriodDisplay payPeriod={expenseReport.pay_period} />
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="adp_pay_period"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <AdpPayPeriodDisplay adpPayPeriod={expenseReport.adp_pay_period} />
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="report_ready"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <SignOffButton
                          expenseReportId={expenseReport.id}
                          action={expenseReport.report_ready !== null ? "report_ready_remove" : "report_ready"}
                          value={expenseReport.report_ready}
                          onChange={handleExpenseReportChange}
                        />
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="received"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <SignOffButton
                          expenseReportId={expenseReport.id}
                          action={expenseReport.received !== null ? "received_remove" : "received"}
                          value={expenseReport.received}
                          label={
                            expenseReport.received_by?.name !== undefined
                              ? `Report generated by ${expenseReport.received_by.name}.`
                              : undefined
                          }
                          onChange={handleExpenseReportChange}
                        />
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="paid"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <SignOffButton
                          expenseReportId={expenseReport.id}
                          action={expenseReport.paid !== null ? "paid_remove" : "paid"}
                          value={expenseReport.paid}
                          label={expenseReport.paid_by?.name !== undefined ? `Email sent by ${expenseReport.paid_by.name}.` : undefined}
                          onChange={handleExpenseReportChange}
                        />
                      </TableCellShow>
                      <TableCellShow columns={selectedColumns} field="confirmed" sx={rowStyle}>
                        <SignOffButton
                          expenseReportId={expenseReport.id}
                          action={expenseReport.confirmed !== null ? "confirmed_remove" : "confirmed"}
                          value={expenseReport.confirmed}
                          label={expenseReport.confirmed_by?.name}
                          onChange={handleExpenseReportChange}
                        />
                      </TableCellShow>
                      <TableCellShow columns={selectedColumns} field="total_amount" sx={{ textAlign: "right", ...rowStyle }}>
                        <FormatNumber value={expenseReport.total_amount} twoDecimalPlaces />
                      </TableCellShow>
                      <TableCellShow columns={selectedColumns} field="total_admin_hours" sx={{ textAlign: "right", ...rowStyle }}>
                        <FormatNumber value={expenseReport.total_admin_hours} twoDecimalPlaces prefixUnits={false} />
                      </TableCellShow>
                      <TableCellShow
                        columns={selectedColumns}
                        field="notes"
                        sx={{
                          ...rowStyle,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <ChangeHtmlField
                          modelId={expenseReport.id}
                          item={expenseReport}
                          field="notes"
                          title="Notes"
                          onChange={handleExpenseReportChange}
                          value={expenseReport.notes}
                          repository={repository}
                        />
                      </TableCellShow>
                      <TableCellShow columns={selectedColumns} field="created" sx={rowStyle}>
                        <FormatDate value={expenseReport.created} />
                      </TableCellShow>
                      <TableCellShow columns={selectedColumns} field="updated" sx={rowStyle}>
                        <FormatDate value={expenseReport.updated} />
                      </TableCellShow>
                    </TableRowSelect>
                  )
                })}
            </TableBody>
          </Table>
          {!loading && (data === undefined || data.results.length === 0) && (
            <Alert color="info" sx={{ m: 1 }}>
              No expense reports found.
            </Alert>
          )}
          <TableLoadingFull loading={loading} rows={paging?.limit} />
        </TableContainer>
      </Box>
      <Divider />
      <TablePaging
        count={count}
        total={data?.count}
        page={paging?.page}
        limit={paging?.limit}
        size={isSmall ? "small" : "medium"}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default IndexPage
