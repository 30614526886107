import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Past Due To Client"
const description = "Reports that are past due to a client."
const baseFilter: IFilter = {
  field: "past_due_to_client",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "past_due_to_client.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * Reports that are past due to a client.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const PastDueToClientReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default PastDueToClientReport
