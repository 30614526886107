import { useCallback, useState } from "react"
import { RestRepository } from "../repositories/RestRepository"
import IProfile, { PROFILES_ENDPOINT } from "../models/main/IProfile"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import useAuth from "./useAuth"

interface TypeProfileResponse<T> {
  value: T | null | undefined
  setValue: (value?: T) => Promise<void>
  loadValue: () => Promise<void>
  loading: boolean
  error: IConnectionError | null
}

const repository = new RestRepository<IProfile>(PROFILES_ENDPOINT)

/**
 * Use the user's profile to store and retrieve values.
 *
 * @param {string} key to access the value.
 * @returns {TypeProfileResponse} the value, function to set the value and status indicator.
 */
const useProfileStorage = <T>(key: string): TypeProfileResponse<T> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [profile, setProfile] = useState<IProfile | null>(null)
  const [value, setValue] = useState<T | undefined | null>(null)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const { currentUser } = useAuth()

  const setNewValue = useCallback(
    async (newValue?: any) => {
      if (currentUser?.user.profile !== undefined && profile !== null) {
        try {
          setLoading(true)
          const patch: any = { [key]: newValue }
          const profileUpdated: IProfile = await repository.patch(patch, currentUser.user.profile)
          setProfile(profileUpdated)
          setValue((profileUpdated as any)?.[key])
          setLoading(false)
        } catch (reason: any) {
          setLoading(false)
          if (reason?.response !== undefined) {
            setSavingError(reason.response)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
      }
    },
    [key, profile, currentUser]
  )

  const loadProfileAndValue = useCallback(async () => {
    if (currentUser?.user.profile !== undefined) {
      try {
        setLoading(true)
        let profile1: IProfile | null = profile
        if (profile === null) {
          profile1 = await repository.read(currentUser.user.profile)
          setProfile(profile1)
        }
        const value1: T | undefined | null = (profile1 as any)?.[key]
        setValue(value1)
        setLoading(false)
      } catch (reason: any) {
        setLoading(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    }
  }, [key, profile, currentUser])

  return {
    value,
    setValue: setNewValue,
    loadValue: loadProfileAndValue,
    loading,
    error: savingError,
  }
}

export default useProfileStorage
