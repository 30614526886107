import * as React from "react"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { RestRepository } from "../repositories/RestRepository"
import { IListItem } from "../models/component/IListItem"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { IPaging } from "../models/IPaging"
import useDebounce from "react-debounced"
import { IFilter } from "../models/IFilter"
import ErrorMessage from "./ErrorMessage"

interface IProps {
  name: string
  label: string
  defaultValue?: IListItem[] | null
  filters?: IFilter[]
  repository?: RestRepository<IListItem>
  onChange: (listItems: IListItem[]) => void
}

/**
 * This component provides a multiple autocomplete form select.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent} the component.
 */
const SelectFilteredMultiple: React.FunctionComponent<IProps> = (props: IProps) => {
  const { name, defaultValue, filters = [], label, repository, onChange } = props
  const debounce = useDebounce()

  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [listItems, setListItems] = useState<readonly IListItem[]>([])
  const [selectedListItems, setSelectedListItems] = useState<IListItem[]>([])
  const [error, setError] = useState<IConnectionError | null>(null)

  const handleOpen = useCallback(async () => {
    if (repository === undefined) {
      setOpen(true)
      return
    }
    setError(null)
    setOpen(true)
    setLoading(true)
    try {
      const paging: IPaging = { filters }
      const results = await repository.list(paging)
      setListItems(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [filters])

  // noinspection DuplicatedCode
  const handleSearch = useCallback(
    async (event: SyntheticEvent, search: string) => {
      if (repository === undefined) {
        setListItems([
          {
            id: search,
            name: search,
          },
        ])
        return
      }
      debounce(async () => {
        setError(null)
        setLoading(true)
        try {
          const paging: IPaging = {
            filters: [
              ...filters,
              {
                field: "search",
                value: search,
              },
            ],
          }
          const results = await repository.list(paging)
          setListItems(results)
        } catch (reason: any) {
          if (reason?.response !== undefined) {
            setError(reason.response)
          } else {
            setError(CONNECTION_ERROR)
          }
        }
        setLoading(false)
      })
    },
    [filters, selectedListItems]
  )

  const handleListItemClick = useCallback((event: SyntheticEvent<Element, Event>, items: any) => {
    setSelectedListItems(items)
    onChange(items)
  }, [])

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      setSelectedListItems([...defaultValue.map(item => ({ ...item }))])
    }
  }, [defaultValue])

  return (
    <>
      <ErrorMessage error={error} />
      <Autocomplete
        open={open}
        multiple
        freeSolo={repository === undefined}
        onOpen={handleOpen}
        defaultValue={[]}
        onClose={() => setOpen(false)}
        onChange={handleListItemClick}
        value={selectedListItems}
        onInputChange={handleSearch}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={listItems}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default SelectFilteredMultiple
