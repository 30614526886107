import * as React from "react"
import { useCallback, useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { IInvoice, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import DialogControls from "../../../shared/components/DialogControls"
import { IPaging } from "../../../shared/models/IPaging"
import BlockIcon from "@mui/icons-material/Block"
import ClearIcon from "@mui/icons-material/Clear"
import FormatDate from "../../../shared/components/format/FormatDate"
import HtmlTooltip from "../../../shared/components/HtmlToolTip"

interface IProps {
  invoice: IInvoice
  onChange: () => void
}

const repository = new RestRepository<IInvoice>(INVOICE_ENDPOINT)

/**
 * This component will void an invoice.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const VoidInvoice: React.FunctionComponent<IProps> = (props: IProps) => {
  const { invoice, onChange } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const [notes, setNotes] = useState<string>("")
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setNotes("")
    setOpen(false)
  }, [])

  const handleVoid = useCallback(async () => {
    try {
      setSaving(true)
      const paging: IPaging = {
        filters: [
          {
            field: "notes",
            value: notes,
          },
        ],
      }

      await repository.action(invoice.id, "void", paging)
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, invoice, notes])

  const handleClear = useCallback(async () => {
    try {
      setSaving(true)
      await repository.action(invoice.id, "clear_void")
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, invoice, notes])

  return (
    <>
      {invoice.void === null ? (
        <Button onClick={handleOpen} startIcon={<BlockIcon />}>
          Void
        </Button>
      ) : (
        <HtmlTooltip
          title={
            <Box>
              By {invoice.voided_by.name} on <FormatDate value={invoice.void} />.<p>{invoice.void_notes}</p>
              <p>Click to clear void.</p>
            </Box>
          }
          placement="bottom"
        >
          <Button onClick={handleClear} startIcon={<ClearIcon />} color={"error"}>
            Voided
          </Button>
        </HtmlTooltip>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Void Invoice</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs>
              <TextField
                fullWidth
                multiline
                minRows={4}
                label="Notes"
                name="notes"
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls
            onSave={handleVoid}
            loading={saving}
            onCancel={handleClose}
            disabled={notes === ""}
            buttonLabel="Void"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default VoidInvoice
