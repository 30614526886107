import { Box } from "@mui/material"
import * as React from "react"
import { useCallback, useState } from "react"

interface IUseTabPanelProps {
  initialTab?: number
}

interface IUseTabPanelResponse {
  tab: number
  handleTabChange: (event: React.SyntheticEvent | null, newValue: number) => void
}

/**
 * This hook handles the state of the tab panel.
 *
 * @param {IUseTabPanelProps} props See IUseTabPanelProps for details.
 * @returns {IUseTabPanelResponse} the state and event handler.
 */
export const useTabPanel = (props?: IUseTabPanelProps): IUseTabPanelResponse => {
  let initialTab = 0
  if (props?.initialTab !== undefined) {
    initialTab = props.initialTab
  }
  const [tab, setTab] = useState<number>(initialTab)

  const handleTabChange = useCallback((event: React.SyntheticEvent | null, newValue: number) => {
    setTab(newValue)
  }, [])

  return {
    tab,
    handleTabChange,
  }
}

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

/**
 * Renders and hides the contents of the tab panel.
 *
 * @param {ITabPanelProps} props See ITabPanelProps for details.
 * @returns {React.FunctionComponent<ITabPanelProps>} The tab panel.
 */
const TabPanel: React.FunctionComponent<ITabPanelProps> = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </Box>
  )
}

export default TabPanel
