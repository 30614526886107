import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import { LoadingButton } from "@mui/lab"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import { type IWorkAssignmentConsultant, WORK_ASSIGNMENT_CONSULTANT_ENDPOINT } from "../../../shared/models/IWorkAssignmentConsultant"
import { CONSULTANTS_ENDPOINT, type IConsultant } from "../../../shared/models/main/IConsultant"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../shared/models/component/IListItem"
import { type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { type IReportFormat } from "../../../shared/models/IReportFormat"

interface IWorkAssignmentConsultantAdd {
  consultant: number
  work_assignment: number
  amount: number
  hourly_rate: boolean
  accepted: boolean
  message: string
}

interface IProps {
  wa: IWorkAssignment
  consultant: IConsultant | null | undefined
  onAdd: () => void
  onCancel: () => void
  open: boolean
  selectConsultant: boolean
}

const consultantRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const waConsultantRepository = new RestRepository<IWorkAssignmentConsultantAdd>(WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

/**
 * Type guard to check if the given reportFormat is of type IReportFormat. 
 * This is to automatically sign off on admin review based on the report type.
 *
 * @param {IReportFormat | number | null} reportFormat - The report format to check.
 * @returns {boolean} True if the report format is of type IReportFormat, false otherwise.
 */
const isReportFormat = (reportFormat: IReportFormat | number | null): reportFormat is IReportFormat => {
  return typeof reportFormat === "object" && reportFormat !== null && "admin_review_needed" in reportFormat;
};

/**
 * Use this component to add a consultant to a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the add consultant component.
 */
const AddConsultantToWorkAssignment: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { wa, consultant, onAdd, onCancel, open, selectConsultant } = props

  const [savingAdd, setSavingAdd] = useState(false)
  const [sendingText, setSendingText] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const [sendTextMessage, setSendTextMessage] = useState(true)

  const planReviewerRate = consultant?.plan_reviewer_rate !== undefined ? consultant.plan_reviewer_rate : 0
  const [amount, setAmount] = useState<number | string>(wa.inspection_type === "PR" ? planReviewerRate : wa.suggested_consultant_amount)
  const [message, setMessage] = useState<string>("")
  const [accepted, setAccepted] = useState<boolean>(false)

  const planReviewerHourly = consultant?.plan_reviewer_hourly !== undefined ? consultant.plan_reviewer_hourly : false
  const [hourlyRate, setHourlyRate] = useState<boolean>(wa.inspection_type === "PR" ? planReviewerHourly : false)

  const [localConsultant, setLocalConsultant] = useState<IConsultant | undefined | null>()

  const handleCancel = useCallback(() => {
    setSavingError(null)
    setSendingText(false)
    setSendTextMessage(false)
    setMessage("")
    setSendingText(true)
    onCancel()
  }, [onCancel])

  const handleClose = useCallback(() => {
    setSavingError(null)
    setSendingText(false)
    setMessage("")
    setSendTextMessage(true)
    onCancel()
    onAdd()
  }, [onCancel])

  const handleAdd = useCallback(async () => {
    if (localConsultant?.id !== undefined && wa !== undefined) {
      try {
        setSavingError(null)
        setSavingAdd(true)
        const waConsultant: IWorkAssignmentConsultantAdd = {
          consultant: localConsultant.id,
          work_assignment: wa.id,
          amount: Number(amount),
          hourly_rate: hourlyRate,
          message,
          accepted,
        }
        const wac = (await waConsultantRepository.add(waConsultant)) as IWorkAssignmentConsultant
        if (sendTextMessage && wac !== null) {
          setSendingText(true)
          await waConsultantRepository.action(wac.id, "send_text_notification_new_request")
        }

        // Checks if admin review is needed
        if (isReportFormat(wa.report_format) && !wa.report_format.admin_review_needed) {
          await waRepository.action(wa.id, "admin_reviewer_not_required");
        }

        setSavingAdd(false)
        setSendingText(false)
        setMessage("")
        setSendTextMessage(true)
        onAdd()
      } catch (reason: any) {
        setSavingAdd(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    }
  }, [wa, localConsultant, amount, message, hourlyRate, accepted, sendTextMessage])

  const handleSelectedConsultant = useCallback(
    (consultant1?: IListItem | null) => {
      setLocalConsultant(consultant1 as IConsultant)
      if (wa.inspection_type === "PR") {
        setHourlyRate((consultant1 as IConsultant).plan_reviewer_hourly)
        setAmount((consultant1 as IConsultant).plan_reviewer_rate)
      }
    },
    [wa]
  )

  useEffect(() => {
    setLocalConsultant(consultant)
    if (wa.inspection_type === "PR" && consultant !== undefined && consultant !== null) {
      setHourlyRate(consultant.plan_reviewer_hourly)
      setAmount(consultant.plan_reviewer_rate)
    }
  }, [consultant])

  useEffect(() => {
    if (wa.inspection_type !== "PR") {
      setAmount(wa.suggested_consultant_amount)
    }
  }, [wa.suggested_consultant_amount])

  return (
    <>
      <Dialog open={open} disableEscapeKeyDown={true} fullWidth maxWidth={selectConsultant ? "sm" : "xs"}>
        <DialogTitle>
          Add <em>{consultant?.name}</em>
        </DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          {savingError !== null && sendingText ? (
            <>
              <Alert severity="warning">The consultant was added but there was an error sending the text message.</Alert>
            </>
          ) : (
            <Grid container spacing={1} sx={{ mt: 1 }} alignItems="start">
              {selectConsultant && (
                <Grid item xs={8}>
                  <SelectFilteredSingle
                    name="consultant"
                    label="Consultant"
                    repository={consultantRepository}
                    onChange={handleSelectedConsultant}
                  />
                </Grid>
              )}
              <Grid item xs={selectConsultant ? 4 : 12}>
                <TextField
                  fullWidth
                  label="Fee"
                  value={amount}
                  disabled={savingAdd}
                  helperText={<FormatNumber value={Number(amount)} suffixUnits={hourlyRate ? "/hr" : ""} />}
                  InputProps={{
                    endAdornment: hourlyRate && <InputAdornment position="end">/hr</InputAdornment>,
                  }}
                  onChange={e => {setAmount(e.target.value)}}
                />
              </Grid>
              <Grid item xs={selectConsultant ? 8 : 6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={accepted} onChange={e => {setAccepted(e.target.checked)}} />}
                    label="Accept"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={selectConsultant ? 4 : 6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={hourlyRate} onChange={e => {setHourlyRate(e.target.checked)}} />}
                    label="Hourly Rate?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                {open && (
                  <TextField fullWidth multiline minRows={2} label="Message" value={message} onChange={e => {setMessage(e.target.value)}} />
                )}
              </Grid>
              <Grid item xs={12}>
                {!(savingError !== null && sendingText) && (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sendTextMessage} onChange={e => {setSendTextMessage(e.target.checked)}} />}
                      label="Send text message notification?"
                    />
                  </FormGroup>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            sx={{
              ml: 0,
              mr: 1,
              mb: 1,
            }}
            alignItems="center"
          >
            <Grid item xs>
              <Button disabled={savingAdd} onClick={handleCancel} color="warning">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              {savingError !== null && sendingText ? (
                <Button disabled={savingAdd} onClick={handleClose}>
                  Close
                </Button>
              ) : (
                <LoadingButton
                  loading={savingAdd}
                  disabled={localConsultant === undefined || localConsultant === null || savingAdd}
                  onClick={handleAdd}
                >
                  Add
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddConsultantToWorkAssignment
