import * as React from "react"
import { Box, Button, Divider, Grid, TableContainer, useMediaQuery, useTheme } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../shared/models/IWorkAssignment"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { styles } from "../../shared/styling/general"
import TablePaging from "../../shared/components/TablePaging"
import WorkAssignmentsTable, { workAssignmentColumns } from "../../shared/components/WorkAssignmentsTable"
import Filtering from "../../shared/components/Filtering"
import ErrorMessage from "../../shared/components/ErrorMessage"
import Goto from "../../shared/components/Goto"
import { WORK_ASSIGNMENT_ADD_URL, WORK_ASSIGNMENT_VIEW_URL, WORK_ASSIGNMENTS_URL } from "../../config/urls"
import { WORK_ASSIGNMENT_FILTERS } from "../../config/config"
import ExcelDownloadButton from "../../shared/components/ExcelDownloadButton"
import TableColumnShow from "../../shared/components/TableColumnShow"
import { Link } from "@reach/router"
import useContentHeight from "../../shared/hooks/useContentHeight"
import AddIcon from "@mui/icons-material/Add"
import useFocus from "../../shared/hooks/useFocus"
import { type IListItem } from "../../shared/models/component/IListItem"
import FilterBookmarksDialog from "../../shared/components/FilterBookmarksDialog"
import FilterSharing from "../../shared/components/FilterSharing"
import PrivateComponent from "../../shared/components/PrivateComponent"
import { ACCESS_EXCEL_DOWNLOAD_GROUP } from "../../config/permissions"
import { BASE_URL } from "../../shared/utilities/request_utility"
import CopyWorkAssignmentsToClipboard from "./components/CopyWorkAssignmentsToClipboard"

const waRepository = new RestRepository<IWorkAssignment | IListItem>(WORK_ASSIGNMENT_ENDPOINT)

/**
 * Renders a table of the work assignments that is filterable.
 *
 * @returns {React.FC} the component.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { focused, handleFocus, handleBlur } = useFocus()
  const height = useContentHeight(-14)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const props: IUseApiPagedResultsProps<IWorkAssignment | IListItem> = { apiFunction: waRepository.findAll }
  const { data, count, paging, error, loading, handleLimit, handlePaging, handleFilter, handleOrdering, call } = useApiPaged<
    IWorkAssignment | IListItem
  >(props)

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={focused ? 6 : 3} md={focused ? 4 : 2} lg={focused ? 3 : 1}>
            <Goto
              repository={waRepository}
              url={WORK_ASSIGNMENT_VIEW_URL}
              onFocus={handleFocus}
              onBlur={handleBlur}
              label="Goto Work Assignment ID"
            />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Filtering
                  availableFilters={WORK_ASSIGNMENT_FILTERS}
                  filters={paging?.filters}
                  onFilter={handleFilter}
                  disabled={loading}
                />
              </Grid>
              <Grid item>
                <FilterBookmarksDialog bookmarkType="work_assignments" filters={paging?.filters} onFilter={handleFilter} />
              </Grid>
              <Grid item>
                <FilterSharing onFilter={handleFilter} filters={paging?.filters} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Button component={Link} startIcon={<AddIcon />} to={WORK_ASSIGNMENT_ADD_URL}>
                  WA
                </Button>
              </Grid>
              <Grid item>
                <PrivateComponent
                  component={
                    <ExcelDownloadButton
                      endpoint={WORK_ASSIGNMENT_ENDPOINT}
                      asyncEndpoint={`${BASE_URL}/lookups/work_assignments_async_file`}
                      filename="work_assignments.xlsx"
                      limit={data?.count}
                      paging={paging}
                    />
                  }
                  groupNames={[ACCESS_EXCEL_DOWNLOAD_GROUP]}
                />
              </Grid>
              {data?.results !== undefined && (
                <Grid item>
                  <CopyWorkAssignmentsToClipboard workAssignments={data?.results as IWorkAssignment[]} />
                </Grid>
              )}
              <Grid item>
                <TableColumnShow columns={workAssignmentColumns} pathname={WORK_ASSIGNMENTS_URL} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <WorkAssignmentsTable
            workAssignments={data?.results as IWorkAssignment[]}
            ordering={paging?.ordering}
            onOrdering={handleOrdering}
            loading={loading}
            coloring
            rowStyle={styles.waIsLateRowStyle}
            stickyHeader={true}
            limit={paging?.limit}
            onRefresh={call}
          />
        </TableContainer>
      </Box>
      <Divider />
      <TablePaging
        count={count}
        total={data?.count}
        page={paging?.page}
        limit={paging?.limit}
        size={isSmall ? "small" : "medium"}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default IndexPage
