import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Reports Due to Client"
const description = "Client Reports Late, Due Today, or Due in 10 Days."
const baseFilter: IFilter = {
  field: "late_today_tomorrow",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "late_today_tomorrow.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * Client Reports Late, Due Today, or Due in 10 Days.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const LateTodayTomorrowReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default LateTodayTomorrowReport
