import * as React from "react"
import { Chip } from "@mui/material"
import { styles } from "../../../shared/styling/general"

type StatusColor = "error" | "warning" | "success"

export const statusColor: StatusColor[] = ["warning", "success", "error"]
export const statusText = ["Pending", "Accepted", "Declined"]

interface IProps {
  status: number
}

/**
 * Tech review request status.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the tech review request status.
 */
const TechReviewRequestStatus: React.FunctionComponent<IProps> = (props: IProps) => {
  const { status } = props

  return (
    <>
      <Chip color={statusColor[status]} sx={styles.review.chip()} size="small" variant="filled" label={statusText[status]} />
    </>
  )
}

export default TechReviewRequestStatus
