import * as React from "react"
import { type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ChangeHtmlFieldInline from "../../../shared/components/ChangeHtmlFieldInline"
import HelpDocsDrawer from "../../../shared/components/help/HelpDocsDrawer"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  wa: IWorkAssignment
  onChange: (wa: IWorkAssignment) => void
}

/**
 * This allows the editing of internal notes.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} edit internal notes component.
 */
const InternalNotesEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { wa, onChange } = props

  return (
    <>
      <Grid container>
        <Grid item xs />
        <Grid item>
          <HelpDocsDrawer showButton showButtonLabel={false} pageId={512} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <ChangeHtmlFieldInline
            onChange={onChange}
            value={wa.notes}
            field="notes"
            title="Internal Notes"
            item={wa}
            repository={waRepository}
            modelId={wa.id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChangeHtmlFieldInline
            onChange={onChange}
            value={wa.job_status}
            field="job_status"
            title="Job Status"
            item={wa}
            repository={waRepository}
            modelId={wa.id}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default InternalNotesEditor
