import {
  Alert,
  Box,
  Breadcrumbs,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import PreviewIcon from "@mui/icons-material/Preview"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { type IFile } from "../../../shared/models/IFile"
import { customerTypeName, type ICustomer } from "../../../shared/models/ICustomer"
import { type IAccount } from "../../../shared/models/IAccount"
import { type ILocation, locationToString } from "../../../shared/models/ILocation"
import FormatDate from "../../../shared/components/format/FormatDate"
import ViewLoading from "../../../shared/components/ViewLoading"
import { type IStorageFile } from "../../../shared/models/IStorageFile"
import TruncateText from "../../../shared/components/TruncateText"
import HtmlToolTip from "../../../shared/components/HtmlToolTip"
import prettyBytes from "pretty-bytes"
import { type IWorkAssignmentFile, WORK_ASSIGNMENT_FILE_ENDPOINT } from "../../../shared/models/IWorkAssignmentFile"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../shared/hooks/useApiPagedLocal"
import { type IListItem } from "../../../shared/models/component/IListItem"
import TablePaging from "../../../shared/components/TablePaging"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import FileViewerDialog from "../../../shared/components/files/FileViewerDialog"
import FilesImportDialog from "./FilesImportDialog"
import FileBundler from "../../../shared/components/files/FileBundler"
import FileDelete from "../../../shared/components/files/FileDelete"
import FileAddEdit from "../../../shared/components/files/FileAddEdit"
import FileDownloadButton from "../../../shared/components/files/FileDownloadButton"
import { useInterval } from "beautiful-react-hooks"
import InlineCheckbox from "../../../shared/components/form/InlineCheckbox"
import FilesArchive from "./FilesArchive"
import RefreshIcon from "@mui/icons-material/Refresh"
import useDebounce from "react-debounced"
import TableColumnShow, { useTableColumnShow } from "../../../shared/components/TableColumnShow"
import { type IColumn } from "../../../shared/models/component/IColumn"
import { WORK_ASSIGNMENT_VIEW_URL } from "../../../config/urls"
import TableCellShow from "../../../shared/components/TableCellShow"
import { type IFileTag } from "../../../shared/models/main/IFileTag"
import { MoreHoriz as MoreIcon } from "@mui/icons-material"
import DropboxSaver from "../../../shared/components/DropboxSaver"

const fileRepository = new RestRepository<IWorkAssignmentFile>(WORK_ASSIGNMENT_FILE_ENDPOINT)

interface IProps {
  waId: number
  files: IFile[]
  bundle: IStorageFile
  onRefresh: () => void
  customer: ICustomer
  account: IAccount
  location: ILocation | null
}

const columns: IColumn[] = [
  {
    field: "name",
    title: "Name",
  },
  {
    field: "ext",
    title: "Extension",
  },
  {
    field: "exists",
    title: "Exists",
  },
  {
    field: "file_tags",
    title: "Tags",
  },
  {
    field: "consultant_can_edit",
    title: "Consultant Can Edit",
  },
  {
    field: "consultant_can_view",
    title: "Consultant Can View",
  },
  {
    field: "archive",
    title: "Archive",
  },
  {
    field: "size",
    title: "Size",
  },
  {
    field: "updated_by",
    title: "Updated By",
  },
  {
    field: "updated",
    title: "Updated",
  },
  {
    field: "dropbox",
    title: "Dropbox",
  },
]

const COLUMNS_PATH = WORK_ASSIGNMENT_VIEW_URL + "_FILES"

type TPagedProps = IUseApiPagedResultsProps<IWorkAssignmentFile | IListItem>

/**
 * This component will allow the editing of file for a work assignment.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the file editing component.
 */
const FilesEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { waId, bundle, onRefresh, customer, account, location } = props
  const [openPreview, setPreviewOpen] = useState(false)

  const [selectedFile, setSelectedFile] = useState<IFile | null>(null)
  const { selectedColumns } = useTableColumnShow({
    columns,
    pathname: COLUMNS_PATH,
  })
  const debounce = useDebounce(3000)

  const initialFilters = useMemo(() => {
    return [
      {
        field: "work_assignment",
        value: waId,
      },
      {
        field: "archive",
        value: "false",
      },
    ]
  }, [waId])

  const propsApi: TPagedProps = {
    apiFunction: fileRepository.findAll,
    initialFilters,
  }
  const apiPaging = useApiPagedLocal<IWorkAssignmentFile | IListItem>(propsApi)
  const { data, call: handleRefresh, count, paging, error, loading, handlePaging, handleFilter } = apiPaging

  const handleOpenPreview = useCallback(
    (file: IFile) => () => {
      setSelectedFile(file)
      setPreviewOpen(true)
    },
    []
  )

  const handleDebounceRefresh = useCallback(() => {
    debounce(() => {
      handleRefresh()
    })
  }, [handleRefresh, debounce])

  useInterval(() => {
    handleRefresh()
  }, 3.6e6) // 1 hour

  // noinspection DuplicatedCode
  return (
    <>
      <Box>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md>
            <Breadcrumbs aria-label="breadcrumb">
              {customer !== undefined && (
                <Grid container>
                  <Grid item>
                    <Typography color="text.primary">
                      <TruncateText text={customer.name} num={20} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <small>({customerTypeName(customer)})</small>
                  </Grid>
                </Grid>
              )}
              <Typography color="text.primary">
                <TruncateText text={account.name} num={20} />
              </Typography>
              {location !== null && (
                <Typography color="text.primary">
                  <TruncateText text={locationToString(location)} num={20} />
                </Typography>
              )}
            </Breadcrumbs>
          </Grid>
          <Grid item>
            {data?.results.length !== undefined && data.results.length > 0 && (
              <>
                <FileBundler waId={waId} bundle={bundle} onChange={onRefresh} />
              </>
            )}
          </Grid>
          <Grid item>
            {location !== null && (
              <FilesImportDialog waId={waId} customer={customer} account={account} location={location} onChange={handleRefresh} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <ViewLoading loading={loading} message="Loading work assignment files." />
          <ErrorMessage error={error} />
          <FileAddEdit parentId={waId} fieldName="work_assignment" onChange={handleRefresh} repository={fileRepository} useDropzone />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCellShow columns={selectedColumns} field="name">
                    Name
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="ext">
                    Ext
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="exists" sx={{ textAlign: "center" }}>
                    Exists
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="file_tags">
                    Tags
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="consultant_can_edit" sx={{ textAlign: "center" }}>
                    Consultant
                    <br />
                    Can Edit
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="consultant_can_view" sx={{ textAlign: "center" }}>
                    Consultant
                    <br />
                    Can View
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="archive" sx={{ textAlign: "center" }}>
                    Archive
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="size" sx={{ textAlign: "center" }}>
                    Size
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="updated_by">
                    Updated By
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="updated">
                    Updated
                  </TableCellShow>
                  <TableCellShow columns={selectedColumns} field="dropbox">
                    Dropbox
                  </TableCellShow>
                  <TableCell
                    sx={{
                      textAlign: "right",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <FilesArchive waId={waId} loading={loading} handleFilter={handleFilter} initialFilters={initialFilters} />
                    <IconButton onClick={() => { handleRefresh(); }} disabled={loading} color="primary" size="small">
                      <RefreshIcon />
                    </IconButton>
                    <TableColumnShow columns={columns} pathname={COLUMNS_PATH} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data?.results as IWorkAssignmentFile[])?.map(file => (
                  <TableRow key={file.id}>
                    <TableCellShow columns={selectedColumns} field="name" sx={{ whiteSpace: "nowrap" }}>
                      <TruncateText text={file.name} num={50} middle />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="ext">
                      {file.ext}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="exists" sx={{ textAlign: "center" }}>
                      {file.file?.exists === undefined || file.file.exists === false ? "No" : "Yes"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="file_tags" sx={{ whiteSpace: "nowrap" }}>
                      {(file.file_tags as IFileTag[])
                        ?.filter((tag, index) => index < 2)
                        .map(tag => (
                          <Chip key={tag.id} sx={{ backgroundColor: tag.color }} label={tag.name} />
                        ))}
                      {(file.file_tags as IFileTag[]).length > 2 && (
                        <HtmlToolTip
                          title={
                            <>
                              {(file.file_tags as IFileTag[])?.map(tag => (
                                <Chip key={tag.id} sx={{ backgroundColor: tag.color }} label={tag.name} />
                              ))}
                            </>
                          }
                        >
                          <IconButton sx={{ mr: 0 }}>
                            <MoreIcon />
                          </IconButton>
                        </HtmlToolTip>
                      )}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="consultant_can_edit" sx={{ textAlign: "center" }}>
                      <InlineCheckbox
                        id={file.id}
                        field="consultant_can_edit"
                        value={file.consultant_can_edit}
                        repository={fileRepository}
                        disabled={loading}
                        onUpdate={handleDebounceRefresh}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="consultant_can_view" sx={{ textAlign: "center" }}>
                      <InlineCheckbox
                        id={file.id}
                        field="consultant_can_view"
                        value={file.consultant_can_view}
                        repository={fileRepository}
                        disabled={loading || file.consultant_can_edit}
                        onUpdate={handleDebounceRefresh}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="archive" sx={{ textAlign: "center" }}>
                      <InlineCheckbox
                        id={file.id}
                        field="archive"
                        value={file.archive}
                        repository={fileRepository}
                        disabled={loading}
                        onUpdate={handleDebounceRefresh}
                      />
                    </TableCellShow>
                    <TableCellShow
                      columns={selectedColumns}
                      field="size"
                      sx={{
                        textAlign: "right",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {file.file?.size !== undefined && prettyBytes(file.file.size)}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="updated_by" sx={{ whiteSpace: "nowrap" }}>
                      {file.updated_by?.name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="updated" sx={{ whiteSpace: "nowrap" }}>
                      <FormatDate value={file.updated} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="dropbox" sx={{ whiteSpace: "nowrap" }}>
                      <DropboxSaver file={file} />
                    </TableCellShow>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        textAlign: "right",
                      }}
                    >
                      <FileDownloadButton file={file} />
                      <FileAddEdit
                        parentId={waId}
                        fieldName="work_assignment"
                        selectedFile={file}
                        onChange={handleRefresh}
                        repository={fileRepository}
                        useEdit
                      />
                      <HtmlToolTip title={`Preview file.`}>
                        <IconButton color="primary" onClick={handleOpenPreview(file)}>
                          <PreviewIcon />
                        </IconButton>
                      </HtmlToolTip>
                      <FileDelete repository={fileRepository} file={file} onChange={handleRefresh} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {data?.results.length !== undefined && data.results.length === 0 && (
              <Box sx={{ mt: 2 }}>
                <Alert severity="info">No files found.</Alert>
              </Box>
            )}
          </TableContainer>

          {data?.results.length !== undefined && data.results.length > 0 && count > 1 && (
            <Box>
              <TablePaging count={count} total={data.count} page={paging?.page} onPaging={handlePaging} />
            </Box>
          )}
          <FileViewerDialog
            open={openPreview}
            files={data?.results as IFile[]}
            selectedFile={selectedFile}
            onClose={() => { setPreviewOpen(false); }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FilesEditor
