import { IWorkAssignment } from "../models/IWorkAssignment"
import * as React from "react"
import { Box } from "@mui/material"
import { isTruncated, truncateString } from "../utilities/format_utility"
import HtmlToolTip from "./HtmlToolTip"

interface IProps {
  wa: IWorkAssignment
}

/**
 * Formats the work assignment location for display in all places.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the location as a name.
 */
const WorkAssignmentLocationName: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wa } = props

  const truncateName = isTruncated(wa.location?.name)
  const truncateAddress = isTruncated(wa.location?.address)

  return wa.location !== null ? (
    <HtmlToolTip
      title={
        <>
          {wa.location.name !== undefined && (
            <>
              {wa.location.name}
              <br />
            </>
          )}
          {wa.location.address !== undefined && (
            <>
              {wa.location.address}
              <br />
            </>
          )}
          {wa.location.city !== undefined && <>{wa.location.city},</>} {wa.location.state_region} {wa.location.country}
        </>
      }
      placement="right"
    >
      {truncateName || truncateAddress ? (
        <Box>
          {wa.location.name !== undefined && (
            <>
              {truncateString(wa.location.name)}
              <br />
            </>
          )}
          {wa.location.address !== undefined && (
            <>
              {truncateString(wa.location.address)}
              <br />
            </>
          )}
          {wa.location.city !== undefined && <>{wa.location.city},</>} {wa.location.state_region} {wa.location.country}
        </Box>
      ) : (
        <>
          {wa.location.name !== undefined && (
            <>
              {wa.location.name}
              <br />
            </>
          )}
          {wa.location.address !== undefined && (
            <>
              {wa.location.address}
              <br />
            </>
          )}
          {wa.location.city !== undefined && <>{wa.location.city},</>} {wa.location.state_region} {wa.location.country}
        </>
      )}
    </HtmlToolTip>
  ) : (
    <>None</>
  )
}

export default WorkAssignmentLocationName
