import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { ILocation, IMapPosition } from "../models/ILocation"
import { IMapLocation } from "../models/component/IMapLocation"
import { icon, PushpinIcon } from "../utilities/icons_utilities"
import { useTheme } from "@mui/material"
import useMapType from "../hooks/useMapType"
import { DEFAULT_CENTER, GOOGLE_MAPS } from "../../config/config"

interface IProps {
  primaryLocation: ILocation | IMapLocation | null | undefined
  initialMapPosition?: IMapPosition | null | undefined
  onPositionChange?: (mapPosition: IMapPosition) => void
  showCenter?: boolean
  showInitialPosition?: boolean
}

/**
 * Displays a map for the location.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the location map.
 */
const LocationMap: React.FunctionComponent<IProps> = (props: IProps) => {
  const { primaryLocation, initialMapPosition, onPositionChange, showCenter = false, showInitialPosition = false } = props
  const theme = useTheme()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(DEFAULT_CENTER)
  const { mapType, handleMapTypeChanged } = useMapType({ map })
  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS)
  const [currentCenter, setCurrentCenter] = useState<google.maps.LatLngLiteral | null>(null)

  const handleLoad = useCallback((map: google.maps.Map) => setMap(map), [])
  const handleUnmount = useCallback(() => setMap(null), [])

  const handlePositionChanged = useCallback(() => {
    if (map !== null) {
      const center = map.getCenter()
      const zoom = map.getZoom()
      if (center !== undefined && zoom !== undefined) {
        const mapPosition: IMapPosition = {
          center: center.toJSON(),
          zoom,
        }
        onPositionChange?.(mapPosition)
        setCurrentCenter(center.toJSON())
      }
    }
  }, [map])

  const mapCenter: google.maps.LatLngLiteral | null = useMemo(() => {
    if (initialMapPosition?.center !== undefined) {
      return initialMapPosition.center
    }
    return center
  }, [currentCenter, initialMapPosition, center])

  useEffect(() => {
    if (primaryLocation !== undefined && primaryLocation !== null) {
      setCenter({
        lat: primaryLocation.latitude,
        lng: primaryLocation.longitude,
      })
      setCurrentCenter({
        lat: primaryLocation.latitude,
        lng: primaryLocation.longitude,
      })
    }
  }, [primaryLocation])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        flexGrow: "1",
        height: "400px",
      }}
      center={mapCenter}
      onLoad={handleLoad}
      onUnmount={handleUnmount}
      zoom={initialMapPosition?.zoom !== undefined ? initialMapPosition.zoom : 18}
      onBoundsChanged={handlePositionChanged}
      onMapTypeIdChanged={handleMapTypeChanged}
      mapTypeId={mapType}
    >
      {primaryLocation !== undefined && primaryLocation !== null && (
        <Marker
          position={{
            lat: primaryLocation.latitude,
            lng: primaryLocation.longitude,
          }}
          icon={icon({
            path: PushpinIcon,
            fillColor: theme.palette.primary,
          })}
        />
      )}
      {currentCenter !== undefined && currentCenter !== null && showCenter && (
        <Marker
          position={{
            lat: currentCenter.lat,
            lng: currentCenter.lng,
          }}
          icon={icon({
            path: PushpinIcon,
            fillColor: theme.palette.secondary,
          })}
        />
      )}
      {initialMapPosition !== undefined && initialMapPosition !== null && showInitialPosition && (
        <Marker
          position={initialMapPosition.center}
          icon={icon({
            path: PushpinIcon,
            fillColor: theme.palette.secondary,
          })}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default LocationMap
