import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { useCallback } from "react"
import { RestRepository } from "../repositories/RestRepository"
import { IPaging } from "../models/IPaging"

interface IUseApiActionResponse<T> {
  handleAction: (action: string, paging?: IPaging) => Promise<T | undefined>
}

export interface IUseApiActionProps<T> {
  repository: RestRepository<T>
  itemId: number
  setSaving: (saving: boolean) => void
  setSavingError: (saving: IConnectionError | undefined) => void
}

/**
 * Hook for sending an action to REST objects.
 *
 * @param {IUseApiActionResponse} props See IProps for details.
 * @returns {IUseApiActionProps} See IUseApiActionProps for details.
 */
const useApiAction = <T>(props: IUseApiActionProps<T>): IUseApiActionResponse<T> => {
  const { repository, itemId, setSaving, setSavingError } = props

  const handleAction = useCallback(
    async (action: string, paging?: IPaging): Promise<T | undefined> => {
      setSaving(true)
      setSavingError(undefined)
      try {
        const item = await repository.action(itemId, action, paging)
        setSaving(false)
        return item
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    },
    [repository, itemId, setSaving, setSavingError]
  )

  return {
    handleAction,
  }
}

export default useApiAction
