import * as React from "react";
import { useCallback } from "react";
import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Assignment, Business, ContactPhone, Contacts, Dashboard, Flag } from "@mui/icons-material";
import { Link, useLocation } from "@reach/router";
import {
  CONSULTANTS_URL,
  CONTACTS_URL,
  CUSTOMERS_URL,
  DASHBOARD_URL,
  REPORTS_URL,
  WORK_ASSIGNMENTS_URL
} from "../../config/urls";
import { graphql, useStaticQuery } from "gatsby";

// todo: remove.
const GATSBY_TOP_MENU = process.env.GATSBY_TOP_MENU === "true"

interface IProps {
  children: any
  margin?: number
  footer?: boolean
  noPaper?: boolean
}

/**
 * Wrapper page for workflow.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the wrapper page.
 */
const WorkflowPage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { children, margin = 2, footer = true, noPaper = false } = props
  const data = useStaticQuery(
    graphql`
      query WorkflowPageQuery {
        site {
          siteMetadata {
            title
            version
            company
          }
        }
      }
    `
  )

  const location = useLocation()
  const isSelected = useCallback((path: string) => location.pathname.startsWith(path), [location])
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  return (
    <Box sx={{ m: isSmall ? 0 : margin }}>
      <Grid container spacing={2}>
        {!GATSBY_TOP_MENU && (
          <Grid item>
            <Paper
              sx={{
                width: 320,
                maxWidth: "100%",
              }}
            >
              <MenuList>
                <MenuItem component={Link} to={DASHBOARD_URL} selected={isSelected(DASHBOARD_URL)}>
                  <ListItemIcon>
                    <Dashboard fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Dashboard</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to={WORK_ASSIGNMENTS_URL} selected={isSelected(WORK_ASSIGNMENTS_URL)}>
                  <ListItemIcon>
                    <Assignment fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Work Assignments</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to={CUSTOMERS_URL} selected={isSelected(CUSTOMERS_URL)}>
                  <ListItemIcon>
                    <Business fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Customers</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to={CONSULTANTS_URL} selected={isSelected(CONSULTANTS_URL)}>
                  <ListItemIcon>
                    <Contacts fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Consultants</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to={CONTACTS_URL} selected={isSelected(CONTACTS_URL)}>
                  <ListItemIcon>
                    <ContactPhone fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Contacts</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to={REPORTS_URL} selected={isSelected(REPORTS_URL)}>
                  <ListItemIcon>
                    <Flag fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Reports</ListItemText>
                </MenuItem>
              </MenuList>
            </Paper>
          </Grid>
        )}
        <Grid item xs>
          {isSmall || noPaper ? children : <Paper>{children}</Paper>}
          {footer && (
            <Box
              sx={{
                mt: 1,
                p: 2,
                textAlign: "right",
              }}
            >
              © {data.site.siteMetadata.company} {new Date().getFullYear()}
              <Box sx={{ ml: 1 }} component="small">
                v{data.site.siteMetadata.version}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default WorkflowPage
