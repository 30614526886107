import { Controller, FieldValues } from "react-hook-form"
import * as React from "react"
import { ChangeEvent } from "react"
import { Control } from "react-hook-form/dist/types/form"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"

interface IProps {
  control: Control<FieldValues, any>
  defaultValue?: string | number | boolean
  label: string
  name: string
  rules?: any
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

/**
 * A form hook MUI checkbox field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the checkbox field.
 */
const FhMuiCheckboxField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, defaultValue = "", onChange, name, label, rules = {} } = props

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                inputProps={{ "aria-label": label }}
                onBlur={field.onBlur}
                onChange={e => {
                  field.onChange(e)
                  onChange?.(e)
                }}
                checked={field.value}
                inputRef={field.ref}
              />
            }
          />
        )}
      />
    </FormGroup>
  )
}

export default FhMuiCheckboxField
