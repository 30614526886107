import * as React from "react"
import { Box, Button, Chip, Grid, IconButton, Link, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { CONSULTANTS_ENDPOINT, getConsultantPhone, IConsultant } from "../../../shared/models/main/IConsultant"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import { Link as BrowserLink } from "gatsby"
import { CONSULTANTS_EDIT_URL, CONSULTANTS_VIEW_URL } from "../../../config/urls"
import PageViewIcon from "@mui/icons-material/Pageview"
import EditIcon from "@mui/icons-material/Edit"
import { IWorkAssignmentConsultant } from "../../../shared/models/IWorkAssignmentConsultant"
import ChangeHtmlField from "../../../shared/components/ChangeHtmlField"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import FormatDate from "../../../shared/components/format/FormatDate"
import ConsultantRating from "../../../shared/components/consultants/ConsultantRating"

const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)

const CATEGORY_SX = {
  display: "inline",
  pr: 1,
}

interface IProps {
  selectedConsultant: IConsultant
  loading: boolean
  waConsultant: IWorkAssignmentConsultant | null
  waIdentifier: string | undefined
  showAddConsultantButton: boolean
  onOpenAdd: () => void
  onConsultantChange: (consultant: IConsultant) => void
}

/**
 * Rendered the consultant card for a selected consultant on the map.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const ConsultantInfoWindow: React.FunctionComponent<IProps> = (props: IProps) => {
  const { selectedConsultant, loading, waConsultant, waIdentifier, showAddConsultantButton, onOpenAdd, onConsultantChange } = props

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography sx={{ fontWeight: "bold" }}>{selectedConsultant.name}</Typography>
        </Grid>
        <Grid item>
          <ConsultantRating rating={selectedConsultant.rating} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 12 }}>
            {selectedConsultant.property_lite && <Box sx={CATEGORY_SX}>PL</Box>}
            {selectedConsultant.highly_protected_risk && <Box sx={CATEGORY_SX}>HPR</Box>}
            {selectedConsultant.highly_protected_risk_lite && <Box sx={CATEGORY_SX}>HPR Lite</Box>}
            {selectedConsultant.boiler_and_machinery && <Box sx={CATEGORY_SX}>B & M</Box>}
            {selectedConsultant.casualty && <Box sx={CATEGORY_SX}>Casualty</Box>}
          </Typography>
        </Grid>
      </Grid>
      <Table size="small" sx={{ mt: 1 }}>
        <TableBody sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableRow>
            <TableCell component="th">Email</TableCell>
            <TableCell>
              <Link href={`mailto:${selectedConsultant.first_name} ${selectedConsultant.last_name}<${selectedConsultant.email}>`}>
                {selectedConsultant.email}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Phone</TableCell>
            <TableCell>
              <Link href={`tel:${getConsultantPhone(selectedConsultant)}`}>{getConsultantPhone(selectedConsultant)}</Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Address</TableCell>
            <TableCell>{selectedConsultant.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" />
            <TableCell>
              {selectedConsultant.city}
              {",  "}
              {selectedConsultant.state_region} {"  "}
              {selectedConsultant.country}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Last Login</TableCell>
            <TableCell>
              {selectedConsultant.last_login === null ? <>Login Not Enabled</> : <FormatDate value={selectedConsultant.last_login} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Notes</TableCell>
            <TableCell>
              <ChangeHtmlField
                modelId={selectedConsultant.id}
                item={selectedConsultant}
                field="notes"
                title="Notes"
                onChange={onConsultantChange}
                value={selectedConsultant.notes}
                repository={consultantRepository}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          {showAddConsultantButton && (
            <Button size="small" disabled={loading} onClick={onOpenAdd} startIcon={<PersonAddIcon />}>
              {waIdentifier}
            </Button>
          )}
          {waConsultant !== null && (
            <>
              {waConsultant.accepted && <Chip label="Accepted" color="success" />}
              {waConsultant.declined && <Chip label="Declined" color="error" />}
              {!waConsultant.declined && !waConsultant.accepted && <Chip label="Pending" color="info" />}
            </>
          )}
        </Grid>
        <Grid item>
          <IconButton component={BrowserLink} to={`${CONSULTANTS_VIEW_URL}/${selectedConsultant.id}`} size="small">
            <PageViewIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton component={BrowserLink} to={`${CONSULTANTS_EDIT_URL}/${selectedConsultant.id}`} size="small">
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConsultantInfoWindow
