import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Admin Review 4 Days or More"
const description = "A report has been in admin review for 4 days or more."
const baseFilter: IFilter = {
  field: "admin_review_10_days_or_more",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "admin_review_4_days_or_more.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * A report has been in admin review for 4 days or more.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const AdminReviewTenDaysOrMoreReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default AdminReviewTenDaysOrMoreReport
