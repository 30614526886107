import { IListItem } from "./component/IListItem"

export const OCCUPANCY_ENDPOINT = "occupancies"

export interface IOccupancy extends IListItem {
  id: number
  name: string
  sic_code: string
  notes: string

  history_count: number
  current_count: number
  current_avg_obtained_risk_score: number
  current_avg_max_risk_score: number
}
