import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { type IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../../shared/hooks/useApiPaged"
import { type ICustomer } from "../../../shared/models/ICustomer"
import { type IAccount } from "../../../shared/models/IAccount"
import TablePaging from "../../../shared/components/TablePaging"
import { type IFilter } from "../../../shared/models/IFilter"
import WorkAssignmentLocationName from "../../../shared/components/WorkAssignmentLocationName"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import TableOrdering from "../../../shared/components/TableOrdering"
import { EXPENSE_REPORTS_URL } from "../../../config/urls"
import { type IPaging } from "../../../shared/models/IPaging"
import ViewLoading from "../../../shared/components/ViewLoading"
import AddIcon from "@mui/icons-material/Add"
import TruncateText from "../../../shared/components/TruncateText"
import { EXPENSE_REPORTS_ENDPOINT, type IExpenseReport } from "../../../shared/models/IExpenseReport"
import { type IConsultant } from "../../../shared/models/main/IConsultant"
import {
  EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT,
  type IWorkAssignmentConsultant,
} from "../../../shared/models/IWorkAssignmentConsultant"
import WorkAssignmentProgress from "../../../shared/components/WorkAssignmentProgress"
import ConsultantRate from "../../../shared/components/ConsultantRate"
import TableLoadingFull from "../../../shared/components/TableLoadingFull"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)
const wacRepository = new RestRepository<IWorkAssignmentConsultant>(EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)

interface IProps {
  expenseReport?: IExpenseReport
  consultant: IConsultant
  onChange?: () => void
}


/**
 * This component assists in adding work assignments to an expense report.
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the add work assignment's dialog.
 */
const AddWorkAssignment: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { expenseReport, consultant, onChange } = props
  const propsWa: IUseApiPagedResultsProps<IWorkAssignmentConsultant> = {
    apiFunction: wacRepository.findAll,
    pathname: EXPENSE_REPORTS_URL + "_add_wac",
  }
  const {
    data,
    count,
    paging,
    error,
    loading,
    handleLimit,
    handlePaging,
    handleOrdering,
    handleFilter,
    call: refreshResults,
  } = useApiPaged<IWorkAssignmentConsultant>(propsWa)

  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [wacIds, setWacIds] = useState<number[]>([])
  const [initial, setInitial] = useState(false)

  const baseFilters: IFilter[] = useMemo(
    () => [
      {
        field: "can_be_expense_reported",
        value: consultant.id,
        display: "Yes",
        title: "Can Be Expense Reported?",
      },
    ],
    [consultant]
  )

  const handleOpen = useCallback(() => {
    refreshResults()
    setOpen(true)
    setInitial(false)
  }, [refreshResults])

  const handleClose = useCallback(() => {
    setOpen(false)
    setWacIds([])
  }, [])

  const handleAddSelected = useCallback(
    (wac: IWorkAssignmentConsultant) => () => {
      setWacIds(ids => {
        if (ids.includes(wac.id)) {
          return ids.filter(id => id !== wac.id)
        }
        return [...ids, wac.id]
      })
    },
    []
  )

  const handleAdd = useCallback(async () => {
    if (expenseReport !== undefined) {
      try {
        setSaving(true)
        const paging: IPaging = {
          filters: [
            {
              field: "wac_ids",
              value: wacIds.join(","),
            },
          ],
        }
        await repository.action(expenseReport.id, "add_work_assignment", paging)
        setWacIds([])
        onChange?.()
        refreshResults()
        handleClose()
      } catch (e) {}
      setSaving(false)
    }
  }, [expenseReport, refreshResults, wacIds])

  useEffect(() => {
    if (!initial && !loading) {
      setInitial(true)
      handleFilter([...baseFilters])
    }
  }, [initial, baseFilters, paging?.filters])

  return (
    <>
      <Button onClick={handleOpen} startIcon={expenseReport !== undefined && <AddIcon />}>
        {expenseReport !== undefined ? "WA" : "Show"}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth fullScreen>
        <DialogTitle sx={{ pb: 0 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>{expenseReport !== undefined ? "Add Work Assignments to Expense Report" : "Work Assignments for Consultant"}</Grid>
          </Grid>
          <ViewLoading loading={saving} message="Saving changes." />
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <ErrorMessage error={error} />
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="id" title="ID" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="customer__name" title="Customer" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="account__name" title="Account" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="location__address" title="Location" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="consultant" title="Consultant" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>Progress</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                  {expenseReport !== undefined && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  data?.results?.map(wac => {
                    const workAssignment = wac.work_assignment as IWorkAssignment
                    return (
                      <TableRow key={wac.id}>
                        <TableCell>{workAssignment.identifier}</TableCell>
                        <TableCell>
                          <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                        </TableCell>
                        <TableCell>
                          <TruncateText text={(workAssignment.account as IAccount)?.name} />
                        </TableCell>
                        <TableCell>
                          <WorkAssignmentLocationName wa={workAssignment} />
                        </TableCell>
                        <TableCell>
                          <TruncateText text={(wac.consultant as IConsultant)?.name} />
                        </TableCell>
                        <TableCell>
                          <WorkAssignmentProgress workAssignment={workAssignment} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <ConsultantRate wac={wac} showDetails />
                        </TableCell>
                        {expenseReport !== undefined && (
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              alignItems: "right",
                            }}
                          >
                            <Checkbox checked={wacIds.includes(wac.id)} disabled={saving} onChange={handleAddSelected(wac)} />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            {!loading && (data === undefined || data.results.length === 0) && (
              <Alert color="info" sx={{ m: 1 }}>
                No consultants found.
              </Alert>
            )}
            <TableLoadingFull loading={loading} rows={paging?.limit} />
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TablePaging
                count={count}
                total={data?.count}
                page={paging?.page}
                limit={paging?.limit}
                onPaging={handlePaging}
                onLimit={handleLimit}
              />
            </Grid>
            <Grid item>
              {wacIds.length > 0 ? (
                <Button onClick={handleAdd} disabled={saving}>
                  Add Selected: {wacIds.length}
                </Button>
              ) : (
                <Button onClick={handleClose} disabled={saving}>
                  Close
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddWorkAssignment
