import * as React from "react"
import { useCallback, useState } from "react"
import { FormControlLabel, Switch } from "@mui/material"
import { IFilter } from "../../../shared/models/IFilter"

interface IProps {
  waId: number
  loading: boolean
  handleFilter: (filters: IFilter[]) => void
  initialFilters: IFilter[]
}

/**
 * Filters files that are archived.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The files archive filter.
 */
const FilesArchive: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { waId, loading, initialFilters, handleFilter } = props
  const [archive, setArchive] = useState(false)

  const handleArchive = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleFilter([
          {
            field: "work_assignment",
            value: waId,
          },
        ])
      } else {
        handleFilter(initialFilters)
      }
      setArchive(event.target.checked)
    },
    [waId, initialFilters, handleFilter]
  )

  return (
    <>
      <FormControlLabel
        color="primary"
        control={<Switch size="small" disabled={loading} checked={archive} onChange={handleArchive} />}
        label="Archived"
      />
    </>
  )
}

export default FilesArchive
