import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import TablePaging from "../../shared/components/TablePaging"
import { Link } from "@reach/router"
import { INVOICES_ADD_URL, INVOICES_VIEW_URL } from "../../config/urls"
import { navigate } from "gatsby"
import ClearIcon from "@mui/icons-material/Clear"
import { styles } from "../../shared/styling/general"
import TableRowSelect from "../../shared/components/TableRowSelect"
import TableOrdering from "../../shared/components/TableOrdering"
import TableColumnShow, { useTableColumnShow } from "../../shared/components/TableColumnShow"
import { type IColumn } from "../../shared/models/component/IColumn"
import TableCellShow from "../../shared/components/TableCellShow"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { type IInvoice, INVOICE_AUDIT_ENDPOINT, INVOICE_ENDPOINT } from "../../shared/models/IInvoice"
import FormatDate from "../../shared/components/format/FormatDate"
import FormatNumber from "../../shared/components/format/FormatNumber"
import useContentHeight from "../../shared/hooks/useContentHeight"
import Goto from "../../shared/components/Goto"
import ExcelDownloadButton from "../../shared/components/ExcelDownloadButton"
import Filtering from "../../shared/components/Filtering"
import { FILTERS } from "../../config/config"
import { type IFilter } from "../../shared/models/IFilter"
import { type IListItem } from "../../shared/models/component/IListItem"
import HtmlTooltip from "../../shared/components/HtmlToolTip"
import AddIcon from "@mui/icons-material/Add"
import useInvoicesAuditsGroup from "./hooks/useInvoicesAuditsGroup"
import PrivateComponent from "../../shared/components/PrivateComponent"
import { ACCESS_AUDIT_GROUP, ACCESS_INVOICES_GROUP } from "../../config/permissions"
import useFocus from "../../shared/hooks/useFocus"
import TruncateText from "../../shared/components/TruncateText"
import AuditedInvoice from "./components/AuditedInvoice"
import { type IPagedResults } from "../../shared/models/IPagedResults"
import { BASE_URL } from "../../shared/utilities/request_utility"
import TableLoadingFull from "../../shared/components/TableLoadingFull"

const columns = [
  {
    field: "number",
    title: "Number",
  },
  {
    field: "customer",
    title: "Customer",
  },
  {
    field: "account",
    title: "account",
  },
  {
    field: "location",
    title: "Location",
  },
  {
    field: "external_tracking",
    title: "External Tracking",
  },
  {
    field: "total_amount",
    title: "Amount",
  },
  {
    field: "work_assignments",
    title: "# WA",
  },
  {
    field: "audited",
    title: "Audited",
  },
  {
    field: "void",
    title: "Void",
  },
  {
    field: "quickbooks_synced",
    title: "Quickbooks",
  },
  {
    field: "invoiced_on",
    title: "Invoiced On",
  },
  {
    field: "created",
    title: "Created",
  },
  {
    field: "updated",
    title: "Updated",
  },
] as IColumn[]

/**
 * Index page for viewing all invoices.
 * @returns {React.FC} the invoice index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { selectedColumns } = useTableColumnShow({ columns })
  const height = useContentHeight(-13)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const { focused, handleFocus, handleBlur } = useFocus()

  const { repository, isInInvoicesGroup } = useInvoicesAuditsGroup()
  const [invoices, setInvoices] = useState<IPagedResults<IInvoice> | null>(null)

  const props: IUseApiPagedResultsProps<IInvoice | IListItem> = { apiFunction: repository.findAll }
  const { data, count, paging, error, loading, handlePaging, handleOrdering, handleLimit, handleFilter } = useApiPaged<
    IInvoice | IListItem
  >(props)

  const handleSelected = useCallback(async (invoice: IInvoice) => {
    await navigate(`${INVOICES_VIEW_URL}/${invoice.id}`)
  }, [])

  const invoiceFilters = useMemo(() => {
    const filters = [
      FILTERS.ACCOUNT,
      FILTERS.INVOICE_WORK_ASSIGNMENT_ID,
      FILTERS.INVOICE_NUMBER,
      FILTERS.CUSTOMER,
      FILTERS.INVOICED_ON,
    ] as IFilter[]
    if (isInInvoicesGroup) {
      filters.push(FILTERS.INVOICE_VOIDED)
    }
    return filters
  }, [isInInvoicesGroup])

  const handleInvoiceChange = useCallback((invoice: IInvoice) => {
    setInvoices(invoices => {
      if (invoices !== null) {
        const results = invoices.results.map(invoice1 => {
          if (invoice1.id === invoice.id) {
            return invoice
          }
          return invoice1
        })
        return {
          ...invoices,
          results,
        }
      }
      return invoices
    })
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      setInvoices(data as IPagedResults<IInvoice>)
    }
  }, [data])

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={focused ? 6 : 3} md={focused ? 4 : 2} lg={focused ? 3 : 1}>
            <Goto repository={repository} onFocus={handleFocus} onBlur={handleBlur} url={INVOICES_VIEW_URL} />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Filtering availableFilters={invoiceFilters} filters={paging?.filters} onFilter={handleFilter} disabled={loading} />
          </Grid>
          <Grid item>
            <Toolbar disableGutters variant="dense">
              <PrivateComponent
                groupNames={[ACCESS_INVOICES_GROUP]}
                component={
                  <>
                    <Button component={Link} to={INVOICES_ADD_URL} startIcon={<AddIcon />}>
                      Invoice
                    </Button>
                    <ExcelDownloadButton
                      endpoint={INVOICE_ENDPOINT}
                      filename="invoices.xlsx"
                      asyncEndpoint={`${BASE_URL}/lookups/invoices_async_file`}
                      limit={invoices?.count}
                      paging={paging}
                    />
                  </>
                }
                componentElse={
                  <ExcelDownloadButton
                    endpoint={INVOICE_AUDIT_ENDPOINT}
                    filename="invoices.xlsx"
                    asyncEndpoint={`${BASE_URL}/lookups/invoices_async_file`}
                    limit={invoices?.count}
                    paging={paging}
                  />
                }
              />
              <TableColumnShow columns={columns} />
            </Toolbar>
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCellShow columns={selectedColumns} field="number">
                  <TableOrdering ordering={paging?.ordering} field="number" title="Number" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="customer">
                  <TableOrdering ordering={paging?.ordering} field="customer" title="Customer" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="account">
                  <TableOrdering ordering={paging?.ordering} field="account" title="Account" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="location">
                  Location
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="external_tracking" sx={{ whiteSpace: "nowrap" }}>
                  External Tracking
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="total_amount">
                  Total
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="work_assignments" sx={{ whiteSpace: "nowrap" }}>
                  # WA
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="audited">
                  <TableOrdering ordering={paging?.ordering} field="audited" title="Audited" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="void">
                  <TableOrdering ordering={paging?.ordering} field="void" title="Void" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="quickbooks_synced">
                  <TableOrdering ordering={paging?.ordering} field="quickbooks_synced" title="Quickbooks" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="invoiced_on" sx={{ whiteSpace: "nowrap" }}>
                  Invoiced On
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="created">
                  <TableOrdering ordering={paging?.ordering} field="created" title="Created" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="updated">
                  <TableOrdering ordering={paging?.ordering} field="updated" title="Updated" onOrdering={handleOrdering} />
                </TableCellShow>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                invoices?.results?.map(invoice => (
                  <TableRowSelect key={invoice.id} item={invoice} onSelected={handleSelected}>
                    <TableCellShow columns={selectedColumns} field="number" sx={{ whiteSpace: "nowrap" }}>
                      {invoice.number}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="customer" sx={{ whiteSpace: "nowrap" }}>
                      <TruncateText text={invoice.customer?.name} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="account" sx={{ whiteSpace: "nowrap" }}>
                      <TruncateText text={invoice.account?.name} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="location" sx={{ whiteSpace: "nowrap" }}>
                      <TruncateText text={invoice.location} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="external_tracking" sx={{ whiteSpace: "nowrap" }}>
                      <TruncateText text={invoice.external_tracking} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="total_amount" sx={{ textAlign: "right" }}>
                      <FormatNumber value={invoice.total_amount} twoDecimalPlaces />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="work_assignments">
                      {invoice.work_assignments.length}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="audited">
                      <PrivateComponent
                        groupNames={[ACCESS_AUDIT_GROUP]}
                        component={<AuditedInvoice invoice={invoice} onChange={handleInvoiceChange} />}
                        componentElse={<AuditedInvoice invoice={invoice} onChange={handleInvoiceChange} asLabel />}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="void">
                      {invoice.void !== null && invoice.voided_by !== null && (
                        <HtmlTooltip title={invoice.voided_by.name}>
                          <Grid container spacing={2}>
                            <Grid item>
                              <ClearIcon color="error" />
                            </Grid>
                            <Grid item>
                              <FormatDate value={invoice.void} />
                            </Grid>
                          </Grid>
                        </HtmlTooltip>
                      )}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="quickbooks_synced">
                      <FormatDate value={invoice.quickbooks_synced} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="invoiced_on">
                      <FormatDate value={invoice.primary_invoice_date} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="created">
                      <FormatDate value={invoice.created} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="updated">
                      <FormatDate value={invoice.updated} />
                    </TableCellShow>
                  </TableRowSelect>
                ))}
            </TableBody>
          </Table>
          {!loading && (data === undefined || data.results.length === 0) && (
            <Alert color="info" sx={{ m: 1 }}>
              No invoices found.
            </Alert>
          )}
          <TableLoadingFull loading={loading} rows={paging?.limit} />
        </TableContainer>
      </Box>
      <Divider />
      <TablePaging
        count={count}
        total={invoices?.count}
        page={paging?.page}
        size={isSmall ? "small" : "medium"}
        limit={paging?.limit}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default IndexPage
