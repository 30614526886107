import { Avatar, Grid } from "@mui/material"
import { green, red } from "@mui/material/colors"
import CheckIcon from "@mui/icons-material/CheckRounded"
import RemoveIcon from "@mui/icons-material/RemoveRounded"
import * as React from "react"

interface IProps {
  value: number | string | any | null | undefined
}

/**
 * Displays a checkmark and the value.
 *
 * @param {IProps} props See props for details.
 * @returns {React.FunctionComponent<IProps>} the checkmark component.
 */
const CheckMark: React.FunctionComponent<IProps> = (props: IProps) => {
  const { value } = props

  return (
    <Grid container spacing={2}>
      <Grid item>
        {value !== undefined && value !== null && value !== false && value !== "" && value !== 0 ? (
          <Avatar
            sx={{
              bgcolor: green[500],
              width: 20,
              height: 20,
            }}
          >
            <CheckIcon />
          </Avatar>
        ) : (
          <Avatar
            sx={{
              bgcolor: red[500],
              width: 20,
              height: 20,
            }}
          >
            <RemoveIcon />
          </Avatar>
        )}
      </Grid>
      <Grid item xs>
        {value}
      </Grid>
    </Grid>
  )
}

export default CheckMark
