import { axiosInstance, urlSearchParams } from "../utilities/request_utility";
import { IPaging } from "../models/IPaging";

/**
 * Repository for requesting message api endpoints.
 */
export class MessagesRepository {
  endpointName: string

  /**
   * Build a message repository.
   *
   * @param {string} endpointName name of the endpoint.
   */
  constructor(endpointName: string = "messages") {
    this.endpointName = endpointName
  }

  send = async <S>(name: string, paging: IPaging): Promise<S> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/${name}/${queryString}`)
    return data as S
  }
}
