import * as React from "react"
import { useMemo } from "react"
import { Box, Chip, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { customerTypeName, ICustomer } from "../../../shared/models/ICustomer"
import { IAccount } from "../../../shared/models/IAccount"
import WorkAssignmentProgress from "../../../shared/components/WorkAssignmentProgress"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import { Link as BrowserLink } from "gatsby"
import { WORK_ASSIGNMENT_EDIT_URL, WORK_ASSIGNMENT_VIEW_URL } from "../../../config/urls"
import PageViewIcon from "@mui/icons-material/Pageview"
import EditIcon from "@mui/icons-material/Edit"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT, workAssignmentProgress } from "../../../shared/models/IWorkAssignment"
import WorkAssignmentConsultantName from "../../../shared/components/WorkAssignmentConsultantName"
import ChangeHtmlField from "../../../shared/components/ChangeHtmlField"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ChangeDate from "../../work_assignments/components/ChangeDate"
import AssigneeEditor from "../../../shared/components/AssigneeEditor"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  selectedWa: IWorkAssignment
  onWorkAssignmentChange: (wa: IWorkAssignment) => void
}

/**
 * Displays work assignment information on the map.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the wa info for the map.
 */
const WaInfoWindow: React.FunctionComponent<IProps> = (props: IProps) => {
  const { selectedWa, onWorkAssignmentChange } = props
  const progress = useMemo(() => workAssignmentProgress(selectedWa), [selectedWa])

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs>
          <Typography sx={{ fontWeight: "bold" }}>
            {(selectedWa.customer as ICustomer).name}
            <Box component="sup" sx={{ pl: 1 }}>
              {customerTypeName(selectedWa.customer as ICustomer)}
            </Box>
          </Typography>
        </Grid>
        <Grid item>
          <Chip label={selectedWa.priority} color="info" />
        </Grid>
        <Grid item>
          {progress.isComplete() ? (
            <Chip label="Completed" color="success" />
          ) : selectedWa.is_report_sent_account_late ? (
            <Chip label="Late" color="error" />
          ) : (
            <Chip label="On Time" />
          )}
        </Grid>
      </Grid>
      <Table size="small">
        <TableBody sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableRow>
            <TableCell component="th">ID</TableCell>
            <TableCell>{selectedWa.identifier}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Account</TableCell>
            <TableCell>{(selectedWa.account as IAccount).name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Status</TableCell>
            <TableCell>
              <WorkAssignmentProgress workAssignment={selectedWa} onChange={onWorkAssignmentChange} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Location</TableCell>
            <TableCell>{selectedWa.location?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">City</TableCell>
            <TableCell>{selectedWa.location?.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Country</TableCell>
            <TableCell>{selectedWa.location?.country}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Inspection Type</TableCell>
            <TableCell>{selectedWa.inspection_type_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Location Specialty</TableCell>
            <TableCell>{selectedWa.location?.specialty?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">TIV</TableCell>
            <TableCell>
              <FormatNumber value={selectedWa.total_insured_value} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Assignee</TableCell>
            <TableCell>
              <AssigneeEditor wa={selectedWa} asButton onChange={onWorkAssignmentChange} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Consultants</TableCell>
            <TableCell>
              <WorkAssignmentConsultantName wa={selectedWa} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">RLI Getting Paid</TableCell>
            <TableCell>
              <FormatNumber value={selectedWa.rli_getting_paid} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Suggested Consultant Fee</TableCell>
            <TableCell>
              <FormatNumber value={selectedWa.suggested_consultant_amount} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Due Date</TableCell>
            <TableCell>
              <ChangeDate
                waId={selectedWa.id}
                field="due_date"
                onChange={onWorkAssignmentChange}
                title="Due Date"
                value={selectedWa.due_date}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Con. Due Date</TableCell>
            <TableCell>
              <ChangeDate
                waId={selectedWa.id}
                field="consultant_due_date"
                onChange={onWorkAssignmentChange}
                title="Consultant Due Date"
                value={selectedWa.consultant_due_date}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Internal Notes</TableCell>
            <TableCell>
              <ChangeHtmlField
                modelId={selectedWa.id}
                item={selectedWa}
                field="notes"
                title="Internal Notes"
                onChange={onWorkAssignmentChange}
                value={selectedWa.notes}
                repository={waRepository}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Job Status</TableCell>
            <TableCell>
              <ChangeHtmlField
                modelId={selectedWa.id}
                item={selectedWa}
                field="job_status"
                title="Job Status"
                onChange={onWorkAssignmentChange}
                value={selectedWa.job_status}
                repository={waRepository}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid container spacing={2}>
        <Grid item xs />
        <Grid item>
          <IconButton component={BrowserLink} to={`${WORK_ASSIGNMENT_VIEW_URL}/${selectedWa.id}`} size="small">
            <PageViewIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton component={BrowserLink} to={`${WORK_ASSIGNMENT_EDIT_URL}/${selectedWa.id}`} size="small">
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default WaInfoWindow
