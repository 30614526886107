import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Grid, IconButton, Tab, Tabs } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import {
  consultantLocation,
  CONSULTANTS_ENDPOINT,
  DEFAULT_PHONE_TYPE,
  DEFAULT_PREFERRED_COM_TYPE,
  DEFAULT_PREFERRED_PAYMENT_TYPES,
  DEFAULT_RATING_TYPE,
  type IConsultant,
  PHONE_TYPES,
  PREFERRED_COM_TYPES,
  PREFERRED_PAYMENT_TYPES,
  RATING_TYPES,
} from "../../shared/models/main/IConsultant"
import { useApiRead } from "../../shared/hooks/useApiRead"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { styles } from "../../shared/styling/general"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { Update as UpdateIcon } from "@mui/icons-material"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { CONNECTION_ERROR, type IConnectionError } from "../../shared/models/IConnectionError"
import useFormLocateAddress from "../../shared/hooks/useFormLocateAddress"
import SelectFilteredMultiple from "../../shared/components/SelectFilteredMultiple"
import { type IListItem } from "../../shared/models/component/IListItem"
import ConsultantLocationMap from "../../shared/components/ConsultantLocationMap"
import { type ILanguage, LANGUAGES_ENDPOINT } from "../../shared/models/ILanguage"
import useApiEdit, { type IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import FhMuiIdField from "../../shared/components/form/FhMuiIdField"
import FhMuiTextField from "../../shared/components/form/FhMuiTextField"
import { requiredRule } from "../../shared/utilities/form_utility"
import FhMuiSelectField from "../../shared/components/form/FhMuiSelectField"
import FhMuiMdField from "../../shared/components/form/FhMuiMdField"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { valueOrEmpty, valueOrNull, valueOrUndefined } from "../../shared/utilities/format_utility"
import FhMuiPhoneField from "../../shared/components/form/FhMuiPhoneField"
import FhMuiCheckboxField from "../../shared/components/form/FhMuiCheckboxField"
import { countriesList, DEFAULT_COUNTRY_CODE } from "../../config/countries"
import { ACCESS_EXPENSE_REPORT_GROUP } from "../../config/permissions"
import PrivateComponent from "../../shared/components/PrivateComponent"

const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)
const lookupLanguageRepository = new RestRepository<ILanguage | IListItem>(LANGUAGES_ENDPOINT)

const COUNTRIES_LIST = countriesList()

/**
 * Renders a form for editing a consultant.
 *
 * @returns {React.FC} the consultant form.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const { control, handleSubmit, getValues, setValue, formState, setError } = useForm()
  const { tab, handleTabChange } = useTabPanel()
  const { tab: tabAddress, handleTabChange: handleAddressTabChange } = useTabPanel()

  const { locateAddress } = useFormLocateAddress({ getValues })
  const [hasData, setHasData] = useState(false)
  const [lookupError, setLookupError] = useState<IConnectionError | undefined>()
  const [consultant, setConsultant] = useState<IConsultant | undefined>()

  const { data, loading, error } = useApiRead<IConsultant>({ apiFunction: consultantRepository.read })

  const editOptions: IUseApiEditProps<IConsultant> = {
    apiFunction: consultantRepository.edit,
    setError,
  }
  const { handleEdit, saving, connectionError } = useApiEdit<IConsultant>(editOptions)

  const handleSave = useCallback(async (consultant1: IConsultant) => {
    if (consultant1.contract_date === "") consultant1.contract_date = null
    if (consultant1.sign_date === "") consultant1.sign_date = null
    if (consultant1.business_cards_received === "") consultant1.business_cards_received = null
    await handleEdit(consultant1)
  }, [])

  const handleLocateAddress = useCallback(
    (alt: boolean = true) =>
      async () => {
        if (consultant !== undefined) {
          try {
            const location = await locateAddress(alt)
            const { longitude, latitude } = location
            setValue(`longitude${alt ? "_alt" : ""}`, longitude)
            setValue(`latitude${alt ? "_alt" : ""}`, latitude)
            setConsultant({
              ...consultant,
              longitude,
              latitude,
              radius: getValues(`radius${alt ? "_alt" : ""}`),
            })
          } catch (reason: any) {
            if (reason?.response !== undefined) {
              setLookupError(reason.response as IConnectionError)
            } else {
              setLookupError(CONNECTION_ERROR)
            }
          }
        }
      },
    [getValues, consultant]
  )

  const handleLanguages = useCallback((languages: IListItem[]) => {
    setValue("languages", languages)
  }, [])

  const handleWillTravelRegions = useCallback((regions: IListItem[]) => {
    setValue(
      "will_travel_regions",
      regions.map(region => region.id)
    )
  }, [])

  const willTravelRegions = useMemo(() => {
    return consultant?.will_travel_regions?.map(region => {
      const item: IListItem = {
        id: region,
        name: region,
      }
      return item
    })
  }, [consultant])

  useEffect(() => {
    if (data !== undefined && !loading && consultant === undefined && !hasData) {
      setHasData(true)
      setConsultant(data)
      setValue("id", data.id)
      setValue("first_name", data.first_name)
      setValue("last_name", data.last_name)
      setValue("legal_entity_name", data.legal_entity_name)
      setValue("active", data.active)
      setValue("send_weekly_reminder_email", data.send_weekly_reminder_email)

      setValue("highly_protected_risk", data.highly_protected_risk)
      setValue("property_lite", data.property_lite)

      setValue("highly_protected_risk_lite", data.highly_protected_risk_lite)
      setValue("boiler_and_machinery", data.boiler_and_machinery)
      setValue("casualty", data.casualty)
      setValue("tech_reviewer", data.tech_reviewer)
      setValue("admin_reviewer", data.admin_reviewer)
      setValue("plan_reviewer", data.plan_reviewer)

      setValue("rating", data.rating)
      setValue("email", data.email)
      setValue("email_2", data.email_2)
      setValue("preferred_com", data.preferred_com)
      setValue("expense_report_payment_type", data.expense_report_payment_type)
      setValue("phone", data.phone)
      setValue("phone_ext", data.phone_ext)
      setValue("phone_type", valueOrEmpty(data.phone_type))
      setValue("phone_2", data.phone_2)
      setValue("phone_2_ext", data.phone_2_ext)
      setValue("phone_2_type", valueOrEmpty(data.phone_2_type))

      setValue("address", valueOrUndefined(data.address))
      setValue("address_2", valueOrUndefined(data.address_2))
      setValue("city", valueOrUndefined(data.city))
      setValue("state_region", valueOrUndefined(data.state_region))
      setValue("country", valueOrUndefined(data.country))
      setValue("postal_code", valueOrUndefined(data.postal_code))
      setValue("longitude", valueOrNull(data.longitude))
      setValue("latitude", valueOrNull(data.latitude))
      setValue("radius", valueOrNull(data.radius))

      setValue("address_alt", valueOrUndefined(data.address_alt))
      setValue("address_2_alt", valueOrUndefined(data.address_2_alt))
      setValue("city_alt", valueOrUndefined(data.city_alt))
      setValue("state_region_alt", valueOrUndefined(data.state_region_alt))
      setValue("country_alt", valueOrUndefined(data.country_alt))
      setValue("postal_code_alt", valueOrUndefined(data.postal_code_alt))
      setValue("longitude_alt", valueOrNull(data.longitude_alt))
      setValue("latitude_alt", valueOrNull(data.latitude_alt))
      setValue("radius_alt", valueOrNull(data.radius_alt))

      setValue("start_of_property_experience", valueOrNull(data.start_of_property_experience))
      setValue("start_of_hpr_experience", valueOrNull(data.start_of_hpr_experience))
      setValue("start_of_casualty_experience", valueOrNull(data.start_of_casualty_experience))
      setValue("contract_date", valueOrEmpty(data.contract_date))
      setValue("sign_date", valueOrEmpty(data.sign_date))
      setValue("business_cards_received", valueOrEmpty(data.business_cards_received))
      setValue("other_experience", valueOrUndefined(data.other_experience))
      setValue("notes", data.notes)
    }
  }, [data, loading])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Edit Consultant" loading={loading || saving} errors={formState.errors} helpDocsId={235} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        {consultant !== undefined && (
          <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={styles.tabsBox}>
                  <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Primary" />
                    <Tab label="Additional Info" />
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
            <TabPanel value={tab} index={0}>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              >
                <Grid item xs={6}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <FhMuiIdField control={control} />
                      <FhMuiTextField control={control} label="First Name" name="first_name" rules={requiredRule()} />
                    </Grid>
                    <Grid item xs={6}>
                      <FhMuiTextField control={control} label="Last Name" name="last_name" rules={requiredRule()} />
                    </Grid>
                    <PrivateComponent
                      groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
                      component={
                        <Grid item xs={6}>
                          <FhMuiTextField control={control} label="Legal Entity Name" name="legal_entity_name" />
                        </Grid>
                      }
                    />
                    <Grid item xs={6}>
                      <FhMuiSelectField
                        control={control}
                        items={[
                          {
                            key: 0,
                            value: "------",
                          },
                          ...RATING_TYPES,
                        ]}
                        defaultValue={DEFAULT_RATING_TYPE.key}
                        label="Rating"
                        name="rating"
                      />
                    </Grid>
                    <Grid item>
                      <FhMuiCheckboxField control={control} name="active" label="Active" />
                    </Grid>
                    <Grid item xs>
                      <FhMuiCheckboxField control={control} name="send_weekly_reminder_email" label="Send Weekly Reminder Email" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="highly_protected_risk" label="HPR" />
                        </Grid>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="property_lite" label="PL" />
                        </Grid>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="highly_protected_risk_lite" label="HPR Lite" />
                        </Grid>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="boiler_and_machinery" label="B & M" />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="casualty" label="Casualty" />
                        </Grid>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="tech_reviewer" label="Tech Reviewer" />
                        </Grid>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="admin_reviewer" label="Admin Reviewer" />
                        </Grid>
                        <Grid item xs>
                          <FhMuiCheckboxField control={control} name="plan_reviewer" label="Plan Reviewer" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <FhMuiTextField control={control} label="Email" name="email" type="email" rules={requiredRule()} />
                      <Box sx={{ mt: 2 }}>
                        <FhMuiTextField control={control} label="Email 2" name="email_2" type="email" />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <FhMuiSelectField
                        control={control}
                        items={PREFERRED_COM_TYPES}
                        defaultValue={DEFAULT_PREFERRED_COM_TYPE.key}
                        name="preferred_com"
                        label="Preferred Com"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <FhMuiPhoneField control={control} label="Phone" name="phone" rules={requiredRule()} />
                        </Grid>
                        <Grid item xs={3}>
                          <FhMuiTextField control={control} label="Ext" name="phone_ext" />
                        </Grid>
                        <Grid item xs={4}>
                          <FhMuiSelectField
                            control={control}
                            items={[
                              {
                                key: "",
                                value: "------",
                              },
                              ...PHONE_TYPES,
                            ]}
                            defaultValue={DEFAULT_PHONE_TYPE.key}
                            name="phone_type"
                            label="Phone Type"
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <FhMuiPhoneField control={control} label="Phone 2" name="phone_2" />
                        </Grid>
                        <Grid item xs={3}>
                          <FhMuiTextField control={control} label="Ext 2" name="phone_2_ext" />
                        </Grid>
                        <Grid item xs={4}>
                          <FhMuiSelectField
                            control={control}
                            items={[
                              {
                                key: "",
                                value: "------",
                              },
                              ...PHONE_TYPES,
                            ]}
                            defaultValue={DEFAULT_PHONE_TYPE.key}
                            name="phone_2_type"
                            label="Phone Type 2"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <SelectFilteredMultiple
                        name="will_travel_regions"
                        label="Will Travel Regions"
                        defaultValue={willTravelRegions}
                        onChange={handleWillTravelRegions}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FhMuiMdField control={control} label="Notes" name="notes" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sx={{ mt: -3 }}>
                      <Tabs value={tabAddress} onChange={handleAddressTabChange}>
                        <Tab label="Address" />
                        <Tab label="Address 2" />
                      </Tabs>
                      <TabPanel index={tabAddress} value={0}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ mt: 2 }}>
                            <FhMuiTextField control={control} label="Address" name="address" />
                          </Grid>
                          <Grid item xs={12}>
                            <FhMuiTextField control={control} label="Address 2" name="address_2" />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiTextField control={control} label="City" name="city" />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiTextField control={control} label="State/Region" name="state_region" />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiSelectField
                              control={control}
                              items={COUNTRIES_LIST}
                              defaultValue={DEFAULT_COUNTRY_CODE}
                              label="Country"
                              name="country"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiTextField control={control} label="Postal Code" name="postal_code" />
                          </Grid>
                          <Grid item xs>
                            <FhMuiTextField control={control} type="number" label="Longitude" name="longitude" />
                          </Grid>
                          <Grid item xs>
                            <FhMuiTextField control={control} type="number" label="Latitude" name="latitude" />
                          </Grid>
                          <Grid item xs>
                            <FhMuiTextField control={control} type="number" label="Radius" name="radius" />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton color="primary" component="span" onClick={handleLocateAddress(false)}>
                              <UpdateIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel index={tabAddress} value={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ mt: 2 }}>
                            <FhMuiTextField control={control} label="Address" name="address_alt" />
                          </Grid>
                          <Grid item xs={12}>
                            <FhMuiTextField control={control} label="Address 2" name="address_2_alt" />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiTextField control={control} label="City" name="city_alt" />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiTextField control={control} label="State/Region" name="state_region_alt" />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiSelectField
                              control={control}
                              items={COUNTRIES_LIST}
                              defaultValue={DEFAULT_COUNTRY_CODE}
                              label="Country"
                              name="country_alt"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FhMuiTextField control={control} label="Postal Code" name="postal_code_alt" />
                          </Grid>
                          <Grid item xs>
                            <FhMuiTextField control={control} type="number" label="Longitude" name="longitude_alt" />
                          </Grid>
                          <Grid item xs>
                            <FhMuiTextField control={control} type="number" label="Latitude" name="latitude_alt" />
                          </Grid>
                          <Grid item xs>
                            <FhMuiTextField control={control} type="number" label="Radius" name="radius_alt" />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton color="primary" component="span" onClick={handleLocateAddress(true)}>
                              <UpdateIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                    <Grid item xs={12}>
                      <ErrorMessage error={lookupError} />
                      <ConsultantLocationMap primaryLocation={consultantLocation(consultant)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              >
                <Grid item xs={5}>
                  <PrivateComponent
                    groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
                    component={
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FhMuiSelectField
                            control={control}
                            items={PREFERRED_PAYMENT_TYPES}
                            defaultValue={DEFAULT_PREFERRED_PAYMENT_TYPES.key}
                            name="expense_report_payment_type"
                            label="Expense Report Payment Type"
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }} />
                      </Grid>
                    }
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FhMuiTextField
                        control={control}
                        type="number"
                        label="Property Experience (4-digit year)"
                        name="start_of_property_experience"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FhMuiTextField
                        control={control}
                        type="number"
                        label="HPR Experience (4-digit year)"
                        name="start_of_hpr_experience"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FhMuiTextField
                        control={control}
                        type="number"
                        label="Casualty Experience (4-digit year)"
                        name="start_of_casualty_experience"
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12} sx={{ mt: 2 }} />
                    <Grid item xs={6}>
                      <FhMuiTextField control={control} type="date" label="Contract Date" name="contract_date" />
                    </Grid>
                    <Grid item xs={6}>
                      <FhMuiTextField control={control} type="date" label="Sign Date" name="sign_date" />
                    </Grid>
                    <Grid item xs={6}>
                      <FhMuiTextField control={control} type="date" label="Business Cards Received" name="business_cards_received" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectFilteredMultiple
                        name="languages"
                        label="Languages"
                        defaultValue={consultant.languages}
                        repository={lookupLanguageRepository}
                        onChange={handleLanguages}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FhMuiTextField control={control} multiline label="Other Experience" name="other_experience" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <FormActionPanel />
          </Box>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
