import * as React from "react"
import { useCallback, useState } from "react"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../../shared/models/IExpenseReport"
import FormatDate from "../../../shared/components/format/FormatDate"
import { LoadingButton } from "@mui/lab"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ViewError from "../../../shared/components/ViewError"
import HtmlToolTip from "../../../shared/components/HtmlToolTip"
import { Box } from "@mui/material"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)

interface IProps {
  expenseReportId: number
  action: string
  value: string | null
  label?: string | undefined
  title?: string | undefined
  onChange: (expenseReport: IExpenseReport) => void
}

/**
 * Sign off on different actions for an expense report.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the sign off button component.
 */
const SignOffButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { expenseReportId, action, value, label, title, onChange } = props

  const [saving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleSignOff = useCallback(async () => {
    try {
      setSaving(true)
      const expenseReport1 = await repository.action(expenseReportId, action)
      onChange(expenseReport1)
      setSaving(false)
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [expenseReportId, action])

  return (
    <>
      <ViewError error={savingError} />
      {value !== null ? (
        <HtmlToolTip title={label !== undefined ? <Box>{label}</Box> : ""}>
          <Box>
            <LoadingButton loading={saving} onClick={handleSignOff} color="success">
              {title !== undefined && title + ": "}
              <FormatDate value={value} />
            </LoadingButton>
          </Box>
        </HtmlToolTip>
      ) : (
        <LoadingButton loading={saving} onClick={handleSignOff} color="warning">
          {title !== undefined && title + ": "}Sign Off
        </LoadingButton>
      )}
    </>
  )
}

export default SignOffButton
