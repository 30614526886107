import * as React from "react"
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react"
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import FormatDate from "../../../shared/components/format/FormatDate"
import { LoadingButton } from "@mui/lab"
import BuildIcon from "@mui/icons-material/Build"
import { IInvoice } from "../../../shared/models/IInvoice"
import useInvoicesAuditsGroup from "../hooks/useInvoicesAuditsGroup"
import DownloadIcon from "@mui/icons-material/Download"
import DialogControls from "../../../shared/components/DialogControls"
import useAzureStorage from "../../../shared/hooks/useAzureStorage"
import StorageFileDownloadButton from "../../../shared/components/StorageFileDownloadButton"
import { IMinimalFile } from "../../../shared/models/IFile"
import FileViewerDialog from "../../../shared/components/files/FileViewerDialog"

interface IProps {
  invoice: IInvoice
  onChange: () => void
}

/**
 * Generates and provides a download link for an invoice document.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component to generate an invoice.
 */
const GenerateInvoiceDocument: React.FunctionComponent<IProps> = (props: IProps) => {
  const { invoice, onChange } = props

  const { repository } = useInvoicesAuditsGroup()
  const [generatingInvoiceFile, setGeneratingInvoiceFile] = useState(false)
  const invoiceFileAnchorRef = useRef<HTMLDivElement>(null)
  const [openInvoiceFileMenu, setOpenInvoiceFileMenu] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [openFinalDialog, setOpenFinalDialog] = useState(false)
  const [fileSelected, setFileSelected] = useState<File | null>(null)
  const [inputKey, setInputKey] = useState<string>(Math.random().toString(36))
  const { uploading, uploadFile } = useAzureStorage()

  const handleGenerateFile = useCallback(async () => {
    if (invoice !== null) {
      setGeneratingInvoiceFile(true)
      setOpenInvoiceFileMenu(false)
      try {
        await repository.action(invoice.id, "generate_invoice_file")
      } catch (e) {}
      onChange()
      setGeneratingInvoiceFile(false)
    }
  }, [repository, invoice])

  // noinspection DuplicatedCode
  const handleSave = useCallback(async () => {
    await uploadFile(invoice.file_final, fileSelected)
    setInputKey(Math.random().toString(36))
    setOpenFinalDialog(false)
    onChange()
  }, [fileSelected])

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files.length > 0) {
      setFileSelected(e.target.files[0])
    }
  }, [])

  const invoiceFile: IMinimalFile | null = useMemo(() => {
    if (invoice.file?.exists !== undefined && invoice.file.exists !== false) {
      return {
        name: `invoice-${invoice.id}`,
        ext: "docx",
        file: invoice.file,
      }
    }
    return null
  }, [invoice])

  return (
    <>
      {invoiceFile !== null && (
        <FileViewerDialog open={previewOpen} files={[invoiceFile]} selectedFile={invoiceFile} onClose={() => setPreviewOpen(false)} />
      )}
      {invoice?.file?.exists !== undefined && invoice?.file?.exists !== false && !generatingInvoiceFile ? (
        <>
          <ButtonGroup ref={invoiceFileAnchorRef} variant="text">
            <Button onClick={() => window.open(invoice.file.read_url, "_blank")} startIcon={<DownloadIcon />}>
              Download
            </Button>
            <Button size="small" onClick={() => setOpenInvoiceFileMenu(o => !o)}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <ButtonGroup ref={invoiceFileAnchorRef} variant="text">
            <LoadingButton
              loadingPosition="start"
              loading={generatingInvoiceFile}
              onClick={handleGenerateFile}
              startIcon={<BuildIcon />}
              disabled={generatingInvoiceFile}
            >
              {generatingInvoiceFile ? "Generating..." : "Generate"}
            </LoadingButton>
            <Button size="small" onClick={() => setOpenInvoiceFileMenu(o => !o)}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </>
      )}

      <Popover
        open={openInvoiceFileMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setOpenInvoiceFileMenu(false)}
        anchorEl={invoiceFileAnchorRef.current}
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setOpenInvoiceFileMenu(false)}>
            <MenuList autoFocusItem>
              {invoiceFile !== null && (
                <MenuItem onClick={() => setPreviewOpen(true)} sx={{ display: "block" }}>
                  Preview
                </MenuItem>
              )}
              {invoice?.file?.exists !== undefined && invoice.file.exists !== false && (
                <MenuItem onClick={handleGenerateFile} sx={{ display: "block" }}>
                  <Box component="div">
                    <Box component="span" sx={{ pr: 1 }}>
                      Regenerate Invoice
                    </Box>
                    <FormatDate value={invoice.file.exists as string} />
                  </Box>
                  <small>Using: {invoice.priority_template_name}</small>
                </MenuItem>
              )}
              <MenuItem onClick={() => setOpenFinalDialog(true)}>Final Invoice...</MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>

      <Dialog open={openFinalDialog} onClose={() => setOpenFinalDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Final Invoice</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs>
              <input type="file" onChange={handleFileChange} key={inputKey} />
            </Grid>
            <Grid item>
              <StorageFileDownloadButton file={invoice?.file_final} title="Final Invoice" showDate />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls
            onSave={handleSave}
            loading={uploading}
            onCancel={() => setOpenFinalDialog(false)}
            disabled={fileSelected === null}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GenerateInvoiceDocument
