import { Alert, Snackbar } from "@mui/material"
import * as React from "react"
import { useEffect, useState } from "react"
import { IConnectionError } from "../models/IConnectionError"

interface IProps {
  error: IConnectionError | null | undefined
}

/**
 * This component displays a snackbar error message.
 *
 * todo rename to something indicating it is displayed as a snackbar.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the loading indicator.
 */
const ViewError: React.FunctionComponent<IProps> = (props: IProps) => {
  const { error } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(error !== null && error !== undefined)
  }, [error])

  return error !== null && error !== undefined ? (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={() => setOpen(false)}
      sx={{ mt: 8 }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Alert severity="error" elevation={8}>
        Issues: {Object.values(error.data).join(", ")}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  )
}

export default ViewError
