import * as React from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { INVOICES_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { IInvoice, INVOICE_ENDPOINT } from "../../shared/models/IInvoice"
import { useApiRead } from "../../shared/hooks/useApiRead"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import InvoiceForm from "./components/InvoiceForm"
import { useCallback } from "react"
import { dateAsIso } from "../../shared/utilities/format_utility"
import moment from "moment"

const repository = new RestRepository<IInvoice>(INVOICE_ENDPOINT)
const redirectView = INVOICES_VIEW_URL

/**
 * This page will edit an invoice.
 *
 * @returns {React.FC} the edit invoice page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const { control, handleSubmit, setError, clearErrors, formState, setValue } = useForm()
  const { data: invoice, error, loading } = useApiRead<IInvoice>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IInvoice> = {
    apiFunction: repository.edit,
    setError,
    redirectView,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<IInvoice>(props)

  const handleSave = useCallback(async (invoice1: IInvoice) => {
    invoice1.invoiced_on = dateAsIso(invoice1.invoiced_on as moment.Moment)
    await handleEdit(invoice1)
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Edit Invoice" loading={saving || loading} errors={formState.errors} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <InvoiceForm invoice={invoice} control={control} setValue={setValue} clearErrors={clearErrors} isEdit />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
