import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT, IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import { IUseApiPagedResultsProps, useApiPaged } from "../../../shared/hooks/useApiPaged"
import TableLoading from "../../../shared/components/TableLoading"
import { ICustomer } from "../../../shared/models/ICustomer"
import { IAccount } from "../../../shared/models/IAccount"
import TablePaging from "../../../shared/components/TablePaging"
import { IFilter } from "../../../shared/models/IFilter"
import WorkAssignmentLocationName from "../../../shared/components/WorkAssignmentLocationName"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import TableOrdering from "../../../shared/components/TableOrdering"
import { EXPENSE_REPORTS_URL } from "../../../config/urls"
import { IPaging } from "../../../shared/models/IPaging"
import ViewLoading from "../../../shared/components/ViewLoading"
import AddIcon from "@mui/icons-material/Add"
import TruncateText from "../../../shared/components/TruncateText"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../../shared/models/IExpenseReport"
import { IConsultant } from "../../../shared/models/main/IConsultant"
import WorkAssignmentProgress from "../../../shared/components/WorkAssignmentProgress"
import FormatNumber from "../../../shared/components/format/FormatNumber"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment>(EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT)

interface IProps {
  expenseReport?: IExpenseReport
  consultant: IConsultant
  onChange?: () => void
}

const columnSize = 10

/**
 * This component assists in adding work assignments to an expense report.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the add work assignment's dialog.
 */
const AddTechReview: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { expenseReport, consultant, onChange } = props
  const propsWa: IUseApiPagedResultsProps<IWorkAssignment> = {
    apiFunction: waRepository.findAll,
    pathname: EXPENSE_REPORTS_URL + "_add_wa_tech_review",
  }
  const {
    data,
    count,
    paging,
    error,
    loading,
    handleLimit,
    handlePaging,
    handleOrdering,
    handleFilter,
    call: refreshResults,
  } = useApiPaged<IWorkAssignment>(propsWa)

  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [waIds, setWaIds] = useState<number[]>([])
  const [initial, setInitial] = useState(false)

  const baseFilters: IFilter[] = useMemo(
    () => [
      {
        field: "can_be_expense_reported",
        value: consultant.id,
        display: "Yes",
        title: "Can Be Expense Reported?",
      },
    ],
    [consultant]
  )

  const handleOpen = useCallback(() => {
    refreshResults()
    setOpen(true)
    setInitial(false)
  }, [refreshResults])

  const handleClose = useCallback(() => {
    setOpen(false)
    setWaIds([])
  }, [])

  const handleAddSelected = useCallback(
    (wa: IWorkAssignment) => () => {
      setWaIds(ids => {
        if (ids.includes(wa.id)) {
          return ids.filter(id => id !== wa.id)
        }
        return [...ids, wa.id]
      })
    },
    []
  )

  const handleAdd = useCallback(async () => {
    if (expenseReport !== undefined) {
      try {
        setSaving(true)
        const paging: IPaging = {
          filters: [
            {
              field: "wa_ids",
              value: waIds.join(","),
            },
          ],
        }
        await repository.action(expenseReport.id, "add_tech_reviews", paging)
        setWaIds([])
        onChange?.()
        refreshResults()
        handleClose()
      } catch (e) {}
      setSaving(false)
    }
  }, [expenseReport, refreshResults, waIds])

  useEffect(() => {
    if (!initial && !loading) {
      setInitial(true)
      handleFilter([...baseFilters])
    }
  }, [initial, baseFilters, paging?.filters])

  return (
    <>
      <Button onClick={handleOpen} startIcon={expenseReport !== undefined && <AddIcon />}>
        {expenseReport !== undefined ? "Tech Review" : "Show"}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth fullScreen>
        <DialogTitle sx={{ pb: 0 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>{expenseReport !== undefined ? "Add Tech Reviews to Expense Report" : "Tech Reviews for Consultant"}</Grid>
          </Grid>
          <ViewLoading loading={saving} message="Saving changes." />
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <ErrorMessage error={error} />
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="id" title="ID" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="customer__name" title="Customer" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="account__name" title="Account" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="location__address" title="Location" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering
                      ordering={paging?.ordering}
                      field="tech_reviewer_consultant"
                      title="Tech Review 1"
                      onOrdering={handleOrdering}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                  <TableCell>
                    <TableOrdering
                      ordering={paging?.ordering}
                      field="tech_reviewer_2_consultant"
                      title="Tech Review 2"
                      onOrdering={handleOrdering}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                  <TableCell>Progress</TableCell>
                  {expenseReport !== undefined && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableLoading loading={loading} columns={columnSize} rows={paging?.limit} />
                {!loading &&
                  data?.results?.map(workAssignment => {
                    return (
                      <TableRow key={workAssignment.id}>
                        <TableCell>{workAssignment.identifier}</TableCell>
                        <TableCell>
                          <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                        </TableCell>
                        <TableCell>
                          <TruncateText text={(workAssignment.account as IAccount)?.name} />
                        </TableCell>
                        <TableCell>
                          <WorkAssignmentLocationName wa={workAssignment} />
                        </TableCell>
                        <TableCell>
                          <TruncateText text={workAssignment.tech_reviewer_consultant?.name} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <FormatNumber value={workAssignment.tech_review_hours} prefixUnits={false} twoDecimalPlaces />
                        </TableCell>
                        <TableCell>
                          <TruncateText text={workAssignment.tech_reviewer_2_consultant?.name} />
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <FormatNumber value={workAssignment.tech_review_2_hours} prefixUnits={false} twoDecimalPlaces />
                        </TableCell>
                        <TableCell>
                          <WorkAssignmentProgress workAssignment={workAssignment} />
                        </TableCell>
                        {expenseReport !== undefined && (
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              alignItems: "right",
                            }}
                          >
                            <Checkbox
                              checked={waIds.includes(workAssignment.id)}
                              disabled={saving}
                              onChange={handleAddSelected(workAssignment)}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}

                {(data?.results.length === 0 || data === undefined) && (
                  <TableRow>
                    <TableCell colSpan={columnSize}>No work assignments found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TablePaging
                count={count}
                total={data?.count}
                page={paging?.page}
                limit={paging?.limit}
                onPaging={handlePaging}
                onLimit={handleLimit}
              />
            </Grid>
            <Grid item>
              {waIds.length > 0 ? (
                <Button onClick={handleAdd} disabled={saving}>
                  Add Selected: {waIds.length}
                </Button>
              ) : (
                <Button onClick={handleClose} disabled={saving}>
                  Close
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddTechReview
