import * as React from "react"
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItemButton, Popover } from "@mui/material"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import useContentHeight from "../../hooks/useContentHeight"
import { IMinimalFile } from "../../models/IFile"
import TruncateText from "../TruncateText"
import DialogControls from "../DialogControls"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import FileDownloadButton from "./FileDownloadButton"
import HtmlToolTip from "../HtmlToolTip"
import { Refresh } from "@mui/icons-material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import DropboxSaver from "../DropboxSaver"

interface IProps {
  open: boolean
  selectedFile: IMinimalFile | null
  onClose: () => void
  files?: IMinimalFile[] | null
}

/**
 * Renders a file viewer dialog for displaying storage files.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the file viewer dialog component.
 */
const FileViewerDialog: React.FunctionComponent<IProps> = (props: IProps) => {
  const { open, selectedFile, files = null, onClose } = props
  const height = useContentHeight(-15)

  const [currentFile, setCurrentFile] = useState<IMinimalFile | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [refresh, setRefresh] = useState<boolean>(true)

  const openFileMenu = Boolean(anchorEl)

  const handleOpenFileMenu = useCallback(
    (event: MouseEvent<any>) => {
      if (files !== null && files.length > 1) {
        setAnchorEl(event.currentTarget)
      }
    },
    [files]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSelectFile = useCallback(
    (file: IMinimalFile | null) => () => {
      if (file !== null) {
        setCurrentFile(file)
        handleClose()
      }
    },
    []
  )

  const nextFile = useMemo(() => {
    if (files !== null && files.length > 1) {
      for (let index = 0; index < files.length; index++) {
        if (files[index].id === currentFile?.id) {
          if (index === files.length - 1) {
            return files[0]
          }
          return files[index + 1]
        }
      }
    }
    return null
  }, [currentFile, files])

  const prevFile = useMemo(() => {
    if (files !== null && files.length > 1) {
      for (let index = 0; index < files.length; index++) {
        if (files[index].id === currentFile?.id) {
          if (index === 0) {
            return files[files.length - 1]
          }
          return files[index - 1]
        }
      }
    }
    return null
  }, [currentFile, files])

  useEffect(() => {
    setCurrentFile(selectedFile)
  }, [selectedFile])

  useEffect(() => {
    setRefresh(true)
  }, [refresh])

  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
        <DialogTitle
          sx={{
            p: 1,
            pr: 2,
            pl: 2,
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <TruncateText text={currentFile !== null ? `${currentFile.name}.${currentFile.ext}` : ""} num={50} placement="top-start" />
            </Grid>
            <Grid item xs>
              {files !== null && files.length > 1 && (
                <>
                  <IconButton onClick={handleOpenFileMenu}>
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Popover
                    open={openFileMenu}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <List>
                      {files?.map((file: IMinimalFile) => (
                        <ListItemButton
                          key={file.id}
                          selected={currentFile !== null && file.id === currentFile.id}
                          onClick={handleSelectFile(file)}
                        >
                          {file.name}.{file.ext}
                        </ListItemButton>
                      ))}
                    </List>
                  </Popover>
                </>
              )}
            </Grid>

            <Grid item>{currentFile !== null && <DropboxSaver file={currentFile} />}</Grid>
            {files !== null && (
              <>
                {files.length > 1 && (
                  <>
                    <Grid item>
                      <HtmlToolTip title={prevFile !== null ? `${prevFile.name}.${prevFile.ext}` : ""}>
                        <IconButton onClick={handleSelectFile(prevFile)}>
                          <ArrowBackIosNewIcon />
                        </IconButton>
                      </HtmlToolTip>
                    </Grid>
                    <Grid item>
                      <HtmlToolTip title={nextFile !== null ? `${nextFile.name}.${nextFile.ext}` : ""}>
                        <IconButton onClick={handleSelectFile(nextFile)}>
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </HtmlToolTip>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <HtmlToolTip title={"Refresh file."}>
                    <IconButton onClick={() => setRefresh(false)}>
                      <Refresh />
                    </IconButton>
                  </HtmlToolTip>
                </Grid>
              </>
            )}
            <Grid item>{currentFile !== null && <FileDownloadButton file={currentFile} />}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            "& a#pdf-download": { display: "none" },
            "& #header-bar": { display: "none" },
            p: 0,
            height,
            overflow: "hidden",
          }}
        >
          {!openFileMenu && currentFile !== null && refresh && (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              style={{ height }}
              config={{ header: { disableFileName: true } }}
              documents={currentFile?.file !== undefined ? [{ uri: currentFile.file.read_url }] : []}
            />
          )}
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={onClose} buttonLabel="Close" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FileViewerDialog
