import { IListItem } from "./component/IListItem"

export const LANGUAGES_ENDPOINT = "lookups/languages"

export interface ILanguage extends IListItem {
  id: string
  name: string
  comment: string
}


