import { IMapLocation } from "./component/IMapLocation";
import { IContact } from "./IContact";
import { IAccount } from "./IAccount";
import { ISpecialty } from "./ISpecialty";
import { ILocationFile } from "./ILocationFile";
import { IWorkAssignment } from "./IWorkAssignment";
import { IOccupancy } from "./IOccupancy";

/**
 * Check to see if an object is a location.
 *
 * @param {any} obj to check.
 * @returns {boolean} true if the object is a location.
 */
export const isLocation = (obj: any): obj is ILocation => true

export const LOCATION_ENDPOINT = "locations"

/**
 * Convert a location to a string.
 *
 * @param {ILocationFile} location to convert.
 * @returns {string} the location as a string.
 */
export const locationToString = (location: ILocation): string => {
  return `${location.address}, ${location.address_2 !== "" && location.address_2 !== null ? `${location.address_2}, ` : ""} 
          ${location.city}, ${location.state_region} ${location.postal_code},  ${location.country}`
}

export interface IMapPosition {
  center: google.maps.LatLngLiteral
  zoom: number
}

export interface ILocation extends IMapLocation {
  id: number
  identifier: string
  name: string
  aliases: string[] | null

  address: string
  address_2: string
  city: string
  state_region: string
  country: string
  postal_code: string

  latitude: number
  longitude: number
  map_position: IMapPosition | null

  area?: number | undefined

  property_damage: number | null
  business_interruption: number | null
  total_insured_value: number

  contacts: IContact[]
  accounts: IAccount[] | number[]
  specialty: ISpecialty | null
  occupancy: IOccupancy | null
  files: ILocationFile[]
  work_assignments: IWorkAssignment[] | number[]
}
