import * as React from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material"
import { IFilter } from "../models/IFilter"
import AddIcon from "@mui/icons-material/Add"
import { RestRepository } from "../repositories/RestRepository"
import { ACCOUNT_ENDPOINT } from "../models/IAccount"
import SelectFilteredSingle from "./SelectFilteredSingle"
import { STAFF_ENDPOINT } from "../models/IUser"
import { CUSTOMER_ENDPOINT, CUSTOMER_TYPES } from "../models/ICustomer"
import { LOCATION_ENDPOINT } from "../models/ILocation"
import {
  INSPECTION_CATEGORIES,
  INSPECTION_TYPES,
  PRIORITY_TYPES,
  WORK_ASSIGNMENT_ENDPOINT,
  workAssignmentSteps,
} from "../models/IWorkAssignment"
import { SPECIALTY_ENDPOINT } from "../models/ISpecialty"
import DeleteIcon from "@mui/icons-material/Delete"
import { useAllKeys } from "react-keyboard-hooks"
import { CONSULTANTS_ENDPOINT, PREFERRED_PAYMENT_TYPES, RATING_TYPES } from "../models/main/IConsultant"
import FilteringDate from "./FilteringDate"
import { countriesList } from "../../config/countries"
import { DATE_INPUT_FORMAT, FILTERS } from "../../config/config"
import { IListItem } from "../models/component/IListItem"
import DialogControls from "./DialogControls"
import { LANGUAGES_ENDPOINT } from "../models/ILanguage"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import moment from "moment"
import { convertDateToPayPeriod, dateAsIso } from "../utilities/format_utility"
import { PAY_PERIODS_ENDPOINT } from "../models/main/IPayPeriod"
import { REPORT_FORMAT_ENDPOINT } from "../models/IReportFormat"

const customerRepository = new RestRepository<IListItem>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)
const consultantRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const locationRepository = new RestRepository<IListItem>(LOCATION_ENDPOINT)
const staffRepository = new RestRepository<IListItem>(STAFF_ENDPOINT)
const specialtyRepository = new RestRepository<IListItem>(SPECIALTY_ENDPOINT)
const adminConsultantsRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const techConsultantsRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const waRepository = new RestRepository<IListItem>(WORK_ASSIGNMENT_ENDPOINT)
const lookupLanguageRepository = new RestRepository<IListItem>(LANGUAGES_ENDPOINT)
const payPeriodRepository = new RestRepository<IListItem>(PAY_PERIODS_ENDPOINT)
const reportFormatRepository = new RestRepository<IListItem>(REPORT_FORMAT_ENDPOINT)

const COUNTRIES_LIST = countriesList()

const workAssignmentStepsUpdated = [
  ...workAssignmentSteps,
  {
    step: workAssignmentSteps.length + 1,
    field: "complete",
    title: "Complete",
  },
]

interface IProps {
  filters?: IFilter[]
  availableFilters: IFilter[]
  onFilter?: (filters: IFilter[]) => void
  asList?: boolean
  enableShortcut?: boolean
  showClearFilters?: boolean
  disabled?: boolean
}

interface IComboItem {
  label: string | undefined
  value: string | number | undefined
}

/**
 * This component handle all filtering for various endpoints.
 * todo: rebuild with child components.
 * https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the filtering component.
 */
const Filtering: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { filters, onFilter, availableFilters, asList = false, enableShortcut = true, showClearFilters = true, disabled = false } = props
  const [open, setOpen] = useState(false)
  const [clearFilters, setClearFilters] = useState(false)

  const [filter, setFilter] = useState<IFilter | null>(null)
  const [value, setValue] = useState<string | number>("")
  const [value2, setValue2] = useState<string | number>("")
  const [display, setDisplay] = useState<string>("")
  const [display2, setDisplay2] = useState<string>("")

  const filtersPopoverRef = useRef()
  const [openFilterList, setOpenFilterList] = useState(false)

  const hasFilters = useMemo(() => {
    return filters?.length !== undefined && filters.length > 0
  }, [filters?.length])

  const handleFiltersClear = useCallback(() => {
    setOpen(false)
    setOpenFilterList(false)
    onFilter?.(filters !== undefined ? [...filters.filter(f => f.cantDelete)] : [])
  }, [filters])

  const handleFilterDelete = useCallback(
    (index: number) => () => {
      if (onFilter !== undefined && filters !== undefined) {
        const newFilters = [...filters.filter((f, i) => i !== index)]
        onFilter(newFilters)
        if (newFilters.length === 0) {
          setOpenFilterList(false)
        }
      }
    },
    [filters]
  )
  const resetForm = useCallback(() => {
    setFilter(null)

    setValue("")
    setValue2("")

    setDisplay("")
    setDisplay2("")
    setClearFilters(false)
  }, [])

  const handleFilterCombo = useCallback(
    (event: React.SyntheticEvent<Element, Event> | null, comboItem: IComboItem | null) => {
      if (comboItem === null) {
        resetForm()
        return
      }
      const selectedFilter = availableFilters.filter(f => f.field === comboItem.value)
      if (selectedFilter.length > 0) {
        const filter1 = { ...selectedFilter[0] }
        setFilter(filter1)

        setValue("")
        setValue2("")

        setDisplay("")
        setDisplay2("")
      }
    },
    [availableFilters]
  )

  const addFilter = useCallback(
    (value: string | number, value2: string | number, display: string, display2: string) => {
      if (onFilter !== undefined && filter !== null) {
        const theFilters: IFilter[] = filters !== undefined && !clearFilters ? filters : []
        const newFilter: IFilter = {
          ...filter,
          value: value2 !== "" ? `${value},${value2}` : value,
          display: display2 !== "" ? `${display},${display2}` : display,
        }
        theFilters.push(newFilter)
        onFilter(theFilters)
      }
      resetForm()
      setOpen(false)
    },
    [onFilter, filters, filter, clearFilters]
  )

  const handleAdd = useCallback(() => {
    addFilter(value, value2, display, display2)
  }, [addFilter, value, value2, display, display2])

  const handleSingleSelect = useCallback(
    (id: string | number, name: string) => {
      addFilter(id, "", name, "")
    },
    [addFilter]
  )

  const handleSingleSelectInline = useCallback(
    (listItem: IListItem | null) => {
      if (listItem !== null) {
        addFilter(listItem.id, "", listItem.name, "")
      } else {
        setValue("")
        setDisplay("")
      }
    },
    [addFilter]
  )

  useAllKeys(["f", "Shift"], () => {
    if (enableShortcut && !open) {
      handleFilterCombo(null, filtersToShowComboItems[0])
      setOpen(true)
    }
  })

  const filtersToShow = useMemo(() => {
    if (filters === undefined || filters === null || clearFilters) {
      return availableFilters
    }
    return availableFilters.filter(af => af?.multiple === true || !filters.some(f => f.field === af.field))
  }, [availableFilters, filters?.length, clearFilters])

  const filtersToShowComboItems: IComboItem[] = useMemo(() => {
    if (filters === undefined || filters === null || clearFilters) {
      return availableFilters.map(filter1 => ({
        label: filter1.title,
        value: filter1.field,
      }))
    }
    return availableFilters
      .filter(af => af?.multiple === true || !filters.some(f => f.field === af.field))
      .map(filter1 => ({
        label: filter1.title,
        value: filter1.field,
      }))
  }, [availableFilters, filters?.length, clearFilters])

  const handleOpen = useCallback(() => {
    handleFilterCombo(null, filtersToShowComboItems[0])
    setOpen(true)
  }, [filtersToShowComboItems, handleFilterCombo])

  const handleClose = useCallback(() => {
    setClearFilters(false)
    setOpen(false)
  }, [])

  const filterDisplay = useCallback((filter: IFilter) => {
    if (filter?.display !== undefined) {
      return ` | ${filter.display}`
    }
    if (filter?.value !== undefined) {
      return ` | ${filter.value}`
    }
    return ""
  }, [])

  const handleOpenFilterList = useCallback(() => setOpenFilterList(true), [])
  const handleCloseFilterList = useCallback(() => setOpenFilterList(false), [])

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add Filter</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                autoHighlight
                onChange={handleFilterCombo}
                defaultValue={filtersToShowComboItems[0]}
                renderInput={params => <TextField {...params} autoFocus label="Filter" />}
                options={filtersToShowComboItems}
              />
            </Grid>
            {filter !== null && (
              <Grid item xs={12}>
                {(filter.field === FILTERS.CUSTOMER.field || filter.field === FILTERS.CUSTOMERS.field) && (
                  <SelectFilteredSingle
                    autoFocus={true}
                    name={filter.field}
                    label={filter.title !== undefined ? filter.title : ""}
                    repository={customerRepository}
                    onChange={customer => {
                      if (customer !== null) {
                        handleSingleSelect(customer.id, customer.name)
                      } else {
                        setValue("")
                        setDisplay("")
                      }
                    }}
                  />
                )}
                {filter.field === FILTERS.CUSTOMER_NOT.field && (
                  <SelectFilteredSingle
                    autoFocus={true}
                    name={filter.field}
                    label={filter.title !== undefined ? filter.title : ""}
                    repository={customerRepository}
                    onChange={customer => {
                      if (customer !== null) {
                        handleSingleSelect(customer.id, customer.name)
                      } else {
                        setValue("")
                        setDisplay("")
                      }
                    }}
                  />
                )}
                {filter.field === FILTERS.REPORT_FORMAT.field && (
                  <>
                    <SelectFilteredSingle
                      autoFocus={true}
                      name={FILTERS.REPORT_FORMAT.field}
                      label={FILTERS.REPORT_FORMAT.title}
                      repository={reportFormatRepository}
                      onChange={reportFormat => {
                        if (reportFormat !== null) {
                          handleSingleSelect(reportFormat.id, reportFormat.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {(filter.field === FILTERS.TECH_REVIEWER_WA.field || filter.field === FILTERS.TECH_REVIEWER_2_WA.field) && (
                  <>
                    <SelectFilteredSingle
                      name={filter.field}
                      label={filter.title !== undefined ? filter.title : "Tech Reviewer"}
                      repository={techConsultantsRepository}
                      filters={[
                        {
                          field: "tech_reviewer",
                          value: "true",
                        },
                        {
                          field: "active",
                          value: "true",
                        },
                      ]}
                      onChange={user => {
                        if (user !== null) {
                          handleSingleSelect(user.id, user.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {(filter.field === FILTERS.INVOICE_WORK_ASSIGNMENT_ID.field || filter.field === FILTERS.WORK_ASSIGNMENT_ID.field) && (
                  <>
                    <SelectFilteredSingle
                      name={filter.field}
                      label={filter.title !== undefined ? filter.title : ""}
                      repository={waRepository}
                      onChange={wa => {
                        if (wa !== null) {
                          handleSingleSelect(wa.id, wa.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.ADMIN_REVIEWER_WA.field && (
                  <>
                    <SelectFilteredSingle
                      name={filter.field}
                      label={filter.title !== undefined ? filter.title : ""}
                      repository={adminConsultantsRepository}
                      filters={[
                        {
                          field: "admin_reviewer",
                          value: "true",
                        },
                        {
                          field: "active",
                          value: "true",
                        },
                      ]}
                      onChange={user => {
                        if (user !== null) {
                          handleSingleSelect(user.id, user.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.ACCOUNT.field && (
                  <SelectFilteredSingle
                    autoFocus={true}
                    name={filter.field}
                    label={filter.title !== undefined ? filter.title : ""}
                    repository={accountRepository}
                    onChange={handleSingleSelectInline}
                  />
                )}
                {filter.field === FILTERS.ACCOUNT_NOT.field && (
                  <SelectFilteredSingle
                    autoFocus={true}
                    name={filter.field}
                    label={filter.title !== undefined ? filter.title : ""}
                    repository={accountRepository}
                    onChange={handleSingleSelectInline}
                  />
                )}
                {filter.field === FILTERS.CONSULTANT_WA.field && (
                  <SelectFilteredSingle
                    name={filter.field}
                    label={filter.title !== undefined ? filter.title : ""}
                    repository={consultantRepository}
                    onChange={consultant => {
                      if (consultant !== null) {
                        handleSingleSelect(consultant.id, consultant.name)
                      } else {
                        setValue("")
                        setDisplay("")
                      }
                    }}
                  />
                )}
                {(filter.field === FILTERS.LOCATIONS.field || filter.field === FILTERS.IN_TRAVEL_RADIUS.field) && (
                  <>
                    <SelectFilteredSingle
                      name={filter.field}
                      label={filter.title !== undefined ? filter.title : ""}
                      repository={locationRepository}
                      onChange={location => {
                        if (location !== null) {
                          handleSingleSelect(location.id, location.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.CONSULTANT_LANGUAGE.field && (
                  <>
                    <SelectFilteredSingle
                      name={filter.field}
                      label={filter.title !== undefined ? filter.title : ""}
                      repository={lookupLanguageRepository}
                      onChange={location => {
                        if (location !== null) {
                          handleSingleSelect(location.id, location.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.SPECIALTY_WA.field && (
                  <>
                    <SelectFilteredSingle
                      name="specialty"
                      label="Specialty"
                      repository={specialtyRepository}
                      onChange={specialty => {
                        if (specialty !== null) {
                          handleSingleSelect(specialty.id, specialty.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.SPECIALTY_SCORE.field && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectFilteredSingle
                        name="specialty"
                        label="Specialty"
                        repository={specialtyRepository}
                        onChange={specialty => {
                          if (specialty !== null) {
                            setValue(specialty.id)
                            setDisplay(specialty.name)
                          } else {
                            setValue("")
                            setDisplay("")
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={
                          <span>
                            Rating <small>(value or better)</small>
                          </span>
                        }
                        fullWidth
                        value={value2}
                        onChange={e => {
                          setValue2(e.target.value)
                          setDisplay2(`Score ${e.target.value}`)
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {(filter.field === FILTERS.EXECUTIVE.field ||
                  filter.field === FILTERS.AE_CUSTOMER_ACCOUNT.field ||
                  filter.field === FILTERS.ACCOUNTS_EXECUTIVE.field ||
                  filter.field === FILTERS.CUSTOMER_EXECUTIVE_WA.field ||
                  filter.field === FILTERS.ACCOUNT_EXECUTIVE_WA.field ||
                  filter.field === FILTERS.TECHNICAL_ADMIN.field ||
                  filter.field === FILTERS.ACCOUNT_ENGINEER.field ||
                  filter.field === FILTERS.ASSIGNEE.field) && (
                  <>
                    <SelectFilteredSingle
                      name={filter.field}
                      label={filter.title !== undefined ? filter.title : ""}
                      repository={staffRepository}
                      onChange={executive => {
                        if (executive !== null) {
                          handleSingleSelect(executive.id, executive.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.RATING.field && (
                  <>
                    <TextField
                      fullWidth
                      label="Rating"
                      select
                      value={value}
                      onChange={e => {
                        const ct = RATING_TYPES.filter(ct => ct.key === Number(e.target.value))
                        if (ct.length === 1) {
                          setValue(ct[0].key)
                          setDisplay(ct[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    >
                      {RATING_TYPES.map(type => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.CUSTOMER_TYPE.field && (
                  <>
                    <TextField
                      fullWidth
                      label="Customer Type"
                      select
                      value={value}
                      onChange={e => {
                        const ct = CUSTOMER_TYPES.filter(ct => ct.key === e.target.value)
                        if (ct.length === 1) {
                          setValue(ct[0].key)
                          setDisplay(ct[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    >
                      {CUSTOMER_TYPES.map(type => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {(filter.field === FILTERS.PROGRESS.field || filter.field === FILTERS.PROGRESS_NOT_SET.field) && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        const step = workAssignmentStepsUpdated.filter(step => step.field === e.target.value)
                        if (step.length === 1) {
                          setValue(step[0].field)
                          setDisplay(step[0].title)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                      value={value}
                    >
                      {workAssignmentStepsUpdated.map((step, i) => (
                        <MenuItem key={i} value={step.field}>
                          {step.step}. {step.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {(filter.field === FILTERS.CONSULTANT_ASSIGNED.field ||
                  filter.field === FILTERS.UNASSIGNED_TR2.field ||
                  filter.field === FILTERS.ARCHIVED.field ||
                  filter.field === FILTERS.INVOICE_VOIDED.field ||
                  filter.field === FILTERS.CONSULTANT_ACTIVE.field ||
                  filter.field === FILTERS.NO_VIRTUAL_SURVEYS.field ||
                  filter.field === FILTERS.PL.field ||
                  filter.field === FILTERS.HPR_LITE.field ||
                  filter.field === FILTERS.BOILER_AND_MACHINERY.field ||
                  filter.field === FILTERS.EXECUTIVE_NOT_SET.field ||
                  filter.field === FILTERS.CASUALTY.field ||
                  filter.field === FILTERS.TECH_REVIEWER_CONSULTANT.field ||
                  filter.field === FILTERS.ADMIN_REVIEWER_CONSULTANT.field ||
                  filter.field === FILTERS.PLAN_REVIEWER_CONSULTANT.field ||
                  filter.field === FILTERS.HPR_OR_HPR_LITE.field ||
                  filter.field === FILTERS.HPR.field) && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        setValue(e.target.value)
                        setDisplay(e.target.value === "false" ? "No" : "Yes")
                      }}
                      value={value}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.PRIORITY.field && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        const wat = PRIORITY_TYPES.filter(entry => entry.key === e.target.value)
                        if (wat.length === 1) {
                          setValue(wat[0].key)
                          setDisplay(wat[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                      value={value}
                    >
                      {PRIORITY_TYPES.map(entry => (
                        <MenuItem key={entry.key} value={entry.key}>
                          {entry.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.INVOICE.field && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        setValue(e.target.value)
                        setDisplay(e.target.value === "false" ? "Yes" : "No")
                      }}
                      value={value}
                    >
                      <MenuItem value="true">No</MenuItem>
                      <MenuItem value="false">Yes</MenuItem>
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.WA_COUNTRY_NOT_US.field && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        setValue(e.target.value)
                        setDisplay("Yes")
                      }}
                      value={value}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.EXPENSE_REPORT_PAY_PERIOD.field && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label={filter?.title}
                        onChange={date => {
                          const dateValue = dateAsIso(convertDateToPayPeriod(moment(date)))
                          if (dateValue !== null) {
                            setValue(dateValue)
                            setDisplay(dateValue)
                          }
                        }}
                        value={moment(value)}
                        mask="____-__-__"
                        inputFormat={DATE_INPUT_FORMAT}
                        renderInput={params => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
                      />
                    </LocalizationProvider>
                  </>
                )}
                {filter.field === FILTERS.ADP_PAY_PERIOD.field && (
                  <>
                    <SelectFilteredSingle
                      autoFocus={true}
                      name="adp_pay_period"
                      label="ADP Pay Period"
                      repository={payPeriodRepository}
                      onChange={payPeriod => {
                        if (payPeriod !== null) {
                          handleSingleSelect(payPeriod.id, payPeriod.name)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                    />
                  </>
                )}
                {filter.field === FILTERS.EXPENSE_REPORT_PAYMENT_TYPE.field && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        const wat = PREFERRED_PAYMENT_TYPES.filter(wat => wat.key === e.target.value)
                        if (wat.length === 1) {
                          setValue(wat[0].key)
                          setDisplay(wat[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                      value={value}
                    >
                      {PREFERRED_PAYMENT_TYPES.map(type => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {(filter.field === FILTERS.DUE_DATE.field ||
                  filter.field === FILTERS.VISIT_DATE.field ||
                  filter.field === FILTERS.CONSULTANT_DUE_DATE.field ||
                  filter.field === FILTERS.ISSUE_DATE.field ||
                  filter.field === FILTERS.REPORT_SENT_TO_CLIENT.field ||
                  filter.field === FILTERS.CONSULTANT_REQUEST_CONFIRMED.field ||
                  filter.field === FILTERS.LAST_LOGIN.field ||
                  filter.field === FILTERS.INVOICED_ON.field ||
                  filter.field === FILTERS.ADMIN_REVIEWER_SIGN_OFF_DATE.field ||
                  filter.field === FILTERS.REPORT_SUBMITTED_BY_CONSULTANT.field) && (
                  <>
                    <FilteringDate
                      value={value}
                      filter={filter}
                      minTypes={
                        filter.field === FILTERS.ISSUE_DATE.field ||
                        filter.field === FILTERS.CONSULTANT_REQUEST_CONFIRMED.field ||
                        filter.field === FILTERS.REPORT_SUBMITTED_BY_CONSULTANT.field ||
                        filter.field === FILTERS.LAST_LOGIN.field ||
                        filter.field === FILTERS.INVOICED_ON.field ||
                        filter.field === FILTERS.ADMIN_REVIEWER_SIGN_OFF_DATE.field ||
                        filter.field === FILTERS.REPORT_SENT_TO_CLIENT.field
                      }
                      onChange={(value, display) => {
                        setValue(value)
                        setDisplay(display)
                      }}
                    />
                  </>
                )}
                {(filter.field === FILTERS.WA_CITY.field ||
                  filter.field === FILTERS.WA_STATE_REGION.field ||
                  filter.field === FILTERS.EXTERNAL_TRACKING.field ||
                  filter.field === FILTERS.INVOICE_NUMBER.field ||
                  filter.field === FILTERS.SEARCH.field ||
                  filter.field === FILTERS.CONSULTANT_STATE_REGION.field) && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      onChange={e => {
                        setValue(e.target.value)
                        setDisplay(e.target.value)
                      }}
                      value={value}
                    />
                  </>
                )}
                {filter.field === FILTERS.CONSULTANT_ACTIVE_WA_COUNT.field && (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="From"
                        onChange={e => {
                          setValue(Number(e.target.value))
                          setDisplay(e.target.value)
                        }}
                        value={value}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="to"
                        onChange={e => {
                          setValue2(Number(e.target.value))
                          setDisplay2(e.target.value)
                        }}
                        value={value2}
                      />
                    </Grid>
                  </Grid>
                )}
                {(filter.field === FILTERS.COUNTRY.field || filter.field === FILTERS.WA_COUNTRY.field) && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        const country = COUNTRIES_LIST.filter(country => country.key === e.target.value)
                        if (country.length === 1) {
                          setValue(country[0].key)
                          setDisplay(country[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                      value={value}
                    >
                      {COUNTRIES_LIST.map(country => (
                        <MenuItem key={country.key} value={country.key}>
                          {country.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.DAILY.field && <>Work Assignments in Review.</>}
                {filter.field === FILTERS.INSPECTION_TYPE.field && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        const wat = INSPECTION_TYPES.filter(wat => wat.key === e.target.value)
                        if (wat.length === 1) {
                          setValue(wat[0].key)
                          setDisplay(wat[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                      value={value}
                    >
                      {INSPECTION_TYPES.map(inspectionType => (
                        <MenuItem key={inspectionType.key} value={inspectionType.key}>
                          {inspectionType.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {filter.field === FILTERS.INSPECTION_CATEGORY.field && (
                  <>
                    <TextField
                      fullWidth
                      label={filter.title}
                      select
                      onChange={e => {
                        const wat = INSPECTION_CATEGORIES.filter(wat => wat.key === e.target.value)
                        if (wat.length === 1) {
                          setValue(wat[0].key)
                          setDisplay(wat[0].value)
                        } else {
                          setValue("")
                          setDisplay("")
                        }
                      }}
                      value={value}
                    >
                      {INSPECTION_CATEGORIES.map(inspectionCategory => (
                        <MenuItem key={inspectionCategory.key} value={inspectionCategory.key}>
                          {inspectionCategory.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </Grid>
            )}
            {showClearFilters && (
              <Grid item xs>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={clearFilters} onChange={e => setClearFilters(e.target.checked)} />}
                    label="Clear Current Filters"
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onCancel={handleClose} onSave={handleAdd} buttonLabel="Add" disabled={value === ""} />
        </DialogActions>
      </Dialog>
      <Grid container alignItems={asList ? "start" : "center"}>
        <Grid item>
          <IconButton color="primary" size="small" onClick={handleOpen} disabled={filtersToShow.length === 0 || disabled}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Grid>

        {!asList && (
          <Grid item>
            <Box ref={filtersPopoverRef}>
              <Button onClick={handleOpenFilterList} disabled={!hasFilters}>
                Filters ({filters?.length !== undefined ? filters.length : 0})
              </Button>
            </Box>

            <Popover
              open={openFilterList}
              anchorEl={filtersPopoverRef?.current}
              onClose={handleCloseFilterList}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List dense>
                {filters?.map((filter, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" disabled={filter.cantDelete} onClick={handleFilterDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={`${filter?.title !== undefined ? filter.title : ""}${filterDisplay(filter)}`} />
                  </ListItem>
                ))}
                {showClearFilters && (
                  <>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" onClick={handleFiltersClear}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary="Clear Filters" />
                    </ListItem>
                  </>
                )}
              </List>
            </Popover>
          </Grid>
        )}

        {asList && hasFilters && (
          <Grid item xs>
            <List dense sx={{ pt: 0 }}>
              {filters?.map((filter, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" disabled={filter.cantDelete} onClick={handleFilterDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText>{`${filter?.title !== undefined ? filter.title : ""}${filterDisplay(filter)}`}</ListItemText>
                </ListItem>
              ))}
              {showClearFilters && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" onClick={handleFiltersClear}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText>Clear Filters</ListItemText>
                </ListItem>
              )}
            </List>
          </Grid>
        )}

        {asList && !hasFilters && (
          <Grid item xs>
            <List dense sx={{ pt: 0 }}>
              <ListItem>
                <ListItemText>No filters set.</ListItemText>
              </ListItem>
            </List>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Filtering
