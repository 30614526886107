import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"
import ImportIcon from "@mui/icons-material/ImportExport"
import { IPaging } from "../../../shared/models/IPaging"
import { CUSTOMER_FILE_ENDPOINT, ICustomerFile } from "../../../shared/models/ICustomerFile"
import { ACCOUNT_FILE_ENDPOINT, IAccountFile } from "../../../shared/models/IAccountFile"
import { ILocationFile, LOCATION_FILE_ENDPOINT } from "../../../shared/models/ILocationFile"
import axios from "axios"
import { IFile } from "../../../shared/models/IFile"
import ViewLoading from "../../../shared/components/ViewLoading"
import FileIcon from "@mui/icons-material/Article"
import DialogControls from "../../../shared/components/DialogControls"
import { ICustomer } from "../../../shared/models/ICustomer"
import { IAccount } from "../../../shared/models/IAccount"
import { ILocation } from "../../../shared/models/ILocation"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import useAzureStorage from "../../../shared/hooks/useAzureStorage"
import { IWorkAssignmentFile, WORK_ASSIGNMENT_FILE_ENDPOINT } from "../../../shared/models/IWorkAssignmentFile"

const customerFileRepository = new RestRepository<ICustomerFile>(CUSTOMER_FILE_ENDPOINT)
const accountFileRepository = new RestRepository<IAccountFile>(ACCOUNT_FILE_ENDPOINT)
const locationFileRepository = new RestRepository<ILocationFile>(LOCATION_FILE_ENDPOINT)
const fileRepository = new RestRepository<IWorkAssignmentFile>(WORK_ASSIGNMENT_FILE_ENDPOINT)

interface IProps {
  waId: number
  customer: ICustomer
  account: IAccount
  location: ILocation
  onChange: () => void
}

/**
 * This component will import files from the customer, account, and location into the work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the import file component.
 */
const FilesImportDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { waId, customer, account, location, onChange } = props
  const { uploading, uploadFile } = useAzureStorage()

  const [savingImports, setSavingImports] = useState(false)
  const [savingImportName, setSavingImportName] = useState("")
  const [importProgress, setImportProgress] = useState(0)

  const [openImporter, setOpenImporter] = useState(false)
  const [loadingImports, setLoadingImports] = useState(false)

  const [customerFiles, setCustomerFiles] = useState<ICustomerFile[] | null>(null)
  const [accountFiles, setAccountFiles] = useState<IAccountFile[] | null>(null)
  const [locationFiles, setLocationFiles] = useState<ILocationFile[] | null>(null)

  const [selectedCustomerFiles, setSelectedCustomerFiles] = useState<ICustomerFile[]>([])
  const [selectedAccountFiles, setSelectedAccountFiles] = useState<IAccountFile[]>([])
  const [selectedLocationFiles, setSelectedLocationFiles] = useState<ILocationFile[]>([])

  const handleCloseImporter = useCallback(() => {
    setOpenImporter(false)
  }, [])

  const handleSaveFile = useCallback(
    async (file1: IFile) => {
      const file: IWorkAssignmentFile = {
        ...file1,
        work_assignment: waId,
      }
      return await fileRepository.add(file)
    },
    [waId]
  )

  const handleToggleCustomerFile = useCallback(
    (file: ICustomerFile) => () => {
      if (selectedCustomerFiles.some(cf => cf.id === file.id)) {
        setSelectedCustomerFiles([...selectedCustomerFiles.filter(cf => cf.id !== file.id)])
      } else {
        setSelectedCustomerFiles([...selectedCustomerFiles, file])
      }
    },
    [selectedCustomerFiles]
  )

  const handleToggleAccountFile = useCallback(
    (file: IAccountFile) => () => {
      if (selectedAccountFiles.some(af => af.id === file.id)) {
        setSelectedAccountFiles([...selectedAccountFiles.filter(af => af.id !== file.id)])
      } else {
        setSelectedAccountFiles([...selectedAccountFiles, file])
      }
    },
    [selectedAccountFiles]
  )

  const handleToggleLocationFile = useCallback(
    (file: ILocationFile) => () => {
      if (selectedLocationFiles.some(lf => lf.id === file.id)) {
        setSelectedLocationFiles([...selectedLocationFiles.filter(lf => lf.id !== file.id)])
      } else {
        setSelectedLocationFiles([...selectedLocationFiles, file])
      }
    },
    [selectedLocationFiles]
  )

  const handleOpenImporter = useCallback(async () => {
    setOpenImporter(true)
    setLoadingImports(true)
    if (customer != null) {
      const paging: IPaging = {
        filters: [
          {
            field: "customer",
            value: customer.id,
          },
        ],
      }
      const cFiles = await customerFileRepository.list(paging)
      setCustomerFiles(cFiles)
    }
    if (account != null) {
      const paging: IPaging = {
        filters: [
          {
            field: "account",
            value: account.id,
          },
        ],
      }
      const aFiles = await accountFileRepository.list(paging)
      setAccountFiles(aFiles)
    }
    if (location != null) {
      const paging: IPaging = {
        filters: [
          {
            field: "location",
            value: location.id,
          },
        ],
      }
      const lFiles = await locationFileRepository.list(paging)
      setLocationFiles(lFiles)
    }
    setLoadingImports(false)
  }, [customer, account, location])

  const handleImport = useCallback(async () => {
    setSavingImports(true)
    setImportProgress(0)
    for (const file of selectedCustomerFiles) {
      setSavingImportName(`Customer file: ${file.name}`)
      // noinspection DuplicatedCode
      const fileBlob = await axios({
        url: file.file?.read_url,
        method: "GET",
        responseType: "blob",
      })
      const saveFile: IFile = {
        name: file.name,
        ext: file.ext,
        consultant_can_edit: false,
        consultant_can_view: false,
        archive: false,
      }
      const savedFile = await handleSaveFile(saveFile)
      if (savedFile.file !== undefined) {
        await uploadFile(savedFile.file, fileBlob.data)
      }
      setImportProgress(p => p + 1)
    }
    for (const file of selectedAccountFiles) {
      setSavingImportName(`Account file: ${file.name}`)
      // noinspection DuplicatedCode
      const fileBlob = await axios({
        url: file.file?.read_url,
        method: "GET",
        responseType: "blob",
      })
      const saveFile: IFile = {
        name: file.name,
        ext: file.ext,
        consultant_can_edit: false,
        consultant_can_view: false,
        archive: false,
      }
      const savedFile = await handleSaveFile(saveFile)
      if (savedFile.file !== undefined) {
        await uploadFile(savedFile.file, fileBlob.data)
      }
      setImportProgress(p => p + 1)
    }
    for (const file of selectedLocationFiles) {
      setSavingImportName(`Location file: ${file.name}`)
      const fileBlob = await axios({
        url: file.file?.read_url,
        method: "GET",
        responseType: "blob",
      })
      const fileSave: IFile = {
        name: file.name,
        ext: file.ext,
        consultant_can_edit: false,
        consultant_can_view: false,
        archive: false,
      }
      const savedFile = await handleSaveFile(fileSave)
      if (savedFile.file !== undefined) {
        await uploadFile(savedFile.file, fileBlob.data)
      }
      setImportProgress(p => p + 1)
    }
    setSavingImports(false)
    onChange()
    setOpenImporter(false)
  }, [selectedCustomerFiles, selectedAccountFiles, selectedLocationFiles, handleSaveFile, onChange])

  const handleSelectAll = useCallback(() => {
    setSelectedCustomerFiles(customerFiles !== null ? [...customerFiles] : [])
    setSelectedAccountFiles(accountFiles !== null ? [...accountFiles] : [])
    setSelectedLocationFiles(locationFiles !== null ? [...locationFiles] : [])
  }, [locationFiles, accountFiles, customerFiles])

  const importCount = useMemo(() => {
    return selectedLocationFiles.length + selectedAccountFiles.length + selectedCustomerFiles.length
  }, [selectedCustomerFiles, selectedAccountFiles, selectedLocationFiles])

  const availableImportCount = useMemo(() => {
    const locationFilesCount = locationFiles?.length !== undefined ? locationFiles.length : 0
    const accountFilesCount = accountFiles?.length !== undefined ? accountFiles.length : 0
    const customerFilesCount = customerFiles?.length !== undefined ? customerFiles.length : 0
    return locationFilesCount + accountFilesCount + customerFilesCount
  }, [locationFiles, accountFiles, customerFiles])

  return (
    <>
      <Button onClick={handleOpenImporter} startIcon={<ImportIcon />}>
        Import Files
      </Button>
      <Dialog onClose={handleCloseImporter} open={openImporter} fullWidth={true} maxWidth="sm">
        <DialogTitle>Import Files</DialogTitle>
        <DialogContent>
          <ViewLoading loading={loadingImports || uploading} message="Loading imports..." />
          {!loadingImports && (
            <Grid container>
              <Grid item xs>
                Files Selected: {importCount} of {availableImportCount}
              </Grid>
              <Grid item>
                <Button onClick={handleSelectAll}>Select All</Button>
              </Grid>
            </Grid>
          )}
          {savingImports && (
            <>
              <Typography variant="caption" color="text.secondary">
                {savingImportName}
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <LinearProgress variant="determinate" value={(importProgress / importCount) * 100} />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="text.secondary">
                    {importProgress} of {importCount}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {customer != null && !loadingImports && customerFiles?.length !== undefined && customerFiles.length > 0 && (
            <>
              <Typography variant="h6">
                Customer Files for: <em>{customer.name}</em>
              </Typography>
              <List>
                {customerFiles?.map(file => (
                  <ListItem
                    key={file.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        disabled={savingImports}
                        onChange={handleToggleCustomerFile(file)}
                        checked={selectedCustomerFiles.some(cf => cf.id === file.id)}
                      />
                    }
                    disablePadding
                  >
                    <ListItemIcon sx={{ pl: 2 }}>
                      <FileIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {!loadingImports && accountFiles?.length !== undefined && accountFiles.length > 0 && (
            <>
              <Typography variant="h6">
                Account Files for: <em>{account.name}</em>
              </Typography>
              <List>
                {accountFiles?.map(file => (
                  <ListItem
                    key={file.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        disabled={savingImports}
                        onChange={handleToggleAccountFile(file)}
                        checked={selectedAccountFiles.some(af => af.id === file.id)}
                      />
                    }
                    disablePadding
                  >
                    <ListItemIcon sx={{ pl: 2 }}>
                      <FileIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {!loadingImports && locationFiles?.length !== undefined && locationFiles.length > 0 && (
            <>
              {location !== null && (
                <Typography variant="h6">
                  Location Files for: <em>{location.name}</em>
                </Typography>
              )}
              <List>
                {locationFiles?.map(file => (
                  <ListItem
                    key={file.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggleLocationFile(file)}
                        disabled={savingImports}
                        checked={selectedLocationFiles.some(lf => lf.id === file.id)}
                      />
                    }
                    disablePadding
                  >
                    <ListItemIcon sx={{ pl: 2 }}>
                      <FileIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DialogControls
            disabled={importCount === 0 || savingImports || uploading}
            onSave={handleImport}
            onCancel={handleCloseImporter}
            buttonLabel="Import"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FilesImportDialog
