import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { IFile } from "../../models/IFile"
import { getNameExt } from "../../utilities/FileUtility"
import useAzureStorage from "../../hooks/useAzureStorage"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import DialogControls from "../DialogControls"
import FileDropZone from "../FileDropZone"
import HtmlToolTip from "../HtmlToolTip"
import EditIcon from "@mui/icons-material/Edit"
import { RestRepository } from "../../repositories/RestRepository"
import SelectFilteredMultiple from "../SelectFilteredMultiple"
import { IListItem } from "../../models/component/IListItem"
import { FILE_TAG_ENDPOINT } from "../../models/main/IFileTag"

const fileTagRepository = new RestRepository<IListItem>(FILE_TAG_ENDPOINT)

interface IProps {
  parentId: number
  onChange: () => void
  repository: RestRepository<any>
  useDropzone?: boolean
  useEdit?: boolean
  selectedFile?: IFile | null
  hideWaPermissions?: boolean
  fieldName: string
}

/**
 * This is a generic component for the adding and editing of files.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the add / edit file component.
 */
const FileAddEdit: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    useDropzone = false,
    useEdit = false,
    hideWaPermissions = false,
    fieldName,
    selectedFile = null,
    parentId,
    onChange,
    repository,
  } = props

  const [open, setOpen] = useState(false)

  const [name, setName] = useState("")
  const [ext, setExt] = useState("")

  const [consultantCanEdit, setConsultantCanEdit] = useState<boolean>(false)
  const [consultantCanView, setConsultantCanView] = useState<boolean>(false)

  const [archive, setArchive] = useState<boolean>(false)

  const [saving, setSaving] = useState(false)
  const { uploading, progress, uploadFile } = useAzureStorage()
  const [inputKey, setInputKey] = useState(Math.random().toString(36))

  const [fileSelected, setFileSelected] = useState<File | null>(null)

  const [fileTags, setFileTags] = useState<IListItem[] | undefined>()

  const handleFileTags = useCallback((fileTags1?: IListItem[]) => {
    setFileTags(fileTags1)
  }, [])

  const handleSaveFile = useCallback(
    async (file1: IFile, isEdit: boolean = false) => {
      const file: any = {
        ...file1,
        [fieldName]: parentId,
      }
      if (isEdit && file.id !== undefined) {
        return await repository.edit(file, file.id)
      } else {
        return await repository.add(file)
      }
    },
    [parentId, fieldName]
  )

  const handleAdd = useCallback(() => {
    setOpen(true)
    setName("")
    setExt("")
    setFileSelected(null)
  }, [])

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files.length > 0) {
      const filename = e.target.files[0].name
      let ext = filename.split(".").pop()
      ext = ext === undefined ? "" : ext
      const name = filename.replace(`.${ext}`, "")
      setName(name)
      setExt(ext === name ? "" : ext)
      setFileSelected(e.target.files[0])
    }
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setName("")
    setExt("")
    setConsultantCanView(false)
    setConsultantCanEdit(false)
    setArchive(false)
    setFileSelected(null)
  }, [])

  const handleEdit = useCallback(() => {
    if (selectedFile !== undefined && selectedFile !== null) {
      setName(selectedFile.name)
      setExt(selectedFile.ext)
      setConsultantCanView(selectedFile.consultant_can_view)
      setConsultantCanEdit(selectedFile.consultant_can_edit)
      setArchive(selectedFile.archive)
      setFileTags(selectedFile.file_tags as IListItem[])
      setOpen(true)
    }
  }, [selectedFile])

  // noinspection DuplicatedCode
  const handleSave = useCallback(async () => {
    setSaving(true)
    let file: IFile | null
    if (selectedFile !== null) {
      const fileSave: IFile = {
        id: selectedFile.id,
        name,
        ext,
        consultant_can_edit: consultantCanEdit,
        consultant_can_view: consultantCanView,
        archive,
        file_tags: fileTags?.map(tag => Number(tag.id)),
      }
      file = await handleSaveFile(fileSave, true)
    } else {
      const fileSave: IFile = {
        name,
        ext,
        consultant_can_edit: consultantCanEdit,
        consultant_can_view: consultantCanView,
        archive,
        file_tags: fileTags?.map(tag => Number(tag.id)),
      }
      file = await handleSaveFile(fileSave)
    }

    setOpen(false)
    if (file?.file !== undefined && fileSelected !== null) {
      await uploadFile(file.file, fileSelected)
    } else {
      onChange()
    }
    setInputKey(Math.random().toString(36))
    setSaving(false)
    handleClose()
  }, [name, ext, consultantCanView, consultantCanEdit, archive, selectedFile, fileTags, fileSelected, handleSaveFile])

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setSaving(true)
      for (const acceptedFile of acceptedFiles) {
        const [name, ext] = getNameExt(acceptedFile)
        const saveFile: IFile = {
          name,
          ext,
          consultant_can_edit: false,
          consultant_can_view: false,
          archive: false,
        }
        const fileSaved = await handleSaveFile(saveFile)
        if (fileSaved.file !== undefined) {
          await uploadFile(fileSaved.file, acceptedFile)
        }
      }
      setSaving(false)
    },
    [handleSaveFile, onChange]
  )

  useEffect(() => {
    if (progress === 100) {
      onChange()
    }
  }, [progress])

  return (
    <>
      {useEdit && (
        <HtmlToolTip title={`Edit file.`}>
          <IconButton color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </HtmlToolTip>
      )}
      {useDropzone && (
        <FileDropZone onDrop={handleDrop} loading={saving || uploading} progress={progress}>
          <Button onClick={handleAdd} startIcon={<AddIcon />}>
            File
          </Button>
        </FileDropZone>
      )}
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>{selectedFile != null ? "Edit" : "Add"} File</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={8}>
              <TextField fullWidth label="Name" name="name" value={name} onChange={e => setName(e.target.value)} />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth label="Ext" name="ext" value={ext} onChange={e => setExt(e.target.value)} />
            </Grid>
            {!hideWaPermissions && (
              <>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={consultantCanEdit} onChange={e => setConsultantCanEdit(e.target.checked)} />}
                      label="Consultant Can Edit"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={consultantCanView} onChange={e => setConsultantCanView(e.target.checked)} />}
                      label="Consultant Can View"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={archive} onChange={e => setArchive(e.target.checked)} />}
                      label="Archive"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredMultiple
                    name="file_tags"
                    label="File Tags"
                    defaultValue={fileTags}
                    repository={fileTagRepository}
                    onChange={handleFileTags}
                  />
                </Grid>
              </>
            )}
            <Grid item xs>
              <input type="file" onChange={handleFileChange} key={inputKey !== "" ? inputKey : ""} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls
            disabled={uploading || saving || (fileSelected === null && selectedFile === null)}
            onSave={handleSave}
            onCancel={handleClose}
            buttonLabel={selectedFile !== null ? "Update" : "Create"}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FileAddEdit
