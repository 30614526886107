/**
 * Get the name and extension for a file.
 *
 * @param {File} file to get the name and extension from.
 * @returns {[string, string]} the file and ext.
 */
export const getNameExt = (file: File): [string, string] => {
  const filename = file.name
  const extRaw = filename.split(".").pop()
  const ext = extRaw !== undefined ? extRaw : ""
  const name = filename.replace(`.${ext}`, "")
  return [name, ext]
}
