import * as React from "react"
import { useCallback, useEffect } from "react"
import { Box, Grid } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { CONTACT_ENDPOINT, IContact } from "../../shared/models/IContact"
import { useApiRead } from "../../shared/hooks/useApiRead"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { styles } from "../../shared/styling/general"
import FormActionPanel from "../../shared/components/FormActionPanel"
import HeaderPanel from "../../shared/components/HeaderPanel"
import SelectFilteredMultiple from "../../shared/components/SelectFilteredMultiple"
import { CUSTOMER_ENDPOINT } from "../../shared/models/ICustomer"
import { LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import { ACCOUNT_ENDPOINT } from "../../shared/models/IAccount"
import ContactForm from "./components/ContactForm"
import FhMuiMdField from "../../shared/components/form/FhMuiMdField"
import FhMuiIdField from "../../shared/components/form/FhMuiIdField"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import { IListItem } from "../../shared/models/component/IListItem"

const contactRepository = new RestRepository<IContact>(CONTACT_ENDPOINT)

const customerRepository = new RestRepository<IListItem>(CUSTOMER_ENDPOINT)
const accountsRepository = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)
const locationsRepository = new RestRepository<IListItem>(LOCATION_ENDPOINT)

/**
 * This is the edit page for a contact.
 *
 * @returns {React.FunctionComponent} the edit page.
 */
const EditPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setValue, formState, setError } = useForm<any>()
  const { data: contact, loading, error } = useApiRead<IContact>({ apiFunction: contactRepository.read })

  const props: IUseApiEditProps<IContact> = {
    apiFunction: contactRepository.edit,
    setError,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<IContact>(props)

  // noinspection DuplicatedCode
  const handleCustomers = useCallback((customers: IListItem[]) => {
    setValue(
      "customers",
      customers.map(c => c.id)
    )
  }, [])

  const handleAccounts = useCallback((accounts: IListItem[]) => {
    setValue(
      "accounts",
      accounts.map(a => a.id)
    )
  }, [])

  const handleLocations = useCallback((locations: IListItem[]) => {
    setValue(
      "locations",
      locations.map(l => l.id)
    )
  }, [])

  useEffect(() => {
    if (!loading && contact !== undefined) {
      setValue("id", contact.id)
      setValue("first_name", contact.first_name)
      setValue("last_name", contact.last_name)
      setValue("business_name", contact.business_name)
      setValue("job_title", contact.job_title)
      setValue("email", contact.email)
      setValue("email_2", contact.email_2)
      setValue("phone", contact.phone)
      setValue("phone_ext", contact.phone_ext)
      setValue("phone_2", contact.phone_2)
      setValue("phone_2_ext", contact.phone_2_ext)
      setValue("notes", contact.notes)
      handleCustomers(contact.customers)
      handleAccounts(contact.accounts)
      handleLocations(contact.locations)
    }
  }, [contact, loading])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Edit Contact" loading={loading || saving} errors={formState.errors} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        {contact !== undefined && (
          <Box component="form" onSubmit={handleSubmit(handleEdit as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
            <FhMuiIdField control={control} />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={5}>
                <ContactForm control={control} />
              </Grid>
              <Grid item xs={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FhMuiMdField control={control} label="Notes" name="notes" />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFilteredMultiple
                      name="customers"
                      label="Customers"
                      defaultValue={contact.customers}
                      repository={customerRepository}
                      onChange={handleCustomers}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFilteredMultiple
                      name="accounts"
                      label="Accounts"
                      defaultValue={contact.accounts}
                      repository={accountsRepository}
                      onChange={handleAccounts}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFilteredMultiple
                      name="locations"
                      label="Locations"
                      defaultValue={contact.locations}
                      repository={locationsRepository}
                      onChange={handleLocations}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FormActionPanel />
          </Box>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
