import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Control } from "react-hook-form/dist/types"
import { Box, Grid, IconButton } from "@mui/material"
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form/dist/types/form"
import FhMuiIdField from "../../../shared/components/form/FhMuiIdField"
import FhMuiTextField from "../../../shared/components/form/FhMuiTextField"
import { requiredRule } from "../../../shared/utilities/form_utility"
import { IInvoice } from "../../../shared/models/IInvoice"
import { LookupsRepository } from "../../../shared/repositories/LookupsRepository"
import { IPaging } from "../../../shared/models/IPaging"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../../shared/models/ICustomer"
import { ACCOUNT_ENDPOINT, IAccount } from "../../../shared/models/IAccount"
import { IListItem } from "../../../shared/models/component/IListItem"
import { CONTACT_ENDPOINT, IContact } from "../../../shared/models/IContact"
import AddContact from "../../../shared/components/AddContact"
import RefreshIcon from "@mui/icons-material/Refresh"
import { CUSTOMER_FILE_ENDPOINT, ICustomerFile } from "../../../shared/models/ICustomerFile"
import { ACCOUNT_FILE_ENDPOINT, IAccountFile } from "../../../shared/models/IAccountFile"
import FhMuiDateField from "../../../shared/components/form/FhMuiDateField"
import { isoAsDate } from "../../../shared/utilities/format_utility"
import SelectFilteredMultiple from "../../../shared/components/SelectFilteredMultiple"
import AddContactCheckMessage from "../../../shared/components/AddContactCheckMessage"

const lookupsRepository = new LookupsRepository()

const customerRepository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)

const customerFileRepository = new RestRepository<IListItem>(CUSTOMER_FILE_ENDPOINT)
const accountFileRepository = new RestRepository<IListItem>(ACCOUNT_FILE_ENDPOINT)

interface INumberInvoice {
  number: string
}

interface IProps {
  control: Control<any>
  invoice?: IInvoice | null
  isEdit?: boolean
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
}

/**
 * Renders the form for editing or creating an invoice.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the invoice form component.
 */
const InvoiceForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { invoice = null, isEdit = false, control, setValue, clearErrors } = props
  const [hasData, setHasData] = useState(false)
  const [customer, setCustomer] = useState<ICustomer | null>(null)
  const [account, setAccount] = useState<IAccount | null>(null)
  const [contact, setContact] = useState<IContact | null>(null)
  const [contacts, setContacts] = useState<IListItem[]>([])

  const [accountFile, setAccountFile] = useState<IAccountFile | null>(null)
  const [customerFile, setCustomerFile] = useState<ICustomerFile | null>(null)

  const handleGenerate = useCallback(async () => {
    try {
      const paging: IPaging = {}
      const nextInvoice = await lookupsRepository.lookup<INumberInvoice>("next_invoice_number", paging)
      setValue("number", nextInvoice.number)
    } catch (e) {}
  }, [])

  const handleCustomer = useCallback((customer1?: IListItem | null) => {
    setValue("customer", customer1 === null || customer1 === undefined ? null : customer1?.id)
    setCustomer(customer1 !== undefined ? (customer1 as ICustomer) : null)

    setValue("account", null)
    setAccount(null)
  }, [])

  const handleAccount = useCallback((account1?: IListItem | null) => {
    setValue("account", account1 === null || account1 === undefined ? null : account1?.id)
    setAccount(account1 !== undefined ? (account1 as IAccount) : null)
  }, [])

  const handleAccountFile = useCallback((accountFile?: IListItem | null) => {
    setValue("account_template_file", accountFile === null || accountFile === undefined ? null : accountFile?.id)
    setAccountFile(accountFile !== undefined ? (accountFile as IAccountFile) : null)
  }, [])

  const handleCustomerFile = useCallback((customerFile?: IListItem | null) => {
    setValue("customer_template_file", customerFile === null || customerFile === undefined ? null : customerFile?.id)
    setCustomerFile(customerFile !== undefined ? (customerFile as ICustomerFile) : null)
  }, [])

  const handleContact = useCallback((contact1?: IListItem | null) => {
    setValue("contact", contact1 === null || contact1 === undefined ? null : contact1.id)
    setContact(contact1 !== undefined ? (contact1 as IContact) : null)
  }, [])

  const updateContacts = useCallback((cs1?: IContact[]) => {
    setValue(
      "contacts",
      cs1?.map(c => c.id)
    )
    setContacts(cs1 !== undefined ? cs1 : [])
  }, [])

  const handleContacts = useCallback((cs1?: IListItem[]) => {
    clearErrors("contacts")
    updateContacts(cs1 as IContact[])
  }, [])

  const handleAddContact = useCallback((contact: IContact) => {
    setContacts(contacts => {
      const cs2 = [...contacts, contact]
      setValue(
        "contacts",
        cs2.map(c => c.id)
      )
      return cs2
    })
  }, [])

  useEffect(() => {
    if (invoice !== null && isEdit && !hasData) {
      setHasData(true)
      handleCustomer(invoice.customer)
      handleAccount(invoice.account as IAccount)
      handleContact(invoice.contact as IContact)
      handleAccountFile(invoice.account_template_file as IListItem)
      handleCustomerFile(invoice.customer_template_file as IListItem)
      setValue("id", invoice.id)
      setValue("number", invoice.number)
      setValue("invoiced_on", isoAsDate(invoice.invoiced_on as string))
      setValue("quickbooks_identifier", invoice.quickbooks_identifier)
      updateContacts(invoice.contacts)
    }
    if (invoice !== null && !hasData) {
      setHasData(true)
    }
  }, [invoice, hasData, setValue, isEdit, handleContact])

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={4}>
        {isEdit && <FhMuiIdField control={control} />}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <FhMuiTextField control={control} label="Number" name="number" rules={requiredRule()} />
          </Grid>
          <Grid item>
            <IconButton onClick={handleGenerate}>
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
          <Grid item xs>
            <SelectFilteredSingle
              name="contact"
              label="Contact"
              defaultValue={contact as IListItem}
              repository={contactRepository}
              onChange={handleContact}
            />
          </Grid>
          <Grid item>
            <AddContact onAddContact={handleContact} />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
          <Grid item xs>
            <SelectFilteredMultiple
              name="contacts"
              label="Additional Contacts"
              defaultValue={contacts}
              repository={contactRepository}
              onChange={handleContacts}
            />
            <AddContactCheckMessage />
          </Grid>
          <Grid item>
            <Box sx={{ mt: -4 }}>
              <AddContact onAddContact={handleAddContact} />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs>
            <FhMuiDateField control={control} label="Invoiced On" name="invoiced_on" />
          </Grid>
          <Grid item xs>
            <FhMuiTextField control={control} label="Quickbooks ID" name="quickbooks_identifier" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="customer"
              label="Customer"
              defaultValue={customer as IListItem}
              repository={customerRepository}
              onChange={handleCustomer}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            {customer != null && (
              <SelectFilteredSingle
                name="account"
                label="Account"
                defaultValue={account as IListItem}
                filters={[
                  {
                    field: "customer",
                    value: customer?.id,
                  },
                ]}
                repository={accountRepository}
                onChange={handleAccount}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {account != null && (
              <SelectFilteredSingle
                name="customer_template_file"
                label="Customer Template File"
                defaultValue={customerFile as IListItem}
                filters={[
                  {
                    field: "customer",
                    value: customer?.id,
                  },
                ]}
                repository={customerFileRepository}
                onChange={handleCustomerFile}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            {account != null && (
              <SelectFilteredSingle
                name="account_template_file"
                label={
                  <>
                    Account Template File <sup>Priority</sup>
                  </>
                }
                defaultValue={accountFile as IListItem}
                filters={[
                  {
                    field: "account",
                    value: account?.id,
                  },
                ]}
                repository={accountFileRepository}
                onChange={handleAccountFile}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvoiceForm
