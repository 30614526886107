import * as React from "react"
import { useCallback, useState } from "react"

import { CONSULTANTS_ENDPOINT, IConsultant } from "../../../shared/models/main/IConsultant"
import { Button, Grid } from "@mui/material"
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob"
import ViewLoading from "../../../shared/components/ViewLoading"
import LinkIcon from "@mui/icons-material/FileDownload"
import UploadIcon from "@mui/icons-material/Upload"
import DeleteIcon from "@mui/icons-material/Delete"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { IStorageFile } from "../../../shared/models/IStorageFile"
import AlertDialog from "../../../shared/components/AlertDialog"

const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)

interface IProps {
  consultant: IConsultant
  theFile: IStorageFile
  action: string
  onChange: () => void
}

/**
 * Renders a component for managing the consultant file assets.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the file editor component.
 */
const ConsultantFileEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { consultant, theFile, action, onChange } = props

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState(null)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  // UI/form management
  const [loading, setLoading] = useState(false)
  const [inputKey, setInputKey] = useState(Math.random().toString(36))

  const createBlobInContainer = useCallback(
    async (containerClient: ContainerClient, file: File) => {
      // create blobClient for container
      const blobClient = containerClient.getBlockBlobClient(theFile.filename)
      // set mimetype as determined from browser with file upload control
      const options = { blobHTTPHeaders: { blobContentType: file.type } }
      // upload file
      await blobClient.uploadData(file, options)
    },
    [theFile.filename]
  )

  const onFileChange = useCallback((event: any) => {
    // capture file into state
    setFileSelected(event.target.files[0])
  }, [])

  const uploadFileToBlob = useCallback(
    async file => {
      if (file === null) return []
      const { write_url: writeUrl, container } = theFile
      // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
      const blobService = new BlobServiceClient(writeUrl)
      // get Container - full public read access
      const containerClient = blobService.getContainerClient(container)
      // upload file
      await createBlobInContainer(containerClient, file)
    },
    [theFile.write_url, createBlobInContainer]
  )

  const onFileUpload = useCallback(async () => {
    setLoading(true)
    await uploadFileToBlob(fileSelected)
    setFileSelected(null)
    setLoading(false)
    setInputKey(Math.random().toString(36))
    onChange()
  }, [uploadFileToBlob, fileSelected])

  const handleDelete = useCallback(async () => {
    if (consultant?.id !== undefined) {
      setLoading(true)
      setSavingError(null)
      try {
        await consultantRepository.action(consultant.id, action)
        onChange()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    }
  }, [consultant, action])

  return (
    <>
      <ViewLoading loading={loading} />
      <ErrorMessage error={savingError} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <input type="file" onChange={onFileChange} key={inputKey !== "" ? inputKey : ""} />
        </Grid>
        <Grid item>
          <Button type="button" startIcon={<UploadIcon />} onClick={onFileUpload} size="small" disabled={fileSelected === null}>
            Upload
          </Button>
        </Grid>
        {theFile.exists !== undefined && theFile.exists !== false && (
          <>
            <Grid item>
              <Button size="small" startIcon={<LinkIcon />} href={`${theFile.read_url}&t=${Math.random()}`} target="_blank">
                Download
              </Button>
            </Grid>
            <Grid item>
              <AlertDialog
                buttonText="Delete"
                buttonIcon={DeleteIcon}
                showIconAndText
                title="Confirm File Delete"
                message="Are you sure you want to delete this file?"
                onYes={handleDelete}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default ConsultantFileEditor
