import { IExpenseReport } from "./IExpenseReport"

export const EXPENSE_REPORT_LINE_ITEMS_ENDPOINT = "expense_report_line_items"

export interface IExpenseReportLineItem {
  id: number
  expense_report: number | IExpenseReport
  description: string
  amount: number
  created: string
  updated: string
}
