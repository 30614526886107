import * as React from "react"
import { Alert, IconButton, Snackbar } from "@mui/material"
import { CopyToClipboard } from "react-copy-to-clipboard"
import CopyIcon from "@mui/icons-material/CopyAll"
import { useTableColumnShow } from "../../../shared/components/TableColumnShow"
import { workAssignmentColumns } from "../../../shared/components/WorkAssignmentsTable"
import { WORK_ASSIGNMENTS_URL } from "../../../config/urls"
import { useCallback, useMemo, useState } from "react"
import {
  IWorkAssignment,
  workAssignmentConsultant,
  workAssignmentLocation,
  workAssignmentProgress,
} from "../../../shared/models/IWorkAssignment"
import { ICustomer } from "../../../shared/models/ICustomer"
import { IAccount } from "../../../shared/models/IAccount"
import { IReportFormat } from "../../../shared/models/IReportFormat"
import IUser from "../../../shared/models/IUser"
import { IConsultant } from "../../../shared/models/main/IConsultant"

interface IProps {
  workAssignments: IWorkAssignment[]
}

/**
 * React functional component that renders a copy to clipboard button for work assignment table.
 *
 * @param {IProps} props - The props object containing workAssignments.
 * @returns {React.ReactElement} The JSX element representing the CopyWorkAssignmentsToClipboard component.
 */
const CopyWorkAssignmentsToClipboard: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { workAssignments } = props

  const [open, setOpen] = useState<boolean>(false)

  const { selectedColumns } = useTableColumnShow({
    columns: workAssignmentColumns,
    pathname: WORK_ASSIGNMENTS_URL,
  })

  const tableHeaderCell = useCallback(
    (field: string, title: string) => {
      return selectedColumns.some(c => c.field === field) ? `<th style='white-space:nowrap;'>${title}</th>` : ""
    },
    [selectedColumns]
  )

  const tableBodyCell = useCallback(
    (field: string, value: string | number | undefined) => {
      const display = value !== undefined ? value : ""
      return selectedColumns.some(c => c.field === field) ? `<td style='white-space:nowrap;border: none;padding: 4px;'>${display}</td>` : ""
    },
    [selectedColumns]
  )

  const tableCopy: string | null = useMemo(() => {
    if (workAssignments !== undefined) {
      const headerHtml = `
        <tr>
          ${workAssignmentColumns
            .map(column => {
              return tableHeaderCell(column.field, column.title.replaceAll(" ", "<br/>"))
            })
            .join("")}
        </tr>
      `

      const tableHtml = workAssignments.map((wa: IWorkAssignment, index: number) => {
        const progress = workAssignmentProgress(wa)
        return `
          <tr style="${index % 2 === 0 ? "background-color:#dddddd;" : ""}">
            ${tableBodyCell("identifier", wa.identifier)}
            ${tableBodyCell("priority", wa.priority_name)}
            ${tableBodyCell("customer__name", (wa.customer as ICustomer)?.name)}
            ${tableBodyCell("account__name", (wa.account as IAccount)?.name)}
            ${tableBodyCell("location__name", workAssignmentLocation(wa))}
            ${tableBodyCell("location__address", wa.location?.address)}
            ${tableBodyCell("location__city", wa.location?.city)}
            ${tableBodyCell("location__state_region", wa.location?.state_region)}
            ${tableBodyCell("location__country", wa.location?.country)}
            ${tableBodyCell("location__postal_code", wa.location?.postal_code)}
            ${tableBodyCell("inspection_category", wa.inspection_category)}
            ${tableBodyCell("inspection_type", wa.inspection_type)}
            ${tableBodyCell("executive", wa.executive?.name)}
            ${tableBodyCell("technical_admin", wa.technical_admin?.name)}
            ${tableBodyCell("report_format", (wa.report_format as IReportFormat)?.name)}
            ${tableBodyCell("consultant", workAssignmentConsultant(wa))}
            ${tableBodyCell("assignee", (wa.assignee as IUser)?.name)}
            ${tableBodyCell("tech_reviewer_consultant", !wa.tech_review_needed ? "Not Needed" : wa.tech_reviewer_consultant?.name)}
            ${tableBodyCell("tech_review_due_date", !wa.tech_review_needed ? "Not Needed" : (wa.tech_review_due_date as string))}
            ${tableBodyCell("tech_review_hours", wa.tech_review_hours)}
            ${tableBodyCell("tech_review_hours_approved_by", wa.tech_review_hours_approved_by?.name)}
            ${tableBodyCell("tech_reviewer_2_consultant", !wa.tech_review_2_needed ? "Not Needed" : wa.tech_reviewer_2_consultant?.name)}
            ${tableBodyCell("tech_review_2_due_date", !wa.tech_review_2_needed ? "Not Needed" : (wa.tech_review_2_due_date as string))}
            ${tableBodyCell("tech_review_2_hours", wa.tech_review_2_hours)}
            ${tableBodyCell("tech_review_2_hours_approved_by", wa.tech_review_2_hours_approved_by?.name)}
            ${tableBodyCell("admin_reviewer_consultant", (wa.admin_reviewer_consultant as IConsultant)?.name)}
            ${tableBodyCell("admin_review_hours", wa.admin_review_hours)}
            ${tableBodyCell("admin_review_hours_approved_by", wa.admin_review_hours_approved_by?.name)}
            ${tableBodyCell("issued_date", wa.issued_date as string)}
            ${tableBodyCell("visit_date", wa.visit_date as string)}
            ${tableBodyCell("consultant_due_date", wa.consultant_due_date as string)}
            ${tableBodyCell("due_date", wa.due_date as string)}
            ${tableBodyCell("progress_consultant_reports_submitted", wa.progress_consultant_reports_submitted)}
            ${tableBodyCell("progress_report_sent_account", wa.progress_report_sent_account)}
            ${tableBodyCell("progress", `${progress.progress.toFixed()}% | ${progress.title}`)}
            ${
              /* todo: this should be fixed on the server side. */
              tableBodyCell("invoice__number", typeof wa.invoice === "string" ? wa.invoice : wa.invoice?.number)
            }
            ${tableBodyCell("rli_getting_paid", wa.rli_getting_paid)}
            ${tableBodyCell("external_tracking", wa.external_tracking)}
            ${tableBodyCell("work_assignment_approved", wa.work_assignment_approved.toString())}
            ${tableBodyCell("notes", wa.notes)}
            ${tableBodyCell("notes_consultant", wa.notes_consultant)}
            ${tableBodyCell("job_status", wa.job_status)}
          </tr>
        `
      })
      return `
        <table style="border-collapse: collapse;font-size: 12pt;">
          <thead>
            ${headerHtml}
          </thead>
          <tbody>
            ${tableHtml.join("")}
          </tbody>
        </table>
        `
    }
    return null
  }, [workAssignments, selectedColumns, tableHeaderCell, tableBodyCell])

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        sx={{ mt: 8 }}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert severity="info" elevation={8}>
          Table has been copied to the clipboard.
        </Alert>
      </Snackbar>
      {tableCopy !== null && (
        <CopyToClipboard options={{ format: "text/html" }} text={tableCopy} onCopy={() => setOpen(true)}>
          <IconButton color="primary" size="small" title="Copy table to the clipboard.">
            <CopyIcon fontSize="small" />
          </IconButton>
        </CopyToClipboard>
      )}
    </>
  )
}

export default CopyWorkAssignmentsToClipboard
