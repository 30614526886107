import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Past Consultant Due Date"
const description = "Below are the work assignments with a past consultant due date."
const baseFilter: IFilter = {
  field: "past_consultant_due_date",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "past_consultant_due_date.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * Below are the work assignments with a past consultant due date.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const DailyStatusReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default DailyStatusReport
