import * as React from "react"
import FormatDate from "../../../shared/components/format/FormatDate"
import { isoAsDate } from "../../../shared/utilities/format_utility"

interface IProps {
  payPeriod: string | null
}


/**
 * Represents a component that displays the pay period.
 *
 * @param {IProps} props - The properties for the PayPeriodDisplay component.
 * @param {Date} props.payPeriod - The end date of the pay period.
 * @returns {React.ReactElement} The rendered PayPeriodDisplay component.
 */
const PayPeriodDisplay: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { payPeriod } = props

  const payPeriodEnd = isoAsDate(payPeriod)
  let payPeriodStart = null
  if (payPeriodEnd !== null) {
    if (payPeriodEnd.date() === 1) {
      payPeriodEnd.subtract(1)

      payPeriodStart = payPeriodEnd.clone()
      payPeriodStart.set("date", 16)
    }
    if (payPeriodEnd.date() === 15) {
      payPeriodStart = payPeriodEnd.clone()
      payPeriodStart.set("date", 1)
    }
  }

  return payPeriodEnd !== null && payPeriodStart !== null ? (
    <>
      <FormatDate value={payPeriodStart} /> <em>to</em> {' '}
      <FormatDate value={payPeriodEnd} />
    </>
  ) : (
    <>Not Set</>
  )
}

export default PayPeriodDisplay
