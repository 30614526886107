import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Submitted By Consultant"
const description = "Step 7 is to be completed regardless of steps 1-6 and not in review."
const baseFilter: IFilter = {
  field: "submitted_by_consultant",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "submitted_by_consultant.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * Step 7 is to be completed regardless of steps 1-6 and not in review.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const SubmittedByConsultantReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default SubmittedByConsultantReport
