import moment from "moment";
import { DATE_OUTPUT_FORMAT } from "../../config/config";

/**
 * Truncate a string to a specific length and add dots to the end. Also removes special characters.
 *
 * @param {string | undefined | null} str string to truncate.
 * @param {number} num length to truncate to.
 * @param {boolean} middle if middle is true then truncation will be in the middle.
 * @returns {string | undefined | null} the truncated string
 */
export const truncateString = (str: string | undefined | null, num: number = 30, middle: boolean = false): string | undefined | null => {
  if (str === null || str === undefined || str.length <= num) {
    return str
  }
  if (middle) {
    const sliceOffset = str.length / 2 - (str.length - num) / 2 - 1
    const replace = str.slice(sliceOffset, sliceOffset + (str.length - num)).replace(/<\/?[^>]+(>|$)/g, "")
    return str.replace(replace, "...")
  }
  return str.slice(0, num).replace(/<\/?[^>]+(>|$)/g, "") + "..."
}

/**
 * Check to see if a string is going to be truncated.
 *
 * @param {string} str to check.
 * @param {number} num length to truncate to.
 * @returns {boolean} true if the value is truncated.
 */
export const isTruncated = (str: string | undefined | null, num: number = 30): boolean => {
  return str !== null && str !== undefined ? str.length > num : false
}

/**
 * Get today's date plus a given number of days.
 *
 * @param {number} plus additional days past today.
 * @returns {string} date
 */
export const getDatePlus = (plus: number = 0): string => {
  const date = new Date()
  if (plus > 0) {
    date.setDate(date.getDate() + plus)
  }
  return date.toISOString().substring(0, 10)
}

/**
 * Check to see if some value is text.
 *
 * @param {any} data to check.
 * @returns {boolean} true if the data is a string.
 */
function isText(data: any): data is string {
  return typeof data === "string"
}

/**
 * Convert an iso date string to a moment or null
 *
 * @param {string | null} value string or null.
 * @returns {moment.Moment | null} the moment or null.
 */
export const isoAsDate = (value: string | null): moment.Moment | null => {
  if (value === null || value === "") {
    return null
  }
  return moment(value)
}

/**
 * Convert a date, string or null to a formatted date DATE_OUTPUT_FORMAT
 *
 * @param {moment.Moment | string | null} value to convert.
 * @returns {string | null} the formatted date string or null.
 */
export const dateAsIso = (value: moment.Moment | string | null): string | null => {
  if (value === null || value === undefined || isText(value)) {
    return null
  }
  return moment(value).format(DATE_OUTPUT_FORMAT)
}

/**
 * If the value is an empty string or 0 then return undefined.
 *
 * @param {string | number} value the value to check.
 * @returns {string | number | undefined} the value or undefined.
 */
export const valueOrUndefined = (value: string | number): string | number | undefined => {
  if (value === "" || value === 0) {
    return undefined
  }
  return value
}

/**
 * If the value is an empty string or 0 then return null.
 *
 * @param {string | number} value the value to check.
 * @returns {string | number | null} the value or null.
 */
export const valueOrNull = (value: string | number): string | number | null => {
  if (value === "" || value === 0) {
    return null
  }
  return value
}

/**
 * Get the value or an empty string.
 *
 * @param {any} value to check.
 * @returns {any} the value passed or an empty string.
 */
export const valueOrEmpty = <T>(value: T | string | null): T | string => {
  if (value === null) {
    return ""
  }
  return value
}

/**
 * This will adjust a date to the current pay period date. Either the 1st or the 15th.
 *
 * @param {moment.Moment | null} payPeriod date to adjust.
 * @returns {moment.Moment | null} the pay period or null.
 */
export const convertDateToPayPeriod = (payPeriod: moment.Moment | null): moment.Moment | null => {
  if (payPeriod !== null) {
    if (payPeriod.date() > 15) {
      payPeriod.add(1, "months")
      payPeriod.set("date", 1)
    } else {
      payPeriod.set("date", 15)
    }
  }
  return payPeriod
}

/**
 * Just get the date for a datetime string.
 *
 * @param {string} datetime to just get the date.
 * @returns {string} just the date.
 */
export const getDateFromDateTimeString = (datetime: string): string => {
  return datetime.split("T")[0]
}
