import * as React from "react"
import Moment from "react-moment"
import moment from "moment"
import { DATE_FORMAT } from "../../../config/config"

interface IProps {
  value: string | moment.Moment | null | undefined
  notSetMessage?: string
  format?: string
}

/**
 * Format a date given a string.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent} a formatted date.
 */
const FormatDate: React.FunctionComponent<IProps> = (props: IProps) => {
  const { value, notSetMessage = "Not Set", format = DATE_FORMAT } = props

  return value !== null && value !== undefined ? <Moment format={format}>{value}</Moment> : <>{notSetMessage}</>
}

export default FormatDate
