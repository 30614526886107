import * as React from "react"
import { Avatar, Chip, Tooltip } from "@mui/material"
import { styles } from "../styling/general"
import { ISpecialty } from "../models/ISpecialty"
import { IConsultantSpecialty } from "../models/IConsultantSpecialty"
import { OverridableStringUnion } from "@mui/types"
import { ChipPropsSizeOverrides } from "@mui/material/Chip/Chip"

interface IProps {
  specialties: IConsultantSpecialty[]
  size?: OverridableStringUnion<"small" | "medium", ChipPropsSizeOverrides>
}

/**
 * Renders the consultant specialties.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultant specialties
 */
const ConsultantSpecialties: React.FunctionComponent<IProps> = (props: IProps) => {
  const { specialties, size = "medium" } = props

  return (
    <>
      {specialties?.map(s => (
        <Tooltip key={s.id} title={s.note}>
          <Chip
            sx={styles.specialty.chip(s.score)}
            size={size}
            variant="outlined"
            avatar={<Avatar sx={styles.specialty.avatar(s.score)}>{s.score}</Avatar>}
            label={(s.specialty as ISpecialty).name}
          />
        </Tooltip>
      ))}
    </>
  )
}

export default ConsultantSpecialties
