import { CONNECTION_ERROR, IConnectionError, setFormConnectionErrors, TSetFormError } from "../models/IConnectionError"
import { SyntheticEvent, useCallback, useState } from "react"
import { useNavigate } from "@reach/router"
import { IMainModel } from "../models/IMainModel"

interface IUseApiAddResponse<T> {
  handleAdd: (t: T, disableRedirect?: boolean) => Promise<T | undefined>
  saving: boolean
  connectionError: IConnectionError | undefined
}

export interface IUseApiAddProps<T> {
  apiFunction: (item: T) => Promise<T>
  redirectView?: string
  redirect?: boolean
  setError: TSetFormError
}

/**
 * Hook for adding REST objects.
 *
 * @param {IUseApiAddProps} props See IProps for details.
 * @returns {IUseApiAddResponse} See IUseApiEditResponse for details.
 */
const useApiAdd = <T extends IMainModel>(props: IUseApiAddProps<T>): IUseApiAddResponse<T> => {
  const { apiFunction, redirectView, setError, redirect = true } = props
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [connectionError, setConnectionError] = useState<IConnectionError | undefined>()

  const handleAdd = useCallback(
    async (t: T, disableRedirect: boolean | SyntheticEvent = false): Promise<T | undefined> => {
      setSaving(true)
      try {
        const c1 = await apiFunction(t)
        if (redirect && (typeof disableRedirect !== "boolean" || !disableRedirect)) {
          if (redirectView !== undefined) {
            await navigate(`${redirectView}/${c1.id}`)
          } else {
            if (typeof window !== "undefined") {
              window.history.go(-1)
            }
          }
        } else {
          setSaving(false)
        }
        return c1
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setFormConnectionErrors(reason.response.data, setError)
        } else {
          setConnectionError(CONNECTION_ERROR)
        }
        setSaving(false)
      }
    },
    [navigate]
  )

  return {
    saving,
    handleAdd,
    connectionError,
  }
}

export default useApiAdd
