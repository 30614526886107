import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT, workAssignmentAdminNotNeeded } from "../../../shared/models/IWorkAssignment"
import { Alert, Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField } from "@mui/material"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { IListItem } from "../../../shared/models/component/IListItem"
import { CONSULTANTS_ENDPOINT, IConsultant } from "../../../shared/models/main/IConsultant"
import DialogControls from "../../../shared/components/DialogControls"
import ViewLoading from "../../../shared/components/ViewLoading"
import AdminReviewerCard from "./AdminReviewerCard"

const cardBadge = {
  display: "block",
  "& .MuiBadge-badge": {
    mt: -0.1,
    mr: 8,
  },
}

interface IPatch {
  admin_reviewer_consultant: number | null
  admin_review_hours: number
}

interface IProps {
  wa: IWorkAssignment
  small?: boolean
  onChange?: () => void
  onChangeWithReturn?: (wa: IWorkAssignment) => void
}

const consultantRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment | IPatch>(WORK_ASSIGNMENT_ENDPOINT)

/**
 * Use this component to assign an admin reviewer.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the assign admin component.
 */
const AssignAdminReviewer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { wa, small = false, onChange, onChangeWithReturn } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [consultant, setConsultant] = useState<IConsultant | null>(wa.admin_reviewer_consultant as IConsultant)
  const [hours, setHours] = useState<number>(0)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => {
    setOpen(true)
    setConsultant(wa.admin_reviewer_consultant as IConsultant)
    setHours(wa.admin_review_hours)
  }, [wa.admin_reviewer_consultant])

  const handleClose = useCallback(() => {
    setOpen(false)
    setSavingError(undefined)
  }, [])

  const handleAssign = useCallback(async () => {
    setSaving(true)
    setSavingError(undefined)
    try {
      const patch: IPatch = {
        admin_reviewer_consultant: consultant?.id !== undefined ? Number(consultant.id) : null,
        admin_review_hours: hours,
      }
      await waRepository.patch(patch, wa.id)
      if (consultant?.id !== undefined && onChangeWithReturn !== undefined) {
        const waUpdated = (await waRepository.read(wa.id)) as IWorkAssignment
        waUpdated.admin_reviewer_consultant = consultant
        onChangeWithReturn(waUpdated)
      }
      onChange?.()
      setOpen(false)
      setSaving(false)
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [consultant, hours])

  const handleReviewerActions = useCallback(
    (action: string) => async () => {
      setSaving(true)
      setSavingError(undefined)
      try {
        await waRepository.action(wa.id, action)
        onChange?.()
        setOpen(false)
        setSaving(false)
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    },
    []
  )

  const handleAdmin = useCallback((consultant1?: IListItem | null) => {
    setConsultant(consultant1 !== undefined ? (consultant1 as IConsultant) : null)
  }, [])

  const handleHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(Number(event.target.value))
  }, [])

  const progress = useMemo(() => {
    return wa.admin_review_sign_off === null ? 0 : 1
  }, [wa])

  return (
    <>
      <ViewLoading loading={saving} />
      {workAssignmentAdminNotNeeded(wa) ? (
        small ? (
          <>Not Needed</>
        ) : (
          <>
            <Alert
              severity="warning"
              action={
                <>
                  <Button color="inherit" size="small" onClick={handleReviewerActions("admin_reviewer_clear")}>
                    UNDO
                  </Button>
                </>
              }
            >
              Admin Reviewer not needed.
            </Alert>
          </>
        )
      ) : (
        <>
          <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>Assign Admin Reviewer</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ErrorMessage error={savingError} />
                </Grid>
                <Grid item xs>
                  <SelectFilteredSingle
                    name="admin_reviewer_consultant"
                    label="Admin Reviewer"
                    defaultValue={consultant as IConsultant}
                    filters={[
                      {
                        field: "admin_reviewer",
                        value: "true",
                      },
                    ]}
                    repository={consultantRepository}
                    onChange={handleAdmin}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField type="number" name="hours" label="Hours" value={hours} onChange={handleHours} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <DialogControls onSave={handleAssign} onCancel={handleClose} loading={saving} buttonLabel="Assign" />
            </DialogActions>
          </Dialog>

          {wa.admin_reviewer_consultant !== null ? (
            <>
              {small ? (
                <Button size="small" onClick={handleOpen}>
                  {(wa.admin_reviewer_consultant as IConsultant)?.name}
                </Button>
              ) : (
                <AdminReviewerCard
                  workAssignment={wa}
                  loading={saving}
                  hours={wa.admin_review_hours}
                  onNotNeeded={handleReviewerActions("admin_reviewer_not_required")}
                  onInProgress={handleReviewerActions("admin_reviewer_in_progress")}
                  onClear={handleReviewerActions("admin_reviewer_clear")}
                  onComplete={handleReviewerActions("admin_reviewer_complete")}
                  onApproveHoursToggle={handleReviewerActions("admin_reviewer_approve_hours_toggle")}
                  onEdit={handleOpen}
                  progress={progress}
                />
              )}
            </>
          ) : (
            <>
              {small ? (
                <Button size="small" onClick={handleOpen}>
                  Not Assigned
                </Button>
              ) : (
                <>
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <Badge color="info" badgeContent="Admin Reviewer" sx={cardBadge}>
                      <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
                        <Grid item xs>
                          <Button fullWidth onClick={handleOpen}>
                            Assign
                          </Button>
                        </Grid>
                        <Grid item xs>
                          <Button fullWidth onClick={handleReviewerActions("admin_reviewer_not_required")}>
                            Not Needed
                          </Button>
                        </Grid>
                      </Grid>
                    </Badge>
                  </Paper>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default AssignAdminReviewer
