import { Controller, FieldValues } from "react-hook-form"
import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"
import { TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DATE_INPUT_FORMAT } from "../../../config/config"
import moment from "moment"

interface IProps {
  control: Control<FieldValues, any>
  defaultValue?: string | number | moment.Moment
  label: string
  name: string
  formatter?: any
  rules?: any
  onChange?: (date: moment.Moment) => void
}

/**
 * A form hook MUI date field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the date field.
 */
const FhMuiDateField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, defaultValue = "", name, label, formatter: Formatter, rules = {}, onChange } = props

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <DesktopDatePicker
                label={label}
                onChange={date => {
                  field.onChange({ target: { value: date } })
                  onChange?.(moment(date))
                }}
                value={field.value}
                inputRef={field.ref}
                mask="__/__/____"
                inputFormat={DATE_INPUT_FORMAT}
                renderInput={params => (
                  <TextField
                    fullWidth
                    {...params}
                    error={fieldState.invalid}
                    InputLabelProps={{ shrink: true }}
                    onBlur={field.onBlur}
                    helperText={Formatter !== undefined ? <Formatter field={field} fieldState={fieldState} /> : fieldState.error?.message}
                  />
                )}
              />
            )
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default FhMuiDateField
