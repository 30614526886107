interface IRequiredResponse {
  required: {
    value: boolean
    message: string
  }
}

/**
 * Use for a form control that is required.
 *
 * @param {string} message to be displayed on error.
 * @returns {IRequiredResponse} the required rule
 */
export const requiredRule = (message = "Required"): IRequiredResponse => {
  return {
    required: {
      value: true,
      message,
    },
  }
}
