import { axiosInstance, urlSearchParams } from "../utilities/request_utility";
import { IPagedResults } from "../models/IPagedResults";
import { IPaging } from "../models/IPaging";

/**
 * Repository for requesting model rest api endpoints.
 */
export class RestRepository<T> {
  endpointName: string

  /**
   * Build a rest repository.
   *
   * @param {string} endpointName name of the endpoint.
   */
  constructor(endpointName: string) {
    this.endpointName = endpointName
  }

  findAll = async (paging?: IPaging | null): Promise<IPagedResults<T>> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/${queryString}`)
    return data as IPagedResults<T>
  }

  list = async (paging?: IPaging): Promise<T[]> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/list/${queryString}`)
    return data as T[]
  }

  read = async (id: string | number): Promise<T> => {
    const { data } = await axiosInstance.get(`/${this.endpointName}/${id}/`)
    return data as T
  }

  action = async (id: string | number, action: string, paging?: IPaging): Promise<T> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axiosInstance.get(`/${this.endpointName}/${id}/${action}/${queryString}`)
    return data as T
  }

  add = async (item: T): Promise<T> => {
    const { data } = await axiosInstance.post(`/${this.endpointName}/`, item)
    return data as T
  }

  edit = async (item: T, id: string | number): Promise<T> => {
    const { data } = await axiosInstance.put(`/${this.endpointName}/${id}/`, item)
    return data as T
  }

  patch = async (item: T, id: string | number): Promise<T> => {
    const { data } = await axiosInstance.patch(`/${this.endpointName}/${id}/`, item)
    return data as T
  }

  delete = async (id: string | number): Promise<void> => {
    await axiosInstance.delete(`/${this.endpointName}/${id}/`)
  }
}
