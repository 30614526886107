import { IListItem } from "./component/IListItem"
import { IGroup } from "./IGroup"

export const STAFF_ENDPOINT = "staff"
export const ADMIN_REVIEWERS_ENDPOINT = "admin_reviewers"
export const TECH_REVIEWERS_ENDPOINT = "tech_reviewers"
export const USERS_ME_ENDPOINT = "users/me"

export default interface IUser extends IListItem {
  id: number
  profile: number
  name: string
  first_name: string
  last_name: string
  username: string
  is_superuser: boolean
  is_staff: boolean
  is_active: boolean
  last_login: string
  email: string
  groups: IGroup[]
  customers: number[]
}
