import * as React from "react"
import { Box, FormHelperText } from "@mui/material"

/**
 * React functional component for displaying a message to check if a contact exists before creating a new one.
 *
 * @returns {React.ReactElement} The rendered component
 */
const AddContactCheckMessage: React.FC = (): React.ReactElement => {
  return (
    <Box>
      <FormHelperText>
        <strong>Before creating a new contact, check to see if the contact exists by typing in their name.</strong>
      </FormHelperText>
    </Box>
  )
}

export default AddContactCheckMessage
