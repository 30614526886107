import { BuildableResource, BuildConfiguration, Builder } from "tapi.js";
import moment from "moment";

/**
 * Builds a change history object from json data.
 *
 * @param {any} json to transform.
 * @returns {ChangeHistory} object.
 */
export const ChangeHistoryBuilder = (json: any): ChangeHistory => {
  const buildConfig = new BuildConfiguration<ChangeHistory>()
    .ignore("actor")
    .transform("changes", (value: string) => JSON.parse(value))
    .transform("timestamp", (value: string) => moment(value))
    .alias("action_name", "actionName")
    .alias("actor_name", "actorName")
    .alias("content_type", "contentType")
    .alias("object_id", "objectId")
    .alias("object_pk", "objectPk")
    .alias("object_repr", "objectRepr")
    .alias("remote_addr", "remoteAddr")
    .alias("serialized_data", "serializedData")

  return new Builder(ChangeHistory, buildConfig).fromJSON(json)
}

interface IChanges {
  [id: string]: [string, string]
}

/**
 * Describes a change history for a resource model
 */
export class ChangeHistory extends BuildableResource {
  public action: number | null = null
  public actionName: string | null = null
  public actorName: string | null = null
  public changes: IChanges | null = null
  public contentType: number | null = null
  public objectId: number | null = null
  public objectPk: string | null = null
  public objectRepr: string | null = null
  public remoteAddr: string | null = null
  public serializedData: string | null = null
  public timestamp: moment.Moment | null = null

  /**
   * Get the fields that have changed.
   *
   * @returns {string} the changed fields.
   */
  getChangeFields(): string {
    if (this.changes !== null) {
      return Object.keys(this.changes).join(", ")
    }
    return ""
  }

  /**
   * Get the changes or an empty object.
   *
   * @returns {IChanges} or an empty IChanges.
   */
  getChanges(): IChanges {
    if (this.changes !== null) {
      return this.changes
    }
    return {}
  }

  /**
   * Get the original value for the field.
   *
   * @param {string} field to get the value from.
   * @returns {string} the original value.
   */
  getOriginal(field: string): string {
    if (this.changes !== null) {
      return this.changes[field][0]
    }
    return ""
  }

  /**
   * Get the changed value for the field.
   *
   * @param {string} field to get the value from.
   * @returns {string} the changed value.
   */
  getChange(field: string): string {
    if (this.changes !== null) {
      return this.changes[field][1]
    }
    return ""
  }
}
