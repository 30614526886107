import * as React from "react"
import { useCallback, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material"
import { IInvoice } from "../../../shared/models/IInvoice"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CATEGORY_OPTIONS, DEFAULT_CATEGORY_OPTIONS, INVOICE_LINE_ITEM_ENDPOINT } from "../../../shared/models/IInvoiceLineItem"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import DialogControls from "../../../shared/components/DialogControls"
import AddIcon from "@mui/icons-material/Add"

interface IProps {
  invoice: IInvoice
  onChange: () => void
}

export interface IInvoiceLineItemAdd {
  invoice: number
  description: string
  category: string
  amount: number
}

const repository = new RestRepository<IInvoiceLineItemAdd>(INVOICE_LINE_ITEM_ENDPOINT)

/**
 * Add a new line item to the invoice.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the component.
 */
const AddLineItem: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { invoice, onChange } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const [description, setDescription] = useState<string>("")
  const [category, setCategory] = useState<string>(DEFAULT_CATEGORY_OPTIONS.key as string)
  const [amount, setAmount] = useState<string>("0")
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleClose = useCallback(() => {
    setDescription("")
    setAmount("0")
    setOpen(false)
  }, [])

  const handleSave = useCallback(async () => {
    try {
      setSaving(true)
      const lineItem: IInvoiceLineItemAdd = {
        invoice: invoice.id,
        description,
        category,
        amount: Number(amount),
      }
      await repository.add(lineItem)
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, invoice, category, description, amount])

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />}>
        Line Item
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Add Line Item</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <>
                <TextField fullWidth label="Cateogry" select onChange={e => setCategory(e.target.value)} value={category}>
                  {CATEGORY_OPTIONS.map(inspectionType => (
                    <MenuItem key={inspectionType.key} value={inspectionType.key}>
                      {inspectionType.value}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} loading={saving} onCancel={handleClose} disabled={description === "" || amount === ""} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddLineItem
