import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { DashboardStore } from "../../store"
import { useCallback } from "react"
import { operations } from "../../store/settings"

interface IProps {
  map: any
}

interface IResponse {
  mapType: string
  handleMapTypeChanged: () => void
}

/**
 * Get the Google map type to be displayed.
 *
 * @param {IProps} props See IProps for details.
 * @returns {IResponse} the map type and a means to change it.
 */
const useMapType = (props: IProps): IResponse => {
  const { map } = props
  const dispatch = useDispatch()
  const mapType: string = useSelector((state: DashboardStore) => state.settings.mapType, shallowEqual)

  const handleMapTypeChanged = useCallback(() => {
    if (map?.getMapTypeId !== undefined) {
      dispatch(operations.setMapType(map.getMapTypeId()))
    }
  }, [map])

  return {
    mapType,
    handleMapTypeChanged,
  }
}

export default useMapType
