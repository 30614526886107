import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import BookmarksIcon from "@mui/icons-material/Bookmarks"
import { type IFilter } from "../models/IFilter"
import DialogControls from "./DialogControls"
import { styles } from "../styling/general"
import TabPanel, { useTabPanel } from "./TabPanel"
import { useLocalStorage } from "beautiful-react-hooks"
import { type IFilterBookmark } from "../models/IFilterBookmark"
import DeleteIcon from "@mui/icons-material/Delete"
import MoveIcon from "@mui/icons-material/Cloud"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import AlertDialog from "./AlertDialog"
import useProfileStorage from "../hooks/useProfileStorage"

interface IProps {
  /**
   * Should match the endpoint where the filters are being used.
   */
  bookmarkType: "work_assignments" | "consultants" | "expense_reports"
  filters?: IFilter[]
  onFilter: (filters: IFilter[]) => void
}

/**
 * This component will allow for the bookmarking of filters.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the filter bookmarking component.
 */
const FilterBookmarksDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { bookmarkType, filters, onFilter } = props
  const [open, setOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const { tab, handleTabChange } = useTabPanel()
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const [showFilters, setShowFilters] = useState<number[]>([])

  const bookmarksKey = `filter-bookmarks-${bookmarkType}`

  const [bookmarks, setBookmarks] = useLocalStorage<IFilterBookmark[]>(bookmarksKey, [])

  const { value, loadValue, setValue, loading } = useProfileStorage<Record<string, IFilterBookmark[] | null>>("bookmarks")

  const handleOpen = useCallback(async () => {
    setOpen(true)
    await loadValue()
  }, [loadValue, setValue])

  const bookmarksRemote: IFilterBookmark[] | null = useMemo(() => {
    return value?.[bookmarksKey] ?? null
  }, [value, bookmarksKey])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleChangeTitle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }, [])

  const handleChangeDescription = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }, [])

  const handleSaveBookmark = useCallback(async () => {
    if (filters !== undefined) {
      const bookmark: IFilterBookmark = {
        title,
        description,
        filters: [...filters],
      }
      if (bookmarksRemote === null) {
        await setValue({ ...value, [bookmarksKey]: [bookmark] })
      } else {
        await setValue({ ...value, [bookmarksKey]: [...bookmarksRemote, bookmark] })
      }
      setTitle("")
      setDescription("")
      handleTabChange(null, 0)
    }
  }, [filters, value, setValue, bookmarksKey, bookmarksRemote, title, description])

  const handleApplyFilters = useCallback(
    (bookmark: IFilterBookmark) => () => {
      onFilter(bookmark.filters.map(filter => ({ ...filter, cantDelete: false })))
      setOpen(false)
    },
    [],
  )

  const handleDeleteBookmark = useCallback(
    (index: number) => async () => {
      if (bookmarksRemote !== null) {
        const bookmarksRemote1 = bookmarksRemote.filter((b1, i) => i !== index)
        await setValue({ ...value, [bookmarksKey]: bookmarksRemote1 })
      }
    },
    [value, setValue, bookmarksRemote],
  )

  const handleShowFilters = useCallback(
    (index: number) => () => {
      setShowFilters(showFilters => {
        if (showFilters.includes(index)) {
          return showFilters.filter(filterIndex => filterIndex !== index)
        }
        return [...showFilters, index]
      })
    },
    [],
  )

  const filterDisplay = useCallback((filter: IFilter) => {
    if (filter?.display !== undefined) {
      return ` | ${filter.display}`
    }
    if (filter?.value !== undefined) {
      return ` | ${filter.value}`
    }
    return ""
  }, [])

  const handleSync = useCallback(async () => {
    if (bookmarksRemote === null) {
      await setValue({ ...value, [bookmarksKey]: [...bookmarks] })
    } else {
      await setValue({ ...value, [bookmarksKey]: [...bookmarksRemote, ...bookmarks] })
    }
  }, [value, setValue, bookmarks])

  return (
    <>
      <Button startIcon={<BookmarksIcon />} onClick={handleOpen} size="small">
        Bookmarks
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth fullScreen={isSmall} maxWidth="sm">
        <DialogTitle>
          <Grid container>
            <Grid item xs>
              Filter Bookmarks
            </Grid>
            {bookmarks?.length > 0 && (
              <Grid item>
                <AlertDialog
                  showIconAndText
                  buttonText="Move to Cloud"
                  buttonIcon={MoveIcon}
                  message={
                    <>
                      <p>You have {bookmarks.length} bookmark(s) saved on this page in your browser.</p>
                      <ul>
                        {bookmarks.map((bookmark, index) => {
                          return <li key={index}>{bookmark.title}</li>
                        })}
                      </ul>
                      <p>Would you like to move bookmarks saved in your browser to the cloud?</p>
                    </>
                  }
                  onYes={handleSync}
                />
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <Box
          sx={{
            ...styles.tabsBox,
            mt: 0,
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Bookmarks" />
            <Tab label="Create" disabled={filters === undefined || filters.length === 0} />
            {/* <Tab label="Dev" disabled={filters === undefined || filters.length === 0} /> */}
          </Tabs>
        </Box>
        <DialogContent sx={{ p: 0 }}>
          <TabPanel index={0} value={tab}>
            {bookmarksRemote === null || bookmarksRemote?.length === 0 || loading ? (
              <Box sx={{ p: 2 }}>
                <Grid container>
                  <Grid item xs>
                    {!loading && <Alert color="info">No filter bookmarks found.</Alert>}
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <>
                <List dense>
                  {bookmarksRemote?.map((bookmark, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          key={index}
                          sx={{
                            pl: 0,
                          }}
                          secondaryAction={
                            <Grid container spacing={1}>
                              <Grid item>
                                <AlertDialog
                                  buttonText="Delete"
                                  buttonIcon={DeleteIcon}
                                  message={
                                    <>
                                      Are you sure you want to delete: <strong>{bookmark.title}</strong>
                                    </>
                                  }
                                  onYes={handleDeleteBookmark(index)}
                                />
                              </Grid>
                              <Grid item>
                                <IconButton edge="end" onClick={handleShowFilters(index)}>
                                  {showFilters.includes(index) ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        >
                          <ListItemButton onClick={handleApplyFilters(bookmark)}>
                            <ListItemText primary={bookmark.title} secondary={bookmark.description} />
                          </ListItemButton>
                        </ListItem>
                        <Collapse in={showFilters.includes(index)} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding dense>
                            {bookmark.filters.map((filter1, fIndex) => {
                              return (
                                <ListItem
                                  sx={{
                                    mt: 0,
                                    pt: 0,
                                  }}
                                  key={fIndex}
                                >
                                  <ListItemText
                                    sx={{ pl: 4 }}
                                    primary={`${filter1?.title !== undefined ? filter1.title : ""}${filterDisplay(filter1)}`}
                                  />
                                </ListItem>
                              )
                            })}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    )
                  })}
                </List>
              </>
            )}
          </TabPanel>
          <TabPanel index={1} value={tab}>
            {filters !== undefined && filters.length > 0 && (
              <Paper sx={{ p: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField disabled={loading} fullWidth value={title} label="Title" onChange={handleChangeTitle} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={loading}
                      fullWidth
                      multiline
                      value={description}
                      label="Description"
                      onChange={handleChangeDescription}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <List dense>
                      {filters.map((filter, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={`${filter?.title !== undefined ? filter.title : ""}${filterDisplay(filter)}`} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Button onClick={handleSaveBookmark} disabled={loading}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </TabPanel>
          <TabPanel index={2} value={tab}>
            <TextField fullWidth multiline maxRows={10} value={JSON.stringify(filters, null, 4)} />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <DialogControls buttonLabel="Close" onSave={handleClose} loading={loading} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FilterBookmarksDialog
