import * as React from "react"
import { useCallback, useState } from "react"
import { IWorkAssignmentConsultant, WORK_ASSIGNMENT_CONSULTANT_ENDPOINT } from "../../../shared/models/IWorkAssignmentConsultant"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import { LoadingButton } from "@mui/lab"
import { IConsultant } from "../../../shared/models/main/IConsultant"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ConsultantRate from "../../../shared/components/ConsultantRate"

interface IProps {
  wac?: IWorkAssignmentConsultant
  onChange: () => void
  useButtonWithAmount?: boolean
}

interface IPatch {
  amount: number
  hourly_rate: boolean
  hours: number
}

const repository = new RestRepository<IPatch>(WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)

/**
 * Edit the work assignment consultant paid values.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const WorkAssignmentConsultantEdit: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wac, onChange, useButtonWithAmount = false } = props

  const consultant = wac?.consultant as IConsultant
  const [amount, setAmount] = useState<number>(0)
  const [hourlyRate, setHourlyRate] = useState<boolean>(false)
  const [hours, setHours] = useState<number>(0)
  const [open, setOpen] = useState(false)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleOpen = useCallback(() => {
    if (wac !== undefined) {
      setAmount(wac.amount)
      setHourlyRate(wac.hourly_rate)
      setHours(wac.hours)
    }
    setOpen(true)
  }, [wac])

  const handleCancel = useCallback(() => setOpen(false), [])

  const handleUpdate = useCallback(
    () => async (event: React.FormEvent) => {
      event.preventDefault()
      setSavingError(null)
      try {
        if (wac?.id !== undefined) {
          setSaving(true)
          const patch: IPatch = {
            amount: Number(amount),
            hourly_rate: hourlyRate,
            hours,
          }
          await repository.patch(patch, wac.id)
          onChange()
          setSaving(false)
          setOpen(false)
        }
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    },
    [wac, amount, hourlyRate, hours]
  )

  const handleAmount = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(event.target.value))
  }, [])

  const handleHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(Number(event.target.value))
  }, [])

  const handleHourlyRate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(0)
    setHourlyRate(event.target.checked)
  }, [])

  return (
    <>
      {useButtonWithAmount && wac !== undefined ? (
        <Button onClick={handleOpen}>
          <ConsultantRate wac={wac} showDetails />
        </Button>
      ) : (
        <MenuItem onClick={handleOpen}>Edit Fee</MenuItem>
      )}
      <Dialog open={open} disableEscapeKeyDown={true}>
        <DialogTitle>
          Edit <em>{consultant?.name}</em>
        </DialogTitle>
        <DialogContent>
          <form method="post" onSubmit={handleUpdate()}>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems="top">
              <Grid item xs={12}>
                <ErrorMessage error={savingError} />
              </Grid>
              {hourlyRate && (
                <Grid item xs>
                  <TextField
                    fullWidth
                    type="number"
                    label="Hours Worked"
                    autoFocus
                    value={hours}
                    disabled={saving}
                    onChange={handleHours}
                  />
                </Grid>
              )}
              <Grid item xs>
                <TextField
                  fullWidth
                  label="Fee"
                  autoFocus
                  value={amount}
                  disabled={saving}
                  helperText={<FormatNumber value={Number(amount)} suffixUnits={hourlyRate ? "/hr" : ""} />}
                  InputProps={{
                    endAdornment: hourlyRate && <InputAdornment position="end">/hr</InputAdornment>,
                  }}
                  onChange={handleAmount}
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={hourlyRate} onChange={handleHourlyRate} />} label="Hourly Rate?" />
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleCancel}>
            Cancel
          </Button>
          <LoadingButton loading={saving} disabled={saving} onClick={handleUpdate()}>
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default WorkAssignmentConsultantEdit
