import * as React from "react"
import { WA_DAILY_BLUE, WA_DAILY_RED, WA_DAILY_YELLOW } from "../../shared/styling/general"
import { IFilter } from "../../shared/models/IFilter"
import { FILTERS } from "../../config/config"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const legendEntries = [
  {
    color: WA_DAILY_RED,
    description: "Work Assignments in RED are 20 days or more from when the site was inspected.",
  },
  {
    color: WA_DAILY_YELLOW,
    description: "Work Assignments in Yellow have been in review for 10+ days.",
  },
  {
    color: WA_DAILY_BLUE,
    description: "Work Assignments in Blue have begun the review process.",
  },
]
const title = "Work Assignments in Review"
const baseFilter: IFilter = {
  field: FILTERS.DAILY.field,
  value: "true",
  title,
  cantDelete: true,
}
const filename = "daily_status.xlsx"

/**
 * This report replaced the daily status report email.
 *
 * @returns {React.FunctionComponent} the report page.
 */
const DailyStatusReport: React.FunctionComponent = () => {
  return <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} legendEntries={legendEntries} filename={filename} />
}

export default DailyStatusReport
