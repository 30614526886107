import { IAccount } from "./IAccount";
import { IContact } from "./IContact";
import IUser from "./IUser";
import { IListItem } from "./component/IListItem";
import { IFile } from "./IFile";
import * as moment from "moment";

/**
 * Test if an object is a customer.
 *
 * @param {any} obj the object to test.
 * @returns {boolean} true if it is a customer.
 */
export const isCustomer = (obj: any): obj is ICustomer => true

export const CUSTOMER_ENDPOINT = "customers"

// These values are duplicated on the server. They shouldn't change much if at all, so duplication should be fine.
// Be sure to reflect any changes on the server.
export const CUSTOMER_TYPES = [
  {
    key: "I",
    value: "Insurance",
  },
  {
    key: "B",
    value: "Broker",
  },
  {
    key: "D",
    value: "Direct",
  },
]

export const DEFAULT_CUSTOMER_TYPE = CUSTOMER_TYPES[0]

/**
 * Get the customer type name.
 *
 * @param {ICustomer} customer to get the type from.
 * @returns {string} then of the type or the default type name.
 */
export const customerTypeName = (customer: ICustomer): string => {
  for (const customerType of CUSTOMER_TYPES) {
    if (customerType.key === customer.customer_type) {
      return customerType.value
    }
  }
  return DEFAULT_CUSTOMER_TYPE.value
}

export interface ICustomer extends IListItem {
  id: number
  identifier: string
  customer_type: string
  service_instructions_url: string
  service_instructions_change_date: string | moment.Moment | null
  consultant_due_date_days_after_visit: number
  consultant_due_date_use_business_days: boolean
  name: string
  notes: string
  since: string
  accounts?: IAccount[]
  accounts_count: number
  contacts: IContact[]
  invoice_contact: IContact | null
  files: IFile[]
  executive?: IUser | null
  technical_admin?: IUser | null

  quickbooks_identifier: string | null | undefined
  quickbooks_synced: string | null | undefined

  created?: string
  updated?: string
}
