import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { ACCOUNTS_VIEW_URL } from "../../config/urls"
import { useLocation } from "@reach/router"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { ACCOUNT_ENDPOINT, IAccount } from "../../shared/models/IAccount"
import AccountForm from "./components/AccountForm"
import { ICustomer } from "../../shared/models/ICustomer"
import useApiAdd, { IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { dateAsIso } from "../../shared/utilities/format_utility"
import moment from "moment/moment"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const redirectView = ACCOUNTS_VIEW_URL

interface ILocationState {
  customer: ICustomer | null | undefined
}

interface ILocalAccount {
  customer: ICustomer
}

/**
 * This page will allow adding of an account.
 *
 * @returns {React.FunctionComponent} the account add page.
 */
const AddPage: React.FunctionComponent = () => {
  const navLocation = useLocation()
  const { control, handleSubmit, setError, formState, setValue, clearErrors } = useForm()
  const [account, setAccount] = useState<IAccount | ILocalAccount | null>(null)

  const props: IUseApiAddProps<IAccount> = {
    apiFunction: repository.add,
    setError,
    redirectView,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<IAccount>(props)

  const handleSave = useCallback(async (account1: IAccount) => {
    account1.service_instructions_change_date = dateAsIso(account1.service_instructions_change_date as moment.Moment)
    await handleAdd(account1)
  }, [])

  useEffect(() => {
    if (navLocation?.state !== undefined) {
      const { customer } = navLocation.state as ILocationState
      if (customer !== undefined && customer !== null && account === null) {
        const account1: ILocalAccount = { customer }
        setAccount(account1)
      }
    }
  }, [navLocation.state])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Add Account" loading={saving} errors={formState.errors} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <AccountForm control={control} account={account as IAccount} clearErrors={clearErrors} setValue={setValue} />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
