import * as React from "react"
import { ControllerFieldState } from "react-hook-form"
import { ControllerRenderProps } from "react-hook-form/dist/types/controller"
import FormatNumber from "./FormatNumber"

interface IProps {
  field: ControllerRenderProps<any, any>
  fieldState: ControllerFieldState
  prefixUnits?: string
  suffixUnits?: string
}

/**
 * Show the number formatted for a field and any error that might be associated with the field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const FormatNumberAndError: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, fieldState, prefixUnits = "$", suffixUnits = "" } = props

  return (
    <>
      <FormatNumber value={field.value} prefixUnits={prefixUnits} suffixUnits={suffixUnits} />
      {fieldState?.error !== undefined ? fieldState.error?.message : ""}
    </>
  )
}

export default FormatNumberAndError
