import { ILocation } from "./ILocation"
import { ICustomer } from "./ICustomer"
import { IListItem } from "./component/IListItem"
import { IContact } from "./IContact"
import { IAccountFile } from "./IAccountFile"
import IUser from "./IUser"
import * as moment from "moment/moment"

/**
 * Check if an object is an IAccount and cast it.
 *
 * @param {any} obj to check.
 * @returns {boolean} if it is an IAccount
 */
export const isAccount = (obj: any): obj is IAccount => true

export const ACCOUNT_ENDPOINT = "accounts"

export interface IAccount extends IListItem {
  id: number
  identifier: string
  name: string
  notes: string
  service_instructions_url: string
  service_instructions_change_date: string | moment.Moment | null
  locations_count: number

  customer: ICustomer | number
  executive: IUser | number
  technical_admin: IUser | number
  locations: ILocation[]
  contacts: IContact[]
  invoice_contact: IContact | null
  files: IAccountFile[]

  quickbooks_identifier: string | null | undefined
  quickbooks_synced: string | null | undefined

  created: string
  updated: string
}
