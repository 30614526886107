import { IInvoice } from "./IInvoice"
import { ISelectItem } from "./component/ISelectItem"

export const INVOICE_LINE_ITEM_ENDPOINT = "invoice_line_items"

export const CATEGORY_OPTIONS = [
  {
    key: "OTHER",
    value: "Other",
  },
  {
    key: "RMIS",
    value: "Risk Management Information System",
  },
  {
    key: "HPR",
    value: "Highly Protected Risk",
  },
  {
    key: "PL",
    value: "Property Lite",
  },
  {
    key: "PR",
    value: "Plan Review",
  },
] as ISelectItem[]

export const DEFAULT_CATEGORY_OPTIONS = CATEGORY_OPTIONS[0]

export interface IInvoiceLineItem {
  id: number
  invoice: number | IInvoice
  description: string
  category: string
  amount: number
  created: string
  updated: string
}
