import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { IAccount } from "../../../shared/models/IAccount"
import { Control } from "react-hook-form/dist/types"
import { Box, Grid } from "@mui/material"
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form/dist/types/form"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../../shared/models/ICustomer"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import SelectFilteredMultiple from "../../../shared/components/SelectFilteredMultiple"
import { CONTACT_ENDPOINT, IContact } from "../../../shared/models/IContact"
import AddContact from "../../../shared/components/AddContact"
import { IListItem } from "../../../shared/models/component/IListItem"
import FhMuiIdField from "../../../shared/components/form/FhMuiIdField"
import FhMuiTextField from "../../../shared/components/form/FhMuiTextField"
import FhMuiMdField from "../../../shared/components/form/FhMuiMdField"
import { requiredRule } from "../../../shared/utilities/form_utility"
import IUser, { STAFF_ENDPOINT } from "../../../shared/models/IUser"
import FhMuiDateField from "../../../shared/components/form/FhMuiDateField"
import { isoAsDate } from "../../../shared/utilities/format_utility"
import AddContactCheckMessage from "../../../shared/components/AddContactCheckMessage"

const customerRepository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)
const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)
const userRepository = new RestRepository<IUser | IListItem>(STAFF_ENDPOINT)

interface IProps {
  control: Control<any>
  account?: IAccount | undefined | null
  isEdit?: boolean
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  minimal?: boolean
}

/**
 * Use this form to add or edit an account.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the form.
 */
const AccountForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { account, isEdit = false, control, setValue, clearErrors, minimal = false } = props
  const [hasData, setHasData] = useState(false)
  const [contacts, setContacts] = useState<IListItem[]>([])
  const [invoiceContact, setInvoiceContact] = useState<IListItem | null>(null)
  const [customer, setCustomer] = useState<ICustomer | null>(null)
  const [executive, setExecutive] = useState<IUser | null>(null)
  const [technicalAdmin, setTechnicalAdmin] = useState<IUser | null>(null)

  const handleCustomer = useCallback((customer1?: IListItem | null) => {
    setValue("customer", customer1 === null || customer1 === undefined ? null : customer1.id)
    setCustomer(customer1 !== undefined ? (customer1 as ICustomer) : null)
  }, [])

  const handleInvoiceContact = useCallback((invoiceContact1?: IListItem | null) => {
    setValue("invoice_contact", invoiceContact1 === null || invoiceContact1 === undefined ? null : invoiceContact1.id)
    setInvoiceContact(invoiceContact1 !== undefined ? (invoiceContact1 as IContact) : null)
  }, [])

  const handleExecutive = useCallback((user1?: IListItem | null) => {
    setValue("executive", user1 === null || user1 === undefined ? null : user1.id)
    setExecutive(user1 !== undefined ? (user1 as IUser) : null)
  }, [])

  const handleTechnicalAdmin = useCallback((user1?: IListItem | null) => {
    setValue("technical_admin", user1 === null || user1 === undefined ? null : user1.id)
    setTechnicalAdmin(user1 !== undefined ? (user1 as IUser) : null)
  }, [])

  const updateContacts = useCallback((cs1?: IContact[]) => {
    setValue(
      "contacts",
      cs1?.map(c => c.id)
    )
    setContacts(cs1 ?? [])
  }, [])

  const handleContacts = useCallback((cs1?: IListItem[]) => {
    clearErrors("contacts")
    updateContacts(cs1 as IContact[])
  }, [])

  const handleAddContact = useCallback((contact: IContact) => {
    setContacts(contacts => {
      const cs2 = [...contacts, contact]
      setValue(
        "contacts",
        cs2.map(c => c.id)
      )
      return cs2
    })
  }, [])

  const handleAddInvoiceContact = useCallback((contact1: IListItem) => {
    clearErrors("invoice_contact")
    setInvoiceContact(contact2 => {
      const cs2 = contact1 !== undefined ? contact1 : contact2
      setValue("invoice_contact", cs2?.id)
      return cs2
    })
  }, [])

  useEffect(() => {
    if (account !== undefined && account !== null) {
      if (isEdit && !hasData) {
        setHasData(true)
        setValue("id", account.id)
        setValue("name", account.name)
        setValue("service_instructions_url", account.service_instructions_url)
        setValue("service_instructions_change_date", isoAsDate(account.service_instructions_change_date as string))
        setValue("notes", account.notes)
        handleCustomer(account.customer as ICustomer)
        handleExecutive(account.executive as IUser)
        handleTechnicalAdmin(account.technical_admin as IUser)
        updateContacts(account.contacts)
        handleInvoiceContact(account.invoice_contact)
      }
      if (!hasData) {
        setHasData(true)
        handleCustomer(account.customer as ICustomer)
      }
    }
  }, [account, hasData, setValue, isEdit])

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={!minimal && 5}>
        <Grid item xs={12}>
          {isEdit && <FhMuiIdField control={control} />}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FhMuiTextField control={control} label="Name" name="name" rules={requiredRule()} />
          </Grid>
          {!minimal && (
            <>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="customer"
                  label="Customer"
                  defaultValue={customer as IListItem}
                  repository={customerRepository}
                  onChange={handleCustomer}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="executive"
                  label="AE"
                  defaultValue={executive as IListItem}
                  repository={userRepository}
                  onChange={handleExecutive}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="technical_admin"
                  label="TA"
                  defaultValue={technicalAdmin as IListItem}
                  repository={userRepository}
                  onChange={handleTechnicalAdmin}
                />
              </Grid>
            </>
          )}
          {!minimal && (
            <>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <SelectFilteredMultiple
                      name="contacts"
                      label="Contacts"
                      defaultValue={contacts}
                      repository={contactRepository}
                      onChange={handleContacts}
                    />
                    <AddContactCheckMessage />
                  </Grid>
                  <Grid item>
                    <Box sx={{ mt: -2 }}>
                      <AddContact onAddContact={handleAddContact} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <SelectFilteredSingle
                      name="invoice_contact"
                      label="Invoice Contact"
                      defaultValue={invoiceContact}
                      repository={contactRepository}
                      onChange={handleInvoiceContact}
                    />
                    <AddContactCheckMessage />
                  </Grid>
                  <Grid item>
                    <Box sx={{ mt: -2 }}>
                      <AddContact onAddContact={handleAddInvoiceContact} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {!minimal && (
        <Grid item xs={12} md={!minimal && 7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiMdField control={control} label="Notes" name="notes" />
            </Grid>
            <Grid item xs={8}>
              <FhMuiTextField control={control} label="Service Instructions (ASI) URL" name="service_instructions_url" />
            </Grid>
            <Grid item xs={4}>
              <FhMuiDateField control={control} label="ASI Changed" name="service_instructions_change_date" />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AccountForm
