import * as React from "react"
import { Box, Grid } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import { CONSULTANTS_ENDPOINT, DEFAULT_PHONE_TYPE, type IConsultant, PHONE_TYPES } from "../../shared/models/main/IConsultant"
import { CONSULTANTS_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import useApiAdd, { type IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import ErrorMessage from "../../shared/components/ErrorMessage"
import FhMuiTextField from "../../shared/components/form/FhMuiTextField"
import { requiredRule } from "../../shared/utilities/form_utility"
import FhMuiSelectField from "../../shared/components/form/FhMuiSelectField"
import FhMuiPhoneField from "../../shared/components/form/FhMuiPhoneField"

const repository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)
const redirectView = CONSULTANTS_VIEW_URL

/**
 * This page will add a consultant.
 *
 * @returns {React.FC} the add consultant page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const { control, handleSubmit, setError, formState } = useForm()

  const props: IUseApiAddProps<IConsultant> = {
    apiFunction: repository.add,
    setError,
    redirectView,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<IConsultant>(props)

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Add Consultant" loading={saving} errors={formState.errors} helpDocsId={235} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleAdd as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiTextField control={control} label="First Name" name="first_name" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12}>
                  <FhMuiTextField control={control} label="Last Name" name="last_name" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12}>
                  <FhMuiTextField control={control} label="Email" name="email" type="email" rules={requiredRule()} />
                </Grid>
                <Grid item xs={8}>
                  <FhMuiPhoneField control={control} label="Phone" name="phone" rules={requiredRule()} />
                </Grid>
                <Grid item xs={4}>
                  <FhMuiTextField control={control} label="Ext" name="phone_ext" />
                </Grid>
                <Grid item xs={4}>
                  <FhMuiSelectField
                    control={control}
                    items={PHONE_TYPES}
                    defaultValue={DEFAULT_PHONE_TYPE.key}
                    name="phone_type"
                    label="Phone Type"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
