import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Box, Button, Card, CardActions, CardContent, Chip, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IPagedResults } from "../../../shared/models/IPagedResults"
import { IPaging } from "../../../shared/models/IPaging"
import { IFilter } from "../../../shared/models/IFilter"
import Moment from "react-moment"
import { DATE_FORMAT, FILTERS } from "../../../config/config"
import WorkAssignmentProgress from "../../../shared/components/WorkAssignmentProgress"
import { navigate } from "gatsby"
import { WORK_ASSIGNMENT_VIEW_URL, WORK_ASSIGNMENTS_URL } from "../../../config/urls"
import ViewLoading from "../../../shared/components/ViewLoading"
import { useDispatch } from "react-redux"
import { operations } from "../../../store/settings"
import { IAccount } from "../../../shared/models/IAccount"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import useAuth from "../../../shared/hooks/useAuth"
import TruncateText from "../../../shared/components/TruncateText"
import { ICustomer } from "../../../shared/models/ICustomer"
import { ILocation } from "../../../shared/models/ILocation"
import { IListItem } from "../../../shared/models/component/IListItem"

const repository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface ICurrentUserFiltersResponse {
  getFilters: (filters: IFilter[], ae?: IListItem | null) => IFilter[]
}

/**
 * Use this hook to add the current user to the filters.
 *
 * @returns {ICurrentUserFiltersResponse} the filters with a user.
 */
export const useCurrentUserFilters = (): ICurrentUserFiltersResponse => {
  const { currentUser } = useAuth()

  const getFilters = useCallback(
    (filters: IFilter[], ae?: IListItem | null): IFilter[] => {
      if (currentUser === undefined || currentUser === null) {
        return []
      }
      let value = currentUser.user.id
      let display = currentUser.user.name
      if (ae !== undefined && ae !== null) {
        value = ae.id as number
        display = ae.name
      }

      return [
        {
          ...FILTERS.ACCOUNT_ENGINEER,
          value,
          display,
        },
        ...filters,
      ]
    },
    [currentUser]
  )
  return { getFilters }
}

interface IProps {
  filters: IFilter[]
  order: string
  title: string
  description: string
  ae: IListItem | null
}

/**
 * A general purpose widget that will show work assignments given a filters.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the reusable widget.
 */
const MyWorkAssignments: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { filters, order, title, description, ae } = props
  const [workAssignments, setWorkAssignments] = useState<IPagedResults<IWorkAssignment> | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const dispatch = useDispatch()

  const handleSelected = useCallback(
    (workAssignment: IWorkAssignment) => async () => {
      await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${workAssignment.id}`)
    },
    []
  )

  const handleShowAll = useCallback(async () => {
    dispatch(operations.setFilters(WORK_ASSIGNMENTS_URL, filters))
    await navigate(WORK_ASSIGNMENTS_URL)
  }, [])

  useEffect(() => {
    void (async () => {
      if (workAssignments === null) {
        const paging: IPaging = {
          limit: 5,
          filters,
          ordering: {
            field: order,
            direction: "desc",
          },
        }
        setLoading(true)
        setErrorMessage(null)
        try {
          const was = await repository.findAll(paging)
          setWorkAssignments(was)
        } catch (reason: any) {
          if (reason?.response !== undefined) {
            setErrorMessage(reason.response)
          } else {
            setErrorMessage(CONNECTION_ERROR)
          }
        }
        setLoading(false)
      }
    })()
  }, [workAssignments, filters, order])

  useEffect(() => {
    setWorkAssignments(null)
  }, [ae])

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container alignItems="baseline">
          <Grid item xs>
            <Typography variant="h5">
              <TruncateText text={title} placement="bottom" />
            </Typography>
            <Typography>
              <TruncateText
                text={description.replace("you are", ae !== undefined && ae !== null ? ae.name + " is" : "you are")}
                num={40}
                placement="bottom"
              />
            </Typography>
          </Grid>
          <Grid item>{workAssignments !== null && <Chip color="warning" label={workAssignments.count} />}</Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <ViewLoading loading={loading} inline />
          <ErrorMessage error={errorMessage} />
        </Box>
        <List>
          {workAssignments?.results.map(wa => (
            <ListItemButton key={wa.id} onClick={handleSelected(wa)}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs>
                      <TruncateText
                        text={`${wa.identifier} | ${(wa.customer as ICustomer).name} | ${(wa.account as IAccount).name}`}
                        placement="bottom"
                      />
                    </Grid>
                    <Grid item>{wa.due_date !== null && <Moment format={DATE_FORMAT}>{wa.due_date}</Moment>}</Grid>
                    <Grid item xs={12}>
                      <TruncateText text={(wa.location as ILocation).address} num={60} placement="bottom" />
                    </Grid>
                  </Grid>
                }
                secondary={<WorkAssignmentProgress workAssignment={wa} />}
                secondaryTypographyProps={{ component: "div" }}
              />
            </ListItemButton>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Grid container>
          <Grid item xs />
          <Grid item>
            <Button size="small" onClick={handleShowAll}>
              Show All
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

export default MyWorkAssignments
