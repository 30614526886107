import * as React from "react"
import { FormEvent, useCallback, useEffect, useState } from "react"
import { Box, Button, Grid, Typography } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { RestRepository } from "../repositories/RestRepository"
import ErrorMessage from "./ErrorMessage"
import { DefaultEditor } from "react-simple-wysiwyg"
import ViewLoading from "./ViewLoading"

interface IProps {
  item: any
  modelId: number
  field: string
  title: string
  onChange: (item: any) => void
  value: string | null
  repository: RestRepository<any>
}

/**
 * This component assists in changing a html field inline for a model.
 *
 * @param {IProps} props See IProps for more details.
 * @returns {React.FunctionComponent<IProps>} the component for changing html text.
 */
const ChangeHtmlFieldInline: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, item, title, value, modelId, onChange, repository } = props

  const [editing, setEditing] = useState(false)
  const [newValue, setNewValue] = useState<string>("")

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setEditing(true), [])

  const handleClose = useCallback(() => setEditing(false), [])

  const handleSave = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      setSaving(true)
      setSavingError(undefined)
      try {
        const modelResponse = await repository.patch({ [field]: newValue } as any, modelId)
        onChange({
          ...item,
          [field]: modelResponse[field],
        })
        setNewValue(modelResponse[field])
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
      setEditing(false)
    },
    [newValue, item, field, modelId]
  )

  useEffect(() => {
    if (value !== null) {
      setNewValue(value)
    }
  }, [value])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs>
          <ViewLoading loading={saving} message={"Saving..."} />
          <ErrorMessage error={savingError} />
        </Grid>
        {editing ? (
          <>
            <Grid item>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleSave}>Save</Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Button onClick={handleOpen}>Edit</Button>
            </Grid>
          </>
        )}
      </Grid>
      <Box sx={{ mt: 2 }}>
        {editing ? (
          <DefaultEditor onChange={e => setNewValue(e.target.value)} value={newValue} />
        ) : (
          <Box dangerouslySetInnerHTML={{ __html: value as string }} />
        )}
      </Box>
    </>
  )
}

export default ChangeHtmlFieldInline
