import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  PaletteColor,
  Typography,
  useTheme,
} from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { Circle, GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api"
import useContentHeight from "../../shared/hooks/useContentHeight"
import { IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { IWorkAssignment, WORK_ASSIGNMENT_MAP_ENDPOINT } from "../../shared/models/IWorkAssignment"
import { RestRepository } from "../../shared/repositories/RestRepository"
import Filtering from "../../shared/components/Filtering"
import ViewLoading from "../../shared/components/ViewLoading"
import { CONSULTANTS_ENDPOINT, IConsultant } from "../../shared/models/main/IConsultant"
import { icon, PersonPushpinIcon, PushpinIcon } from "../../shared/utilities/icons_utilities"
import { MAP_URL } from "../../config/urls"
import TablePaging from "../../shared/components/TablePaging"
import AddConsultantToWorkAssignment from "../work_assignments/components/AddConsultantToWorkAssignment"
import { IWorkAssignmentConsultant } from "../../shared/models/IWorkAssignmentConsultant"
import { blue, cyan, deepPurple, green, grey, lime, pink } from "@mui/material/colors"
import ConsultantInfoWindow from "./components/ConsultantInfoWindow"
import ChangeLimit from "./components/ChangeLimit"
import WaInfoWindow from "./components/WaInfoWindow"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { DashboardStore } from "../../store"
import IOverviewViewState from "../../store/settings/models/IOverviewViewState"
import { operations } from "../../store/settings"
import {
  buildColor,
  CONSULTANT_ACTIVE_FILTER,
  CONSULTANT_FILTERS,
  DEFAULT_CENTER,
  GOOGLE_MAPS,
  WA_NO_VIRTUAL_SURVEYS,
  WORK_ASSIGNMENT_FILTERS,
} from "../../config/config"
import useMapType from "../../shared/hooks/useMapType"
import HighlightedKey from "../../shared/components/HighlightedKey"
import FilterBookmarksDialog from "../../shared/components/FilterBookmarksDialog"
import FilterSharing from "../../shared/components/FilterSharing"

const workAssignmentRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_MAP_ENDPOINT)
const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)

const waKeyEntries = [
  {
    color: buildColor(cyan).main,
    description: "Work Assignments with no consultant accepts and is PL",
  },
  {
    color: buildColor(grey).main,
    description: "Work Assignments with no consultant accepts and is HPR",
  },
  {
    color: buildColor(blue).main,
    description: "Work Assignments with consultant accepts, is PL and visit is complete.",
  },
  {
    color: buildColor(deepPurple).main,
    description: "Work Assignments with consultant accepts, is HPR and visit is complete.",
  },
  {
    color: buildColor(lime).main,
    description: "Work Assignments with consultant accepts, is PLBRR and visit is not complete.",
  },
]

const consultantKeyEntries = [
  {
    color: buildColor(green).main,
    description: "Property Lite (PL)",
  },
  {
    color: buildColor(pink).main,
    description: "Highly Protected Risk (HPR)",
  },
]

/**
 * Renders the full page map for works assignments and consultants.
 *
 * @returns {React.FunctionComponent} the page.
 */
const IndexPage: React.FunctionComponent = () => {
  const height = useContentHeight(-30)
  const theme = useTheme()
  const dispatch = useDispatch()
  const [initial, setInitial] = useState(false)

  const [selectedWa, setSelectedWa] = useState<IWorkAssignment | null>(null)
  const [waInfoOpen, setWaInfoOpen] = useState(false)
  const [waMarkerMap, setWaMarkerMap] = useState<any>({})

  const [selectedConsultant, setSelectedConsultant] = useState<IConsultant | null>(null)
  const [consultantInfoOpen, setConsultantInfoOpen] = useState(false)
  const [consultantMarkerMap, setConsultantMarkerMap] = useState<any>({})
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | undefined>()
  const [openAdd, setOpenAdd] = useState(false)
  const [updateSelected, setUpdatedSelected] = useState(false)

  const viewState: IOverviewViewState = useSelector((state: DashboardStore) => state.settings.overviewView, shallowEqual)
  const { mapType, handleMapTypeChanged } = useMapType({ map })
  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS)

  const zoom = useMemo(() => {
    return viewState?.zoom !== undefined ? viewState.zoom : 4
  }, [viewState])

  const center = useMemo(() => {
    return viewState?.center !== undefined ? viewState.center : DEFAULT_CENTER
  }, [viewState])

  const propsWa: IUseApiPagedResultsProps<IWorkAssignment> = {
    apiFunction: workAssignmentRepository.findAll,
    pathname: MAP_URL + "_wa",
  }
  const {
    data: workAssignments,
    count: waCount,
    paging: waPaging,
    handlePaging: handleWaPaging,
    loading: waLoading,
    handleFilter: handleWaFilter,
    handleLimit: handleWaLimit,
    call: waCall,
  } = useApiPaged<IWorkAssignment>(propsWa)

  const handleWaPagingWrapper = useCallback((e: ChangeEvent<unknown> | null, page: number) => {
    setSelectedWa(null)
    setWaInfoOpen(false)
    setWaMarkerMap({})
    handleWaPaging(e, page)
  }, [])

  const propsConsultant: IUseApiPagedResultsProps<IConsultant> = {
    apiFunction: consultantRepository.findAll,
    pathname: MAP_URL + "_consultant",
  }
  const {
    data: consultants,
    count: consultantsCount,
    paging: consultantsPaging,
    handlePaging: handleConsultantsPaging,
    loading: consultantLoading,
    handleFilter: handleConsultantFilter,
    handleLimit: handleConsultantLimit,
    call: consultantCall,
  } = useApiPaged<IConsultant>(propsConsultant)

  const handleTravelRadius = useCallback(
    (e: any) => {
      dispatch(
        operations.setOverviewView({
          ...viewState,
          showTravelRadius: e.target.checked,
        })
      )
    },
    [viewState]
  )

  const handleZoomChanged = useCallback(() => {
    if (map !== null) {
      dispatch(
        operations.setOverviewView({
          ...viewState,
          zoom: map.getZoom(),
        })
      )
    }
  }, [map, viewState])

  const handleCenterChanged = useCallback(() => {
    if (map !== null) {
      const center = map.getCenter()
      dispatch(
        operations.setOverviewView({
          ...viewState,
          center,
        })
      )
    }
  }, [map, viewState])

  const waMarkerColor = useCallback(
    (wa: IWorkAssignment): PaletteColor => {
      const waAccepted = wa.work_assignment_consultants.filter(c => c.accepted)
      if (waAccepted.length === 0 && wa.inspection_category === "PL") {
        return buildColor(cyan)
      }
      if (waAccepted.length === 0 && wa.inspection_category === "HPR") {
        return buildColor(grey)
      }
      if (waAccepted.length > 0 && wa.inspection_category === "PL" && wa.progress_consultant_visit_complete !== null) {
        return buildColor(blue)
      }
      if (waAccepted.length > 0 && wa.inspection_category === "HPR" && wa.progress_consultant_visit_complete !== null) {
        return buildColor(deepPurple)
      }
      if (waAccepted.length > 0 && wa.inspection_type === "PLBRR" && wa.progress_consultant_visit_complete === null) {
        return buildColor(lime)
      }

      return theme.palette.secondary
    },
    [theme]
  )

  const consultantColor = useCallback(
    (consultant: IConsultant): PaletteColor => {
      if (consultant.property_lite) {
        return buildColor(green)
      }
      if (consultant.highly_protected_risk) {
        return buildColor(pink)
      }
      return theme.palette.warning
    },
    [theme]
  )

  const waMarkerLoadHandler = useCallback((marker, name) => {
    setWaMarkerMap((prevState: any) => ({
      ...prevState,
      [name]: marker,
    }))
  }, [])

  const consultantMarkerLoadHandler = useCallback((marker, name) => {
    setConsultantMarkerMap((prevState: any) => ({
      ...prevState,
      [name]: marker,
    }))
  }, [])

  const waMarkerClickHandler = useCallback(
    (workAssignment: IWorkAssignment) => {
      if (!consultantLoading && !waLoading) {
        // Remember which work assignment was clicked
        setSelectedWa(workAssignment)
        // Required so clicking a 2nd marker works as expected
        if (waInfoOpen) {
          setWaInfoOpen(false)
        }
        setWaInfoOpen(true)
      }
    },
    [waInfoOpen, consultantLoading, waLoading]
  )

  const consultantMarkerClickHandler = useCallback(
    (consultant: IConsultant) => {
      if (!consultantLoading && !waLoading) {
        // Remember which work assignment was clicked
        setSelectedConsultant(consultant)
        // Required so clicking a 2nd marker works as expected
        if (consultantInfoOpen) {
          setConsultantInfoOpen(false)
        }
        setConsultantInfoOpen(true)
      }
    },
    [consultantInfoOpen, consultantLoading, waLoading]
  )

  const handleCloseWaInfoWindow = useCallback(() => {
    if (!consultantLoading && !waLoading) {
      setWaInfoOpen(false)
      setSelectedWa(null)
    }
  }, [consultantLoading, waLoading])

  const handleCloseConsultantInfoWindow = useCallback(() => {
    if (!consultantLoading && !waLoading) {
      setConsultantInfoOpen(false)
      setSelectedConsultant(null)
    }
  }, [consultantLoading, waLoading])

  const handleAdd = useCallback(async () => {
    setOpenAdd(false)
    await waCall()
    setUpdatedSelected(true)
  }, [waCall])

  const handleConsultantChange = useCallback(async () => {
    await consultantCall()
    setUpdatedSelected(true)
  }, [consultantCall])

  const onLoad = useCallback((map: google.maps.Map) => {
    setMapBounds(map.getBounds())
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMapBounds(undefined)
    setMap(null)
  }, [])

  const filterWaByBounds = useCallback(
    (wa: IWorkAssignment) => {
      if (mapBounds === undefined || wa.location === null) {
        return false
      }
      return mapBounds.contains({
        lat: wa.location.latitude,
        lng: wa.location.longitude,
      })
    },
    [mapBounds]
  )

  const filterConsultantByBounds = useCallback(
    (consultant1: IConsultant) => {
      if (mapBounds === undefined || consultant1.longitude === null) {
        return false
      }
      return mapBounds.contains({
        lat: consultant1.latitude,
        lng: consultant1.longitude,
      })
    },
    [mapBounds]
  )

  const showAddConsultantButton = useMemo(() => {
    return (
      selectedWa !== null &&
      selectedConsultant !== null &&
      !selectedWa.work_assignment_consultants.some(c => (c.consultant as IConsultant).id === selectedConsultant.id)
    )
  }, [selectedWa, selectedConsultant])

  const showAddConsultantInfo = useMemo((): IWorkAssignmentConsultant | null => {
    if (selectedWa !== null && selectedConsultant !== null) {
      const waConsultant = selectedWa.work_assignment_consultants.filter(c => (c.consultant as IConsultant).id === selectedConsultant.id)
      if (waConsultant.length > 0) {
        return waConsultant[0]
      }
    }
    return null
  }, [selectedWa, selectedConsultant])

  useEffect(() => {
    if (selectedWa !== null && workAssignments !== undefined && updateSelected) {
      setUpdatedSelected(false)
      for (const workAssignment of workAssignments.results) {
        if (workAssignment.id === selectedWa.id) {
          setSelectedWa(workAssignment)
          break
        }
      }
    }
    if (selectedConsultant !== null && consultants !== undefined && updateSelected) {
      setUpdatedSelected(false)
      for (const consultant of consultants.results) {
        if (consultant.id === selectedConsultant.id) {
          setSelectedConsultant(consultant)
          break
        }
      }
    }
  }, [workAssignments, consultants, selectedWa, selectedConsultant])

  useEffect(() => {
    if (!initial) {
      const noConsultFilters = consultantsPaging?.filters === undefined || !consultantsPaging.filters.some(f => f.field === "active")
      if (noConsultFilters) {
        handleConsultantFilter([CONSULTANT_ACTIVE_FILTER])
      }
      const noWaFilters = waPaging?.filters === undefined || !waPaging.filters.some(f => f.field === "no_virtual_surveys")
      if (noWaFilters) {
        handleWaFilter([WA_NO_VIRTUAL_SURVEYS])
      }
      if (!noConsultFilters && !noWaFilters) {
        setInitial(true)
      }
    }
  }, [initial, consultantsPaging?.filters])

  const handleWorkAssignmentChange = useCallback((wa: IWorkAssignment) => {
    waCall()
    setSelectedWa(wa)
  }, [])

  return (
    <WorkflowPage margin={0} footer={false}>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            position: "absolute",
            zIndex: 99,
            ml: 1,
            mt: 12,
          }}
        >
          <Accordion defaultExpanded={true}>
            <AccordionSummary>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <HighlightedKey entries={waKeyEntries} />
                </Grid>
                <Grid item xs>
                  <Typography>Work Assignments</Typography>
                </Grid>
                <Grid item>
                  <ViewLoading loading={waLoading} size="1.1rem" />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <FilterBookmarksDialog bookmarkType="work_assignments" filters={waPaging?.filters} onFilter={handleWaFilter} />
                </Grid>
                <Grid item>
                  <FilterSharing onFilter={handleWaFilter} filters={waPaging?.filters} />
                </Grid>
              </Grid>
              <Filtering
                availableFilters={WORK_ASSIGNMENT_FILTERS}
                asList={true}
                filters={waPaging?.filters}
                onFilter={handleWaFilter}
                disabled={waLoading}
              />
              <TablePaging
                count={waCount}
                total={workAssignments?.count}
                size="small"
                page={waPaging?.page}
                onPaging={handleWaPagingWrapper}
              />
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <ChangeLimit onChange={handleWaLimit} defaultLimit={waPaging?.limit} title="Work Assignment" />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <HighlightedKey entries={consultantKeyEntries} />
                </Grid>
                <Grid item xs>
                  <Typography>Consultants</Typography>
                </Grid>
                <Grid item>
                  <ViewLoading loading={consultantLoading} size="1.1rem" />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Filtering
                availableFilters={CONSULTANT_FILTERS}
                asList={true}
                filters={consultantsPaging?.filters}
                enableShortcut={false}
                onFilter={handleConsultantFilter}
                disabled={consultantLoading}
              />
              <TablePaging
                count={consultantsCount}
                total={consultants?.count}
                size="small"
                page={consultantsPaging?.page}
                onPaging={handleConsultantsPaging}
              />
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <ChangeLimit onChange={handleConsultantLimit} defaultLimit={consultantsPaging?.limit} title="Consultants" />
                </Grid>
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={viewState?.showTravelRadius} onChange={handleTravelRadius} />}
                      label="Show Travel Radius"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{
                  flexGrow: "1",
                  height: `${height}px`,
                }}
                center={center}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onZoomChanged={handleZoomChanged}
                onMapTypeIdChanged={handleMapTypeChanged}
                onCenterChanged={handleCenterChanged}
                onBoundsChanged={() => setMapBounds(map?.getBounds())}
                mapTypeId={mapType}
              >
                <>
                  {workAssignments?.results.filter(filterWaByBounds).map(wa => (
                    <React.Fragment key={wa.id}>
                      {wa.location !== null && (
                        <Marker
                          position={{
                            lat: wa.location.latitude + Math.sin(wa.id) / 40000,
                            lng: wa.location.longitude + Math.sin(wa.id) / 40000,
                          }}
                          icon={icon({
                            path: PushpinIcon,
                            fillColor: waMarkerColor(wa),
                          })}
                          onLoad={marker => waMarkerLoadHandler(marker, `${wa.id}`)}
                          onClick={() => waMarkerClickHandler(wa)}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </>

                <>
                  {consultants?.results.filter(filterConsultantByBounds).map(c => (
                    <Marker
                      key={c.id}
                      position={{
                        lat: c.latitude + Math.sin(c.id) / 40000,
                        lng: c.longitude + Math.sin(c.id) / 40000,
                      }}
                      icon={icon({
                        path: PersonPushpinIcon,
                        fillColor: consultantColor(c),
                      })}
                      onLoad={marker => consultantMarkerLoadHandler(marker, `${c.id}`)}
                      onClick={() => consultantMarkerClickHandler(c)}
                    />
                  ))}
                </>

                {viewState?.showTravelRadius === true &&
                  consultants?.results.filter(filterConsultantByBounds).map(c => (
                    <Circle
                      key={c.id}
                      options={{
                        strokeColor: consultantColor(c).main,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: consultantColor(c).light,
                        fillOpacity: 0.35,
                      }}
                      center={{
                        lat: c.latitude + Math.sin(c.id) / 40000,
                        lng: c.longitude + Math.sin(c.id) / 40000,
                      }}
                      radius={c.radius * 1609.344}
                    />
                  ))}

                {waInfoOpen && selectedWa !== null && (
                  <InfoWindow anchor={waMarkerMap[`${selectedWa.id}`]} onCloseClick={handleCloseWaInfoWindow}>
                    <WaInfoWindow selectedWa={selectedWa} onWorkAssignmentChange={handleWorkAssignmentChange} />
                  </InfoWindow>
                )}

                {consultantInfoOpen && selectedConsultant !== null && (
                  <InfoWindow anchor={consultantMarkerMap[`${selectedConsultant.id}`]} onCloseClick={handleCloseConsultantInfoWindow}>
                    <ConsultantInfoWindow
                      selectedConsultant={selectedConsultant}
                      loading={waLoading || consultantLoading}
                      waIdentifier={selectedWa?.identifier}
                      onConsultantChange={handleConsultantChange}
                      onOpenAdd={() => setOpenAdd(true)}
                      showAddConsultantButton={showAddConsultantButton}
                      waConsultant={showAddConsultantInfo}
                    />
                  </InfoWindow>
                )}
              </GoogleMap>
            )}
          </Grid>
        </Grid>

        {selectedWa !== null && selectedConsultant !== null && (
          <AddConsultantToWorkAssignment
            wa={selectedWa}
            consultant={selectedConsultant}
            onAdd={handleAdd}
            selectConsultant={false}
            onCancel={() => setOpenAdd(false)}
            open={openAdd}
          />
        )}
      </Box>
    </WorkflowPage>
  )
}

export default IndexPage
