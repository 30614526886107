import * as React from "react"
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { type IConsultant } from "../../../shared/models/main/IConsultant"
import { type IFilter } from "../../../shared/models/IFilter"
import { type IPagedResults } from "../../../shared/models/IPagedResults"
import { EXPENSE_REPORTS_ENDPOINT, type IExpenseReport } from "../../../shared/models/IExpenseReport"
import { type IPaging } from "../../../shared/models/IPaging"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import TableCellShow from "../../../shared/components/TableCellShow"
import TableOrdering from "../../../shared/components/TableOrdering"
import TableRowSelect from "../../../shared/components/TableRowSelect"
import FormatDate from "../../../shared/components/format/FormatDate"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import TablePaging from "../../../shared/components/TablePaging"
import { useTableColumnShow } from "../../../shared/components/TableColumnShow"
import { EXPENSE_REPORTS_ADD_URL, EXPENSE_REPORTS_URL, EXPENSE_REPORTS_VIEW_URL } from "../../../config/urls"
import { type IColumn } from "../../../shared/models/component/IColumn"
import { navigate } from "gatsby"
import AddIcon from "@mui/icons-material/Add"
import { Link as BrowserLink } from "@reach/router"
import SignOffButton from "../../expense_reports/components/SignOffButton"
import { useUpdateResults } from "../../../shared/hooks/useUpdateResults"
import ChangeHtmlField from "../../../shared/components/ChangeHtmlField"
import CheckForExpenseReportItems from "../../expense_reports/components/CheckForExpenseReportItems"
import TableLoadingFull from "../../../shared/components/TableLoadingFull"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)

interface IProps {
  consultant: IConsultant
}

const columns = [
  {
    field: "id",
    title: "ID",
  },
  {
    field: "consultant",
    title: "Consultant",
  },
  {
    field: "report_ready",
    title: "Report Ready",
  },
  {
    field: "received",
    title: "Received",
  },
  {
    field: "received_by",
    title: "Received By",
  },
  {
    field: "paid",
    title: "Paid",
  },
  {
    field: "paid_by",
    title: "Paid By",
  },
  {
    field: "pay_period",
    title: "Pay Period",
  },
  {
    field: "total_amount",
    title: "Amount",
  },
  {
    field: "notes",
    title: "Notes",
  },
  {
    field: "created",
    title: "Created",
  },
  {
    field: "updated",
    title: "Updated",
  },
] as IColumn[]
const limit = 10

/**
 * Edit and manage consultant expense reports.
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the editor component.
 */
const ExpenseReportsEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { consultant } = props
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [expenseReports, setExpenseReports] = useState<IPagedResults<IExpenseReport> | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)

  const handleExpenseReportChange = useUpdateResults<IExpenseReport>({ setResults: setExpenseReports })

  const { selectedColumns } = useTableColumnShow({
    columns,
    pathname: EXPENSE_REPORTS_URL,
  })

  const handleSelected = useCallback(async (expenseReport: IExpenseReport) => {
    await navigate(`${EXPENSE_REPORTS_VIEW_URL}/${expenseReport.id}`)
  }, [])

  const handlePaging = useCallback((_e: ChangeEvent<unknown> | null, page1: number) => {
    setPage(page1)
    setExpenseReports(null)
  }, [])

  const filters: IFilter[] = useMemo(() => {
    return [
      {
        field: "consultant",
        value: consultant.id,
      },
    ]
  }, [consultant.id])

  const loadExpenseReports = useCallback(async () => {
    try {
      setError(null)
      setLoading(true)
      const paging: IPaging = {
        filters,
        limit,
        offset: limit * (page - 1),
      }
      const results = await repository.findAll(paging)
      setExpenseReports(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response as IConnectionError)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [filters, page])

  useEffect(() => {
    if (expenseReports === null) {
      void (async () => {
        await loadExpenseReports()
      })()
    }
  }, [expenseReports, loadExpenseReports])

  return (
    <>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <Grid container spacing={1}>
          <Grid item xs>
            <CheckForExpenseReportItems consultant={consultant} />
          </Grid>
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              component={BrowserLink}
              state={{
                consultant: {
                  id: consultant.id,
                  name: `${consultant.first_name} ${consultant.last_name}`,
                },
              }}
              to={`${EXPENSE_REPORTS_ADD_URL}`}
            >
              Expense Report
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCellShow columns={selectedColumns} field="id" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering field="id" title="ID" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="consultant" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering field="consultant" title="Consultant" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="pay_period" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering field="pay_period" title="Paid Period" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="total_amount" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering field="total_amount" title="Amount" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="report_ready">
                  <TableOrdering field="report_ready" title="Report Ready" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="received">
                  <TableOrdering field="received" title="Received" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="paid">
                  <TableOrdering field="paid" title="Paid" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="notes">
                  <TableOrdering field="notes" title="Notes" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="created">
                  <TableOrdering field="created" title="Created" />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="updated">
                  <TableOrdering field="updated" title="Updated" />
                </TableCellShow>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                expenseReports?.results.map(expenseReport => (
                  <TableRowSelect key={expenseReport.id} item={expenseReport} onSelected={handleSelected}>
                    <TableCellShow columns={selectedColumns} field="id">
                      {expenseReport.id}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="consultant">
                      {expenseReport.consultant.name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="pay_period">
                      <FormatDate value={expenseReport.pay_period} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="total_amount" sx={{ textAlign: "right" }}>
                      <FormatNumber value={expenseReport.total_amount} twoDecimalPlaces />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="report_ready">
                      <SignOffButton
                        expenseReportId={expenseReport.id}
                        action="report_ready"
                        value={expenseReport.report_ready}
                        onChange={handleExpenseReportChange}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="received">
                      <SignOffButton
                        expenseReportId={expenseReport.id}
                        action="received"
                        value={expenseReport.received}
                        label={expenseReport.received_by?.name}
                        onChange={handleExpenseReportChange}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="paid">
                      <SignOffButton
                        expenseReportId={expenseReport.id}
                        action="paid"
                        value={expenseReport.paid}
                        label={expenseReport.paid_by?.name}
                        onChange={handleExpenseReportChange}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="notes">
                      <ChangeHtmlField
                        modelId={expenseReport.id}
                        item={expenseReport}
                        field="notes"
                        title="Notes"
                        onChange={handleExpenseReportChange}
                        value={expenseReport.notes}
                        repository={repository}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="created">
                      <FormatDate value={expenseReport.created} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="updated">
                      <FormatDate value={expenseReport.updated} />
                    </TableCellShow>
                  </TableRowSelect>
                ))}
            </TableBody>
          </Table>
          {!loading && (expenseReports === undefined || expenseReports?.results.length === 0) && (
            <Alert color="info" sx={{ m: 1 }}>
              No consultants found.
            </Alert>
          )}
          <TableLoadingFull loading={loading} rows={limit} />
        </TableContainer>
      </Box>
      <Divider />
      {expenseReports !== null && (
        <TablePaging count={Math.ceil(expenseReports.count / limit)} total={expenseReports.count} page={page} onPaging={handlePaging} />
      )}
    </>
  )
}

export default ExpenseReportsEditor
