import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import ViewColumnIcon from "@mui/icons-material/ViewColumn"
import { IColumn } from "../models/component/IColumn"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useLocation } from "@reach/router"
import { DashboardStore } from "../../store"
import { operations } from "../../store/settings"
import DialogControls from "./DialogControls"
import ClearIcon from "@mui/icons-material/Clear"

interface IUseProps {
  columns: IColumn[]
  pathname?: string
}

interface IUseTableColumnShowResponse {
  selectedColumns: IColumn[]
  path: string
}

/**
 * This hook helps manage the state of the table column show component.
 *
 * @param {IUseProps} props See IUseProps for details.
 * @returns {IUseTableColumnShowResponse} the use table column show hook.
 */
export const useTableColumnShow = (props: IUseProps): IUseTableColumnShowResponse => {
  const { columns, pathname } = props
  const location = useLocation()
  const columnsMap = useSelector((state: DashboardStore) => state.settings.columns, shallowEqual) as {
    [id: string]: IColumn[]
  }
  const path: string = pathname !== undefined ? pathname : location.pathname

  const selectedColumns = useMemo(() => {
    return path in columnsMap ? columnsMap[path] : columns
  }, [path, columnsMap])

  return {
    selectedColumns,
    path,
  }
}

interface IProps {
  columns: IColumn[]
  pathname?: string
}

/**
 * Displays a list of columns that are available in a table.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} displays the table column component.
 */
const TableColumnShow: React.FunctionComponent<IProps> = (props: IProps) => {
  const { columns, pathname } = props
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const [filterText, setFilterText] = useState<string>("")

  const { selectedColumns, path } = useTableColumnShow({
    columns,
    pathname,
  })

  const handleToggle = useCallback(
    (value: IColumn) => () => {
      if (!selectedColumns.some(c => c.field === value.field)) {
        const sc1 = [...selectedColumns, value]
        dispatch(operations.setColumns(path, sc1))
      } else {
        const sc1 = selectedColumns.filter(c1 => c1.field !== value.field)
        dispatch(operations.setColumns(path, sc1))
      }
    },
    [selectedColumns]
  )

  const handleReset = useCallback(() => {
    dispatch(operations.setColumns(path, [...columns]))
  }, [columns])

  return (
    <>
      <IconButton onClick={handleOpen} color="primary" size="small" title="Select table columns to display.">
        <ViewColumnIcon fontSize="small" />
      </IconButton>

      <Dialog onClose={handleClose} maxWidth="xs" fullWidth open={open} fullScreen={isSmall} scroll="paper">
        <DialogTitle>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              Display Columns
            </Grid>
            <Grid item>
              <Button size="small" onClick={handleReset}>
                Reset
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center" sx={{ pt: 1 }}>
            <Grid item xs>
              <TextField
                size="small"
                label="Filter..."
                variant="standard"
                fullWidth
                autoFocus
                value={filterText}
                onChange={event => setFilterText(event.target.value)}
              />
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" onClick={() => setFilterText("")} disabled={filterText === ""}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            pl: 1,
            pr: 1,
          }}
        >
          <List
            sx={{
              pt: 0,
              height: isSmall ? "inherit" : 500,
            }}
          >
            {columns
              .filter(column => column.title.toLowerCase().includes(filterText))
              .map(column => (
                <ListItem
                  key={column.field}
                  secondaryAction={
                    <Checkbox edge="end" onChange={handleToggle(column)} checked={selectedColumns.some(c => c.field === column.field)} />
                  }
                >
                  <ListItemText primary={column.title} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <DialogControls buttonLabel="Close" onSave={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableColumnShow
