import * as React from "react"
import { useCallback, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { IInvoice, INVOICE_AUDIT_ENDPOINT } from "../../../shared/models/IInvoice"
import VerifiedIcon from "@mui/icons-material/Verified"
import NotVerifiedIcon from "@mui/icons-material/Warning"
import HtmlTooltip from "../../../shared/components/HtmlToolTip"
import { Box, Chip } from "@mui/material"
import FormatDate from "../../../shared/components/format/FormatDate"
import ViewError from "../../../shared/components/ViewError"
import { RestRepository } from "../../../shared/repositories/RestRepository"

const repository = new RestRepository<IInvoice>(INVOICE_AUDIT_ENDPOINT)

interface IProps {
  invoice: IInvoice
  onChange: (invoice: IInvoice) => void
  asLabel?: boolean
}

/**
 * Set an invoice as audit completed.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the audit component.
 */
const AuditedInvoice: React.FunctionComponent<IProps> = (props: IProps) => {
  const { invoice, asLabel = false, onChange } = props
  const [saving, setSaving] = useState(false)

  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleSave = useCallback(async () => {
    setSaving(true)
    try {
      const invoice1 = await repository.action(invoice.id, "audited")
      onChange(invoice1)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
  }, [invoice])

  return (
    <>
      <HtmlTooltip
        title={
          <Box>
            <ViewError error={savingError} />
            {invoice.audited_by === null ? (
              <>Click to audit.</>
            ) : (
              <>
                By {invoice.audited_by.name} on <FormatDate value={invoice.audited} />.
              </>
            )}
          </Box>
        }
        placement="bottom"
      >
        <Box>
          {asLabel ? (
            invoice.audited !== null && (
              <Chip color="success" variant="outlined" icon={<VerifiedIcon color="success" />} label="Audited!" />
            )
          ) : (
            <LoadingButton
              loading={saving}
              disabled={saving}
              onClick={handleSave}
              color={invoice.audited === null ? "warning" : "success"}
              startIcon={
                invoice.audited === null ? <NotVerifiedIcon color="warning" /> : <VerifiedIcon color="success" />
              }
              variant={"text"}
            >
              {invoice.audited === null ? "Audit?" : "Audited!"}
            </LoadingButton>
          )}
        </Box>
      </HtmlTooltip>
    </>
  )
}

export default AuditedInvoice
