import * as React from "react"
import HtmlToolTip from "../../../shared/components/HtmlToolTip"
import { Alert, Box } from "@mui/material"

interface IProps {
  dateComment: string[] | null
  label: string | React.ReactElement
}

/**
 * Display a date comment as a tooltip over a label.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the date comment label.
 */
const DateCommentLabel: React.FunctionComponent<IProps> = (props: IProps) => {
  const { dateComment, label } = props

  return (
    <>
      <HtmlToolTip
        noBackground={true}
        title={dateComment !== null && dateComment.length > 0 ? <Alert severity="warning">{dateComment.join(" ")}</Alert> : ""}
      >
        <Box
          component="span"
          sx={{
            color: dateComment !== null && dateComment.length > 0 ? "warning.main" : "inherit",
          }}
        >
          {label}
        </Box>
      </HtmlToolTip>
    </>
  )
}

export default DateCommentLabel
