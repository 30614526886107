import { Alert, Grid } from "@mui/material"
import * as React from "react"
import { IConnectionError } from "../models/IConnectionError"

interface IProps {
  error: IConnectionError | null | undefined
}

/**
 * Displays the error message returned from a connection request.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const ErrorMessage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { error } = props

  return error !== null && error !== undefined ? (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs>
        <Alert severity="error">Issues: {Object.values(error.data).join(", ")}</Alert>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default ErrorMessage
