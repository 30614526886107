import * as React from "react"
import { useMemo } from "react"
import { Avatar, Chip } from "@mui/material"
import { styles } from "../../styling/general"
import { RATING_TYPES } from "../../models/main/IConsultant"

interface IProps {
  rating: number | null
}

/**
 * Displays the rating for the consultant in a visual way.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultant rating.
 */
const ConsultantRating: React.FunctionComponent<IProps> = (props: IProps) => {
  const { rating } = props

  const ratingText = useMemo(() => {
    const ratingType = RATING_TYPES.filter(type => type.key === rating)
    if (ratingType !== undefined && ratingType.length > 0) {
      return ratingType[0].value
    }
    return rating
  }, [rating])

  if (rating === 0 || rating === null) {
    return <></>
  }

  return (
    <Chip
      sx={styles.specialty.chip(rating)}
      size="small"
      variant="filled"
      avatar={<Avatar sx={styles.specialty.avatar(rating)}>{rating}</Avatar>}
      label={ratingText}
    />
  )
}

export default ConsultantRating
