import React from "react"
import HtmlTooltip from "./HtmlToolTip"
import { Badge, Box } from "@mui/material"
import FormatNumber from "./format/FormatNumber"
import { IWorkAssignment } from "../models/IWorkAssignment"

interface IProps {
  workAssignment: IWorkAssignment
}

/**
 * Displays the RLI getting paid amount taking into account consultant hours.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the rli getting paid amount.
 */
const RliGettingPaidAmount: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { workAssignment } = props
  return (
    <>
      <HtmlTooltip
        title={
          workAssignment.rli_getting_paid_hourly_rate ? (
            <Box>
              <FormatNumber value={workAssignment.rli_getting_paid} display="inline" twoDecimalPlaces /> x{" "}
              {workAssignment.rli_getting_paid_hours}/hr
            </Box>
          ) : (
            ""
          )
        }
      >
        <Badge variant={workAssignment.rli_getting_paid_hourly_rate ? "dot" : "standard"} color="primary">
          <FormatNumber value={workAssignment.rli_getting_paid_amount} twoDecimalPlaces />
        </Badge>
      </HtmlTooltip>
    </>
  )
}

export default RliGettingPaidAmount
