import * as React from "react"
import { useCallback } from "react"
import { IPagedResults } from "../models/IPagedResults"
import { IMainModel } from "../models/IMainModel"

type IUseUpdateResultsResponse<T extends IMainModel> = (itemUpdate: T) => void

export interface IUseUpdateResultsProps<T extends IMainModel> {
  setResults: React.Dispatch<React.SetStateAction<IPagedResults<T> | null>>
}

/**
 * This hook will update a results set with a new entry.
 *
 * @param {IUseUpdateResultsProps} props see IUseUpdateResultsProps<T> for details.
 * @returns {IUseUpdateResultsResponse} update state function.
 */
export const useUpdateResults = <T extends IMainModel>(props: IUseUpdateResultsProps<T>): IUseUpdateResultsResponse<T> => {
  const { setResults } = props
  return useCallback((itemUpdate: T) => {
    setResults((results: IPagedResults<T> | null) => {
      if (results !== null) {
        return {
          ...results,
          results: results.results.map(item => {
            return item.id === itemUpdate.id ? itemUpdate : item
          }),
        }
      }
      return null
    })
  }, [])
}
