import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { CUSTOMER_TYPES, DEFAULT_CUSTOMER_TYPE, ICustomer } from "../../../shared/models/ICustomer"
import { Control } from "react-hook-form/dist/types"
import { Alert, Box, Grid, Typography } from "@mui/material"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import IUser, { STAFF_ENDPOINT } from "../../../shared/models/IUser"
import { CONTACT_ENDPOINT, IContact } from "../../../shared/models/IContact"
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form/dist/types/form"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import SelectFilteredMultiple from "../../../shared/components/SelectFilteredMultiple"
import AddContact from "../../../shared/components/AddContact"
import { IListItem } from "../../../shared/models/component/IListItem"
import FhMuiMdField from "../../../shared/components/form/FhMuiMdField"
import FhMuiIdField from "../../../shared/components/form/FhMuiIdField"
import FhMuiTextField from "../../../shared/components/form/FhMuiTextField"
import { requiredRule } from "../../../shared/utilities/form_utility"
import FhMuiSelectField from "../../../shared/components/form/FhMuiSelectField"
import FhMuiDateField from "../../../shared/components/form/FhMuiDateField"
import { isoAsDate } from "../../../shared/utilities/format_utility"
import AddContactCheckMessage from "../../../shared/components/AddContactCheckMessage"

const userRepository = new RestRepository<IListItem>(STAFF_ENDPOINT)
const contactRepository = new RestRepository<IListItem>(CONTACT_ENDPOINT)

const today = new Date().toISOString().substring(0, 10)

interface IProps {
  control: Control<any>
  customer?: ICustomer | null
  isEdit?: boolean
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  minimal?: boolean
}

/**
 * The form manages the adding editing of a customer.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the customer form.
 */
const CustomerForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { customer, isEdit = false, control, setValue, clearErrors, minimal = false } = props
  const [hasData, setHasData] = useState(false)
  const [contacts, setContacts] = useState<IListItem[] | undefined>([])
  const [invoiceContact, setInvoiceContact] = useState<IListItem | null>(null)
  const [executive, setExecutive] = useState<IUser | null>(null)
  const [technicalAdmin, setTechnicalAdmin] = useState<IUser | null>(null)

  const handleExecutive = useCallback((user1?: IListItem | null) => {
    setValue("executive", user1 === null || user1 === undefined ? null : user1.id)
    setExecutive(user1 !== undefined ? (user1 as IUser) : null)
  }, [])

  const handleInvoiceContact = useCallback((invoiceContact1?: IListItem | null) => {
    setValue("invoice_contact", invoiceContact1 === null || invoiceContact1 === undefined ? null : invoiceContact1.id)
    setInvoiceContact(invoiceContact1 !== undefined ? (invoiceContact1 as IContact) : null)
  }, [])

  const handleTechnicalAdmin = useCallback((user1?: IListItem | null) => {
    setValue("technical_admin", user1 === null || user1 === undefined ? null : user1.id)
    setTechnicalAdmin(user1 !== undefined ? (user1 as IUser) : null)
  }, [])

  const handleContacts = useCallback((contacts1?: IListItem[]) => {
    clearErrors("contacts")
    setValue(
      "contacts",
      contacts1?.map(c => c.id)
    )
    setContacts(contacts1)
  }, [])

  const handleAddContact = useCallback((contact: IListItem) => {
    clearErrors("contacts")
    setContacts(contacts => {
      const cs2 = contacts !== undefined ? [...contacts, contact] : [contact]
      setValue(
        "contacts",
        cs2?.map(c => c.id)
      )
      return cs2
    })
  }, [])

  const handleAddInvoiceContact = useCallback((contact1: IListItem) => {
    clearErrors("invoice_contact")
    setInvoiceContact(contact2 => {
      const cs2 = contact1 !== undefined ? contact1 : contact2
      setValue("invoice_contact", cs2?.id)
      return cs2
    })
  }, [])

  useEffect(() => {
    if (customer != null && isEdit && !hasData) {
      setHasData(true)
      setValue("id", customer.id)
      setValue("name", customer.name)
      setValue("service_instructions_url", customer.service_instructions_url)
      setValue("service_instructions_change_date", isoAsDate(customer.service_instructions_change_date as string))
      setValue("consultant_due_date_days_after_visit", customer.consultant_due_date_days_after_visit)
      setValue("customer_type", customer.customer_type)
      setValue("since", customer.since)
      setValue("notes", customer.notes)
      handleExecutive(customer.executive)
      handleTechnicalAdmin(customer.technical_admin)
      handleContacts(customer.contacts)
      handleInvoiceContact(customer.invoice_contact)
    }
  }, [customer, hasData, setValue, isEdit])

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={!minimal && 5}>
        <Grid item xs={12}>
          {isEdit && <FhMuiIdField control={control} />}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isEdit ? (
              <Typography variant="h3">{customer?.name}</Typography>
            ) : (
              <FhMuiTextField control={control} label="Name" name="name" rules={requiredRule()} />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FhMuiSelectField
              control={control}
              items={CUSTOMER_TYPES}
              defaultValue={DEFAULT_CUSTOMER_TYPE.key}
              name="customer_type"
              label="Customer Type"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FhMuiTextField control={control} type="date" label="Since" name="since" defaultValue={today} />
          </Grid>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="executive"
              label="AE"
              defaultValue={executive as IListItem}
              repository={userRepository}
              onChange={handleExecutive}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="technical_admin"
              label="TA"
              defaultValue={technicalAdmin as IListItem}
              repository={userRepository}
              onChange={handleTechnicalAdmin}
            />
          </Grid>
          {!minimal && (
            <>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <SelectFilteredMultiple
                      name="contacts"
                      label="Contacts"
                      defaultValue={contacts}
                      repository={contactRepository}
                      onChange={handleContacts}
                    />
                    <AddContactCheckMessage />
                  </Grid>
                  <Grid item>
                    <Box sx={{ mt: -2 }}>
                      <AddContact onAddContact={handleAddContact} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <SelectFilteredSingle
                      name="invoice_contact"
                      label="Invoice Contact"
                      defaultValue={invoiceContact}
                      repository={contactRepository}
                      onChange={handleInvoiceContact}
                    />
                    <AddContactCheckMessage />
                  </Grid>
                  <Grid item>
                    <Box sx={{ mt: -2 }}>
                      <AddContact onAddContact={handleAddInvoiceContact} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {!minimal && (
        <Grid item xs={12} md={!minimal && 7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiMdField control={control} label="Notes" name="notes" />
            </Grid>
            <Grid item xs={8}>
              <FhMuiTextField control={control} label="Service Instructions (ASI) URL" name="service_instructions_url" />
            </Grid>
            <Grid item xs={4}>
              <FhMuiDateField control={control} label="ASI Changed" name="service_instructions_change_date" />
            </Grid>
            <Grid item xs={4}>
              <FhMuiTextField
                control={control}
                defaultValue={0}
                label="Consultant Due Date - Days After Visit"
                name="consultant_due_date_days_after_visit"
              />
            </Grid>
            <Grid item xs={8}>
              <Alert color="info">All calculated due dates are base on business days and Risk Logic holidays.</Alert>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default CustomerForm
