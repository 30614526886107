import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { Grid, MenuItem, TextField } from "@mui/material"
import { IFilter } from "../models/IFilter"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from "../../config/config"
import moment from "moment"
import { ISelectItem } from "../models/component/ISelectItem"

const DATE_FILTER_TYPES = [
  {
    key: "not_set",
    value: "Not Set",
  },
  {
    key: "in_next_30_days",
    value: "In Next 30 Days",
  },
  {
    key: "in_next_10_days",
    value: "In Next 10 Days",
  },
  {
    key: "in_next_5_days",
    value: "In Next 5 Days",
  },
  {
    key: "tomorrow",
    value: "Tomorrow",
  },
  {
    key: "today",
    value: "Today",
  },
  {
    key: "is_late",
    value: "Is Late",
  },
  {
    key: "has_not_occurred",
    value: "Has Not Occurred",
  },
  {
    key: "range",
    value: "Range",
  },
] as ISelectItem[]

const MIN_TYPES = ["not_set", "today", "range"]

interface IProps {
  filter: IFilter
  value: string | number
  minTypes: boolean
  onChange: (value: string | number, display: string) => void
}

/**
 * This field is used to filter dates.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the filtering date field.
 */
const FilteringDate: React.FunctionComponent<IProps> = (props: IProps) => {
  const { filter, value, onChange, minTypes } = props

  const [startDate, setStartDate] = useState<moment.Moment>(moment())
  const [endDate, setEndDate] = useState<moment.Moment>(moment())

  const selectValue = (value as string).startsWith("range_") ? "range" : value

  const handleDateRangeChange = useCallback(() => {
    const start = startDate.format(DATE_OUTPUT_FORMAT)
    const end = endDate.format(DATE_OUTPUT_FORMAT)
    onChange(`range_${start},${end}`, `Range: ${start} to ${end}`)
  }, [startDate, endDate])

  const handleStartDateChange = useCallback(
    e => {
      const newStartDate = moment(e)
      setStartDate(newStartDate)
      const start = newStartDate.format(DATE_OUTPUT_FORMAT)
      const end = endDate.format(DATE_OUTPUT_FORMAT)
      onChange(`range_${start},${end}`, `Range: ${start} to ${end}`)
    },
    [startDate, endDate]
  )

  const handleEndDateChange = useCallback(
    e => {
      const newEndDate = moment(e)
      setEndDate(newEndDate)
      const start = startDate.format(DATE_OUTPUT_FORMAT)
      const end = newEndDate.format(DATE_OUTPUT_FORMAT)
      onChange(`range_${start},${end}`, `Range: ${start} to ${end}`)
    },
    [startDate, endDate]
  )

  const dateTypes = useMemo(() => {
    return minTypes ? DATE_FILTER_TYPES.filter(dft => MIN_TYPES.some(key => key === dft.key)) : DATE_FILTER_TYPES
  }, [minTypes])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={filter.title}
          select
          onChange={e => {
            const dft = DATE_FILTER_TYPES.filter(df => df.key === e.target.value)
            if (dft.length === 1) {
              if (dft[0].key === "range") {
                handleDateRangeChange()
              } else {
                onChange(dft[0].key, dft[0].value)
              }
            } else {
              onChange("", "")
            }
          }}
          value={selectValue}
        >
          {dateTypes.map(dft => (
            <MenuItem key={dft.key} value={dft.key}>
              {dft.value}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {(value as string).startsWith("range_") && (
        <>
          <Grid item xs>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Start Date"
                onChange={handleStartDateChange}
                value={startDate}
                mask="____-__-__"
                inputFormat={DATE_INPUT_FORMAT}
                renderInput={params => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="End Date"
                onChange={handleEndDateChange}
                value={endDate}
                mask="____-__-__"
                inputFormat={DATE_INPUT_FORMAT}
                renderInput={params => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
              />
            </LocalizationProvider>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default FilteringDate
