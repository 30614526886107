import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Alert, Box, Button, Chip, Grid, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Link as BrowserLink, Link as RouterLink, useParams } from "@reach/router"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { isWorkAssignment, type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT, workAssignmentAdminNotNeeded, workAssignmentProgress } from "../../shared/models/IWorkAssignment"
import ContactCard from "../../shared/components/ContactCard"
import UserCard from "../../shared/components/UserCard"
import ConsultantCard from "../../shared/components/ConsultantCard"
import WorkAssignmentMap from "../../shared/components/WorkAssignmentMap"
import ViewProperty from "../../shared/components/ViewProperty"
import WorkAssignmentProgressTable from "../../shared/components/WorkAssignmentProgressTable"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import MessagesEditor from "./components/MessagesEditor"
import { type ILocation } from "../../shared/models/ILocation"
import { type IConsultant } from "../../shared/models/main/IConsultant"
import { customerTypeName, type ICustomer, isCustomer } from "../../shared/models/ICustomer"
import { type IAccount, isAccount } from "../../shared/models/IAccount"
import ConsultantsEditor from "./components/ConsultantsEditor"
import WorkAssignmentLocation from "../../shared/components/WorkAssignmentLocation"
import FormatNumber from "../../shared/components/format/FormatNumber"
import { ACCOUNTS_VIEW_URL, CUSTOMERS_VIEW_URL, WORK_ASSIGNMENT_EDIT_URL } from "../../config/urls"
import FilesEditor from "./components/FilesEditor"
import AssignTechReviewer from "./components/AssignTechReviewer"
import AssignAdminReviewer from "./components/AssignAdminReviewer"
import InternalNotesEditor from "./components/InternalNotesEditor"
import { type IReportFormat } from "../../shared/models/IReportFormat"
import ChangeDate from "./components/ChangeDate"
import Archive from "./components/Archive"
import TruncateText from "../../shared/components/TruncateText"
import ConsultantNotesEditor from "./components/ConsultantNotesEditor"
import AssigneeEditor from "../../shared/components/AssigneeEditor"
import InvoiceEditor from "./components/InvoiceEditor"
import EditIcon from "@mui/icons-material/Edit"
import { type IMessage, WORK_ASSIGNMENT_MESSAGE_ENDPOINT } from "../../shared/models/IMessage"
import DateCommentLabel from "./components/DateCommentLabel"
import ExtensionsEditor from "./components/ExtensionsEditor"
import FileViewerDialog from "../../shared/components/files/FileViewerDialog"
import HistoryViewer from "./components/HistoryViewer"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"

const repo = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

const messageRepository = new RestRepository<IMessage>(WORK_ASSIGNMENT_MESSAGE_ENDPOINT)

/**
 * View and manage all aspects of a work assignment.
 *
 * @returns {React.FunctionComponent} work assignment viewer component.
 */
const ViewPage: React.FunctionComponent = () => {
  const { data, error, loading, call: refreshWa } = useApiRead<IWorkAssignment>({ apiFunction: repo.read })
  const { tabIndex } = useParams()
  const initialTab = tabIndex === "extensions" ? 6 : 0

  const [wa, setWa] = useState<IWorkAssignment | null>(null)
  const [initWa, setInitWa] = useState(false)
  const { tab, handleTabChange } = useTabPanel({ initialTab })
  const [location, setLocation] = useState<ILocation | null>(null)
  const [openPreview, setPreviewOpen] = useState(false)

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const progress = useMemo(() => {
    return wa !== null ? workAssignmentProgress(wa) : null
  }, [wa])

  const handleWorkAssignmentChange = useCallback((waUpdated: IWorkAssignment) => {
    setWa({ ...waUpdated })
  }, [])

  useEffect(() => {
    if (!initWa && data !== undefined && !loading) {
      setWa({ ...data })
      setLocation(data.location)
      setInitWa(true)
    }
  }, [initWa, data, loading])

  const acceptedConsultants = useMemo(() => {
    if (wa !== null) {
      return wa.work_assignment_consultants.filter(c => c.accepted)
    }
    return []
  }, [wa])

  const declinedConsultants = useMemo(() => {
    if (wa !== null) {
      return wa.work_assignment_consultants.filter(c => c.declined)
    }
    return []
  }, [wa])

  const pendingConsultants = useMemo(() => {
    if (wa !== null) {
      return wa.work_assignment_consultants.filter(c => !c.accepted && !c.declined)
    }
    return []
  }, [wa])

  const handleRefresh = useCallback(() => {
    refreshWa()
    setInitWa(false)
  }, [])

  return (
    <WorkflowPage footer={false}>
      <Box sx={styles.pageFunc(isSmall)}>
        <ViewLoading loading={loading && wa == null} />
        <ErrorMessage error={error} />
        {wa != null && isWorkAssignment(wa) && (
          <>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h3" onClick={handleRefresh} sx={{ cursor: "pointer" }}>
                      {wa.identifier}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {progress?.isComplete() === true ? (
                      <Chip label="Completed" color="success" />
                    ) : wa.is_report_sent_account_late ? (
                      <Chip label="Late" color="error" />
                    ) : (
                      <Chip label="On Time" />
                    )}
                    {wa.archive && <Chip label="Archived" sx={{ ml: 2 }} color="warning" />}
                  </Grid>
                  <Grid item xs>
                    <ViewLoading loading={loading} message="Updating..." />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Archive waId={wa.id} archive={wa.archive} onChange={handleWorkAssignmentChange} />
              </Grid>
              <Grid item>
                <Button startIcon={<EditIcon />} component={RouterLink} to={`${WORK_ASSIGNMENT_EDIT_URL}/${wa.id}`}>
                  WA
                </Button>
              </Grid>
              <Grid item>
                <HelpDocsDrawer showButton showButtonLabel={false} pageId={228} />
              </Grid>
            </Grid>
            <Box sx={styles.tabsBox}>
              <Tabs value={tab} onChange={handleTabChange} variant="scrollable">
                <Tab label="Overview" />
                <Tab label="Consultants" disabled={wa.location == null} />
                <Tab label="Files" />
                <Tab label="Internal Notes" />
                <Tab label="Consultant Notes" />
                <Tab label="Messages" />
                <Tab label="Extensions" />
                <Tab label="History" />
              </Tabs>
            </Box>
            <TabPanel index={0} value={tab}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <ViewProperty label="Customer" vertical>
                        <Typography sx={{ mb: 1.5 }} component="div" color="text.secondary">
                          {isCustomer(wa.customer) && (
                            <Grid container>
                              <Grid item>
                                <Link component={BrowserLink} to={`${CUSTOMERS_VIEW_URL}/${wa.customer.id}`}>
                                  <TruncateText text={wa.customer.name} num={20} />
                                </Link>
                              </Grid>
                              <Grid item>
                                <small>({customerTypeName(wa.customer)})</small>
                              </Grid>
                            </Grid>
                          )}
                        </Typography>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <ViewProperty label="Account" vertical>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {isAccount(wa.account) && (
                            <Link component={BrowserLink} to={`${ACCOUNTS_VIEW_URL}/${wa.account.id}`}>
                              <TruncateText text={wa.account.name} num={20} />
                            </Link>
                          )}
                        </Typography>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <ViewProperty label="Priority">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.priority_name}
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="TIV" show={wa.inspection_category !== "PR"}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <FormatNumber value={wa.total_insured_value} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="RLI Getting Paid">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <FormatNumber value={wa.rli_getting_paid} suffixUnits={wa.rli_getting_paid_hourly_rate ? "/hr" : ""} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Suggested Con. Fee">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <FormatNumber value={wa.suggested_consultant_amount} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Inspection Category">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.inspection_category ?? <>Not Set</>}
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Inspection Type">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.inspection_type !== null ? (
                            <TruncateText text={wa.inspection_type} altText={wa.inspection_type_name} placement="right" />
                          ) : (
                            <>Not Set</>
                          )}
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Report Format" show={wa.inspection_category !== "PR"}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.report_format !== null ? (
                            <>
                              {(wa.report_format as IReportFormat).file.exists !== false ? (
                                <>
                                  <Button size="small" onClick={() => {setPreviewOpen(true)}}>
                                    <TruncateText text={(wa.report_format as IReportFormat).name} num={15} />
                                  </Button>
                                  <FileViewerDialog
                                    open={openPreview}
                                    files={[wa.report_format as IReportFormat]}
                                    selectedFile={wa.report_format as IReportFormat}
                                    onClose={() => {setPreviewOpen(false)}}
                                  />
                                </>
                              ) : (
                                <>
                                  <TruncateText text={(wa.report_format as IReportFormat).name} num={15} />
                                </>
                              )}
                            </>
                          ) : (
                            <>Not Set</>
                          )}
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="External Tracking">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <TruncateText
                            text={wa.external_tracking !== null || wa.external_tracking !== "" ? wa.external_tracking : "Not Set"}
                            num={15}
                          />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="ASI" show={wa.service_instructions_url !== null && wa.service_instructions_url !== ""}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.service_instructions_url !== null && wa.service_instructions_url !== "" && (
                            <Link target="_blank" href={wa.service_instructions_url} sx={{ whiteSpace: "nowrap" }}>
                              Service Instructions
                            </Link>
                          )}
                        </Box>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <ViewProperty label="Added to WF">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <ChangeDate
                            waId={wa.id}
                            field="issued_date"
                            onChange={handleWorkAssignmentChange}
                            title="Added to WF"
                            value={wa.issued_date}
                          />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Visit Date" show={wa.inspection_category !== "PR"}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <ChangeDate
                            waId={wa.id}
                            field="visit_date"
                            onChange={handleWorkAssignmentChange}
                            title="Visit Date"
                            value={wa.visit_date}
                          />
                        </Box>
                      </ViewProperty>

                      {wa.consultant_due_date === null && wa.consultant_due_date_days_after_visit > 0 ? (
                        <ViewProperty
                          label={
                            <>
                              Con. Due Date
                              <br />
                              <small>Days After Visit</small>
                            </>
                          }
                        >
                          <Box
                            sx={{
                              textAlign: "right",
                              minHeight: 31,
                            }}
                          >
                            {wa.consultant_due_date_days_after_visit}
                          </Box>
                        </ViewProperty>
                      ) : (
                        <ViewProperty label={<DateCommentLabel dateComment={wa.consultant_due_date_comment} label="Con. Due Date" />}>
                          <Box
                            sx={{
                              textAlign: "right",
                              minHeight: 31,
                            }}
                          >
                            <ChangeDate
                              waId={wa.id}
                              field="consultant_due_date"
                              onChange={handleWorkAssignmentChange}
                              title="Consultant Due Date"
                              value={wa.consultant_due_date}
                            />
                          </Box>
                        </ViewProperty>
                      )}

                      <ViewProperty label="Admin Due Date" show={wa.inspection_category !== "PR"}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {!workAssignmentAdminNotNeeded(wa) ? (
                          <ChangeDate
                            waId={wa.id}
                            field="admin_review_due_date"
                            onChange={handleWorkAssignmentChange}
                            title="Admin Review Due Date"
                            value={wa.admin_review_due_date}
                          />
                          ) : (
                            <>Not Needed</>
                          )}
                        </Box>
                      </ViewProperty>

                      <ViewProperty label="Tech Due Date" show={wa.inspection_category !== "PR"}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.tech_review_needed ? (
                            <ChangeDate
                              waId={wa.id}
                              field="tech_review_due_date"
                              onChange={handleWorkAssignmentChange}
                              title="Tech Review Due Date"
                              value={wa.tech_review_due_date}
                            />
                          ) : (
                            <>Not Needed</>
                          )}
                        </Box>
                      </ViewProperty>

                      <ViewProperty label="Tech 2 Due Date" show={wa.inspection_category !== "PR"}>
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          {wa.tech_review_2_needed ? (
                            <ChangeDate
                              waId={wa.id}
                              field="tech_review_2_due_date"
                              onChange={handleWorkAssignmentChange}
                              title="Tech Review 2 Due Date"
                              value={wa.tech_review_2_due_date}
                            />
                          ) : (
                            <>Not Needed</>
                          )}
                        </Box>
                      </ViewProperty>

                      {wa.due_date === null && wa.due_date_days_after_visit > 0 ? (
                        <ViewProperty
                          label={
                            <>
                              Due Date
                              <br />
                              <small>Days After Visit</small>
                            </>
                          }
                        >
                          <Box
                            sx={{
                              textAlign: "right",
                              minHeight: 31,
                            }}
                          >
                            {wa.due_date_days_after_visit}
                          </Box>
                        </ViewProperty>
                      ) : (
                        <Box
                          sx={{
                            bgcolor: "#ddd",
                            pl: 1,
                            borderRadius: 1,
                          }}
                        >
                          <ViewProperty label={<DateCommentLabel dateComment={wa.due_date_comment} label="Due Date" />}>
                            <Box
                              sx={{
                                textAlign: "right",
                                minHeight: 31,
                              }}
                            >
                              <ChangeDate
                                waId={wa.id}
                                field="due_date"
                                onChange={handleWorkAssignmentChange}
                                title="Due Date"
                                value={wa.due_date}
                              />
                            </Box>
                          </ViewProperty>
                        </Box>
                      )}

                      <ViewProperty label="Invoice">
                        <Box
                          sx={{
                            textAlign: "right",
                            minHeight: 31,
                          }}
                        >
                          <InvoiceEditor workAssignment={wa} onChange={handleRefresh} />
                        </Box>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      {progress !== null && (
                        <WorkAssignmentProgressTable workAssignment={wa} progress={progress} onChange={handleWorkAssignmentChange} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {wa.location != null && (
                    <>
                      <WorkAssignmentLocation wa={wa} onChange={handleRefresh} />
                      <WorkAssignmentMap location={wa.location} centerLocation={location} />
                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} lg={6}>
                          {wa.location.contacts.map(contact => (
                            <Box sx={{ mb: 2 }} key={contact.id}>
                              <ContactCard contact={contact} prefixLabel="Location" onChange={handleRefresh} />
                            </Box>
                          ))}
                          {wa.location.contacts.length === 0 && <Alert severity="warning">No contacts for this location.</Alert>}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          {wa.executive !== null && <UserCard user={wa.executive} label="AE" />}
                          {wa.technical_admin !== null && (
                            <Box sx={{ mt: 2 }}>
                              <UserCard user={wa.technical_admin} label="TA" />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          {wa.invoice_contact !== null && (
                            <Box sx={{ mb: 2 }}>
                              <ContactCard contact={wa.invoice_contact} prefixLabel="Invoice" onChange={handleRefresh} />
                            </Box>
                          )}
                          {wa.location.contacts.length === 0 && <Alert severity="warning">No contacts for this location.</Alert>}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {acceptedConsultants.map((waConsultant, index) => (
                      <Grid key={index} item xs={12} lg={6}>
                        <ConsultantCard
                          consultant={waConsultant.consultant as IConsultant}
                          wac={waConsultant}
                          onChange={handleRefresh}
                          amount={<FormatNumber value={waConsultant.amount} suffixUnits={waConsultant.hourly_rate ? "/hr" : ""} />}
                          wa={wa}
                        />
                      </Grid>
                    ))}
                    {acceptedConsultants.length === 0 && (
                      <Grid item xs={12}>
                        <Alert severity="warning">
                          No consultants have been assigned. Requests <strong>pending</strong> {pendingConsultants.length}, requests{" "}
                          <strong>declined</strong> {declinedConsultants.length}.
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {wa.inspection_category !== "PR" && (
                      <>
                        <Grid item xs={12}>
                          <Typography fontWeight={600}>Reviewers</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AssignAdminReviewer wa={wa} onChange={handleRefresh} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AssignTechReviewer wa={wa} onChange={handleRefresh} reviewerNumber={1} />
                          <Box sx={{ mt: 2 }}>
                            <AssignTechReviewer wa={wa} onChange={handleRefresh} reviewerNumber={2} />
                          </Box>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} lg={6}>
                      <AssigneeEditor wa={wa} onChange={handleRefresh} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel index={1} value={tab}>
              <ConsultantsEditor wa={wa} onChange={handleWorkAssignmentChange} />
            </TabPanel>
            <TabPanel index={2} value={tab}>
              <FilesEditor
                files={wa.files}
                waId={wa.id}
                bundle={wa.files_bundle}
                onRefresh={handleRefresh}
                customer={wa.customer as ICustomer}
                account={wa.account as IAccount}
                location={wa.location}
              />
            </TabPanel>
            <TabPanel index={3} value={tab}>
              <InternalNotesEditor wa={wa} onChange={handleWorkAssignmentChange} />
            </TabPanel>
            <TabPanel index={4} value={tab}>
              <ConsultantNotesEditor wa={wa} onChange={handleWorkAssignmentChange} />
            </TabPanel>
            <TabPanel index={5} value={tab}>
              <MessagesEditor waId={wa.id} repository={messageRepository} />
            </TabPanel>
            <TabPanel index={6} value={tab}>
              <ExtensionsEditor wa={wa} onChange={handleWorkAssignmentChange} />
            </TabPanel>
            <TabPanel index={7} value={tab}>
              <HistoryViewer waId={wa.id} />
            </TabPanel>
          </>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
