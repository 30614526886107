import * as React from "react"
import { FormEvent, MouseEvent, useCallback, useEffect, useState } from "react"
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import DraftsIcon from "@mui/icons-material/Drafts"
import SearchIcon from "@mui/icons-material/Search"
import { truncateString } from "../../../shared/utilities/format_utility"
import Moment from "react-moment"
import { DATE_TIME_FORMAT } from "../../../config/config"
import { IMessage } from "../../../shared/models/IMessage"
import useContentHeight from "../../../shared/hooks/useContentHeight"
import root from "react-shadow"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IListItem } from "../../../shared/models/component/IListItem"
import ViewLoading from "../../../shared/components/ViewLoading"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import TablePaging from "../../../shared/components/TablePaging"
import { IUseApiPagedResultsProps, useApiPagedLocal } from "../../../shared/hooks/useApiPagedLocal"

interface IProps<IMessage> {
  waId: number
  repository: RestRepository<IMessage>
}

type TPagedProps = IUseApiPagedResultsProps<IMessage | IListItem>

/**
 * This component displays all message.
 *
 * @param {IProps<IMessage>} props See IProps for details.
 * @returns {React.FunctionComponent<IProps<IMessage>>} the messages editor.
 */
const MessagesEditor: React.FunctionComponent<IProps<IMessage>> = (props: IProps<IMessage>) => {
  const { waId, repository } = props

  const [filter, setFilter] = useState("")
  const [message, setMessage] = useState<IMessage | null>(null)
  const tabHeight = useContentHeight(-9)
  const listHeight = useContentHeight(9)
  const contentHeight = useContentHeight(7)

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const initialFilters = [
    {
      field: "work_assignment",
      value: waId,
    },
  ]
  const propsApi: TPagedProps = {
    apiFunction: repository.findAll,
    initialFilters,
  }
  const apiPaging = useApiPagedLocal<IMessage | IListItem>(propsApi)
  const { data, call, count, paging, error, loading, handlePaging, handleFilter } = apiPaging

  const handleMessageSelected = useCallback(
    (message1: IMessage) => () => {
      setMessage(message1)
    },
    []
  )

  const handleSearch = useCallback(
    (event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      handleFilter([
        ...initialFilters,
        {
          field: "search",
          value: filter,
        },
      ])
    },
    [filter, waId, call, initialFilters]
  )

  useEffect(() => {
    if (message === null && data !== undefined && data.results.length > 0) {
      setMessage(data.results[0] as IMessage)
    }
  }, [message, data])

  return (
    <Box sx={{ height: tabHeight }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ViewLoading loading={loading} />
          <ErrorMessage error={error} />
        </Grid>
        {data !== undefined && data.results.length > 0 ? (
          <>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <form onSubmit={handleSearch}>
                    <TextField label="Search" fullWidth onChange={e => setFilter(e.target.value)} />
                  </form>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box
                sx={{
                  height: isSmall ? "auto" : listHeight,
                  overflow: "auto",
                }}
              >
                <List component="nav">
                  {(data.results as IMessage[]).map((message1: IMessage, index: number) => (
                    <ListItemButton key={index} onClick={handleMessageSelected(message1)} selected={Boolean(message?.id === message1.id)}>
                      <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={truncateString(message1.subject)}
                        secondary={
                          <>
                            <Typography
                              sx={{
                                display: "inline",
                                mr: 2,
                              }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {message1.to_name}
                            </Typography>
                            <Moment format={DATE_TIME_FORMAT}>{message1.created}</Moment>
                          </>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Box>
              <Box>
                <TablePaging count={count} total={data.count} size="small" page={paging?.page} onPaging={handlePaging} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                p: 1,
                pr: 0,
              }}
            >
              {message !== null && (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {message.subject}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={600}>{message.to_name}</Typography>
                  </Grid>
                  {message.to_email !== "" && (
                    <Grid item>
                      <Typography fontWeight={600}>&lt;{message.to_email}&gt;</Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography fontWeight={600}>{message.to_phone}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        p: 1,
                        height: isSmall ? "auto" : contentHeight,
                        overflow: "auto",
                      }}
                      variant="outlined"
                    >
                      <root.div>
                        <Box dangerouslySetInnerHTML={{ __html: message.content }} />
                      </root.div>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">No messages sent.</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default MessagesEditor
