import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Box, Grid, IconButton, TableContainer, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../shared/models/IWorkAssignment"
import { IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { styles } from "../../shared/styling/general"
import TablePaging from "../../shared/components/TablePaging"
import WorkAssignmentsTable, { workAssignmentColumns } from "../../shared/components/WorkAssignmentsTable"
import { IFilter } from "../../shared/models/IFilter"
import Filtering from "../../shared/components/Filtering"
import ErrorMessage from "../../shared/components/ErrorMessage"
import HighlightedKey from "../../shared/components/HighlightedKey"
import ExcelDownloadButton from "../../shared/components/ExcelDownloadButton"
import TableColumnShow from "../../shared/components/TableColumnShow"
import { WORK_ASSIGNMENTS_URL } from "../../config/urls"
import WorkflowPage from "../../shared/components/WorkflowPage"
import useContentHeight from "../../shared/hooks/useContentHeight"
import { STATUS_REPORT_FILTERS } from "../../config/config"
import { SxProps } from "@mui/system"
import FilterBookmarksDialog from "../../shared/components/FilterBookmarksDialog"
import FilterSharing from "../../shared/components/FilterSharing"
import RefreshIcon from "@mui/icons-material/Refresh"
import { ACCESS_EXCEL_DOWNLOAD_GROUP } from "../../config/permissions"
import PrivateComponent from "../../shared/components/PrivateComponent"
import CopyWorkAssignmentsToClipboard from "../work_assignments/components/CopyWorkAssignmentsToClipboard"

const repository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  title: string
  description?: string
  baseFilter: IFilter
  legendEntries?: any[]
  filename?: string
  rowStyle?: (wa: IWorkAssignment) => SxProps<Theme>
}

/**
 * Reusable page for displaying work assignment reports.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the work assignments report template page.
 */
const WorkAssignmentStatusReport: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, description, baseFilter, legendEntries, filename = "work_assignments.xlsx", rowStyle } = props
  const [initial, setInitial] = useState(false)

  const pagedProps: IUseApiPagedResultsProps<IWorkAssignment> = {
    apiFunction: repository.findAll,
    dontCallOnMount: !initial,
  }
  const { data, count, paging, error, loading, handlePaging, call, handleFilter, handleOrdering, handleLimit } =
    useApiPaged<IWorkAssignment>(pagedProps)

  const handleRefresh = useCallback(() => call(), [call])

  useEffect(() => {
    if (!initial && !loading) {
      setInitial(true)
      if (
        (paging?.filters !== undefined && paging.filters.filter(f => f.field === baseFilter.field).length === 0) ||
        paging?.filters === undefined
      ) {
        handleFilter([baseFilter])
      }
    }
  }, [initial, baseFilter, paging?.filters])

  const height = useContentHeight(description !== undefined ? -5 : -12)

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <Typography variant="h4">{title}</Typography>
            {description !== undefined && <p>{description}</p>}
          </Grid>
          <Grid item>
            <Filtering
              availableFilters={STATUS_REPORT_FILTERS}
              filters={paging?.filters}
              onFilter={handleFilter}
              showClearFilters={false}
              disabled={loading}
            />
          </Grid>
          <Grid item>
            <FilterBookmarksDialog bookmarkType="work_assignments" filters={paging?.filters} onFilter={handleFilter} />
          </Grid>
          <Grid item>
            <FilterSharing onFilter={handleFilter} filters={paging?.filters} />
          </Grid>
          {legendEntries !== undefined && (
            <Grid item>
              <HighlightedKey entries={legendEntries} />
            </Grid>
          )}
          <Grid item>
            <PrivateComponent
              component={
                <ExcelDownloadButton endpoint={WORK_ASSIGNMENT_ENDPOINT} filename={filename} limit={data?.count} paging={paging} />
              }
              groupNames={[ACCESS_EXCEL_DOWNLOAD_GROUP]}
            />
          </Grid>
          {data?.results !== undefined && (
            <Grid item>
              <CopyWorkAssignmentsToClipboard workAssignments={data.results} />
            </Grid>
          )}
          <Grid item>
            <IconButton onClick={handleRefresh} color="primary" size="small" title="Refresh">
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item>
            <TableColumnShow columns={workAssignmentColumns} pathname={WORK_ASSIGNMENTS_URL} />
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <WorkAssignmentsTable
            workAssignments={data?.results}
            ordering={paging?.ordering}
            onOrdering={handleOrdering}
            stickyHeader
            coloring
            rowStyle={rowStyle}
            loading={loading}
            limit={paging?.limit}
          />
        </TableContainer>
      </Box>
      <TablePaging
        count={count}
        total={data?.count}
        page={paging?.page}
        limit={paging?.limit}
        size={isSmall ? "small" : "medium"}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default WorkAssignmentStatusReport
