import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material"
import { Link as BrowserLink } from "@reach/router"
import DeleteIcon from "@mui/icons-material/Delete"

import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"

import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import { ACCOUNTS_VIEW_URL, INVOICES_EDIT_URL, WORK_ASSIGNMENT_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { type IInvoice } from "../../shared/models/IInvoice"
import { type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../shared/models/IWorkAssignment"
import { type ICustomer } from "../../shared/models/ICustomer"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../shared/models/IAccount"
import AddWorkAssignment from "./components/AddWorkAssignment"
import FormatNumber from "../../shared/components/format/FormatNumber"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { navigate } from "gatsby"
import AddLineItem from "./components/AddLineItem"
import { type IInvoiceLineItem, INVOICE_LINE_ITEM_ENDPOINT } from "../../shared/models/IInvoiceLineItem"
import WorkAssignmentProgress from "../../shared/components/WorkAssignmentProgress"
import ChangeTextField from "../../shared/components/ChangeTextField"
import ChangeCheckbox from "../../shared/components/ChangeCheckbox"
import WorkAssignmentLocationName from "../../shared/components/WorkAssignmentLocationName"
import TableOrdering from "../../shared/components/TableOrdering"
import { type IOrdering } from "../../shared/models/IOrdering"
import EditLineItem from "./components/EditLineItem"
import ContactCard from "../../shared/components/ContactCard"
import AuditedInvoice from "./components/AuditedInvoice"
import EditTotal from "./components/EditTotal"
import EditIcon from "@mui/icons-material/Edit"
import useInvoicesAuditsGroup from "./hooks/useInvoicesAuditsGroup"
import { type IListItem } from "../../shared/models/component/IListItem"
import { type IPaging } from "../../shared/models/IPaging"
import { ACCESS_AUDIT_GROUP, ACCESS_INTUIT_GROUP, ACCESS_INVOICES_GROUP, ACCESS_WORK_ASSIGNMENT_GROUP } from "../../config/permissions"
import PrivateComponent from "../../shared/components/PrivateComponent"
import GenerateInvoiceDocument from "./components/GenerateInvoiceDocument"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"
import VoidInvoice from "./components/VoidInvoice"
import TruncateText from "../../shared/components/TruncateText"
import RliGettingPaidAmount from "../../shared/components/RliGettingPaidAmount"
import IntuitAuthentication from "./components/IntuitAuthentication"
import FormatDate from "../../shared/components/format/FormatDate"

const invoiceLineItemRepository = new RestRepository<IInvoiceLineItem>(INVOICE_LINE_ITEM_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)
const accountRepository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)

const columnSize = 11

/**
 * View a selected invoice.
 * @returns {React.FC} the component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { repository, isInWorkAssignmentGroup } = useInvoicesAuditsGroup()
  const { data, error, loading, call: refreshInvoice } = useApiRead<IInvoice | IListItem>({ apiFunction: repository.read })
  const [invoice, setInvoice] = useState<IInvoice | null>(null)

  const [ordering, setOrdering] = useState<IOrdering | undefined>(undefined)

  const handleSelected = useCallback(async (workAssignment: IWorkAssignment) => {
    await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${workAssignment.id}`)
  }, [])

  const handleRemove = useCallback(
    (wa: IWorkAssignment) => async () => {
      if (invoice !== null) {
        const paging: IPaging = {
          filters: [
            {
              field: "wa_id",
              value: wa.id,
            },
          ],
        }
        try {
          await repository.action(invoice.id, "remove_work_assignment", paging)
          refreshInvoice()
        } catch (e) {}
      }
    },
    [invoice, repository],
  )

  const handleRemoveLineItem = useCallback(
    (lineItem: IInvoiceLineItem) => async () => {
      try {
        await invoiceLineItemRepository.delete(lineItem.id)
        refreshInvoice()
      } catch (e) {}
    },
    [invoice],
  )

  const handleWorkAssignmentChange = useCallback((_wa: IWorkAssignment) => {
    refreshInvoice()
  }, [])

  const handleNotesChange = useCallback((_invoice: IInvoice) => {
    refreshInvoice()
  }, [])

  const primaryContactLabel = useMemo(() => {
    if (invoice?.primary_contact_from !== undefined && invoice.primary_contact_from !== null) {
      if (invoice.primary_contact_from === "invoice") {
        return "Invoice"
      }
      if (invoice.primary_contact_from === "account") {
        return "Account Invoice"
      }
      if (invoice.primary_contact_from === "customer") {
        return "Customer Invoice"
      }
    }
    return ""
  }, [invoice?.primary_contact_from])

  const workAssignments = useMemo(() => {
    return invoice?.work_assignments?.sort((a: any, b: any) => {
      if (ordering == null) {
        return 0
      }
      const fa = a[ordering.field].name.toLowerCase()
      const fb = b[ordering.field].name.toLowerCase()
      if (fa < fb) {
        return ordering.direction === "asc" ? -1 : 1
      }
      if (fa > fb) {
        return ordering.direction === "asc" ? 1 : -1
      }
      return 0
    })
  }, [invoice, ordering])

  useEffect(() => {
    if (data !== undefined) {
      setInvoice(data as IInvoice)
    }
  }, [data])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading || invoice === undefined} />
        <ErrorMessage error={error} />
        {invoice !== undefined && invoice !== null && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="h3" component="h2" onClick={refreshInvoice} sx={{ cursor: "pointer" }}>
                    {invoice.number}
                  </Typography>
                </Grid>
                <Grid item>
                  <PrivateComponent
                    groupNames={[ACCESS_INVOICES_GROUP]}
                    component={
                      <Typography variant="h5" component="h5">
                        {invoice.account !== null ? (
                          <Link component={BrowserLink} to={`${ACCOUNTS_VIEW_URL}/${invoice.account.id}`}>
                            <TruncateText text={invoice.account.name} num={20} />
                          </Link>
                        ) : (
                          invoice.customer !== null && (
                            <Link component={BrowserLink} to={`${ACCOUNTS_VIEW_URL}/${invoice.customer.id}`}>
                              <TruncateText text={invoice.customer.name} num={20} />
                            </Link>
                          )
                        )}
                      </Typography>
                    }
                    componentElse={
                      <Typography variant="h5" component="h5">
                        {invoice.account !== null ? (
                          <TruncateText text={invoice.account.name} num={20} />
                        ) : (
                          invoice.customer !== null && <TruncateText text={invoice.customer.name} num={20} />
                        )}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h5" component="h5">
                    <FormatDate value={invoice.primary_invoice_date} />
                  </Typography>
                </Grid>
                <Grid item xs>
                  <ViewLoading loading={loading} message="Updating..." />
                </Grid>
                <Grid item>
                  <Toolbar disableGutters>
                    <PrivateComponent
                      groupNames={[ACCESS_AUDIT_GROUP]}
                      component={<AuditedInvoice invoice={invoice} onChange={refreshInvoice} />}
                      componentElse={<AuditedInvoice invoice={invoice} onChange={refreshInvoice} asLabel />}
                    />
                    <PrivateComponent
                      groupNames={[ACCESS_INVOICES_GROUP]}
                      component={
                        <>
                          <VoidInvoice invoice={invoice} onChange={refreshInvoice} />
                          <GenerateInvoiceDocument invoice={invoice} onChange={refreshInvoice} />
                          <Button component={BrowserLink} to={`${INVOICES_EDIT_URL}/${invoice.id}`} startIcon={<EditIcon />}>
                            Invoice
                          </Button>
                        </>
                      }
                    />
                  </Toolbar>
                </Grid>
              </Grid>
            </Grid>
            <PrivateComponent
              groupNames={[ACCESS_INVOICES_GROUP]}
              component={
                <Grid container spacing={1}>
                  {invoice.primary_contact !== null ? (
                    <Grid item lg={3} md={6} xs={12} sx={{ mt: 2 }}>
                      <ContactCard contact={invoice.primary_contact} prefixLabel={primaryContactLabel} contacts={invoice.contacts} />
                    </Grid>
                  ) : (
                    invoice.contacts.length > 0 && <ContactCard contact={invoice.contacts[0]} prefixLabel={primaryContactLabel} contacts={invoice.contacts} />
                  )}
                  <Grid item xs>
                    <Grid container>
                      <Grid item xs />
                      <PrivateComponent
                        groupNames={[ACCESS_INTUIT_GROUP]}
                        component={
                          <Grid item>
                            <IntuitAuthentication invoice={invoice} onRefresh={refreshInvoice} />
                          </Grid>
                        }
                      />
                      <Grid item>
                        {invoice.account !== null && (
                          <ChangeHtmlField
                            modelId={invoice.account.id}
                            item={invoice.account}
                            field="notes"
                            title="Account Notes"
                            buttonTitle="Edit Account Notes"
                            onChange={handleNotesChange}
                            value={invoice.account.notes}
                            repository={accountRepository}
                            size="medium"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Work Assignments
                  </Typography>
                </Grid>
                <Grid item>
                  <PrivateComponent
                    groupNames={[ACCESS_INVOICES_GROUP]}
                    component={<AddWorkAssignment invoice={invoice} onChange={refreshInvoice} />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>ID</TableCell>
                      <TableCell>
                        <TableOrdering ordering={ordering} field="customer" title="Customer" onOrdering={setOrdering} />
                      </TableCell>
                      <TableCell>
                        <TableOrdering ordering={ordering} field="account" title="Account" onOrdering={setOrdering} />
                      </TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>
                        Inspection
                        <br />
                        Category
                      </TableCell>
                      <TableCell>External Tracking</TableCell>
                      <TableCell>Approved</TableCell>
                      <TableCell>Progress (Next Step)</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                      <PrivateComponent groupNames={[ACCESS_INVOICES_GROUP]} component={<TableCell />} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workAssignments?.map((wa, index) => (
                      <TableRowSelect key={wa.id} item={wa} onSelected={isInWorkAssignmentGroup ? handleSelected : undefined}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{wa.identifier}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <TruncateText text={(wa.customer as ICustomer)?.name} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <TruncateText text={(wa.account as IAccount)?.name} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <WorkAssignmentLocationName wa={wa} />
                        </TableCell>
                        <TableCell>{wa.inspection_category}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <PrivateComponent
                            groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
                            component={
                              <ChangeTextField
                                modelId={wa.id}
                                item={wa}
                                field="external_tracking"
                                title="External Tracking"
                                onChange={refreshInvoice}
                                value={wa.external_tracking}
                                repository={waRepository}
                              />
                            }
                            componentElse={<>{wa.external_tracking}</>}
                          />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <PrivateComponent
                            groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
                            component={
                              <ChangeCheckbox
                                modelId={wa.id}
                                item={wa}
                                field="work_assignment_approved"
                                title="Approved"
                                label="Has the work assignment be approved?"
                                onChange={refreshInvoice}
                                value={wa.work_assignment_approved}
                                repository={waRepository}
                              />
                            }
                            componentElse={<>{wa.work_assignment_approved ? "Yes" : "No"}</>}
                          />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <PrivateComponent
                            groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
                            component={<WorkAssignmentProgress workAssignment={wa} onChange={handleWorkAssignmentChange} />}
                            componentElse={<WorkAssignmentProgress workAssignment={wa} />}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <RliGettingPaidAmount workAssignment={wa} />
                        </TableCell>
                        <PrivateComponent
                          groupNames={[ACCESS_INVOICES_GROUP]}
                          component={
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                textAlign: "right",
                              }}
                            >
                              <IconButton onClick={handleRemove(wa)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          }
                        />
                      </TableRowSelect>
                    ))}

                    {invoice.work_assignments.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={columnSize}>No work assignments found.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Line Items
                  </Typography>
                </Grid>
                <Grid item>
                  <PrivateComponent
                    groupNames={[ACCESS_INVOICES_GROUP]}
                    component={<AddLineItem invoice={invoice} onChange={refreshInvoice} />}
                  />
                </Grid>
              </Grid>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell sx={{ width: "70%" }}>Description</TableCell>
                      <TableCell sx={{ width: "10%" }}>Category</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                      <PrivateComponent groupNames={[ACCESS_INVOICES_GROUP]} component={<TableCell />} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice?.line_items.map((lineItem, index) => (
                      <TableRow key={lineItem.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{lineItem.description}</TableCell>
                        <TableCell>{lineItem.category}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <FormatNumber value={lineItem.amount} twoDecimalPlaces />
                        </TableCell>

                        <PrivateComponent
                          groupNames={[ACCESS_INVOICES_GROUP]}
                          component={
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                textAlign: "right",
                              }}
                            >
                              <EditLineItem lineItem={lineItem} onChange={refreshInvoice} />
                              <IconButton onClick={handleRemoveLineItem(lineItem)} sx={{ ml: 2 }}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          }
                        />
                      </TableRow>
                    ))}

                    {invoice.line_items.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5}>No line items found.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs />
                <Grid item>
                  <Typography variant="h5">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    {invoice.total_amount === 0 ? "$0" : <FormatNumber value={invoice.total_amount} twoDecimalPlaces />}
                  </Typography>
                </Grid>
                <PrivateComponent
                  groupNames={[ACCESS_INVOICES_GROUP]}
                  component={
                    <Grid item>
                      <EditTotal invoice={invoice} onChange={refreshInvoice} />
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
