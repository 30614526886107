import * as React from "react"
import { Box, Grid } from "@mui/material"
import { useDropzone } from "react-dropzone"
import ViewLoading from "./ViewLoading"

interface IProps {
  onDrop: (acceptedFiles: File[]) => void
  loading: boolean
  children?: React.ReactNode
  progress?: number | null | undefined
}

/**
 * Reusable file drop zone.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the file drop zone.
 */
const FileDropZone: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onDrop, loading, children, progress } = props

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    disabled: loading,
  })

  return (
    <Box
      {...getRootProps()}
      sx={
        loading
          ? undefined
          : {
              border: isDragActive ? "2px dashed #888" : "1px solid #fff",
              backgroundColor: isDragActive ? "#ccc" : "#eee",
              p: 1,
            }
      }
    >
      <input {...getInputProps()} />
      {loading ? (
        <ViewLoading loading={loading} inline progress={progress} message="Uploading..." />
      ) : (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            {isDragActive ? <p>Drop the files here ...</p> : <p>Drag &apos;n&apos; drop files here.</p>}
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      )}
    </Box>
  )
}

export default FileDropZone
