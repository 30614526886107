import * as React from "react"
import { IconButton } from "@mui/material"
import { IMinimalFile } from "../../models/IFile"
import HtmlToolTip from "../HtmlToolTip"
import DownloadIcon from "@mui/icons-material/Download"

interface IProps {
  file: IMinimalFile
}

/**
 * This component will download a file that is in azure storage.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the download button.
 */
const FileDownloadButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { file } = props

  return (
    <>
      {file.file?.filename !== undefined && (
        <HtmlToolTip title={`Download file: ${file.file.filename}`}>
          <IconButton color="primary" href={file.file.read_url}>
            <DownloadIcon />
          </IconButton>
        </HtmlToolTip>
      )}
    </>
  )
}

export default FileDownloadButton
