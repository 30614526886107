import * as React from "react"
import { FormEvent, useCallback, useMemo, useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { RestRepository } from "../repositories/RestRepository"
import ErrorMessage from "./ErrorMessage"
import DialogControls from "./DialogControls"
import HtmlTooltip from "./HtmlToolTip"
import { truncateString } from "../utilities/format_utility"
import { OverridableStringUnion } from "@mui/types"
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button"

interface IProps {
  modelId: number
  item: any
  field: string
  title: string
  onChange: (item: any) => void
  value: string | undefined | null
  repository: RestRepository<any>
  size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>
}

/**
 * This component assists in changing a text field for a model.
 *
 * @param {IProps} props See IProps for more details.
 * @returns {React.FunctionComponent<IProps>} the component for changing text.
 */
const ChangeTextField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, title, item, value, modelId, onChange, repository, size = "small" } = props

  const [open, setOpen] = useState(false)
  const [newValue, setNewValue] = useState<string | undefined | null>(value)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleClose = useCallback(() => setOpen(false), [])

  const handleSave = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      setSaving(true)
      setSavingError(undefined)
      try {
        await repository.patch({ [field]: newValue } as any, modelId)
        onChange({
          ...item,
          [field]: newValue,
        })
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
      setOpen(false)
    },
    [newValue, field, modelId, item]
  )

  const isValueNotEmpty = useMemo(() => {
    return value !== null && value !== ""
  }, [value])

  return (
    <>
      <HtmlTooltip
        title={isValueNotEmpty ? <Box sx={{ p: 1 }} dangerouslySetInnerHTML={{ __html: value as string }} /> : ""}
        placement="right"
      >
        <Button size={size} onClick={handleOpen}>
          <>
            {isValueNotEmpty ? (
              <Box dangerouslySetInnerHTML={{ __html: truncateString(value) as string }} />
            ) : (
              "Not Set"
            )}
          </>
        </Button>
      </HtmlTooltip>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Change {title}</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <form method="post" onSubmit={handleSave}>
            <Box sx={{ mt: 1 }}>
              <TextField fullWidth autoFocus onChange={e => setNewValue(e.target.value)} value={newValue} />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <DialogControls
              onSave={handleSave}
              loading={saving}
              onCancel={handleClose}
              disabled={newValue === null || newValue === undefined}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeTextField
