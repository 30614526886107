import * as React from "react"
import { useCallback } from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { ACCOUNTS_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { ACCOUNT_ENDPOINT, IAccount } from "../../shared/models/IAccount"
import AccountForm from "./components/AccountForm"
import { useApiRead } from "../../shared/hooks/useApiRead"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import { dateAsIso } from "../../shared/utilities/format_utility"
import moment from "moment"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const redirectView = ACCOUNTS_VIEW_URL

/**
 * This page will allow editing of an account.
 *
 * @returns {React.FunctionComponent} the account edit page.
 */
const EditPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setError, formState, setValue, clearErrors } = useForm()
  const { data: account, error, loading } = useApiRead<IAccount>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IAccount> = {
    apiFunction: repository.edit,
    setError,
    redirectView,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<IAccount>(props)

  const handleSave = useCallback(async (account1: IAccount) => {
    account1.service_instructions_change_date = dateAsIso(account1.service_instructions_change_date as moment.Moment)
    await handleEdit(account1)
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Edit Account" loading={saving || loading} errors={formState.errors} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <AccountForm account={account} control={control} setValue={setValue} clearErrors={clearErrors} isEdit />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
