import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "7 Days Without Consultant Assigned"
const description = "Work assignments 7 days since added to WF without a consultant assigned."
const baseFilter: IFilter = {
  field: "seven_days_without_assignment",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "seven_days_without_assignment.xlsx"
const rowStyle = styles.waIsLateRowStyle

/**
 * Work assignments 7 days since added to WF without a consultant assigned.
 *
 * @returns {React.FunctionComponent} the reports page.
 */
const SevenDaysWithoutAssignmentReport: React.FunctionComponent = () => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default SevenDaysWithoutAssignmentReport
