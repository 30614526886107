import * as React from "react"
import { useCallback, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { LoadingButton } from "@mui/lab"
import ArchiveIcon from "@mui/icons-material/Archive"
import UnarchiveIcon from "@mui/icons-material/Unarchive"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import DialogControls from "../../../shared/components/DialogControls"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  waId: number
  archive: boolean
  onChange: (wa: IWorkAssignment) => void
}

/**
 * Archive a work assignment when finished.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the archive component.
 */
const Archive: React.FunctionComponent<IProps> = (props: IProps) => {
  const { waId, archive, onChange } = props
  const [saving, setSaving] = useState(false)

  const [open, setOpen] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleSave = useCallback(async () => {
    setSaving(true)
    try {
      await waRepository.patch({ archive: !archive } as any, waId)
      const wa1 = await waRepository.read(waId)
      onChange(wa1)
      setOpen(false)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
  }, [archive, waId])

  return (
    <>
      <LoadingButton
        loading={saving}
        disabled={saving}
        onClick={handleOpen}
        sx={{ mr: 2 }}
        color="warning"
        startIcon={archive ? <UnarchiveIcon /> : <ArchiveIcon />}
      >
        {archive ? "Un-Archive" : "Archive"}
      </LoadingButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{archive ? "Un-Archive" : "Archive"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ErrorMessage error={savingError} />
            Are you sure you want to <strong>{archive ? "un-archive" : "archive"}</strong> this work assignment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogControls
            onSave={handleSave}
            loading={saving}
            onCancel={handleClose}
            disabled={saving}
            buttonLabel="Confirm"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Archive
