import * as React from "react"
import { useCallback } from "react"
import { IFile } from "../../models/IFile"
import { RestRepository } from "../../repositories/RestRepository"
import AlertDialog from "../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"

interface IProps {
  repository: RestRepository<any>
  file: IFile
  onChange: () => void
}

/**
 * Handles the deleting of a file.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the delete component.
 */
const FileDelete: React.FunctionComponent<IProps> = (props: IProps) => {
  const { repository, file, onChange } = props

  const handleDelete = useCallback(
    (file1: IFile) => async () => {
      if (file1.id !== undefined) {
        await repository.delete(file1.id)
        onChange()
      }
    },
    [onChange]
  )

  return (
    <>
      <AlertDialog
        buttonText="Delete file."
        buttonIcon={DeleteIcon}
        title="Confirm File Delete"
        message={
          <>
            Are you sure you want to delete the file:
            <p>
              <em>
                {file.name}.{file.ext}
              </em>
            </p>
          </>
        }
        onYes={handleDelete(file)}
      />
    </>
  )
}

export default FileDelete
