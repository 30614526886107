import { Controller, FieldValues } from "react-hook-form"
import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"
import { TextField } from "@mui/material"
import { InputProps as StandardInputProps } from "@mui/material/Input/Input"

interface IProps {
  control: Control<FieldValues, any>
  defaultValue?: string | number
  label: string
  name: string
  type?: React.HTMLInputTypeAttribute
  formatter?: any
  rules?: any
  onChange?: StandardInputProps["onChange"]
  multiline?: boolean
  suffixUnits?: string
}

/**
 * A reusable text input form field that uses mui and react hook form.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the input field.
 */
const FhMuiTextField: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    control,
    type = "string",
    defaultValue = "",
    onChange,
    name,
    label,
    formatter: Formatter,
    suffixUnits = "",
    rules = {},
    multiline = false,
  } = props

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <TextField
              fullWidth
              type={type}
              multiline={multiline}
              error={fieldState.invalid}
              InputLabelProps={{ shrink: true }}
              helperText={
                Formatter !== undefined ? (
                  <Formatter field={field} fieldState={fieldState} suffixUnits={suffixUnits} />
                ) : (
                  fieldState.error?.message
                )
              }
              label={label}
              onBlur={field.onBlur}
              onChange={e => {
                field.onChange(e)
                onChange?.(e)
              }}
              value={field.value}
              inputRef={field.ref}
            />
          )
        }}
      />
    </>
  )
}

export default FhMuiTextField
