import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Tech Review Hours Approval"
const description = "These tech reviews need to have hours approved."
const baseFilter: IFilter = {
  field: "tech_review_hours_approval",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "tech_review_hours_approval.xlsx"
const rowStyle = styles.waTechReviewHoursStyle

/**
 * This report is for the signing off tech reviews.
 *
 * @returns {React.FC} the reports page.
 */
const TechReviewHoursApprovalReport: React.FC = (): React.ReactElement => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default TechReviewHoursApprovalReport
