import { IWorkAssignment } from "./IWorkAssignment"
import { IInvoiceLineItem } from "./IInvoiceLineItem"
import { IListItem } from "./component/IListItem"
import { IStorageFile } from "./IStorageFile"
import { ICustomer } from "./ICustomer"
import { IAccount } from "./IAccount"
import { IContact } from "./IContact"
import IUser from "./IUser"
import { IAccountFile } from "./IAccountFile"
import { ICustomerFile } from "./ICustomerFile"
import moment from "moment"

export const INVOICE_ENDPOINT = "invoices"
export const INVOICE_AUDIT_ENDPOINT = "invoice_audits"

interface IIntuitObject {
  workflow_id: number
  name: string
  workflow_type: string | null
  quickbooks_identifier: string | null
  quickbooks_synced: string | null
}

export interface IInvoice extends IListItem {
  void_notes: string
  voided_by: IUser
  void: string | null
  file: IStorageFile
  file_final: IStorageFile
  id: number
  number: string
  customer: ICustomer | null
  account: IAccount | null
  primary_contact: IContact | null
  primary_contact_from: "invoice" | "customer" | "account" | null
  contact: IContact | null
  contacts: IContact[]

  account_template_file: IAccountFile | null
  customer_template_file: ICustomerFile | null
  priority_template_name: string

  location: string

  audited: string | null
  audited_by: IUser | null

  amount_overwrite: boolean
  amount: number
  notes: string
  external_tracking: string
  total_amount: number
  sum_amount: number

  work_assignments: IWorkAssignment[]
  line_items: IInvoiceLineItem[]
  invoiced_on: string | moment.Moment | null
  primary_invoice_date: string | moment.Moment | null

  quickbooks_identifier: string | null | undefined
  quickbooks_synced: string | null | undefined
  primary_contact_for_billing: IIntuitObject

  created: string
  updated: string
}
