import { Button, Divider, Grid } from "@mui/material"
import * as React from "react"
import { useCallback } from "react"
import FhMuiCheckboxField from "./form/FhMuiCheckboxField"
import { Control } from "react-hook-form/dist/types/form"
import { FieldValues } from "react-hook-form"

interface IProps {
  saveAndAdd?: boolean
  control?: Control<FieldValues, any>
  disabled?: boolean
  onCancel?: () => void
}

/**
 * A standard form save can cancel panel for reuse.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the action panel.
 */
const FormActionPanel: React.FunctionComponent<IProps> = (props: IProps) => {
  const { saveAndAdd = false, control, disabled = false, onCancel } = props

  const handleCancel = useCallback(() => {
    if (onCancel !== undefined) {
      onCancel()
    } else {
      if (typeof window !== "undefined") {
        window.history.go(-1)
      }
    }
  }, [onCancel])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs>
        <Button onClick={handleCancel}>Cancel</Button>
      </Grid>
      {saveAndAdd && control !== undefined && (
        <Grid item>
          <FhMuiCheckboxField control={control} defaultValue={false} name="save_and_add_another" label="Save & Add Another" />
        </Grid>
      )}
      <Grid item>
        <Button type="submit" variant="contained" color="secondary" disabled={disabled}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default FormActionPanel
