import * as React from "react"
import { useCallback, useState } from "react"
import { IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import { Alert, Dialog, DialogTitle, IconButton, List, ListItem, ListItemText } from "@mui/material"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import { ICustomer } from "../../../shared/models/ICustomer"

interface IProps {
  was: IWorkAssignment[]
}

/**
 * Displays previously saved work assignments.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const SavedWAs: React.FunctionComponent<IProps> = (props: IProps) => {
  const { was } = props
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  return was.length > 0 ? (
    <>
      <Alert
        severity="info"
        action={
          <IconButton onClick={handleOpen}>
            <PlaylistAddIcon />
          </IconButton>
        }
      >
        Added: {was[was.length - 1].identifier} - {(was[was.length - 1].customer as ICustomer).name}
      </Alert>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Previous Saved WAs</DialogTitle>
        <List sx={{ pt: 0 }}>
          {was.map(wa => (
            <ListItem key={wa.id}>
              <ListItemText primary={`${wa.identifier} - ${(wa.customer as ICustomer).name}`} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  ) : (
    <></>
  )
}

export default SavedWAs
