import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { Alert, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField } from "@mui/material"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { IListItem } from "../../../shared/models/component/IListItem"
import { CONSULTANTS_ENDPOINT, IConsultant } from "../../../shared/models/main/IConsultant"
import DialogControls from "../../../shared/components/DialogControls"
import TechReviewerCard from "./TechReviewerCard"
import TechReviewRequestStatus from "./TechReviewRequestStatus"
import ReviewProgress from "./ReviewProgress"
import useApiAction, { IUseApiActionProps } from "../../../shared/hooks/useApiAction"
import { IPaging } from "../../../shared/models/IPaging"
import moment from "moment/moment"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from "../../../config/config"

const cardBadge = {
  display: "block",
  "& .MuiBadge-badge": {
    mt: -0.1,
    mr: 8,
  },
}

interface IProps {
  wa: IWorkAssignment
  onChange: () => void
  reviewerNumber: number
}

interface IPatchTechReview {
  tech_reviewer_consultant: number | null
  tech_review_due_date: string | null
  tech_review_hours: number
}

interface IPatchTechReview2 {
  tech_reviewer_2_consultant: number | null
  tech_review_2_due_date: string | null
  tech_review_2_hours: number
}

const consultantRepository = new RestRepository<IListItem>(CONSULTANTS_ENDPOINT)
const repository = new RestRepository<IPatchTechReview | IPatchTechReview2>(WORK_ASSIGNMENT_ENDPOINT)

/**
 * Use this component to assign a tech reviewer 1 and 2.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the assign tech component.
 */
const AssignTechReviewer: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wa, onChange, reviewerNumber } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [consultant, setConsultant] = useState<IConsultant | null>(null)
  const [hours, setHours] = useState<number>(0)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()
  const [date, setDate] = useState<moment.Moment | null>(null)

  const paging: IPaging = useMemo(() => {
    return {
      filters: [
        {
          field: "number",
          value: reviewerNumber,
        },
      ],
    }
  }, [reviewerNumber])

  const techReviewActionProps: IUseApiActionProps<IPatchTechReview | IPatchTechReview2> = {
    repository,
    itemId: wa.id,
    setSaving,
    setSavingError,
  }
  const { handleAction } = useApiAction(techReviewActionProps)

  const handleOpen = useCallback(() => {
    setOpen(true)
    if (reviewerNumber === 1) {
      setConsultant(wa.tech_reviewer_consultant as IConsultant)
      setDate(wa.tech_review_due_date !== null ? moment(wa.tech_review_due_date) : null)
      setHours(wa.tech_review_hours)
    } else {
      setConsultant(wa.tech_reviewer_2_consultant as IConsultant)
      setDate(wa.tech_review_2_due_date !== null ? moment(wa.tech_review_2_due_date) : null)
      setHours(wa.tech_review_2_hours)
    }
  }, [wa.tech_reviewer_consultant, wa.tech_reviewer_2_consultant, reviewerNumber])

  const handleClose = useCallback(() => {
    setOpen(false)
    setSavingError(undefined)
  }, [])

  const handleAssign = useCallback(async () => {
    setSaving(true)
    setSavingError(undefined)
    try {
      const dateFormatted = date?.format(DATE_OUTPUT_FORMAT)
      if (reviewerNumber === 1) {
        const patch: IPatchTechReview = {
          tech_reviewer_consultant: consultant?.id !== undefined ? consultant.id : null,
          tech_review_due_date: dateFormatted !== undefined ? dateFormatted : null,
          tech_review_hours: hours,
        }
        await repository.patch(patch, wa.id)
      } else {
        const patch: IPatchTechReview2 = {
          tech_reviewer_2_consultant: consultant?.id !== undefined ? consultant.id : null,
          tech_review_2_due_date: dateFormatted !== undefined ? dateFormatted : null,
          tech_review_2_hours: hours,
        }
        await repository.patch(patch, wa.id)
      }
      onChange()
      setOpen(false)
      setSaving(false)
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [consultant, date, hours, reviewerNumber])

  const handleTechReviewerActions = useCallback(
    (action: string) => async () => {
      setSaving(true)
      const item = await handleAction(action, paging)
      if (item !== undefined) {
        onChange()
      }
      setOpen(false)
      setSaving(false)
    },
    [paging]
  )

  const handleTech = useCallback((consultant1?: IListItem | null) => {
    setConsultant(consultant1 !== undefined ? (consultant1 as IConsultant) : null)
  }, [])

  const handleHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(Number(event.target.value))
  }, [])

  const handleDateChange = useCallback((newDate: moment.Moment | null) => {
    setDate(newDate !== null ? moment(newDate) : null)
  }, [])

  const status = useMemo(() => {
    if (reviewerNumber === 1) {
      if (wa.tech_review_accept !== null) {
        return 1
      }
      if (wa.tech_review_decline !== null) {
        return 2
      }
    }
    if (reviewerNumber === 2) {
      if (wa.tech_review_2_accept !== null) {
        return 1
      }
      if (wa.tech_review_2_decline !== null) {
        return 2
      }
    }
    return 0
  }, [wa, reviewerNumber])

  const progress = useMemo(() => {
    if (reviewerNumber === 1) {
      return wa.tech_review_sign_off === null ? 0 : 1
    }
    if (reviewerNumber === 2) {
      return wa.tech_review_2_sign_off === null ? 0 : 1
    }
    return 0
  }, [wa, reviewerNumber])

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              Assign Tech Reviewer {reviewerNumber}
            </Grid>
            <Grid item>
              <TechReviewRequestStatus status={status} />
            </Grid>
            <Grid item>
              <ReviewProgress progress={progress} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ErrorMessage error={savingError} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs>
                <SelectFilteredSingle
                  name="tech_reviewer"
                  label={`Tech Reviewer ${reviewerNumber}`}
                  defaultValue={consultant as IListItem}
                  filters={[
                    {
                      field: "tech_reviewer",
                      value: "true",
                    },
                  ]}
                  repository={consultantRepository}
                  onChange={handleTech}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField type="number" name="hours" label="Hours" value={hours} onChange={handleHours} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date"
                    onChange={handleDateChange}
                    value={date}
                    mask="__/__/____"
                    inputFormat={DATE_INPUT_FORMAT}
                    renderInput={params => {
                      return (
                        <TextField
                          fullWidth
                          {...params}
                          error={!(params.value !== "" && params.value !== null)}
                          autoFocus
                          InputLabelProps={{ shrink: true }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs />
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleAssign} onCancel={handleClose} loading={saving} />
        </DialogActions>
      </Dialog>
      {(!wa.tech_review_needed && reviewerNumber === 1) || (!wa.tech_review_2_needed && reviewerNumber === 2) ? (
        <>
          <Alert
            severity="warning"
            action={
              <>
                {(reviewerNumber === 1 || (reviewerNumber === 2 && wa.tech_review_needed)) && (
                  <Button color="inherit" size="small" onClick={handleTechReviewerActions("tech_reviewer_needed")}>
                    UNDO
                  </Button>
                )}
              </>
            }
          >
            Tech Reviewer {reviewerNumber} not needed.
          </Alert>
        </>
      ) : (
        <>
          {(reviewerNumber === 1 && wa.tech_reviewer_consultant !== null) ||
          (reviewerNumber === 2 && wa.tech_reviewer_2_consultant !== null) ? (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <TechReviewerCard
                  consultant={(reviewerNumber === 1 ? wa.tech_reviewer_consultant : wa.tech_reviewer_2_consultant) as IConsultant}
                  label={`Tech Reviewer ${reviewerNumber}`}
                  loading={saving}
                  hours={reviewerNumber === 1 ? wa.tech_review_hours : wa.tech_review_2_hours}
                  hoursApprovedBy={reviewerNumber === 1 ? wa.tech_review_hours_approved_by : wa.tech_review_2_hours_approved_by}
                  status={status}
                  progress={progress}
                  onEdit={handleOpen}
                  onAccept={handleTechReviewerActions("tech_reviewer_accept")}
                  onComplete={handleTechReviewerActions("tech_reviewer_complete")}
                  onInProgress={handleTechReviewerActions("tech_reviewer_in_progress")}
                  onClear={handleTechReviewerActions("tech_reviewer_clear")}
                  onNotNeeded={handleTechReviewerActions("tech_reviewer_not_needed")}
                  onApproveHoursToggle={handleTechReviewerActions("tech_reviewer_approve_hours_toggle")}
                />
              </Grid>
            </Grid>
          ) : (
            <Paper variant="outlined" sx={{ p: 1 }}>
              <Badge color="info" badgeContent={<>Tech Reviewer {reviewerNumber}</>} sx={cardBadge}>
                <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
                  <Grid item xs>
                    <Button fullWidth onClick={handleOpen}>
                      Assign
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button fullWidth onClick={handleTechReviewerActions("tech_reviewer_not_needed")}>
                      Not Needed
                    </Button>
                  </Grid>
                </Grid>
              </Badge>
            </Paper>
          )}
        </>
      )}
    </>
  )
}

export default AssignTechReviewer
