import * as React from "react"
import { Box } from "@mui/material"

interface IProps {
  value: number | null | undefined
  prefixUnits?: string | React.ReactNode
  suffixUnits?: string | React.ReactNode
  display?: string
  twoDecimalPlaces?: boolean
}

const numFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}
const twoDecimalFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

/**
 * Format a number for display.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the formatted number.
 */
const FormatNumber: React.FunctionComponent<IProps> = (props: IProps) => {
  const { value, prefixUnits = "$", suffixUnits = "", display = "block", twoDecimalPlaces = false } = props

  return (
    <Box component="span" sx={{ display }}>
      {value !== undefined && value !== null ? (
        <>
          <>{prefixUnits}</>
          {Number(value).toLocaleString(undefined, twoDecimalPlaces ? twoDecimalFormatOptions : numFormatOptions)}
          <>{suffixUnits}</>
        </>
      ) : (
        <>Not Set</>
      )}
    </Box>
  )
}

export default FormatNumber
