import { IMainModel } from "./IMainModel"
import { IConsultant } from "./main/IConsultant"
import IUser from "./IUser"
import { IWorkAssignmentConsultant } from "./IWorkAssignmentConsultant"
import { IExpenseReportLineItem } from "./IExpenseReportLineItem"
import { IStorageFile } from "./IStorageFile"
import { IExpenseReportAdminItem } from "./IExpenseReportAdminItem"
import { IWorkAssignment } from "./IWorkAssignment"
import { IPayPeriod } from "./main/IPayPeriod"

export const EXPENSE_REPORTS_ENDPOINT = "expense_reports"

export interface IExpenseReport extends IMainModel {
  identifier: string
  name: string
  consultant: IConsultant
  report_ready: string | null
  received: string | null
  received_by: IUser | undefined
  paid: string | null
  paid_by: IUser | undefined
  confirmed: string | null
  confirmed_by: IUser | undefined
  pay_period: string | null
  adp_pay_period: IPayPeriod | null
  notes: string
  work_assignment_consultants: IWorkAssignmentConsultant[]
  admin_review_work_assignments: IWorkAssignment[]
  tech_review_work_assignments: IWorkAssignment[]
  tech_review_2_work_assignments: IWorkAssignment[]
  line_items: IExpenseReportLineItem[]
  admin_items: IExpenseReportAdminItem[]
  total_amount: number
  total_admin_hours: number
  sum_amount: number
  amount: number
  amount_overwrite: boolean
  file: IStorageFile | undefined
  created: string
  updated: string
}
