import * as React from "react"
import { IFilter } from "../../shared/models/IFilter"
import { styles } from "../../shared/styling/general"
import WorkAssignmentStatusReport from "./WorkAssignmentStatusReport"

const title = "Admin Review Hours Approval"
const description = "These admin reviews need to have hours approved."
const baseFilter: IFilter = {
  field: "admin_review_hours_approval",
  value: "true",
  title,
  cantDelete: true,
}
const filename = "admin_review_hours_approval.xlsx"
const rowStyle = styles.waAdminReviewHoursStyle

/**
 * This report is for the signing off admin reviews.
 *
 * @returns {React.FC} the reports page.
 */
const AdminReviewHoursApprovalReport: React.FC = (): React.ReactElement => {
  return (
    <WorkAssignmentStatusReport title={title} baseFilter={baseFilter} description={description} filename={filename} rowStyle={rowStyle} />
  )
}

export default AdminReviewHoursApprovalReport
