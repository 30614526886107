import * as React from "react"
import { useCallback, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import DialogControls from "../../../shared/components/DialogControls"
import AddIcon from "@mui/icons-material/Add"
import { IExpenseReport } from "../../../shared/models/IExpenseReport"
import { EXPENSE_REPORT_LINE_ITEMS_ENDPOINT } from "../../../shared/models/IExpenseReportLineItem"

interface IProps {
  expenseReport: IExpenseReport
  onChange: () => void
}

export interface IExpenseReportLineItemAdd {
  expense_report: number
  description: string
  amount: number
}

const repository = new RestRepository<IExpenseReportLineItemAdd>(EXPENSE_REPORT_LINE_ITEMS_ENDPOINT)

/**
 * Add a new line item to the expense report.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const AddLineItem: React.FunctionComponent<IProps> = (props: IProps) => {
  const { expenseReport, onChange } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string>("0")
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleClose = useCallback(() => {
    setDescription("")
    setAmount("0")
    setOpen(false)
  }, [])

  const handleSave = useCallback(async () => {
    try {
      setSaving(true)
      const lineItem: IExpenseReportLineItemAdd = {
        expense_report: expenseReport.id,
        description,
        amount: Number(amount),
      }
      await repository.add(lineItem)
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, expenseReport, description, amount])

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />}>
        Line Item
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Line Item</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth label="Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} loading={saving} onCancel={handleClose} disabled={description === "" || amount === ""} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddLineItem
