import * as React from "react"
import { useCallback } from "react"
import { Box } from "@mui/material"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"

import WorkflowPage from "../../shared/components/WorkflowPage"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { ILocation, LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import { useApiRead } from "../../shared/hooks/useApiRead"
import LocationForm from "./components/LocationForm"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import ErrorMessage from "../../shared/components/ErrorMessage"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

/**
 * Use this page to edit a location.
 *
 * @returns {React.FunctionComponent} the edit location page.
 */
const EditPage: React.FunctionComponent = () => {
  const { data: location, loading, error } = useApiRead<ILocation>({ apiFunction: repository.read })
  const { control, handleSubmit, setError, formState, setValue, getValues, clearErrors } = useForm()

  const props: IUseApiEditProps<ILocation> = {
    apiFunction: repository.edit,
    setError,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<ILocation>(props)

  const handleEditWrapper = useCallback(async (location1: ILocation) => {
    location1.business_interruption = location1.business_interruption !== null ? location1.business_interruption : 0
    location1.property_damage = location1.property_damage !== null ? location1.property_damage : 0
    location1.area = Number(location1.area)
    await handleEdit(location1)
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Edit Location" loading={saving || loading} errors={formState.errors} />
        <ErrorMessage error={error} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleEditWrapper as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <LocationForm location={location} clearErrors={clearErrors} control={control} setValue={setValue} getValues={getValues} isEdit />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default EditPage
