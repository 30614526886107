import * as React from "react"
import { Grid } from "@mui/material"
import { Control } from "react-hook-form/dist/types"
import FhMuiTextField from "../../../shared/components/form/FhMuiTextField"
import { requiredRule } from "../../../shared/utilities/form_utility"
import FhMuiPhoneField from "../../../shared/components/form/FhMuiPhoneField"

interface IProps {
  control: Control<any>
}

/**
 * Reusable contact form for creating and editing a contact.
 *
 * @param {IProps} props See Props for details.
 * @returns {React.FC<IProps>} the contact form.
 */
const ContactForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { control } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FhMuiTextField control={control} label="First Name" name="first_name" rules={requiredRule()} />
      </Grid>
      <Grid item xs={12}>
        <FhMuiTextField control={control} label="Last Name" name="last_name" rules={requiredRule()} />
      </Grid>
      <Grid item xs={12}>
        <FhMuiTextField control={control} label="Business Name" name="business_name" />
      </Grid>
      <Grid item xs={12}>
        <FhMuiTextField control={control} label="Job Title" name="job_title" />
      </Grid>
      <Grid item xs={12}>
        <FhMuiTextField control={control} label="Email" name="email" type="email" />
      </Grid>
      <Grid item xs={12}>
        <FhMuiTextField control={control} label="Email 2" name="email_2" type="email" />
      </Grid>
      <Grid item xs={8}>
        <FhMuiPhoneField control={control} label="Phone" name="phone" />
      </Grid>
      <Grid item xs={4}>
        <FhMuiTextField control={control} label="Ext" name="phone_ext" />
      </Grid>
      <Grid item xs={8}>
        <FhMuiPhoneField control={control} label="Phone 2" name="phone_2" />
      </Grid>
      <Grid item xs={4}>
        <FhMuiTextField control={control} label="Ext 2" name="phone_2_ext" />
      </Grid>
    </Grid>
  )
}

export default ContactForm
