import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { type ChangeHistory, ChangeHistoryBuilder } from "../../../shared/models/ChangeHistory"
import useContentHeight from "../../../shared/hooks/useContentHeight"
import ViewLoading from "../../../shared/components/ViewLoading"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import FormatDate from "../../../shared/components/format/FormatDate"
import { DATE_TIME_FORMAT } from "../../../config/config"
import TruncateText from "../../../shared/components/TruncateText"
import DialogControls from "../../../shared/components/DialogControls"

const repository = new RestRepository<ChangeHistory[]>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  waId: number
}

/**
 * View the change history for a selected work assignment.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the history viewer.
 */
const HistoryViewer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { waId } = props
  const [history, setHistory] = useState<ChangeHistory[] | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectedHistory, setSelectedHistory] = useState<ChangeHistory | null>(null)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string>("")

  const tabHeight = useContentHeight(-7)

  const handleLoadChangeHistory = useCallback(() => {
    setLoading(true)
    setError(null)
    repository
      .action(waId, "change_history")
      .then(json => {
        setHistory(json.map(ChangeHistoryBuilder))
      })
      .catch((reason: any) => {
        if (reason?.response !== undefined) {
          setError(reason.response as IConnectionError)
        } else {
          setError(CONNECTION_ERROR)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [waId])

  const handleOpen = useCallback(
    (history1: ChangeHistory) => () => {
      setSelectedHistory(history1)
      setOpen(true)
    },
    [],
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }, [])

  const searchFilter = useCallback(
    (entry: ChangeHistory): boolean => {
      return (
        entry.getChangeFields().toLowerCase().includes(search.toLowerCase()) ||
        entry.actorName?.toLowerCase().includes(search.toLowerCase()) === true
      )
    },
    [search],
  )

  useEffect(() => {
    handleLoadChangeHistory()
  }, [])

  return (
    <Box
      sx={{
        height: tabHeight,
        overflow: "auto",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <ViewLoading loading={loading} />
          <ErrorMessage error={error} />
          <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
            {selectedHistory !== null && (
              <>
                <DialogTitle>
                  Change on <FormatDate value={selectedHistory.timestamp} format={DATE_TIME_FORMAT} />
                </DialogTitle>
                <DialogContent>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Original</TableCell>
                        <TableCell>Change</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(selectedHistory.getChanges()).map((field, index) => (
                        <TableRow key={index}>
                          <TableCell>{field}</TableCell>
                          <TableCell>
                            <Box dangerouslySetInnerHTML={{ __html: selectedHistory.getOriginal(field) }} />
                          </TableCell>
                          <TableCell>
                            <Box dangerouslySetInnerHTML={{ __html: selectedHistory.getChange(field) }} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </DialogContent>
                <DialogActions>
                  <DialogControls onSave={handleClose} buttonLabel="Close" />
                </DialogActions>
              </>
            )}
          </Dialog>
        </Grid>
        {history !== null && history.length > 0 && (
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box sx={{ mt: 1 }}>
                <TextField label="Search" value={search} onChange={handleSearch} fullWidth />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell>Changed</TableCell>
                    <TableCell>By</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.filter(searchFilter).map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.actionName}</TableCell>
                      <TableCell>{entry.objectRepr}</TableCell>
                      <TableCell>
                        <Button onClick={handleOpen(entry)}>
                          <TruncateText text={entry.getChangeFields()} />
                        </Button>
                      </TableCell>
                      <TableCell>{entry.actorName}</TableCell>
                      <TableCell>
                        <FormatDate value={entry.timestamp} format={DATE_TIME_FORMAT} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default HistoryViewer
