import * as React from "react"
import { useCallback, useState } from "react"
import { IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Switch,
  TextField,
} from "@mui/material"
import { Link as BrowserLink } from "@reach/router"
import { INVOICES_VIEW_URL } from "../../../config/urls"
import { IInvoice, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import AddIcon from "@mui/icons-material/Add"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import DialogControls from "../../../shared/components/DialogControls"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IListItem } from "../../../shared/models/component/IListItem"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import EditIcon from "@mui/icons-material/Edit"
import { IPaging } from "../../../shared/models/IPaging"
import { LookupsRepository } from "../../../shared/repositories/LookupsRepository"
import RefreshIcon from "@mui/icons-material/Refresh"
import PrivateComponent from "../../../shared/components/PrivateComponent"
import { ACCESS_INVOICES_GROUP } from "../../../config/permissions"

interface INumberInvoice {
  number: string
}

interface IProps {
  workAssignment: IWorkAssignment
  onChange: () => void
}

const lookupsRepository = new LookupsRepository()
const repository = new RestRepository<INumberInvoice | IListItem>(INVOICE_ENDPOINT)

/**
 * Edit an invoice for a specified work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the editor component.
 */
const InvoiceEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { workAssignment, onChange } = props
  const invoice = workAssignment.invoice as IInvoice | null

  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()
  const [selectedInvoice, setSelectedInvoice] = useState<IListItem | null>(null)
  const [number, setNumber] = useState<string>("")
  const [createNew, setCreateNew] = useState<boolean>(false)

  const handleOpen = useCallback(() => {
    if (invoice !== null) {
      const item: IListItem = {
        id: invoice.id,
        name: invoice.number,
      }
      setCreateNew(false)
      setNumber("")
      setSelectedInvoice(item)
    }
    setOpen(true)
  }, [invoice])

  const handleClose = useCallback(() => setOpen(false), [])

  const handleSave = useCallback(async () => {
    try {
      setSaving(true)
      if (createNew) {
        const newInvoice = (await repository.add({ number })) as IInvoice
        const paging: IPaging = {
          filters: [
            {
              field: "wa_ids",
              value: workAssignment.id,
            },
          ],
        }
        await repository.action(newInvoice.id, "add_work_assignment", paging)
      } else {
        if (selectedInvoice !== null) {
          const paging: IPaging = {
            filters: [
              {
                field: "wa_ids",
                value: workAssignment.id,
              },
            ],
          }
          await repository.action(selectedInvoice.id, "add_work_assignment", paging)
        }
      }
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, workAssignment, selectedInvoice, number, createNew])

  const handleGenerate = useCallback(async () => {
    try {
      const paging: IPaging = {}
      const nextInvoice = await lookupsRepository.lookup<INumberInvoice>("next_invoice_number", paging)
      setNumber(nextInvoice.number)
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [])

  const handleCreateNew = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateNew(event.target.checked)
  }, [])

  const handleChangeNumber = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event.target.value)
  }, [])

  return (
    <>
      <PrivateComponent
        groupNames={[ACCESS_INVOICES_GROUP]}
        component={
          <Grid container spacing={1} alignItems="center">
            <Grid item xs />
            <Grid item>
              {invoice !== null && (
                <Link component={BrowserLink} to={`${INVOICES_VIEW_URL}/${(workAssignment.invoice as IInvoice)?.id}`}>
                  {invoice?.number}
                </Link>
              )}
            </Grid>

            <Grid item>
              <IconButton size="small" onClick={handleOpen}>
                {invoice !== null ? <EditIcon /> : <AddIcon />}
              </IconButton>
            </Grid>
          </Grid>
        }
        componentElse={<>{invoice !== null ? invoice.number : "Not Set"}</>}
      />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Set Invoice</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={createNew} onChange={handleCreateNew} />}
                  label="Create New Invoice"
                />
              </FormGroup>
            </Grid>
            {createNew ? (
              <>
                <Grid item xs>
                  <TextField fullWidth label="Number" name="number" value={number} onChange={handleChangeNumber} />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleGenerate}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid item xs>
                <SelectFilteredSingle
                  name="goto"
                  label="Existing Invoice"
                  repository={repository as RestRepository<IListItem>}
                  onChange={setSelectedInvoice}
                  defaultValue={selectedInvoice}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls
            onSave={handleSave}
            loading={saving}
            onCancel={handleClose}
            disabled={(selectedInvoice === null && !createNew) || (number === "" && createNew)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InvoiceEditor
