import { Badge, Box, ButtonGroup, Grid, IconButton, Link, Paper, Popover, Typography } from "@mui/material"
import * as React from "react"
import { type MouseEvent, type ReactElement, useCallback, useState } from "react"
import { getConsultantPhone, type IConsultant } from "../models/main/IConsultant"
import { Link as BrowserLink } from "@reach/router"
import { CONSULTANTS_VIEW_URL } from "../../config/urls"
import { InfoOutlined } from "@mui/icons-material"
import { type IWorkAssignmentConsultant, isAdminScoringComplete, isTechScoringComplete, isTech2ScoringComplete } from "../models/IWorkAssignmentConsultant"
import WorkAssignmentConsultantEdit from "../../pages/work_assignments/components/WorkAssignmentConsultantEdit"
import ConsultantRating from "./consultants/ConsultantRating"
import ConsultantScoring from "../../pages/work_assignments/components/ConsultantScoring"
import { type IWorkAssignment, workAssignmentAdminNotNeeded } from "../models/IWorkAssignment"

interface IProps {
  consultant: IConsultant
  label?: string
  amount?: ReactElement | null
  hours?: ReactElement | string | number | null
  wac?: IWorkAssignmentConsultant
  onChange?: () => void
  wa: IWorkAssignment // Do we need to have this be or null? I was getting a linting error in the props when I tried to make it optional.
}

/**
 * Displays the consultant as a card.
 * todo: this needs to be rebuilt because it only appears on the wa view.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultant card.
 */
const ConsultantCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const { consultant, label = "Consultant", amount, hours = null, wac, onChange } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {setAnchorEl(null)}, [])
  const open = Boolean(anchorEl)

  const handleFeeChange = useCallback(() => {
    if (onChange !== undefined) {
      onChange()
    }
  }, [])

  const isAdminCompleteStatus = isAdminScoringComplete(wac);
  const isTechCompleteStatus = isTechScoringComplete(wac);
  const isTech2CompleteStatus = isTech2ScoringComplete(wac);

  const adminButtonColor = (isAdminCompleteStatus && (props.wa.admin_review_sign_off !== null)) ? "success" : "warning";
  const techButtonColor = (isTechCompleteStatus && props.wa.tech_review_sign_off !== null) ? "success" : "warning";
  const tech2ButtonColor = (isTech2CompleteStatus && props.wa.tech_review_2_sign_off !== null) ? "success" : "warning";

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            m: 1,
            p: 1,
          }}
        >
          <ConsultantRating rating={consultant.rating} />
          <Typography variant="body2">
            <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email}>`}>{consultant.email}</Link>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <Link href={`tel:${getConsultantPhone(consultant)}`}>{getConsultantPhone(consultant)}</Link>
          </Typography>
        </Box>
      </Popover>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Badge
          color="info"
          badgeContent={label}
          sx={{
            display: "block",
            "& .MuiBadge-badge": {
              mt: -0.1,
              mr: 8,
            },
          }}
        >
          <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
            <Grid item xs>
              <Typography variant="h5" component="div">
                <Link component={BrowserLink} to={`${CONSULTANTS_VIEW_URL}/${consultant.id}`}>
                  {consultant.first_name} {consultant.last_name}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              {wac !== undefined && <WorkAssignmentConsultantEdit wac={wac} onChange={handleFeeChange} useButtonWithAmount />}
              {amount !== null && wac === undefined && <Typography variant="h6">{amount}</Typography>}
              {hours !== null && <Typography variant="h6">{hours} hrs</Typography>}
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" component="span" onClick={handleClick}>
                <InfoOutlined />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <ConsultantRating rating={consultant.rating} />
            </Grid>
          </Grid>
          {wac !== undefined && props.wa.inspection_category !== "PR" && (!workAssignmentAdminNotNeeded(props.wa) || props.wa.tech_review_needed || props.wa.tech_review_2_needed) && (
            <Grid>
              <Typography fontWeight={600} component="span" sx={{mr:1}}>
                Report Score
              </Typography>
              <ButtonGroup size="small">
                {wac !== undefined && !workAssignmentAdminNotNeeded(props.wa) && (
                  <ConsultantScoring 
                    wac={wac} 
                    reviewType="Admin" 
                    buttonColor={adminButtonColor}
                    onChange={handleFeeChange}
                  />
                )}
                {wac !== undefined && props.wa.tech_review_needed && (
                  <ConsultantScoring 
                    wac={wac} 
                    reviewType="Tech" 
                    buttonColor={techButtonColor}
                    onChange={handleFeeChange}
                  />
                )}
                {wac !== undefined && props.wa.tech_review_2_needed && (
                  <ConsultantScoring 
                    wac={wac} 
                    reviewType="Tech 2" 
                    buttonColor={tech2ButtonColor}
                    onChange={handleFeeChange}
                  />
                )}
              </ButtonGroup>
            </Grid>
          )}
        </Badge>
      </Paper>
    </>
  )
}

export default ConsultantCard
