import * as React from "react"
import { useCallback, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import HtmlToolTip from "./HtmlToolTip"
import DialogControls from "./DialogControls"

interface IProps {
  buttonText: string
  buttonIcon?: any
  showIconAndText?: boolean
  title?: string
  message: string | React.ReactNode
  onYes: () => void
}

/**
 * This component displays an alert before doing something.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the alert component.
 */
const AlertDialog: React.FunctionComponent<IProps> = (props: IProps) => {
  const { buttonText, buttonIcon: ButtonIcon, showIconAndText = false, onYes, title = "Confirm", message } = props

  const [open, setOpen] = useState<boolean>(false)

  const handleYes = useCallback(() => {
    setOpen(false)
    onYes()
  }, [onYes])

  return (
    <>
      {ButtonIcon !== undefined && !showIconAndText ? (
        <HtmlToolTip title={buttonText}>
          <IconButton color="primary" onClick={() => setOpen(true)}>
            <ButtonIcon />
          </IconButton>
        </HtmlToolTip>
      ) : (
        <Button
          size="small"
          startIcon={
            showIconAndText ? (
              <>
                <ButtonIcon />
              </>
            ) : undefined
          }
          onClick={() => setOpen(true)}
          variant="text"
        >
          {buttonText}
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <DialogControls onSave={handleYes} onCancel={() => setOpen(false)} buttonLabel="Yes, I'm Sure" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialog
