import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material"
import DialogControls from "../../../shared/components/DialogControls"
import { IFilter } from "../../../shared/models/IFilter"
import { DATE_TIME_FILE_FORMAT, FILTERS } from "../../../config/config"
import { axiosInstance } from "../../../shared/utilities/request_utility"
import { IListItem } from "../../../shared/models/component/IListItem"
import ViewLoading from "../../../shared/components/ViewLoading"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import { IStorageFile } from "../../../shared/models/IStorageFile"
import StorageFileDownloadButton from "../../../shared/components/StorageFileDownloadButton"
import FormatDate from "../../../shared/components/format/FormatDate"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../../shared/models/IExpenseReport"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import MoneyIcon from "@mui/icons-material/CalendarViewWeek"
import DropboxSaver from "../../../shared/components/DropboxSaver"
import moment from "moment"

interface IPayPeriodDetails {
  exists: string
  amount: number
  ach_amount: number
  ach_count: number
  wire_amount: number
  wire_count: number
  adp_amount: number
  adp_count: number
  admin_hours: number
  results: IListItem[]
  bundle: IStorageFile
}

interface IProps {
  filters: IFilter[] | undefined
  onChange: () => void
}

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)

/**
 * Display a dialog for handle pay period processing and downloading.
 *
 * @param {IProps} props See props for details.
 * @returns {React.FC<IProps>} the pay period dialog.
 */
const PayPeriodDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { filters, onChange } = props
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<IConnectionError | null>(null)

  const [reportReady, setReportReady] = useState<boolean>(false)
  const [received, setReceived] = useState<boolean>(false)
  const [paid, setPaid] = useState<boolean>(false)

  const [progressText, setProgressText] = useState<string | null>(null)
  const [progress, setProgress] = useState(0)
  const [payPeriodDetails, setPayPeriodDetails] = useState<IPayPeriodDetails | null>(null)

  const payPeriod: IFilter | null = useMemo(() => {
    const payPeriodFilter = filters?.filter(
      filter => filter.field === FILTERS.EXPENSE_REPORT_PAY_PERIOD.field || filter.field === FILTERS.ADP_PAY_PERIOD.field
    )
    if (payPeriodFilter !== undefined && payPeriodFilter.length > 0) {
      return payPeriodFilter[0]
    }
    return null
  }, [filters?.length])

  const isAdpPayPeriod: boolean = useMemo(() => {
    const payPeriodFilter = filters?.filter(filter => filter.field === FILTERS.ADP_PAY_PERIOD.field)
    return payPeriodFilter !== undefined && payPeriodFilter.length > 0
  }, [filters?.length])

  const isPayPeriodSelected = useMemo(() => {
    return payPeriod !== null
  }, [payPeriod])

  const handleClose = useCallback(() => {
    setOpen(false)
    setPayPeriodDetails(null)
    setReportReady(false)
    setReceived(true)
    setPaid(true)
    setProgress(0)
  }, [])

  const handleOpen = useCallback(async () => {
    setOpen(true)
    setLoading(true)
    setError(null)
    if (payPeriod !== null) {
      try {
        const { data } = await axiosInstance.get(
          `/pay_period/?pay_period=${payPeriod.value as string}&adp=${isAdpPayPeriod ? "true" : "false"}`
        )
        setPayPeriodDetails(data)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
    }
    setLoading(false)
  }, [payPeriod, isAdpPayPeriod])

  const handleSave = useCallback(async () => {
    setError(null)
    if (payPeriodDetails !== null && payPeriod !== null) {
      setLoading(true)
      let total = 0
      if (reportReady) {
        total += payPeriodDetails.results.length
      }
      if (received) {
        total += payPeriodDetails.results.length
      }
      if (paid) {
        total += payPeriodDetails.results.length
      }
      let count = 0
      try {
        for (const item of payPeriodDetails.results) {
          setProgressText(`Processing ${item.name}...`)
          if (reportReady) {
            await repository.action(item.id, "report_ready")
            count++
            setProgress((count / total) * 100)
          }
          if (received) {
            await repository.action(item.id, "received")
            count++
            setProgress((count / total) * 100)
          }
          if (paid) {
            await repository.action(item.id, "paid")
            count++
            setProgress((count / total) * 100)
          }
        }
        setProgress(-1)
        setProgressText(`Bundling pay period...`)
        const { data } = await axiosInstance.get(
          `/pay_period_build_bundle/?pay_period=${payPeriod.value as string}&adp=${isAdpPayPeriod ? "true" : "false"}`
        )
        setPayPeriodDetails(data)
        onChange()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
      setProgressText(null)
      setProgress(0)
      setLoading(false)
    }
  }, [payPeriodDetails, payPeriod, reportReady, received, paid, isAdpPayPeriod])

  const dropboxFilename: string = useMemo(() => {
    if (payPeriodDetails !== null) {
      const fileParts = payPeriodDetails.bundle.filename.split("/")
      const baseName = fileParts[fileParts.length - 1].replace(".zip", "")
      const datetime = moment(payPeriodDetails.exists).format(DATE_TIME_FILE_FORMAT)
      return `${baseName}-${datetime}`
    }
    return ""
  }, [payPeriodDetails])

  return isPayPeriodSelected && payPeriod !== null ? (
    <>
      <Button onClick={handleOpen} startIcon={<MoneyIcon />}>
        Pay Period -
        {isAdpPayPeriod ? (
          <>
            <Box
              component="span"
              sx={{
                ml: 1,
                mr: 1,
              }}
            >
              ADP
            </Box>
            <FormatDate value={payPeriod.display as string} />
          </>
        ) : (
          <FormatDate value={payPeriod.value as string} />
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Pay Period for
          {isAdpPayPeriod ? (
            <>
              <Box
                component="span"
                sx={{
                  ml: 1,
                  mr: 1,
                }}
              >
                ADP
              </Box>
              <FormatDate value={payPeriod.display as string} />
            </>
          ) : (
            <FormatDate value={payPeriod.value as string} />
          )}
        </DialogTitle>
        <DialogContent>
          <ViewLoading loading={loading} />
          <ErrorMessage error={error} />
          {payPeriodDetails !== null && (
            <>
              <Paper
                sx={{
                  mb: 1,
                  p: 2,
                }}
              >
                <Grid container spacing={2} alignItems="end">
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <Typography variant="subtitle1">Total Count</Typography>
                    <Typography variant="h6">{payPeriodDetails.results.length}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <Typography variant="subtitle1">Total Amount</Typography>
                    <Typography variant="h6">
                      <FormatNumber value={payPeriodDetails.amount} twoDecimalPlaces />
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <Typography variant="subtitle1">Total Hours</Typography>
                    <Typography variant="h6">
                      <FormatNumber value={payPeriodDetails.admin_hours} prefixUnits={false} twoDecimalPlaces={true} />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                sx={{
                  mb: 1,
                  p: 2,
                }}
              >
                <Grid container spacing={2} alignItems="end">
                  <>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1">ACH Count</Typography>
                      <Typography variant="h6">{payPeriodDetails.ach_count}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1">Wire Count</Typography>
                      <Typography variant="h6">{payPeriodDetails.wire_count}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1">ADP Count</Typography>
                      <Typography variant="h6">{payPeriodDetails.adp_count}</Typography>
                    </Grid>

                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1">ACH Amount</Typography>
                      <Typography variant="h6">
                        <FormatNumber value={payPeriodDetails.ach_amount} twoDecimalPlaces />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1">Wire Amount</Typography>
                      <Typography variant="h6">
                        <FormatNumber value={payPeriodDetails.wire_amount} twoDecimalPlaces />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle1">ADP Amount</Typography>
                      <Typography variant="h6">
                        <FormatNumber value={payPeriodDetails.adp_amount} twoDecimalPlaces />
                      </Typography>
                    </Grid>
                  </>
                </Grid>
              </Paper>
            </>
          )}
          <Paper
            sx={{
              mt: 2,
              p: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox checked={reportReady} value="report_ready" onChange={e => setReportReady(e.target.checked)} />}
                    label="Report Ready"
                  />
                  <FormHelperText>Consultant submitted.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox value="received" checked={received} onChange={e => setReceived(e.target.checked)} />}
                    label="Received"
                  />
                  <FormHelperText>Generate PDF.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs sx={{ textAlign: "center" }}>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox value="paid" checked={paid} onChange={e => setPaid(e.target.checked)} />}
                    label="Paid"
                  />
                  <FormHelperText>Send emails.</FormHelperText>
                </FormControl>
              </Grid>
              {progress !== 0 && (
                <Grid item xs={12}>
                  <strong>{progressText}</strong>
                  <LinearProgress variant={progress === -1 ? "indeterminate" : "determinate"} value={progress} />
                </Grid>
              )}
            </Grid>
          </Paper>
          <Paper
            sx={{
              mt: 2,
              p: 2,
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs>
                {payPeriodDetails?.bundle?.exists !== undefined && payPeriodDetails.bundle.exists !== false ? (
                  <DropboxSaver
                    file={{
                      name: dropboxFilename,
                      ext: "zip",
                      file: payPeriodDetails.bundle,
                    }}
                  />
                ) : (
                  <Alert color="info">Archive has not been built.</Alert>
                )}
              </Grid>
              <Grid item>
                <StorageFileDownloadButton file={payPeriodDetails?.bundle} title="Download" showDate />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <DialogControls disabled={loading} onSave={handleSave} onCancel={handleClose} buttonLabel="Process" cancelButtonLabel="Close" />
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <></>
  )
}

export default PayPeriodDialog
