import { IListItem } from "../component/IListItem"

export const PAY_PERIODS_ENDPOINT = "pay_periods"

/**
 * Represents a pay period.
 */
export interface IPayPeriod extends IListItem {
  id: number
  name: string
  start_date: string
  expense_report_due_date: string
  payment_date: string
}
