import IUser from "../IUser"
import { IFilterBookmark } from "../IFilterBookmark"

export const PROFILES_ENDPOINT = "profiles"

export default interface IProfile {
  id: number
  user: IUser
  bookmarks: Record<string, IFilterBookmark[] | null>
}
