import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { CONTACT_ENDPOINT, IContact } from "../models/IContact"
import FormatDate from "./format/FormatDate"
import { LoadingButton } from "@mui/lab"
import { RestRepository } from "../repositories/RestRepository"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import VerifiedIcon from "@mui/icons-material/Verified"
import ReportIcon from "@mui/icons-material/Report"
import { Badge, Box } from "@mui/material"
import HtmlTooltip from "./HtmlToolTip"
import ErrorMessage from "./ErrorMessage"

const repo = new RestRepository<IContact>(CONTACT_ENDPOINT)

interface IProps {
  contact: IContact
  onChange?: () => void
}

/**
 * Display a verification badge if the contact has been verified and a button to verify the contact if not verified.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const ContactVerified: React.FunctionComponent<IProps> = (props: IProps) => {
  const { contact, onChange } = props
  const [saving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)
  const [localContact, setLocalContact] = useState<IContact | null>(null)

  /**
   * Toggle the verified state of the contact.
   */
  const handleToggleVerified = useCallback(async () => {
    if (localContact !== null) {
      setSaving(true)
      setSavingError(null)
      try {
        const contactUpdated = await repo.action(localContact.id, "toggle_verified")
        setLocalContact(contactUpdated)
        onChange?.()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    }
  }, [localContact])

  /**
   * Set the local contact state if it hasn't been set.
   */
  useEffect(() => {
    if (localContact === null) {
      setLocalContact(contact)
    }
  }, [localContact, contact])

  return (
    <HtmlTooltip
      title={
        <>
          <ErrorMessage error={savingError} />
          <Box>{localContact?.verified === null ? <>Click to verify</> : localContact?.verified_by?.name}</Box>
        </>
      }
      placement="right"
    >
      <Badge variant="dot" color="error" invisible={savingError === null}>
        {localContact?.verified !== null && localContact?.verified !== undefined ? (
          <LoadingButton loading={saving} onClick={handleToggleVerified} startIcon={<VerifiedIcon color="success" />}>
            <FormatDate value={localContact.verified} notSetMessage="Not Verified" />
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={saving}
            disabled={localContact === null}
            startIcon={<ReportIcon color="error" />}
            onClick={handleToggleVerified}
          >
            Verify
          </LoadingButton>
        )}
      </Badge>
    </HtmlTooltip>
  )
}

export default ContactVerified
