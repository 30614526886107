import { useCallback, useState } from "react"

interface IUseFocusResponse {
  focused: boolean
  handleFocus: () => void
  handleBlur: () => void
}

/**
 * Helper hook to package focusing functionality.
 *
 * @returns {IUseFocusResponse} the focus handlers.
 */
const useFocus = (): IUseFocusResponse => {
  const [focused, setFocused] = useState<boolean>(false)
  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])

  return {
    focused,
    handleFocus,
    handleBlur,
  }
}

export default useFocus
