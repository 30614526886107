import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import DialogControls from "../../../shared/components/DialogControls"
import { IPagedResults } from "../../../shared/models/IPagedResults"
import { ILocation, LOCATION_ENDPOINT } from "../../../shared/models/ILocation"
import { IMapLocation } from "../../../shared/models/component/IMapLocation"
import { IAccount } from "../../../shared/models/IAccount"
import TruncateText from "../../../shared/components/TruncateText"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { LOCATIONS_VIEW_URL } from "../../../config/urls"
import useApiPatch, { IUseApiPatchProps } from "../../../shared/hooks/useApiPatch"
import { IConnectionError } from "../../../shared/models/IConnectionError"
import ViewLoading from "../../../shared/components/ViewLoading"
import ViewError from "../../../shared/components/ViewError"

interface IPatch {
  accounts: number[]
}

const repository = new RestRepository<IPatch>(LOCATION_ENDPOINT)

interface IProps {
  locations: IPagedResults<ILocation> | null
  mapLocation: IMapLocation | null
  open: boolean
  onClose: () => void
  parentAccount?: IAccount | null | undefined
  onLocationSelected?: (location: ILocation) => void
}

/**
 * Displays a dialog with a bunch of locations are close to the map location.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} The dialog of locations.
 */
const SimilarLocations: React.FunctionComponent<IProps> = (props: IProps) => {
  const { locations, mapLocation, open, onClose, parentAccount, onLocationSelected } = props

  const [saving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const patchProps: IUseApiPatchProps<IPatch> = {
    repository,
    setSaving,
    setSavingError,
  }
  const { handlePatch } = useApiPatch<IPatch>(patchProps)

  const locationCount = useMemo(() => {
    return locations?.count !== undefined && locations.count
  }, [locations])

  const usingParentAccount = useMemo(() => {
    return parentAccount !== undefined && parentAccount !== null
  }, [parentAccount])

  const handleSelect = useCallback(
    (location: ILocation) => async () => {
      if (parentAccount !== undefined && parentAccount !== null) {
        const patch: IPatch = {
          accounts: [...(location.accounts as IAccount[]).map(account => account.id), parentAccount.id],
        }
        await handlePatch(patch, location.id)
        onLocationSelected?.(location)
        onClose()
      }
    },
    [parentAccount, onLocationSelected]
  )

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={locationCount > 0 ? "lg" : "md"} fullWidth>
        <DialogTitle>Similar Locations {locationCount > 0 && <>- Found {locationCount}</>}</DialogTitle>
        <DialogContent>
          {locationCount > 0 ? (
            <>
              <Alert color="warning">
                There were {locationCount} locations found in a 1 sq km area of this location. If one of these locations looks correct,
                {usingParentAccount ? (
                  <>
                    &nbsp;select the location to use and add to account, <strong>{parentAccount?.name}</strong>
                  </>
                ) : (
                  <> don&apos;t create another location.</>
                )}
              </Alert>
              <ViewLoading loading={saving} message="Updating location..." />
              <ViewError error={savingError} />
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Accounts</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Country</TableCell>
                      {usingParentAccount && <TableCell />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locations?.results.map(location => {
                      return (
                        <TableRow key={location.id}>
                          <TableCell>
                            <Link href={`${LOCATIONS_VIEW_URL}/${location.id}`} target="_blank">
                              {location.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <TruncateText text={(location.accounts as IAccount[]).map(account => account.name).join(", ")} />
                          </TableCell>
                          <TableCell>
                            <TruncateText text={location.name} />
                          </TableCell>
                          <TableCell>
                            <TruncateText text={location.address} />
                          </TableCell>
                          <TableCell>{location.city}</TableCell>
                          <TableCell>{location.state_region}</TableCell>
                          <TableCell>{location.country}</TableCell>
                          {usingParentAccount && (
                            <TableCell>
                              <Button onClick={handleSelect(location)} disabled={saving}>
                                Select
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Alert color="success">
                There are currently no locations with in 1km of {mapLocation?.latitude}, {mapLocation?.longitude}.
              </Alert>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={onClose} buttonLabel="Close" disabled={saving} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SimilarLocations
