import { Controller, FieldValues } from "react-hook-form"
import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"
import { FormGroup, FormLabel } from "@mui/material"
import { DefaultEditor } from "react-simple-wysiwyg"

interface IProps {
  control: Control<FieldValues, any>
  defaultValue?: string | number
  label: string
  name: string
}

/**
 * A form hook for MUI wysiwyg html editor
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the editor.
 */
const FhMuiMdField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, defaultValue = "", name, label } = props

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <DefaultEditor onBlur={field.onBlur} onChange={field.onChange} value={field.value} />
          </>
        )}
      />
    </FormGroup>
  )
}

export default FhMuiMdField
