import { Badge, Divider, Grid, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material"
import * as React from "react"
import { type MouseEvent, useCallback, useState } from "react"
import { getConsultantPhone, type IConsultant } from "../../../shared/models/main/IConsultant"
import { Link as BrowserLink } from "@reach/router"
import { CONSULTANTS_VIEW_URL } from "../../../config/urls"
import MenuIcon from "@mui/icons-material/MoreVert"
import ClearIcon from "@mui/icons-material/Clear"
import CheckIcon from "@mui/icons-material/Check"
import ApprovalIcon from "@mui/icons-material/Approval"
import RemoveIcon from "@mui/icons-material/Remove"
import ConsultantRating from "../../../shared/components/consultants/ConsultantRating"
import TechReviewRequestStatus from "./TechReviewRequestStatus"
import HtmlToolTip from "../../../shared/components/HtmlToolTip"
import EditIcon from "@mui/icons-material/Edit"
import LoopIcon from "@mui/icons-material/Loop"
import { LoadingButton } from "@mui/lab"
import type IUser from "../../../shared/models/IUser"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import { ACCESS_CAN_APPROVE_TECH_REVIEW } from "../../../config/permissions"
import PrivateComponent from "../../../shared/components/PrivateComponent"

const cardBadge = {
  display: "block",
  "& .MuiBadge-badge": {
    mt: -0.1,
    mr: 8,
  },
}

interface IProps {
  consultant: IConsultant
  hoursApprovedBy: IUser | null
  label: string
  loading: boolean
  status: number
  progress: number
  hours: number | null
  onEdit: () => void
  onAccept: () => void
  onComplete: () => void
  onInProgress: () => void
  onClear: () => void
  onNotNeeded: () => void
  onApproveHoursToggle: () => void
}

/**
 * Displays the tech reviewer as a card.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the consultant card.
 */
const TechReviewerCard: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    consultant,
    label,
    loading,
    hours = null,
    hoursApprovedBy,
    status,
    progress,
    onEdit,
    onAccept,
    onComplete,
    onInProgress,
    onClear,
    onNotNeeded,
    onApproveHoursToggle,
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Badge color="info" badgeContent={label} sx={cardBadge}>
          <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
            <Grid item xs>
              <HtmlToolTip
                title={
                  <>
                    <ConsultantRating rating={consultant.rating} />
                    <Typography variant="body2">
                      <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email}>`}>{consultant.email}</Link>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <Link href={`tel:${getConsultantPhone(consultant)}`}>{getConsultantPhone(consultant)}</Link>
                    </Typography>
                  </>
                }
                placement="left"
              >
                <Typography variant="h5" component="div">
                  <Link component={BrowserLink} to={`${CONSULTANTS_VIEW_URL}/${consultant.id}`}>
                    {consultant.first_name} {consultant.last_name}
                  </Link>
                </Typography>
              </HtmlToolTip>
            </Grid>
            <Grid item>
              {hours !== null && (
                <Typography variant="h6">
                  <FormatNumber value={hours} twoDecimalPlaces prefixUnits={false} suffixUnits="hrs" />
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" component="span" onClick={handleClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
              >
                <MenuItem onClick={onEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Reviewer</ListItemText>
                </MenuItem>
                <Divider />
                {status !== 1 && (
                  <MenuItem onClick={onAccept}>
                    <ListItemIcon>
                      <ApprovalIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Accept</ListItemText>
                  </MenuItem>
                )}
                {progress === 0 && (
                  <MenuItem onClick={onComplete}>
                    <ListItemIcon>
                      <CheckIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Complete</ListItemText>
                  </MenuItem>
                )}
                {progress === 1 && (
                  <MenuItem onClick={onInProgress}>
                    <ListItemIcon>
                      <LoopIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>In Progress</ListItemText>
                  </MenuItem>
                )}
                <MenuItem onClick={onNotNeeded}>
                  <ListItemIcon>
                    <RemoveIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Not Needed</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={onClear}>
                  <ListItemIcon>
                    <ClearIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Reset Reviewer</ListItemText>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={12} />
            <PrivateComponent
              groupNames={[ACCESS_CAN_APPROVE_TECH_REVIEW]}
              component={
                <Grid item xs>
                  {hoursApprovedBy === null ? (
                    <LoadingButton size="small" color="warning" onClick={onApproveHoursToggle} loading={loading}>
                      Approve Hours
                    </LoadingButton>
                  ) : (
                    <LoadingButton size="small" color="success" onClick={onApproveHoursToggle} loading={loading}>
                      Hours Approved!
                    </LoadingButton>
                  )}
                </Grid>
              }
              componentElse={<Grid item xs />}
            />
            <Grid item>
              {status === 0 ? (
                <LoadingButton size="small" color="warning" onClick={onAccept} loading={loading}>
                  Accept
                </LoadingButton>
              ) : (
                <TechReviewRequestStatus status={status} />
              )}
            </Grid>
            <Grid item>
              {progress === 0 ? (
                <LoadingButton size="small" color="warning" onClick={onComplete} loading={loading}>
                  In Progress
                </LoadingButton>
              ) : (
                <LoadingButton size="small" color="success" onClick={onInProgress} loading={loading}>
                  Complete
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Badge>
      </Paper>
    </>
  )
}

export default TechReviewerCard
