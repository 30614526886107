import * as React from "react";
import { useMemo } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import WorkflowPage from "../../shared/components/WorkflowPage";
import { styles } from "../../shared/styling/general";
import MessageTests from "./components/MessageTests";
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel";
import EmailPreferences from "./components/EmailPreferences";
import UserProfile from "./components/UserProfile";
import useAuth from "../../shared/hooks/useAuth";
import { ACCESS_WORK_ASSIGNMENT_GROUP } from "../../config/permissions";

/**
 * This component will render the setting page.
 *
 * @returns {React.FC} the component.
 */
const IndexPage: React.FC = () : React.ReactElement => {
  const { tab, handleTabChange } = useTabPanel()
  const { isUserInGroups } = useAuth()

  const isUserInWorkAssignments = useMemo(() => {
    return isUserInGroups?.([ACCESS_WORK_ASSIGNMENT_GROUP]) === true
  }, [isUserInGroups])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <Box sx={styles.tabsBox}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Profile" />
            <Tab label="Email Preferences" disabled={!isUserInWorkAssignments} />
            <Tab label="Tools" disabled />
          </Tabs>
        </Box>
        <TabPanel index={0} value={tab}>
          <UserProfile />
        </TabPanel>
        <TabPanel index={1} value={tab}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <EmailPreferences />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel index={2} value={tab}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MessageTests />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </WorkflowPage>
  )
}

export default IndexPage
