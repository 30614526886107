import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Box, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material"
import useAuth from "../../../shared/hooks/useAuth"
import { useDispatch } from "react-redux"
import { EMAIL_GROUP_ENDPOINT, IGroup } from "../../../shared/models/IGroup"
import { IPaging } from "../../../shared/models/IPaging"
import { operations } from "../../../store/settings"
import IUser, { USERS_ME_ENDPOINT } from "../../../shared/models/IUser"
import { RestRepository } from "../../../shared/repositories/RestRepository"

const emailGroupRepository = new RestRepository<IGroup>(EMAIL_GROUP_ENDPOINT)
const userRepository = new RestRepository<IUser>(USERS_ME_ENDPOINT)

/**
 * Allows user to manage their email preferences.
 *
 * @returns {React.FunctionComponent} the email preferences component.
 */
const EmailPreferences: React.FunctionComponent = () => {
  const [groups, setGroups] = useState<IGroup[] | null>(null)
  const [initial, setInitial] = useState<boolean>(true)
  const [userGroups, setUserGroups] = useState<IGroup[] | null>(null)
  const { currentUser, refreshUser } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    if (initial && refreshUser !== null) {
      setInitial(false)
      void (async () => await refreshUser())()
    }
  }, [initial, refreshUser])

  useEffect(() => {
    if (currentUser?.user.groups !== undefined) {
      setUserGroups(currentUser?.user.groups)
    } else {
      setUserGroups(null)
    }
  }, [currentUser?.user.groups])

  useEffect(() => {
    if (groups === null) {
      emailGroupRepository
        .list()
        .then(groups1 => {
          setGroups(groups1)
        })
        .catch((reason: any) => {
          console.log(reason)
        })
    }
  }, [groups])

  const handleEmailChange = useCallback(
    (group: IGroup) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentUser?.user !== undefined) {
        const paging: IPaging = {
          filters: [
            {
              field: "group_id",
              value: group.id,
            },
          ],
        }

        if (event.target.checked) {
          setUserGroups((userGroups1: IGroup[] | null) => {
            return [...(userGroups1 !== null ? userGroups1 : []), group]
          })
          const updatedUser = await userRepository.action(currentUser.user.id, "add_to_email_group", paging)
          dispatch(
            operations.setCurrentUser({
              user: updatedUser,
              token: currentUser.token,
            })
          )
        } else {
          setUserGroups((userGroups1: IGroup[] | null) => {
            return userGroups1 !== null ? userGroups1.filter((userGroup: IGroup) => userGroup.id !== group.id) : null
          })
          const updatedUser = await userRepository.action(currentUser.user.id, "remove_from_email_group", paging)
          dispatch(
            operations.setCurrentUser({
              user: updatedUser,
              token: currentUser.token,
            })
          )
        }
      }
    },
    [currentUser?.user]
  )

  const hasGroup = useCallback(
    (group: IGroup) => {
      if (userGroups !== null) {
        return userGroups.some(group1 => group1.id === group.id)
      }
      return false
    },
    [userGroups]
  )

  const aeCount = useMemo((): number => {
    return currentUser?.user?.customers !== undefined ? currentUser.user.customers.length : 0
  }, [])

  return (
    <>
      <Typography variant="h6">Select the emails you want to receive.</Typography>
      {aeCount > 0 && (
        <Box
          sx={{
            pt: 2,
            pb: 2,
          }}
        >
          Your are an AE on {aeCount} accounts. You will receive emails based on those accounts.
        </Box>
      )}
      <FormGroup>
        {groups?.map(group => {
          return (
            <React.Fragment key={group.id}>
              <FormControlLabel
                control={<Switch onChange={handleEmailChange(group)} checked={hasGroup(group)} />}
                label={group.name}
              />
            </React.Fragment>
          )
        })}
      </FormGroup>
    </>
  )
}

export default EmailPreferences
