import { Badge, Box, Grid, IconButton, Link, Paper, Popover, Typography } from "@mui/material"
import * as React from "react"
import { type MouseEvent, useCallback, useState } from "react"
import { getContactPhone, getContactPhone2, type IContact } from "../models/IContact"
import { InfoOutlined } from "@mui/icons-material"
import { Link as BrowserLink } from "@reach/router"
import { CONTACTS_VIEW_URL } from "../../config/urls"
import ContactVerified from "./ContactVerified"
import ContactAsPrimary from "./ContactAsPrimary"
import ContactsDialog from "./ContactsDialog"

interface IProps {
  contact: IContact
  contacts?: IContact[]
  prefixLabel?: string
  onChange?: () => void
}

/**
 * Displays a contact and allows various actions for the contact.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the contact card component.
 */
const ContactCard: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { contact, contacts, prefixLabel = "", onChange } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const open = Boolean(anchorEl)

  return (
    <>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
        <Box sx={{ m: 1, p: 1 }}>
          {contact.business_name !== null && contact.business_name !== "" && (
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {contact.business_name}
            </Typography>
          )}
          {contact.job_title !== null && contact.job_title !== "" && (
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {contact.job_title}
            </Typography>
          )}
          {contact.email !== null && contact.email !== "" && (
            <Typography variant="body2">
              <Link href={`mailto:${contact.first_name} ${contact.last_name}<${contact.email}>`}>{contact.email}</Link>
            </Typography>
          )}
          {contact.email_2 !== null && contact.email_2 !== "" && (
            <Typography variant="body2">
              <Link href={`mailto:${contact.first_name} ${contact.last_name}<${contact.email_2}>`}>{contact.email_2}</Link>
            </Typography>
          )}
          {contact.phone !== null && contact.phone !== "" && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              <Link href={`tel:${getContactPhone(contact)}`}>{getContactPhone(contact)}</Link>
            </Typography>
          )}
          {contact.phone_2 !== null && contact.phone_2 !== "" && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              <Link href={`tel:${getContactPhone2(contact)}`}>{getContactPhone2(contact)}</Link>
            </Typography>
          )}
        </Box>
      </Popover>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Badge
          color="info"
          badgeContent={`${prefixLabel !== "" ? prefixLabel + " " : ""}Contact`}
          sx={{
            display: "block",
            "& .MuiBadge-badge": {
              mt: -0.1,
              mr: 8,
            },
          }}
        >
          <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
            <Grid item xs>
              <Typography variant="h5" component="div">
                <Link component={BrowserLink} to={`${CONTACTS_VIEW_URL}/${contact.id}`}>
                  {contact.first_name} {contact.last_name}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" component="span" onClick={handleClick}>
                <InfoOutlined />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <ContactVerified contact={contact} onChange={onChange} />
            </Grid>
            <Grid item>
              <ContactAsPrimary contact={contact} />
            </Grid>
            {contacts !== undefined && contacts.length > 0 && (
              <Grid item>
                <ContactsDialog contacts={contacts} />
              </Grid>
            )}
          </Grid>
        </Badge>
      </Paper>
    </>
  )
}

export default ContactCard
