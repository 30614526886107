import * as React from "react"
import { useCallback, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { IUseApiPagedResultsProps, useApiPaged } from "../../../shared/hooks/useApiPaged"
import TableLoading from "../../../shared/components/TableLoading"
import { ICustomer } from "../../../shared/models/ICustomer"
import { IAccount } from "../../../shared/models/IAccount"
import TablePaging from "../../../shared/components/TablePaging"
import Filtering from "../../../shared/components/Filtering"
import { FILTERS } from "../../../config/config"
import { IInvoice, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import { IFilter } from "../../../shared/models/IFilter"
import WorkAssignmentLocationName from "../../../shared/components/WorkAssignmentLocationName"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import TableOrdering from "../../../shared/components/TableOrdering"
import FormatDate from "../../../shared/components/format/FormatDate"
import { INVOICES_URL } from "../../../config/urls"
import { IPaging } from "../../../shared/models/IPaging"
import ViewLoading from "../../../shared/components/ViewLoading"
import AddIcon from "@mui/icons-material/Add"
import TruncateText from "../../../shared/components/TruncateText"
import RliGettingPaidAmount from "../../../shared/components/RliGettingPaidAmount"
import _ from "lodash"

const repository = new RestRepository<IInvoice>(INVOICE_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

const waFilters = [
  FILTERS.INVOICE,
  FILTERS.EXTERNAL_TRACKING,
  FILTERS.ACCOUNT,
  FILTERS.CUSTOMER,
  FILTERS.CONSULTANT_WA,
  FILTERS.LOCATIONS,
  FILTERS.WORK_ASSIGNMENT_ID,
  FILTERS.INSPECTION_CATEGORY,
  FILTERS.INSPECTION_TYPE,
  FILTERS.PROGRESS,
  FILTERS.PROGRESS_NOT_SET,
  FILTERS.REPORT_SUBMITTED_BY_CONSULTANT,
  FILTERS.REPORT_SENT_TO_CLIENT,
  FILTERS.CONSULTANT_ASSIGNED,
  FILTERS.SPECIALTY_WA,
  FILTERS.ISSUE_DATE,
  FILTERS.DUE_DATE,
  FILTERS.VISIT_DATE,
  FILTERS.CONSULTANT_DUE_DATE,
  FILTERS.ARCHIVED,
] as IFilter[]

interface IProps {
  invoice: IInvoice
  onChange: () => void
}

const columnSize = 9

/**
 * This component assists in adding work assignments to an invoice.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the add work assignment's dialog.
 */
const AddWorkAssignment: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { invoice, onChange } = props
  const propsWa: IUseApiPagedResultsProps<IWorkAssignment> = {
    apiFunction: waRepository.findAll,
    pathname: INVOICES_URL + "_add_wa",
  }
  const {
    data,
    count,
    paging,
    error,
    loading,
    handleLimit,
    handlePaging,
    handleOrdering,
    handleFilter,
    call: refreshResults,
  } = useApiPaged<IWorkAssignment>(propsWa)

  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [waIds, setWaIds] = useState<number[]>([])

  const handleOpen = useCallback(() => {
    refreshResults()
    setOpen(true)
  }, [refreshResults])

  const handleClose = useCallback(() => {
    setOpen(false)
    setWaIds([])
  }, [])

  const handleAddSelected = useCallback(
    (wa: IWorkAssignment) => () => {
      setWaIds(ids => {
        if (ids.includes(wa.id)) {
          return ids.filter(id => id !== wa.id)
        }
        return [...ids, wa.id]
      })
    },
    []
  )

  const handleSelectAll = useCallback(() => {
    if (data !== undefined) {
      const newIds = data.results.filter(wa => wa.invoice === null).map(wa => wa.id)
      setWaIds(ids => {
        return _.uniq([...ids, ...newIds])
      })
    }
  }, [data])

  const handleClearAll = useCallback(() => {
    if (data !== undefined) {
      setWaIds([])
    }
  }, [data])

  const handleAdd = useCallback(async () => {
    try {
      setSaving(true)
      const paging: IPaging = {
        filters: [
          {
            field: "wa_ids",
            value: waIds.join(","),
          },
        ],
      }
      await repository.action(invoice.id, "add_work_assignment", paging)
      setWaIds([])
      onChange()
      refreshResults()
      handleClose()
    } catch (e) {}
    setSaving(false)
  }, [invoice, refreshResults, waIds])

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />}>
        WA
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth fullScreen>
        <DialogTitle sx={{ pb: 0 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>Add Work Assignments to Invoice</Grid>
            <Grid item xs>
              <Filtering availableFilters={waFilters} filters={paging?.filters} onFilter={handleFilter} disabled={loading} />
            </Grid>
            <Grid item>
              <Button onClick={handleClearAll}>Clear All</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleSelectAll}>Select All</Button>
            </Grid>
          </Grid>
          <ViewLoading loading={saving} message="Saving changes." />
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <ErrorMessage error={error} />
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="id" title="ID" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="customer__name" title="Customer" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="account__name" title="Account" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="location__address" title="Location" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={paging?.ordering} field="invoice__number" title="Invoice" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering
                      ordering={paging?.ordering}
                      field="external_tracking"
                      title="External Tracking"
                      onOrdering={handleOrdering}
                    />
                  </TableCell>
                  <TableCell>
                    <TableOrdering
                      ordering={paging?.ordering}
                      field="progress_report_sent_account"
                      title={
                        <>
                          Report Sent
                          <br />
                          to Client
                        </>
                      }
                      onOrdering={handleOrdering}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <TableOrdering
                      ordering={paging?.ordering}
                      field="rli_getting_paid_amount"
                      title="RLI Getting Paid"
                      onOrdering={handleOrdering}
                    />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableLoading loading={loading} columns={columnSize} rows={paging?.limit} />
                {!loading &&
                  data?.results?.map(wa => (
                    <TableRow key={wa.id}>
                      <TableCell>{wa.identifier}</TableCell>
                      <TableCell>
                        <TruncateText text={(wa.customer as ICustomer)?.name} />
                      </TableCell>
                      <TableCell>
                        <TruncateText text={(wa.account as IAccount)?.name} />
                      </TableCell>
                      <TableCell>
                        <WorkAssignmentLocationName wa={wa} />
                      </TableCell>
                      <TableCell>{wa.invoice}</TableCell>
                      <TableCell>
                        <TruncateText text={wa.external_tracking} />
                      </TableCell>
                      <TableCell>
                        <FormatDate value={wa.progress_report_sent_account} />
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        <RliGettingPaidAmount workAssignment={wa} />
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          alignItems: "right",
                        }}
                      >
                        {wa.invoice === null && (
                          <Checkbox checked={waIds.includes(wa.id)} disabled={saving} onChange={handleAddSelected(wa)} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                {(data?.results.length === 0 || data === undefined) && (
                  <TableRow>
                    <TableCell colSpan={columnSize}>No work assignments found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TablePaging
                count={count}
                total={data?.count}
                page={paging?.page}
                limit={paging?.limit}
                onPaging={handlePaging}
                onLimit={handleLimit}
              />
            </Grid>
            <Grid item>
              {waIds.length > 0 ? (
                <Button onClick={handleAdd} disabled={saving}>
                  Add Selected: {waIds.length}
                </Button>
              ) : (
                <Button onClick={handleClose} disabled={saving}>
                  Close
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddWorkAssignment
