import * as React from "react"
import { useCallback, useState } from "react"
import PhoneInput from "react-phone-input-2"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { IPaging } from "../../../shared/models/IPaging"
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material"
import ViewLoading from "../../../shared/components/ViewLoading"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { MessagesRepository } from "../../../shared/repositories/MessagesRepository"

const messagesRepository = new MessagesRepository()

/**
 * This component will test the sending of email and text messages.
 *
 * @returns {React.FunctionComponent} the component.
 */
const MessageTests: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)

  const [phone, setPhone] = useState<string>("")
  const [email, setEmail] = useState<string>("")

  const handleText = useCallback(
    (send: string, value: string) => async () => {
      setErrorMessage(null)
      setLoading(true)
      try {
        const paging: IPaging = {
          filters: [
            {
              field: "to",
              value,
            },
          ],
        }
        await messagesRepository.send<any>(send, paging)
      } catch (reason: any) {
        if (reason.response !== undefined) {
          setErrorMessage(reason.response)
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    },
    []
  )

  return (
    <Card variant="outlined" sx={{ mt: 2 }}>
      <CardContent>
        <Grid container alignItems="baseline">
          <Grid item xs>
            <Typography variant="h5">Message Tests</Typography>
            For testing purposes only.
          </Grid>
          <Grid item>
            <ViewLoading loading={loading} />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <ErrorMessage error={errorMessage} />
        </Box>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{
            mt: 1,
            mb: 1,
          }}
        >
          <Grid item xs>
            <PhoneInput
              country="us"
              containerStyle={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              onChange={value => setPhone(value)}
              value={phone}
            />
          </Grid>
          <Grid item>
            <Button disabled={loading || phone === ""} onClick={handleText("text/test", phone)}>
              Send Text
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{
            mt: 1,
            mb: 1,
          }}
        >
          <Grid item xs>
            <TextField label="Email" fullWidth onChange={e => setEmail(e.target.value)} value={email} />
          </Grid>
          <Grid item>
            <Button disabled={loading || email === ""} onClick={handleText("email/test", email)}>
              Send Email
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MessageTests
