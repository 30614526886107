export const WORK_ASSIGNMENT_MESSAGE_ENDPOINT = "work_assignment_messages"

export interface IMessage {
  id: number

  to_name: string
  to_phone: string
  to_email: string

  subject: string
  content: string

  created: string
  updated: string
}
