import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Alert,
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"

import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import { EXPENSE_REPORTS_EDIT_URL, WORK_ASSIGNMENT_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../shared/models/IExpenseReport"
import { IWorkAssignment } from "../../shared/models/IWorkAssignment"
import { ICustomer } from "../../shared/models/ICustomer"
import { IAccount } from "../../shared/models/IAccount"
import FormatNumber from "../../shared/components/format/FormatNumber"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { navigate } from "gatsby"
import { EXPENSE_REPORT_LINE_ITEMS_ENDPOINT, IExpenseReportLineItem } from "../../shared/models/IExpenseReportLineItem"
import WorkAssignmentProgress from "../../shared/components/WorkAssignmentProgress"
import WorkAssignmentLocationName from "../../shared/components/WorkAssignmentLocationName"
import { IListItem } from "../../shared/models/component/IListItem"
import { IPaging } from "../../shared/models/IPaging"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"
import TruncateText from "../../shared/components/TruncateText"
import { IWorkAssignmentConsultant } from "../../shared/models/IWorkAssignmentConsultant"
import EditLineItem from "./components/EditLineItem"
import EditTotal from "./components/EditTotal"
import AddLineItem from "./components/AddLineItem"
import AddWorkAssignment from "./components/AddWorkAssignment"
import FormatDate from "../../shared/components/format/FormatDate"
import SignOffButton from "./components/SignOffButton"
import ConsultantRate from "../../shared/components/ConsultantRate"
import GenerateExpenseReportDocument from "./components/GenerateExpenseReportDocument"
import { Link as BrowserLink } from "@reach/router"
import EditIcon from "@mui/icons-material/Edit"
import AlertDialog from "../../shared/components/AlertDialog"
import { CONNECTION_ERROR, IConnectionError } from "../../shared/models/IConnectionError"
import AddAdminItem, { IExpenseReportAdminItemAdd } from "./components/AddAdminItem"
import { EXPENSE_REPORT_ADMIN_ITEMS_ENDPOINT, IExpenseReportAdminItem } from "../../shared/models/IExpenseReportAdminItem"
import EditAdminItem from "./components/EditAdminItem"
import AddTechReview from "./components/AddTechReview"
import AddAdminReview from "./components/AddAdminReview"
import PayPeriodDisplay from "./components/PayPeriodDisplay"
import AdpPayPeriodDisplay from "./components/AdpPayPeriodDisplay"

const expenseReportLineItemRepository = new RestRepository<IExpenseReportLineItem>(EXPENSE_REPORT_LINE_ITEMS_ENDPOINT)
const expenseReportAdminItemRepository = new RestRepository<IExpenseReportAdminItemAdd>(EXPENSE_REPORT_ADMIN_ITEMS_ENDPOINT)
const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)

/**
 * View a selected expense report.
 *
 * @returns {React.FC} the component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, error, loading, call: refreshExpenseReport } = useApiRead<IExpenseReport | IListItem>({ apiFunction: repository.read })
  const [expenseReport, setExpenseReport] = useState<IExpenseReport | null>(null)
  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleSelected = useCallback(async (workAssignment: IWorkAssignment) => {
    await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${workAssignment.id}`)
  }, [])

  const handleRemove = useCallback(
    (wac: IWorkAssignmentConsultant) => async () => {
      setSavingError(null)
      if (expenseReport !== null) {
        setSaving(true)
        const paging: IPaging = {
          filters: [
            {
              field: "wac_id",
              value: wac.id,
            },
          ],
        }
        try {
          await repository.action(expenseReport.id, "remove_work_assignment", paging)
          refreshExpenseReport()
        } catch (reason: any) {
          if (reason?.response !== undefined) {
            setSavingError(reason.response)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
        setSaving(false)
      }
    },
    [expenseReport, repository]
  )

  const handleRemoveReview = useCallback(
    (workAssignment: IWorkAssignment, type: string, trNumber: number = 1) =>
      async () => {
        setSavingError(null)
        if (expenseReport !== null) {
          setSaving(true)
          const paging: IPaging = {
            filters: [
              {
                field: "wa_id",
                value: workAssignment.id,
              },
              {
                field: "number",
                value: trNumber,
              },
            ],
          }
          try {
            await repository.action(expenseReport.id, `remove_${type}_reviews`, paging)
            refreshExpenseReport()
          } catch (reason: any) {
            if (reason?.response !== undefined) {
              setSavingError(reason.response)
            } else {
              setSavingError(CONNECTION_ERROR)
            }
          }
          setSaving(false)
        }
      },
    [expenseReport, repository]
  )

  const handleRemoveLineItem = useCallback(
    (lineItem: IExpenseReportLineItem) => async () => {
      setSaving(true)
      setSavingError(null)
      try {
        await expenseReportLineItemRepository.delete(lineItem.id)
        refreshExpenseReport()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    },
    [expenseReport]
  )

  const handleRemoveAdminItem = useCallback(
    (adminItem: IExpenseReportAdminItem) => async () => {
      setSaving(true)
      setSavingError(null)
      try {
        await expenseReportAdminItemRepository.delete(adminItem.id)
        refreshExpenseReport()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    },
    [expenseReport]
  )

  const handleWorkAssignmentChange = useCallback((_wac: IWorkAssignment) => {
    refreshExpenseReport()
  }, [])

  const handleNotesChange = useCallback((_expenseReport: IExpenseReport) => {
    refreshExpenseReport()
  }, [])

  const handleExpenseReportChange = useCallback((expenseReport1: IExpenseReport) => {
    setExpenseReport(expenseReport1)
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      setExpenseReport(data as IExpenseReport)
    }
  }, [data])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading || expenseReport === undefined || saving} />
        <ErrorMessage error={error} />
        <ErrorMessage error={savingError} />
        {expenseReport !== undefined && expenseReport !== null && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="start">
                <Grid item>
                  <Badge badgeContent={expenseReport.consultant.expense_report_payment_type} color="info">
                    <Typography variant="h3" component="h2" onClick={refreshExpenseReport} sx={{ cursor: "pointer" }}>
                      {expenseReport.consultant.name}
                    </Typography>
                  </Badge>
                  <Grid container spacing={2}>
                    {expenseReport.consultant.legal_entity_name !== null && expenseReport.consultant.legal_entity_name !== "" && (
                      <Grid item xs>
                        <Typography variant="subtitle1">{expenseReport.consultant.legal_entity_name}</Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant="subtitle1">
                        {expenseReport.adp_pay_period !== null ? (
                          <>
                            <Chip label="adp" color="info" sx={{ mr: 2 }} />
                            <AdpPayPeriodDisplay adpPayPeriod={expenseReport.adp_pay_period} />
                          </>
                        ) : (
                          <PayPeriodDisplay payPeriod={expenseReport.pay_period} />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <ViewLoading loading={loading} message="Updating..." />
                </Grid>
                <Grid item>
                  <Toolbar disableGutters>
                    <GenerateExpenseReportDocument expenseReport={expenseReport} onChange={refreshExpenseReport} />
                    <ChangeHtmlField
                      modelId={expenseReport.id}
                      item={expenseReport.notes}
                      field="notes"
                      title="Notes"
                      buttonTitle="Edit Notes"
                      onChange={handleNotesChange}
                      value={expenseReport.notes}
                      repository={repository}
                      size="medium"
                    />
                    <Button component={BrowserLink} to={`${EXPENSE_REPORTS_EDIT_URL}/${expenseReport.id}`} startIcon={<EditIcon />}>
                      Expense Report
                    </Button>
                  </Toolbar>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs>
                  <Toolbar disableGutters>
                    <SignOffButton
                      title="Report Ready"
                      expenseReportId={expenseReport.id}
                      action={expenseReport.report_ready !== null ? "report_ready_remove" : "report_ready"}
                      value={expenseReport.report_ready}
                      onChange={handleExpenseReportChange}
                    />
                    <SignOffButton
                      title="Received"
                      expenseReportId={expenseReport.id}
                      action={expenseReport.received !== null ? "received_remove" : "received"}
                      value={expenseReport.received}
                      label={
                        expenseReport.received_by?.name !== undefined ? `Report generated by ${expenseReport.received_by.name}.` : undefined
                      }
                      onChange={handleExpenseReportChange}
                    />
                    <SignOffButton
                      title="Paid"
                      expenseReportId={expenseReport.id}
                      action={expenseReport.paid !== null ? "paid_remove" : "paid"}
                      value={expenseReport.paid}
                      label={expenseReport.paid_by?.name !== undefined ? `Email sent by ${expenseReport.paid_by.name}.` : undefined}
                      onChange={handleExpenseReportChange}
                    />
                    <SignOffButton
                      title="Confirmed"
                      expenseReportId={expenseReport.id}
                      action={expenseReport.confirmed !== null ? "confirmed_remove" : "confirmed"}
                      value={expenseReport.confirmed}
                      label={expenseReport.confirmed_by?.name}
                      onChange={handleExpenseReportChange}
                    />
                  </Toolbar>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Work Assignments
                  </Typography>
                </Grid>
                <Grid item>
                  <AddWorkAssignment expenseReport={expenseReport} consultant={expenseReport.consultant} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {expenseReport.work_assignment_consultants.length > 0 ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>ID</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>Report Submitted <br/>by Consultant</TableCell>
                        <TableCell>Progress (Next Step)</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport.work_assignment_consultants.map((wac, index) => {
                        const workAssignment = wac.work_assignment as IWorkAssignment
                        return (
                          <TableRowSelect key={wac.id} item={workAssignment} onSelected={handleSelected}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.account as IAccount)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentLocationName wa={workAssignment} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <FormatDate value={workAssignment.progress_consultant_reports_submitted} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentProgress workAssignment={workAssignment} onChange={handleWorkAssignmentChange} />
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              <ConsultantRate wac={wac} showDetails />
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                textAlign: "right",
                              }}
                            >
                              <AlertDialog
                                buttonText="Delete"
                                buttonIcon={DeleteIcon}
                                message={`Are you sure you want to remove ${workAssignment.identifier} from the expense report?`}
                                onYes={handleRemove(wac)}
                              />
                            </TableCell>
                          </TableRowSelect>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert color="info">No work assignments have been added to this expense report.</Alert>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Line Items
                  </Typography>
                </Grid>
                <Grid item>
                  <AddLineItem expenseReport={expenseReport} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {expenseReport.line_items.length > 0 ? (
                <TableContainer>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell sx={{ width: "80%" }}>Description</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport?.line_items.map((lineItem, index) => (
                        <TableRow key={lineItem.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{lineItem.description}</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            <FormatNumber value={lineItem.amount} twoDecimalPlaces />
                          </TableCell>

                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              textAlign: "right",
                            }}
                          >
                            <EditLineItem lineItem={lineItem} onChange={refreshExpenseReport} />
                            <AlertDialog
                              buttonText="Delete"
                              buttonIcon={DeleteIcon}
                              message={
                                <>
                                  <Box sx={{ pb: 2 }}>Are you sure you want to remove the line item from the expense report?</Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs>
                                      {lineItem.description}
                                    </Grid>
                                    <Grid item>
                                      <FormatNumber value={lineItem.amount} twoDecimalPlaces />
                                    </Grid>
                                  </Grid>
                                </>
                              }
                              onYes={handleRemoveLineItem(lineItem)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert color="info">No line items have been added to this expense report.</Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs />
                <Grid item>
                  <Typography variant="h5">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    {expenseReport.total_amount === 0 ? "$0" : <FormatNumber value={expenseReport.total_amount} twoDecimalPlaces />}
                  </Typography>
                </Grid>
                <Grid item>
                  <EditTotal expenseReport={expenseReport} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Admin Review
                  </Typography>
                </Grid>
                <Grid item>
                  <AddAdminReview expenseReport={expenseReport} consultant={expenseReport.consultant} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {expenseReport.admin_review_work_assignments.length > 0 ? (
                <TableContainer>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>ID</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Progress (Next Step)</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport.admin_review_work_assignments?.map((workAssignment, index) => {
                        return (
                          <TableRowSelect key={workAssignment.id} item={workAssignment} onSelected={handleSelected}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.account as IAccount)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentLocationName wa={workAssignment} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentProgress workAssignment={workAssignment} onChange={handleWorkAssignmentChange} />
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              <FormatNumber value={workAssignment.admin_review_hours} prefixUnits={false} twoDecimalPlaces />
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                textAlign: "right",
                              }}
                            >
                              <AlertDialog
                                buttonText="Delete"
                                buttonIcon={DeleteIcon}
                                message={`Are you sure you want to remove ${workAssignment.identifier} from the expense report?`}
                                onYes={handleRemoveReview(workAssignment, "admin")}
                              />
                            </TableCell>
                          </TableRowSelect>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert color="info">No admin reviews have been added to this expense report.</Alert>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Tech Review 1
                  </Typography>
                </Grid>
                <Grid item>
                  <AddTechReview expenseReport={expenseReport} consultant={expenseReport.consultant} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {expenseReport.tech_review_work_assignments.length > 0 ? (
                <TableContainer>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>ID</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Progress (Next Step)</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport.tech_review_work_assignments?.map((workAssignment, index) => {
                        return (
                          <TableRowSelect key={workAssignment.id} item={workAssignment} onSelected={handleSelected}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.account as IAccount)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentLocationName wa={workAssignment} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentProgress workAssignment={workAssignment} onChange={handleWorkAssignmentChange} />
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              <FormatNumber value={workAssignment.tech_review_hours} prefixUnits={false} twoDecimalPlaces />
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                textAlign: "right",
                              }}
                            >
                              <AlertDialog
                                buttonText="Delete"
                                buttonIcon={DeleteIcon}
                                message={`Are you sure you want to remove ${workAssignment.identifier} from the expense report?`}
                                onYes={handleRemoveReview(workAssignment, "tech")}
                              />
                            </TableCell>
                          </TableRowSelect>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert color="info">No tech reviews have been added to this expense report.</Alert>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Tech Review 2
                  </Typography>
                </Grid>
                <Grid item>
                  <AddTechReview expenseReport={expenseReport} consultant={expenseReport.consultant} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {expenseReport.tech_review_2_work_assignments.length > 0 ? (
                <TableContainer>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>ID</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Progress (Next Step)</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport.tech_review_2_work_assignments?.map((workAssignment, index) => {
                        return (
                          <TableRowSelect key={workAssignment.id} item={workAssignment} onSelected={handleSelected}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <TruncateText text={(workAssignment.account as IAccount)?.name} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentLocationName wa={workAssignment} />
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              <WorkAssignmentProgress workAssignment={workAssignment} onChange={handleWorkAssignmentChange} />
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              <FormatNumber value={workAssignment.tech_review_2_hours} prefixUnits={false} twoDecimalPlaces />
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                textAlign: "right",
                              }}
                            >
                              <AlertDialog
                                buttonText="Delete"
                                buttonIcon={DeleteIcon}
                                message={`Are you sure you want to remove ${workAssignment.identifier} from the expense report?`}
                                onYes={handleRemoveReview(workAssignment, "tech", 2)}
                              />
                            </TableCell>
                          </TableRowSelect>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert color="info">No second tech reviews have been added to this expense report.</Alert>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="end">
                <Grid item xs>
                  <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                    Admin Hours
                  </Typography>
                </Grid>
                <Grid item>
                  <AddAdminItem expenseReport={expenseReport} onChange={refreshExpenseReport} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {expenseReport.admin_items.length > 0 ? (
                <TableContainer>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Customer</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell sx={{ width: "30%" }}>Description</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport.admin_items.map((adminItem, index) => (
                        <TableRow key={adminItem.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={adminItem.customer?.name} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={adminItem.account?.name} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={adminItem.expense_report_category?.name} />
                          </TableCell>
                          <TableCell>
                            <FormatDate value={adminItem.on_date} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={adminItem.description} />
                          </TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            <FormatNumber value={adminItem.hours} twoDecimalPlaces prefixUnits={false} />
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              textAlign: "right",
                            }}
                          >
                            <EditAdminItem adminItem={adminItem} expenseReport={expenseReport} onChange={refreshExpenseReport} />
                            <AlertDialog
                              buttonText="Delete"
                              buttonIcon={DeleteIcon}
                              message={
                                <>
                                  <Box sx={{ pb: 2 }}>Are you sure you want to remove the admin item from the expense report?</Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs>
                                      {adminItem.description}
                                    </Grid>
                                    <Grid item>
                                      <FormatNumber value={adminItem.hours} twoDecimalPlaces prefixUnits={false} />
                                    </Grid>
                                  </Grid>
                                </>
                              }
                              onYes={handleRemoveAdminItem(adminItem)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert color="info">No admin hours have been added to this expense report.</Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs />
                <Grid item>
                  <Typography variant="h5">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    {expenseReport.total_admin_hours === 0 ? (
                      "0"
                    ) : (
                      <FormatNumber value={expenseReport.total_admin_hours} twoDecimalPlaces prefixUnits={false} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
