import { ISpecialty } from "./ISpecialty"
import { blue, green, orange, red, yellow } from "@mui/material/colors"
import { IConsultant } from "./main/IConsultant"

export const CONSULTANT_SPECIALTY_ENDPOINT = "consultant_specialties"

export interface IConsultantSpecialty {
  id: number
  score: number
  note: string
  specialty: ISpecialty | number
  consultant: IConsultant | number
}

export interface IConsultantSpecialtyCreate {
  score: number
  note: string
  specialty: number
  consultant: number
}

export const SpecialtyScoreColor = [green[400], blue[400], yellow[400], orange[400], red[400]]
