import * as React from "react"
import { useCallback, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../models/IWorkAssignment"
import { RestRepository } from "../repositories/RestRepository"
import { LoadingButton } from "@mui/lab"
import IUser from "../models/IUser"
import { IPaging } from "../models/IPaging"

const repository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  workAssignment: IWorkAssignment
  hoursApprovedBy: IUser | null
  action: string
  paging?: IPaging
  onChange: (workAssignment: IWorkAssignment) => void
}

/**
 * Sign off a progress step with this component.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the sign off component.
 */
const ReviewApproveHours: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { workAssignment, hoursApprovedBy, action, paging, onChange } = props
  const [loading, setLoading] = useState(false)

  const handleApproveToggle = useCallback(async () => {
    setLoading(true)
    try {
      const wa1 = await repository.action(workAssignment.id, action, paging)
      onChange(wa1)
    } catch (reason: any) {}
    setLoading(false)
  }, [workAssignment, action, paging])

  return (
    <>
      {hoursApprovedBy === null ? (
        <LoadingButton size="small" color="warning" onClick={handleApproveToggle} loading={loading}>
          Approve Hours
        </LoadingButton>
      ) : (
        <LoadingButton size="small" color="success" onClick={handleApproveToggle} loading={loading}>
          Hours Approved!
        </LoadingButton>
      )}
    </>
  )
}

export default ReviewApproveHours
