import { IListItem } from "../component/IListItem"

export const FILE_TAG_ENDPOINT = "file_tags"

export interface IFileTag extends IListItem {
  id: number
  name: string
  color: string
  notes: string
}
