import * as React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../app/shared/components/PrivateRoute"
import LoginPage from "../app/pages/auth/LoginPage"
import DefaultLayout from "../app/shared/layouts/DefaultLayout"
import { Provider } from "react-redux"
import store from "../app/store"

import MapView from "../app/pages/map/IndexPage"
import Dashboard from "../app/pages/dashboard/IndexPage"

import WorkAssignmentsIndex from "../app/pages/work_assignments/IndexPage"
import WorkAssignmentsView from "../app/pages/work_assignments/ViewPage"
import WorkAssignmentsAdd from "../app/pages/work_assignments/AddPage"
import WorkAssignmentsEdit from "../app/pages/work_assignments/EditPage"

import InvoicesIndex from "../app/pages/invoices/IndexPage"
import InvoicesAdd from "../app/pages/invoices/AddPage"
import InvoicesView from "../app/pages/invoices/ViewPage"
import InvoicesEdit from "../app/pages/invoices/EditPage"

import ExpenseReportsIndex from "../app/pages/expense_reports/IndexPage"
import ExpenseReportsAdd from "../app/pages/expense_reports/AddPage"
import ExpenseReportsView from "../app/pages/expense_reports/ViewPage"
import ExpenseReportsEdit from "../app/pages/expense_reports/EditPage"

import CustomersIndex from "../app/pages/customers/IndexPage"
import CustomersAdd from "../app/pages/customers/AddPage"
import CustomersView from "../app/pages/customers/ViewPage"
import CustomersEdit from "../app/pages/customers/EditPage"

import ConsultantsIndex from "../app/pages/consultants/IndexPage"
import ConsultantsAdd from "../app/pages/consultants/AddPage"
import ConsultantsView from "../app/pages/consultants/ViewPage"
import ConsultantsEdit from "../app/pages/consultants/EditPage"

import ContactsIndex from "../app/pages/contacts/IndexPage"
import ContactsAdd from "../app/pages/contacts/AddPage"
import ContactsView from "../app/pages/contacts/ViewPage"
import ContactsEdit from "../app/pages/contacts/EditPage"

import AccountsAdd from "../app/pages/accounts/AddPage"
import AccountsView from "../app/pages/accounts/ViewPage"
import AccountsEdit from "../app/pages/accounts/EditPage"

import LocationsAdd from "../app/pages/locations/AddPage"
import LocationsEdit from "../app/pages/locations/EditPage"
import LocationsView from "../app/pages/locations/ViewPage"

import DailyStatusReport from "../app/pages/reports/DailyStatusReport"
import InspectionsDueReport from "../app/pages/reports/InspectionsDueReport"
import LateTodayTomorrowReport from "../app/pages/reports/LateTodayTomorrowReport"
import PastConsultantDueDateReport from "../app/pages/reports/PastConsultantDueDateReport"
import SevenDaysWithoutAssignmentReport from "../app/pages/reports/SevenDaysWithoutAssignmentReport"
import DueToClientInSevenDaysOrLessReport from "../app/pages/reports/DueToClientInSevenDaysOrLessReport"
import PastDueToClientReport from "../app/pages/reports/PastDueToClientReport"
import TechReviewHoursApprovalReport from "../app/pages/reports/TechReviewHoursApprovalReport"
import AdminReviewHoursApprovalReport from "../app/pages/reports/AdminReviewHoursApprovalReport"

import SettingsIndex from "../app/pages/settings/IndexPage"
import { graphql } from "gatsby"
import NotFound from "./404"
import {
  ACCOUNTS_ADD_URL,
  ACCOUNTS_EDIT_URL,
  ACCOUNTS_VIEW_URL,
  CONSULTANTS_ADD_URL,
  CONSULTANTS_EDIT_URL,
  CONSULTANTS_URL,
  CONSULTANTS_VIEW_URL,
  CONTACTS_ADD_URL,
  CONTACTS_EDIT_URL,
  CONTACTS_URL,
  CONTACTS_VIEW_URL,
  CUSTOMERS_ADD_URL,
  CUSTOMERS_EDIT_URL,
  CUSTOMERS_URL,
  CUSTOMERS_VIEW_URL,
  DASHBOARD_URL,
  EXPENSE_REPORTS_ADD_URL,
  EXPENSE_REPORTS_EDIT_URL,
  EXPENSE_REPORTS_URL,
  EXPENSE_REPORTS_VIEW_URL,
  INVOICES_ADD_URL,
  INVOICES_EDIT_URL,
  INVOICES_URL,
  INVOICES_VIEW_URL,
  LOCATIONS_ADD_URL,
  LOCATIONS_EDIT_URL,
  LOCATIONS_VIEW_URL,
  LOGIN_URL,
  MAP_URL,
  REPORTS_URL,
  SETTINGS_URL,
  WORK_ASSIGNMENT_ADD_URL,
  WORK_ASSIGNMENT_EDIT_URL,
  WORK_ASSIGNMENT_VIEW_URL,
  WORK_ASSIGNMENTS_URL,
} from "../app/config/urls"
import LoginLayout from "../app/shared/layouts/LoginLayout"
import SubmittedByConsultantReport from "../app/pages/reports/SubmittedByConsultantReport"
import AdminReviewTenDaysOrMoreReport from "../app/pages/reports/AdminReviewTenDaysOrMoreReport"
import TechReviewTenDaysOrMoreReport from "../app/pages/reports/TechReviewTenDaysOrMoreReport"
import {
  ACCESS_AUDIT_GROUP,
  ACCESS_EXPENSE_REPORT_GROUP,
  ACCESS_INVOICES_GROUP,
  ACCESS_WORK_ASSIGNMENT_GROUP,
} from "../app/config/permissions"
import { GoogleOAuthProvider } from "@react-oauth/google"

const GATSBY_GOOGLE_AUTH_TOKEN: string = process.env.GATSBY_GOOGLE_AUTH_TOKEN === undefined ? "" : process.env.GATSBY_GOOGLE_AUTH_TOKEN

interface AppProps {
  data: any
}

/**
 * Main app component. Handles routing.
 *
 * @param {AppProps} props see AppProps for details.
 * @returns {React.FunctionComponent<AppProps>} the app component.
 */
const App: React.FunctionComponent<AppProps> = (props: AppProps) => {
  const { data } = props

  return (
    <>
      <Router>
        <LoginLayout path="/" title="Login" component={LoginPage} />
        <LoginLayout path={LOGIN_URL} title="Login" component={LoginPage} />
        <PrivateRoute
          path={DASHBOARD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Dashboard" component={Dashboard} />}
        />
        <PrivateRoute
          path={MAP_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Map" component={MapView} />}
        />

        <PrivateRoute
          path={WORK_ASSIGNMENTS_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Work Assignments" component={WorkAssignmentsIndex} />}
        />
        <PrivateRoute
          path={`${WORK_ASSIGNMENT_VIEW_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Work Assignment" component={WorkAssignmentsView} />}
        />
        <PrivateRoute
          path={`${WORK_ASSIGNMENT_VIEW_URL}/:id/:tabIndex`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Work Assignment" component={WorkAssignmentsView} />}
        />
        <PrivateRoute
          path={WORK_ASSIGNMENT_ADD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Add Work Assignment" component={WorkAssignmentsAdd} />}
        />
        <PrivateRoute
          path={`${WORK_ASSIGNMENT_EDIT_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Edit Work Assignment" component={WorkAssignmentsEdit} />}
        />

        <PrivateRoute
          path={INVOICES_URL}
          groupNames={[ACCESS_INVOICES_GROUP, ACCESS_AUDIT_GROUP]}
          component={() => <DefaultLayout title="Invoices" component={InvoicesIndex} />}
        />
        <PrivateRoute
          path={INVOICES_ADD_URL}
          groupNames={[ACCESS_INVOICES_GROUP]}
          component={() => <DefaultLayout title="Add Invoice" component={InvoicesAdd} />}
        />
        <PrivateRoute
          path={`${INVOICES_VIEW_URL}/:id`}
          groupNames={[ACCESS_INVOICES_GROUP, ACCESS_AUDIT_GROUP]}
          component={() => <DefaultLayout title="Invoice" component={InvoicesView} />}
        />
        <PrivateRoute
          path={`${INVOICES_EDIT_URL}/:id`}
          groupNames={[ACCESS_INVOICES_GROUP]}
          component={() => <DefaultLayout title="Edit Invoice" component={InvoicesEdit} />}
        />

        <PrivateRoute
          path={EXPENSE_REPORTS_URL}
          groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
          component={() => <DefaultLayout title="Expense Reports" component={ExpenseReportsIndex} />}
        />
        <PrivateRoute
          path={EXPENSE_REPORTS_ADD_URL}
          groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
          component={() => <DefaultLayout title="Add Expense Report" component={ExpenseReportsAdd} />}
        />
        <PrivateRoute
          path={`${EXPENSE_REPORTS_VIEW_URL}/:id`}
          groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
          component={() => <DefaultLayout title="Expense Report" component={ExpenseReportsView} />}
        />
        <PrivateRoute
          path={`${EXPENSE_REPORTS_EDIT_URL}/:id`}
          groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
          component={() => <DefaultLayout title="Edit Expense Report" component={ExpenseReportsEdit} />}
        />

        <PrivateRoute
          path={CUSTOMERS_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Customers" component={CustomersIndex} />}
        />
        <PrivateRoute
          path={CUSTOMERS_ADD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Add Customer" component={CustomersAdd} />}
        />
        <PrivateRoute
          path={`${CUSTOMERS_VIEW_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Customer" component={CustomersView} />}
        />
        <PrivateRoute
          path={`${CUSTOMERS_EDIT_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Edit Customer" component={CustomersEdit} />}
        />

        <PrivateRoute
          path={`${CONSULTANTS_URL}`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Consultants" component={ConsultantsIndex} />}
        />
        <PrivateRoute
          path={CONSULTANTS_ADD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Add Consultant" component={ConsultantsAdd} />}
        />
        <PrivateRoute
          path={`${CONSULTANTS_VIEW_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Consultant" component={ConsultantsView} />}
        />
        <PrivateRoute
          path={`${CONSULTANTS_EDIT_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Edit Consultant" component={ConsultantsEdit} />}
        />

        <PrivateRoute
          path={`${CONTACTS_URL}`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Contacts" component={ContactsIndex} />}
        />
        <PrivateRoute
          path={CONTACTS_ADD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Add Contact" component={ContactsAdd} />}
        />
        <PrivateRoute
          path={`${CONTACTS_VIEW_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Contact" component={ContactsView} />}
        />
        <PrivateRoute
          path={`${CONTACTS_EDIT_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Edit Contact" component={ContactsEdit} />}
        />

        <PrivateRoute
          path={ACCOUNTS_ADD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Add Account" component={AccountsAdd} />}
        />
        <PrivateRoute
          path={`${ACCOUNTS_VIEW_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Account" component={AccountsView} />}
        />
        <PrivateRoute
          path={`${ACCOUNTS_EDIT_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Edit Account" component={AccountsEdit} />}
        />

        <PrivateRoute
          path={LOCATIONS_ADD_URL}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Add Location" component={LocationsAdd} />}
        />
        <PrivateRoute
          path={`${LOCATIONS_VIEW_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Location" component={LocationsView} />}
        />
        <PrivateRoute
          path={`${LOCATIONS_EDIT_URL}/:id`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Edit Location" component={LocationsEdit} />}
        />

        <PrivateRoute
          path={`${REPORTS_URL}/daily`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Daily Status" component={DailyStatusReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/past_consultant_due_date`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Past Consultant Due Date" component={PastConsultantDueDateReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/inspections_due`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Inspections Due" component={InspectionsDueReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/seven_days_without_assignment`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="7 Days Without Assignment" component={SevenDaysWithoutAssignmentReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/late_today_tomorrow`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Consultant Report Late, Due Today, or Due Tomorrow" component={LateTodayTomorrowReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/due_to_client_in_7_days_or_less`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Due To Client In 7 Days or Less" component={DueToClientInSevenDaysOrLessReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/past_due_to_client`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Past Due To Client" component={PastDueToClientReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/submitted_by_consultant`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Submitted By Consultant" component={SubmittedByConsultantReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/admin_review_10_days_or_more`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Admin Review 10 Days or More" component={AdminReviewTenDaysOrMoreReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/tech_review_10_days_or_more`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Tech Review 10 Days or More" component={TechReviewTenDaysOrMoreReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/admin_review_hours_approval`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Admin Review Hours Approval" component={AdminReviewHoursApprovalReport} />}
        />
        <PrivateRoute
          path={`${REPORTS_URL}/tech_review_hours_approval`}
          groupNames={[ACCESS_WORK_ASSIGNMENT_GROUP]}
          component={() => <DefaultLayout title="Tech Review Hours Approval" component={TechReviewHoursApprovalReport} />}
        />
        <PrivateRoute path={SETTINGS_URL} component={() => <DefaultLayout title="Settings" component={SettingsIndex} />} />
        <NotFound default data={data} />
      </Router>
    </>
  )
}

/**
 * Wraps the app with a redux store.
 *
 * @param {AppProps} props see AppProps for details.
 * @returns {React.FC} the app wrapper component.
 */
const AppWrapper: React.FC<AppProps> = (props: AppProps): React.ReactElement => {
  return (
    <>
      <GoogleOAuthProvider clientId={GATSBY_GOOGLE_AUTH_TOKEN}>
        <Provider store={store.store}>
          <App {...props} />
        </Provider>
      </GoogleOAuthProvider>
    </>
  )
}

export default AppWrapper

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        version
      }
    }
  }
`
