import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { type IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { CONSULTANTS_ENDPOINT, getConsultantPhone, type IConsultant } from "../../shared/models/main/IConsultant"
import TablePaging from "../../shared/components/TablePaging"
import { Link } from "@reach/router"
import { CONSULTANTS_ADD_URL, CONSULTANTS_VIEW_URL } from "../../config/urls"
import { navigate } from "gatsby"
import { styles } from "../../shared/styling/general"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { RestRepository } from "../../shared/repositories/RestRepository"
import TableOrdering from "../../shared/components/TableOrdering"
import { type IFilter } from "../../shared/models/IFilter"
import Filtering from "../../shared/components/Filtering"
import FormatDate from "../../shared/components/format/FormatDate"
import Goto from "../../shared/components/Goto"
import { type IColumn } from "../../shared/models/component/IColumn"
import TableColumnShow, { useTableColumnShow } from "../../shared/components/TableColumnShow"
import TableCellShow from "../../shared/components/TableCellShow"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { CONSULTANT_FILTERS, FILTERS } from "../../config/config"
import useContentHeight from "../../shared/hooks/useContentHeight"
import AddIcon from "@mui/icons-material/Add"
import { type IListItem } from "../../shared/models/component/IListItem"
import { type IPagedResults } from "../../shared/models/IPagedResults"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"
import useFocus from "../../shared/hooks/useFocus"
import ConsultantRating from "../../shared/components/consultants/ConsultantRating"
import TruncateText from "../../shared/components/TruncateText"
import FilterBookmarksDialog from "../../shared/components/FilterBookmarksDialog"
import FilterSharing from "../../shared/components/FilterSharing"
import TableLoadingFull from "../../shared/components/TableLoadingFull"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"
import PrivateComponent from "../../shared/components/PrivateComponent"
import { ACCESS_CONSULTANT_EXCEL_GROUP } from "../../config/permissions"
import ExcelDownloadButton from "../../shared/components/ExcelDownloadButton"

const repository = new RestRepository<IConsultant | IListItem>(CONSULTANTS_ENDPOINT)

const columns = [
  {
    field: "identifier",
    title: "ID",
  },
  {
    field: "first_name",
    title: "First Name",
  },
  {
    field: "last_name",
    title: "Last Name",
  },
  {
    field: "legal_entity_name",
    title: "Legal Entity Name",
  },
  {
    field: "rating",
    title: "Rating",
  },
  {
    field: "active",
    title: "Active",
  },
  {
    field: "last_login",
    title: "Last Login",
  },
  {
    field: "notes",
    title: "Notes",
  },
  {
    field: "highly_protected_risk",
    title: "HPR",
  },
  {
    field: "property_lite",
    title: "PL",
  },
  {
    field: "highly_protected_risk_lite",
    title: "HPR Lite",
  },
  {
    field: "boiler_and_machinery",
    title: "B & M",
  },
  {
    field: "casualty",
    title: "Casualty",
  },
  {
    field: "tech_reviewer",
    title: "Tech Reviewer",
  },
  {
    field: "admin_reviewer",
    title: "Admin Reviewer",
  },
  {
    field: "plan_reviewer",
    title: "Plan Reviewer",
  },
  {
    field: "phone",
    title: "Phone",
  },
  {
    field: "email",
    title: "Email",
  },
  {
    field: "address",
    title: "Address",
  },
  {
    field: "city",
    title: "City",
  },
  {
    field: "state_region",
    title: "State",
  },
  {
    field: "country",
    title: "Country",
  },
  {
    field: "specialty_count",
    title: "# Specialties",
  },
  {
    field: "active_work_assignment_count",
    title: "# Active WAs",
  },
  {
    field: "languages",
    title: "Languages",
  },
  {
    field: "contract_date",
    title: "Contract Date",
  },
] as IColumn[]

const GOTO_FILTER: IFilter[] = [
  {
    field: "show_all",
    value: "true",
  },
]

const AVAILABLE_FILTERS = [FILTERS.CONSULTANT_ACTIVE, ...CONSULTANT_FILTERS]

/**
 * Renders a table of the consultants that is filterable.
 * @returns {React.FC} the component.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { focused, handleFocus, handleBlur } = useFocus()
  const { selectedColumns } = useTableColumnShow({ columns })
  const height = useContentHeight(-14)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const [results, setResults] = useState<IPagedResults<IConsultant> | null>(null)

  const props: IUseApiPagedResultsProps<IConsultant | IListItem> = { apiFunction: repository.findAll }
  const { data, count, paging, error, loading, handlePaging, handleOrdering, handleFilter, handleLimit } = useApiPaged<
    IConsultant | IListItem
  >(props)

  const handleSelected = useCallback(async (consultant: IConsultant) => {
    await navigate(`${CONSULTANTS_VIEW_URL}/${consultant.id}`)
  }, [])

  const handleConsultantChange = useCallback((consultant: IConsultant) => {
    setResults((consultants: IPagedResults<IConsultant> | null) => {
      if (consultants !== null) {
        return {
          ...consultants,
          results: consultants.results.map(consultant1 => {
            return consultant1.id === consultant.id ? consultant : consultant1
          }),
        }
      }
      return null
    })
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      setResults(data as IPagedResults<IConsultant>)
    }
  }, [data])

  return (
    <WorkflowPage noPaper={true} footer={false} margin={0}>
      <Box sx={styles.index.search}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={focused ? 6 : 3} md={focused ? 4 : 2} lg={focused ? 3 : 1}>
            <Goto repository={repository} url={CONSULTANTS_VIEW_URL} onFocus={handleFocus} onBlur={handleBlur} filters={GOTO_FILTER} />
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Filtering availableFilters={AVAILABLE_FILTERS} filters={paging?.filters} onFilter={handleFilter} disabled={loading} />
              </Grid>
              <Grid item>
                <FilterBookmarksDialog bookmarkType="consultants" filters={paging?.filters} onFilter={handleFilter} />
              </Grid>
              <Grid item>
                <FilterSharing onFilter={handleFilter} filters={paging?.filters} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button component={Link} to={CONSULTANTS_ADD_URL} startIcon={<AddIcon />}>
              Consultant
            </Button>
          </Grid>
          <Grid item>
            <PrivateComponent
              component={
                <ExcelDownloadButton
                  endpoint={CONSULTANTS_ENDPOINT}
                  filename="consultants.xlsx"
                  limit={data?.count}
                  paging={paging}
                />
              }
              groupNames={[ACCESS_CONSULTANT_EXCEL_GROUP]}
            />
          </Grid>
          <Grid item>
            <TableColumnShow columns={columns} />
          </Grid>
          <Grid item>
            <HelpDocsDrawer showButton showButtonLabel={false} pageId={235} />
          </Grid>
        </Grid>
      </Box>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box>
        <TableContainer sx={{ height: isSmall ? null : height }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCellShow columns={selectedColumns} field="identifier">
                  <TableOrdering ordering={paging?.ordering} field="id" title="ID" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="first_name" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering ordering={paging?.ordering} field="first_name" title="First Name" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="last_name" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering ordering={paging?.ordering} field="last_name" title="Last Name" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="legal_entity_name" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering
                    ordering={paging?.ordering}
                    field="legal_entity_name"
                    title="Legal Entity Name"
                    onOrdering={handleOrdering}
                  />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="rating">
                  <TableOrdering ordering={paging?.ordering} field="rating" title="Rating" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="active">
                  <TableOrdering ordering={paging?.ordering} field="active" title="Active" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="last_login" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering
                    ordering={paging?.ordering}
                    field="profile__user__last_login"
                    title="Last Login"
                    onOrdering={handleOrdering}
                  />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="notes">
                  Notes
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="highly_protected_risk">
                  <TableOrdering ordering={paging?.ordering} field="highly_protected_risk" title="HPR" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="property_lite">
                  <TableOrdering ordering={paging?.ordering} field="property_lite" title="PL" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="highly_protected_risk_lite" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering
                    ordering={paging?.ordering}
                    field="highly_protected_risk_lite"
                    title="HPR Lite"
                    onOrdering={handleOrdering}
                  />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="boiler_and_machinery" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering ordering={paging?.ordering} field="boiler_and_machinery" title="B & M" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="casualty">
                  <TableOrdering ordering={paging?.ordering} field="casualty" title="Casualty" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="tech_reviewer">
                  <TableOrdering ordering={paging?.ordering} field="tech_reviewer" title="Tech Reviewer" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="admin_reviewer">
                  <TableOrdering ordering={paging?.ordering} field="admin_reviewer" title="Admin Reviewer" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="plan_reviewer">
                  <TableOrdering ordering={paging?.ordering} field="plan_reviewer" title="Plan Reviewer" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="phone">
                  Phone
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="email">
                  <TableOrdering ordering={paging?.ordering} field="email" title="Email" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="address">
                  Address
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="city">
                  City
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="state_region">
                  <TableOrdering ordering={paging?.ordering} field="state_region" title="State" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="country">
                  <TableOrdering ordering={paging?.ordering} field="country" title="Country" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="specialty_count" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering ordering={paging?.ordering} field="specialty_count" title="# Specialties" onOrdering={handleOrdering} />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="active_work_assignment_count" sx={{ whiteSpace: "nowrap" }}>
                  <TableOrdering
                    ordering={paging?.ordering}
                    field="active_work_assignment_count"
                    title="# Active WAs"
                    onOrdering={handleOrdering}
                  />
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="languages">
                  Languages
                </TableCellShow>
                <TableCellShow columns={selectedColumns} field="contract_date" sx={{ whiteSpace: "nowrap" }}>
                  Contract Date
                </TableCellShow>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                results?.results.map(consultant => (
                  <TableRowSelect key={consultant.id} item={consultant} onSelected={handleSelected}>
                    <TableCellShow columns={selectedColumns} field="identifier" sx={{ whiteSpace: "nowrap" }}>
                      {consultant.identifier}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="first_name">
                      {consultant.first_name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="last_name">
                      {consultant.last_name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="legal_entity_name" sx={{ whiteSpace: "nowrap" }}>
                      {consultant.legal_entity_name}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="rating">
                      <ConsultantRating rating={consultant.rating} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="active">
                      {consultant.active ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="last_login" sx={{ whiteSpace: "nowrap" }}>
                      {consultant.last_login === null ? <>Not Set</> : <FormatDate value={consultant.last_login} />}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="notes" sx={{ whiteSpace: "nowrap" }}>
                      <ChangeHtmlField
                        modelId={consultant.id}
                        item={consultant}
                        field="notes"
                        title="Notes"
                        onChange={handleConsultantChange}
                        value={consultant.notes}
                        repository={repository}
                      />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="highly_protected_risk">
                      {consultant.highly_protected_risk ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="property_lite">
                      {consultant.property_lite ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="highly_protected_risk_lite">
                      {consultant.highly_protected_risk_lite ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="boiler_and_machinery">
                      {consultant.boiler_and_machinery ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="casualty">
                      {consultant.casualty ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="tech_reviewer">
                      {consultant.tech_reviewer ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="admin_reviewer">
                      {consultant.admin_reviewer ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="plan_reviewer">
                      {consultant.plan_reviewer ? "Yes" : "No"}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="phone" sx={{ whiteSpace: "nowrap" }}>
                      {getConsultantPhone(consultant)}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="email">
                      {consultant.email}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="address" sx={{ whiteSpace: "nowrap" }}>
                      {consultant.address}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="city" sx={{ whiteSpace: "nowrap" }}>
                      {consultant.city}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="state_region">
                      {consultant.state_region}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="country">
                      {consultant.country}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="specialty_count">
                      {consultant.specialty_count}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="active_work_assignment_count">
                      {consultant.active_work_assignments_count}
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="languages" sx={{ whiteSpace: "nowrap" }}>
                      <TruncateText text={consultant.languages?.map(language => language.name).join(", ")} />
                    </TableCellShow>
                    <TableCellShow columns={selectedColumns} field="contract_date">
                      <FormatDate value={consultant.contract_date} />
                    </TableCellShow>
                  </TableRowSelect>
                ))}
            </TableBody>
          </Table>
          {!loading && (data === undefined || data.results.length === 0) && (
            <Alert color="info" sx={{ m: 1 }}>
              No consultants found.
            </Alert>
          )}
          <TableLoadingFull loading={loading} rows={paging?.limit} />
        </TableContainer>
      </Box>
      <Divider />
      <TablePaging
        count={count}
        total={data?.count}
        page={paging?.page}
        limit={paging?.limit}
        size={isSmall ? "small" : "medium"}
        onPaging={handlePaging}
        onLimit={handleLimit}
      />
    </WorkflowPage>
  )
}

export default IndexPage
