import { Controller, FieldValues } from "react-hook-form"
import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"
import PhoneInput, { CountryData } from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"
import { Box } from "@mui/material"

interface IProps {
  control: Control<FieldValues, any>
  defaultValue?: string | number
  label: string
  name: string
  formatter?: any
  rules?: any
}

/**
 * The form hook MUI phone field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the form input for a phone.
 */
const FhMuiPhoneField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, defaultValue = "", name, label, rules = {} } = props

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <PhoneInput
              country="us"
              containerStyle={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              specialLabel={label}
              onBlur={field.onBlur}
              onChange={(value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
                field.onChange(formattedValue)
              }}
              value={field.value}
            />
            <Box>{field.value}</Box>
            <Box>{fieldState.error?.message}</Box>
          </>
        )}
      />
    </>
  )
}

export default FhMuiPhoneField
