import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import Checkbox from "@mui/material/Checkbox"
import { RestRepository } from "../../repositories/RestRepository"
import { CircularProgress } from "@mui/material"

interface IProps {
  id: number | undefined
  field: string
  value: boolean
  repository: RestRepository<any>
  disabled?: boolean
  onUpdate?: () => void
}

/**
 * This inline checkbox will update the server when toggled.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The inline checkbox.
 */
const InlineCheckbox: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { id, field, value, repository, disabled = false, onUpdate } = props

  const [saving, setSaving] = useState<boolean>(false)
  const [localValue, setLocalValue] = useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (id !== undefined) {
        setSaving(true)
        const isChecked = event.target.checked
        const item: any = {
          [field]: isChecked,
        }
        try {
          await repository.patch(item, id)
          setLocalValue(isChecked)
        } catch (reason) {
          console.log(reason)
        }
        setSaving(false)
        onUpdate?.()
      }
    },
    [id, field]
  )

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      setLocalValue(value)
    }
  }, [])

  return <>{saving ? <CircularProgress size="1rem" /> : <Checkbox checked={localValue} onChange={handleChange} disabled={disabled} />}</>
}

export default InlineCheckbox
