import * as React from "react"
import { useCallback, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { LoadingButton } from "@mui/lab"
import ContactForm from "../../pages/contacts/components/ContactForm"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { CONTACT_ENDPOINT, IContact } from "../models/IContact"
import { CONNECTION_ERROR, setFormConnectionErrors } from "../models/IConnectionError"
import { RestRepository } from "../repositories/RestRepository"
import { IPaging } from "../models/IPaging"

const contactRepository = new RestRepository<IContact>(CONTACT_ENDPOINT)

interface IProps {
  onAddContact: (contact: IContact) => void
  disabled?: boolean
}

/**
 * Displays a dialog for adding a new contact. Then returns the created contact.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the add contact component.
 */
const AddContact: React.FC<IProps> = (props: IProps) : React.ReactElement => {
  const { onAddContact, disabled = false } = props
  const [open, setOpen] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [didCheck, setDidCheck] = useState(false)
  const [checkResults, setCheckResults] = useState<IContact[] | null>(null)

  const { control, handleSubmit, setError, reset, getValues } = useForm()

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setRequesting(false)
    setDidCheck(false)
    setCheckResults(null)
    reset()
  }, [])

  const handleCheckContact = useCallback(async () => {
    setRequesting(true)
    setDidCheck(true)
    try {
      const email = getValues("email")
      const emailPaging: IPaging = {
        filters: [
          {
            field: "search",
            value: email,
          },
        ],
      }
      const emailResults = await contactRepository.list(emailPaging)

      const lastName = getValues("last_name")
      const lastNamePaging: IPaging = {
        filters: [{ field: "search", value: lastName }],
      }
      const lastNameResults = await contactRepository.list(lastNamePaging)

      setCheckResults([...emailResults, ...lastNameResults])
    } catch (errors) {}
    setRequesting(false)
  }, [])

  const handleAddExistContact = useCallback(
    (contact: IContact) => () => {
      onAddContact(contact)
      handleClose()
    },
    []
  )

  const handleAdd = useCallback(async (contact: IContact) => {
    setRequesting(true)
    try {
      contact.customers = []
      contact.accounts = []
      contact.locations = []
      const c = await contactRepository.add(contact)
      onAddContact(c)
      handleClose()
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setFormConnectionErrors(reason.response.data, setError)
      } else {
        setFormConnectionErrors(CONNECTION_ERROR, setError)
      }
    }
    setRequesting(false)
  }, [])

  return (
    <>
      <IconButton onClick={handleOpen} disabled={disabled}>
        <AddIcon />
      </IconButton>
      <Dialog onClose={requesting ? () => ({}) : handleClose} open={open} fullWidth={true} maxWidth={didCheck ? "md" : "sm"}>
        <DialogTitle>Add Contact</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">The &quot;Check&quot; button searches on last name and email.</Alert>
            </Grid>
            <Grid item xs>
              <ContactForm control={control} />
            </Grid>
            {didCheck && (
              <Grid item xs>
                <Typography variant="h6">Similar Contacts</Typography>
                {checkResults !== null && checkResults.length > 0 ? (
                  <List sx={{height: 550, overflow: "auto"}}>
                    {checkResults.map(contact => (
                      <ListItemButton key={contact.id} onClick={handleAddExistContact(contact)}>
                        <ListItemText
                          primary={
                            <>
                              {contact.id} - {contact.name}
                            </>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                ) : (
                  <List>
                    <ListItem>
                      <ListItemText primary="No similar contacts found." />
                    </ListItem>
                  </List>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            sx={{
              ml: 0,
              mr: 1,
              mb: 1,
            }}
          >
            <Grid item xs>
              <Button disabled={requesting} onClick={handleClose} color="warning">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton loading={requesting} onClick={handleCheckContact}>
                Check
              </LoadingButton>
              {didCheck && (
                <Box component="span" sx={{ pl: 2 }}>
                  <LoadingButton loading={requesting} onClick={handleSubmit(handleAdd as SubmitHandler<FieldValues>)}>
                    Create
                  </LoadingButton>
                </Box>
              )}
            </Grid>
            {didCheck && <Grid item xs />}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddContact
