import { Controller, FieldValues } from "react-hook-form"
import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"

interface IProps {
  control: Control<FieldValues, any>
  defaultValue?: number
}

/**
 * Just renders a hidden ID field for the form hook post.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the hidden id field.
 */
const FhMuiIdField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, defaultValue = 0 } = props

  return (
    <>
      <Controller name="id" control={control} defaultValue={defaultValue} render={({ field }) => <input type="hidden" {...field} />} />
    </>
  )
}

export default FhMuiIdField
