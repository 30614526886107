import * as React from "react"
import { type FormEvent, useCallback, useState } from "react"
import moment from "moment"
import FormatDate from "../../../shared/components/format/FormatDate"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from "../../../config/config"
import { type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ViewLoading from "../../../shared/components/ViewLoading"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import DateCommentLabel from "./DateCommentLabel"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  waId: number
  field: string
  title: string
  onChange: (wa: IWorkAssignment) => void
  value: string | moment.Moment | null
  dateComment?: string[] | null | undefined
}

/**
 * This dialog allows for the changing of a date.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the change date dialog
 */
const ChangeDate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { field, title, value, waId, onChange, dateComment } = props

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<moment.Moment | undefined>(moment(value))

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleDateChange = useCallback((newDate: string |  null) => {
    setDate(moment(newDate))
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSave = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (date !== undefined) {
        setSaving(true)
        setSavingError(undefined)
        try {
          await waRepository.patch({ [field]: date.format(DATE_OUTPUT_FORMAT) } as any, waId)
          const wa1 = await waRepository.read(waId)
          onChange(wa1)
        } catch (reason: any) {
          if (reason?.response !== undefined) {
            setSavingError(reason.response as IConnectionError)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
        setSaving(false)
        setOpen(false)
      }
    },
    [date, waId],
  )

  const handleRemove = useCallback(async () => {
    setSaving(true)
    setSavingError(undefined)
    try {
      await waRepository.patch({ [field]: null } as any, waId)
      const wa1 = await waRepository.read(waId)
      onChange(wa1)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setSavingError(reason.response as IConnectionError)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
    setOpen(false)
  }, [date, waId])

  return (
    <>
      <Button size="small" onClick={handleOpen}>
        {dateComment !== null && dateComment !== undefined ? (
          <DateCommentLabel dateComment={dateComment} label={<FormatDate value={value as string} />} />
        ) : (
          <FormatDate value={value as string} />
        )}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Set Date for {title}</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <form method="post" onSubmit={handleSave}>
            <Box sx={{ mt: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date"
                  onChange={handleDateChange}
                  value={date}
                  mask="__/__/____"
                  inputFormat={DATE_INPUT_FORMAT}
                  renderInput={params => <TextField fullWidth {...params} autoFocus InputLabelProps={{ shrink: true }} />}
                />
              </LocalizationProvider>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item>
              <Button onClick={handleRemove}>Remove Date</Button>
            </Grid>
            <Grid item xs>
              <ViewLoading loading={saving} />
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSave} disabled={date === undefined}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeDate
