import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { useLocation } from "@reach/router"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { ILocation, LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import LocationForm from "./components/LocationForm"
import { IAccount } from "../../shared/models/IAccount"
import useApiAdd, { IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import ErrorMessage from "../../shared/components/ErrorMessage"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

interface ILocationState {
  account: IAccount
}

/**
 * Use this page to add a location.
 *
 * @returns {React.FunctionComponent} the add location page.
 */
const AddPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setError, formState, setValue, getValues, clearErrors } = useForm()
  const navLocation = useLocation()
  const [location, setLocation] = useState<ILocation | null>(null)

  const props: IUseApiAddProps<ILocation> = {
    apiFunction: repository.add,
    setError,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<ILocation>(props)

  const handleAddWrapper = useCallback(async (location1: ILocation) => {
    location1.business_interruption = location1.business_interruption !== null ? location1.business_interruption : 0
    location1.property_damage = location1.property_damage !== null ? location1.property_damage : 0
    location1.area = Number(location1.area)
    await handleAdd(location1)
  }, [])

  useEffect(() => {
    if (navLocation?.state !== undefined) {
      const { account } = navLocation.state as ILocationState
      if (account !== undefined && location == null) {
        const location1: any = { accounts: [account] }
        setLocation(location1)
      }
    }
  }, [navLocation.state])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Add Location" loading={saving} errors={formState.errors} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleAddWrapper as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <LocationForm control={control} clearErrors={clearErrors} location={location} setValue={setValue} getValues={getValues} />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
