import useAuth from "./useAuth"
import { useCallback, useEffect, useState } from "react"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { IPaging } from "../models/IPaging"

interface IUseApiListResponse<T> {
  call: () => void
  loading: boolean
  data: T | undefined
  error: IConnectionError | undefined
}

export interface IUseApiListProps<T> {
  apiFunction: (paging?: IPaging) => Promise<T>
  paging?: IPaging
  dontCallOnMount?: boolean
}

/**
 * This hook will do all the heavy lifting of a list of api results.
 *
 * @param {IUseApiListProps} props see IUseApiEditProps<T> for details.
 * @returns {IUseApiListResponse} edit state
 */
export const useApiList = <T>(props: IUseApiListProps<T>): IUseApiListResponse<T> => {
  const { apiFunction, dontCallOnMount, paging } = props
  const { logoutWithConnectionError } = useAuth()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T | undefined>()
  const [error, setError] = useState<IConnectionError | undefined>()

  const call = useCallback(() => {
    setLoading(true)
    apiFunction(paging)
      .then(results => {
        setData(results)
        setError(undefined)
      })
      .catch((reason: any) => {
        if (reason.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      })
      .finally(() => setLoading(false))
  }, [apiFunction, paging, logoutWithConnectionError])

  useEffect(() => {
    if (dontCallOnMount === undefined || !dontCallOnMount) {
      call()
    }
  }, [call, dontCallOnMount])

  return {
    call,
    loading,
    data,
    error,
  }
}
