import * as React from "react"
import { useCallback, useState } from "react"
import { ILocation, LOCATION_ENDPOINT } from "../../../shared/models/ILocation"
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { IUseApiReadProps, useApiRead } from "../../../shared/hooks/useApiRead"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import useApiEdit, { IUseApiEditProps } from "../../../shared/hooks/useApiEdit"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import HeaderPanel from "../../../shared/components/HeaderPanel"
import LocationForm from "../../locations/components/LocationForm"
import FormActionPanel from "../../../shared/components/FormActionPanel"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)

interface IProps {
  currentLocation: ILocation
  onChange?: () => void
}

/**
 * This is a wrapper for the editing the location form in a dialog.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the dialog location editor component.
 */
const LocationEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { currentLocation, onChange } = props
  const [open, setOpen] = useState(false)

  const propsRead: IUseApiReadProps<ILocation> = {
    apiFunction: repository.read,
    itemId: currentLocation.id,
    dontCallOnMount: true,
  }
  const { data: location, loading, error, call: readLocation } = useApiRead<ILocation>(propsRead)
  const { control, handleSubmit, setError, formState, setValue, getValues, clearErrors, reset } = useForm()

  const propsEdit: IUseApiEditProps<ILocation> = {
    apiFunction: repository.edit,
    setError,
    redirect: false,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<ILocation>(propsEdit)

  const handleEditWrapper = useCallback(
    async (location1: ILocation) => {
      location1.business_interruption = location1.business_interruption != null ? location1.business_interruption : 0
      location1.property_damage = location1.property_damage != null ? location1.property_damage : 0
      location1.area = Number(location1.area)
      const location2 = await handleEdit(location1, true)
      if (location2 !== undefined) {
        if (onChange !== undefined) {
          onChange()
        }
        setOpen(false)
      }
    },
    [handleEdit, onChange, location, currentLocation, reset]
  )

  const handleOpen = useCallback(() => {
    setOpen(true)
    readLocation()
  }, [])

  return (
    <>
      <IconButton onClick={handleOpen} disabled={loading}>
        {loading ? <CircularProgress size={20} /> : <EditIcon />}
      </IconButton>

      <Dialog open={open && !loading} onClose={() => setOpen(false)} fullWidth maxWidth="xl">
        <Box component="form" onSubmit={handleSubmit(handleEditWrapper as SubmitHandler<FieldValues>)} autoComplete="off">
          <DialogTitle>
            <HeaderPanel title="Edit Location" loading={saving || loading} errors={formState.errors} />
          </DialogTitle>
          <DialogContent>
            <Box sx={{ p: 2 }}>
              <ErrorMessage error={error} />
              <ErrorMessage error={connectionError} />
              {!loading && (
                <LocationForm
                  location={location}
                  clearErrors={clearErrors}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  isEdit
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <FormActionPanel onCancel={() => setOpen(false)} />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default LocationEditor
