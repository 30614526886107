import * as React from "react"
import { useEffect, useState } from "react"
import { LookupsRepository } from "../../../shared/repositories/LookupsRepository"
import { IPayPeriod } from "../../../shared/models/main/IPayPeriod"
import { Box, Grid } from "@mui/material"
import FormatDate from "../../../shared/components/format/FormatDate"

const repository = new LookupsRepository("pay_period")

interface ICurrentPayPeriods {
  current_adp_pay_period: IPayPeriod | null
  current_pay_period: IPayPeriod | null
}

const CurrentPayPeriods: React.FC = (): React.ReactElement => {
  const [payPeriods, setPayPeriods] = useState<ICurrentPayPeriods | null>(null)

  useEffect(() => {
    if (payPeriods === null) {
      ;(async () => {
        const payPeriods = await repository.lookup<ICurrentPayPeriods>("current_expense_report_pay_periods")
        setPayPeriods(payPeriods)
      })()
    }
  }, [payPeriods])

  return (
    <>
      {payPeriods !== null && (
        <>
          {payPeriods.current_pay_period !== null && (
            <Box>
              <Grid container>
                <Grid item xs={2}>
                  <strong>General</strong>
                </Grid>
                <Grid item>
                  <FormatDate value={payPeriods.current_pay_period?.start_date} />
                </Grid>
                <Grid item>
                  <Box component="em" sx={{ p: 1 }}>
                    to
                  </Box>
                </Grid>
                <Grid item xs>
                  <FormatDate value={payPeriods.current_pay_period?.expense_report_due_date} />
                </Grid>
              </Grid>
            </Box>
          )}
          {payPeriods.current_adp_pay_period !== null && (
            <Box>
              <Grid container>
                <Grid item xs={2}>
                  <strong>ADP</strong>
                </Grid>
                <Grid item>
                  <FormatDate value={payPeriods.current_adp_pay_period?.start_date} />
                </Grid>
                <Grid item>
                  <Box component="em" sx={{ p: 1 }}>
                    to
                  </Box>
                </Grid>
                <Grid item>
                  <FormatDate value={payPeriods.current_adp_pay_period?.expense_report_due_date} />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default CurrentPayPeriods
