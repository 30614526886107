import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { Box } from "@mui/material"
import { IMinimalFile } from "../models/IFile"

interface IDropboxFile {
  url: string
  filename: string
}

interface IProps {
  file: IMinimalFile
}

/**
 * Dropbox saver button for saving a file.
 * https://www.dropbox.com/developers/saver
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the saver button.
 */
const DropboxSaver: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { file } = props

  const buttonRef = useRef<HTMLDivElement>(null)

  const files: IDropboxFile[] | null = useMemo(() => {
    if (file?.file !== undefined) {
      return [
        {
          url: file.file.read_url,
          filename: `${file.name}.${file.ext}`,
        },
      ]
    }
    return null
  }, [file])

  const isDropboxReady = useMemo(() => {
    return window.Dropbox !== undefined
  }, [])

  const onSave = useCallback(() => {
    if (!isDropboxReady) {
      return null
    }
  }, [isDropboxReady])

  useEffect(() => {
    if (isDropboxReady && buttonRef?.current !== undefined && buttonRef.current !== null && files !== null) {
      const button = window.Dropbox.createSaveButton({
        files,
      })
      buttonRef.current.innerHTML = ""
      buttonRef.current.appendChild(button)
    }
  }, [isDropboxReady, buttonRef, files])

  return <Box ref={buttonRef} onClick={onSave}></Box>
}

export default DropboxSaver
