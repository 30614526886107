import * as React from "react"
import { useMemo } from "react"
import { Alert, Box, Button, Chip, Grid, Link, Tab, Tabs, Typography } from "@mui/material"
import { Link as BrowserLink } from "@reach/router"

import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"

import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import { ACCOUNTS_VIEW_URL, LOCATIONS_EDIT_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import ContactCard from "../../shared/components/ContactCard"
import FilesEditor from "../../shared/components/FilesEditor"
import { IFile } from "../../shared/models/IFile"
import { ILocation, isLocation, LOCATION_ENDPOINT } from "../../shared/models/ILocation"
import { LOCATION_FILE_ENDPOINT } from "../../shared/models/ILocationFile"
import LocationMap from "../../shared/components/LocationMap"
import ViewProperty from "../../shared/components/ViewProperty"
import FormatNumber from "../../shared/components/format/FormatNumber"
import { IAccount } from "../../shared/models/IAccount"
import { getCountryByAlpha2 } from "../../config/countries"
import TruncateText from "../../shared/components/TruncateText"
import EditIcon from "@mui/icons-material/Edit"

const locationRepository = new RestRepository<ILocation>(LOCATION_ENDPOINT)
const fileRepository = new RestRepository<IFile>(LOCATION_FILE_ENDPOINT)

/**
 * This component will display a location.
 *
 * @returns {React.FunctionComponent} the page.
 */
const ViewPage: React.FunctionComponent = () => {
  const { data: location, error, loading, call: refreshLocation } = useApiRead<ILocation>({ apiFunction: locationRepository.read })

  const { tab, handleTabChange } = useTabPanel()

  const country = useMemo(() => {
    if (location !== undefined) {
      return getCountryByAlpha2(location.country)
    }
    return null
  }, [location])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading} />
        <ErrorMessage error={error} />
        {location !== undefined && isLocation(location) && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="h4" component="h2" onClick={refreshLocation} sx={{ cursor: "pointer" }}>
                    <TruncateText text={location.name !== "" ? location.name : location.address} />
                  </Typography>
                </Grid>
                <Grid item xs>
                  <ViewLoading loading={loading} message="Updating..." />
                </Grid>
                <Grid item>
                  <Button startIcon={<EditIcon />} component={BrowserLink} to={`${LOCATIONS_EDIT_URL}/${location.id}`}>
                    Location
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {location.aliases?.map((alias, index) => (
                <Chip key={index} label={alias} sx={{ mr: 1 }} />
              ))}
            </Grid>

            <Grid item xs={12}>
              <Box sx={styles.tabsBox}>
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Details" />
                  <Tab label="Files" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography fontWeight={600}>Accounts</Typography>
                      </Grid>
                      {(location.accounts as IAccount[])?.map(account => (
                        <Grid item key={account.id} sm={4}>
                          <Link component={BrowserLink} to={`${ACCOUNTS_VIEW_URL}/${account.id}`}>
                            {account.name}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Typography fontWeight={600}>Contacts</Typography>
                      </Grid>
                      {location.contacts?.map(contact => (
                        <Grid item key={contact.id} sm={4}>
                          <ContactCard contact={contact} />
                        </Grid>
                      ))}
                      {location.contacts.length === 0 && (
                        <Grid item xs>
                          <Alert severity="warning">No contacts for this location.</Alert>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <ViewProperty label="Address">{location.address}</ViewProperty>
                        <ViewProperty label="Address 2">{location.address_2}</ViewProperty>
                        <ViewProperty label="City">{location.city}</ViewProperty>
                        <ViewProperty label="State/Region">{location.state_region}</ViewProperty>
                        <ViewProperty label="Country">{country?.name}</ViewProperty>
                        <ViewProperty label="Postal Code">{location.postal_code}</ViewProperty>
                      </Grid>
                      <Grid item xs>
                        <ViewProperty label="Specialty">{location.specialty?.name}</ViewProperty>
                        <ViewProperty label="Occupancy">
                          <TruncateText text={location.occupancy?.name} num={25} />
                        </ViewProperty>
                        <ViewProperty label="Property Damage">
                          <FormatNumber value={location.property_damage} />
                        </ViewProperty>
                        <ViewProperty label="Business Interruption">
                          <FormatNumber value={location.business_interruption} />
                        </ViewProperty>
                        <ViewProperty label="Total Insurable Value">
                          <FormatNumber value={location.total_insured_value} />
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <LocationMap primaryLocation={location} initialMapPosition={location.map_position} showInitialPosition />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <FilesEditor parentId={location.id} fileRepository={fileRepository} fieldName="location" />
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
