import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Circle, GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api"
import { ILocation } from "../models/ILocation"
import { Grid, Typography } from "@mui/material"
import { IConsultant, isConsultant } from "../models/main/IConsultant"
import { IWorkAssignmentConsultant } from "../models/IWorkAssignmentConsultant"
import { icon, PersonPushpinIcon, PushpinIcon } from "../utilities/icons_utilities"
import theme from "../themes/default_theme"
import useMapType from "../hooks/useMapType"
import { DEFAULT_CENTER, GOOGLE_MAPS } from "../../config/config"

interface IProps {
  location: ILocation
  centerLocation?: ILocation | null
  zoom?: number
  useZoom?: boolean
  consultants?: IConsultant[]
  consultant?: IConsultant | null
  waConsultants?: IWorkAssignmentConsultant[]
  onConsultantSelect?: (consultant: IConsultant) => void
  height?: string
  showTravelRadius?: boolean
}

/**
 * Displays the work assignment location and consultants on a map.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the work assignment map.
 */
const WorkAssignmentMap: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    location,
    centerLocation = null,
    waConsultants,
    consultant,
    consultants,
    onConsultantSelect,
    zoom = 19,
    height = "400px",
    showTravelRadius = true,
    useZoom = false,
  } = props
  const [center, setCenter] = useState<{ lat: number; lng: number }>(DEFAULT_CENTER)

  const [selected, setSelected] = useState<ILocation | IConsultant>()
  const [infoOpen, setInfoOpen] = useState(false)
  const [markerMap, setMarkerMap] = useState({})
  const [map, setMap] = useState(null)
  const { mapType, handleMapTypeChanged } = useMapType({ map })
  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS)

  useEffect(() => {
    if (map !== null) {
      if (centerLocation !== null) {
        setCenter({
          lat: centerLocation.map_position?.center !== undefined ? centerLocation.map_position.center.lat : centerLocation.latitude,
          lng: centerLocation.map_position?.center !== undefined ? centerLocation.map_position.center.lng : centerLocation.longitude,
        })
      } else {
        setCenter({
          lat: location.map_position?.center !== undefined ? location.map_position.center.lat : location.latitude,
          lng: location.map_position?.center !== undefined ? location.map_position.center.lng : location.longitude,
        })
      }
    }
  }, [map, centerLocation, location])

  const markerLoadHandler = useCallback((marker, name) => {
    setMarkerMap(prevState => ({
      ...prevState,
      [name]: marker,
    }))
  }, [])

  const markerClickHandler = useCallback(
    (s: ILocation | IConsultant) => {
      setSelected(s)
      if (isConsultant(s) && onConsultantSelect !== undefined) {
        onConsultantSelect(s)
      }
      // Required so clicking a 2nd marker works as expected
      if (infoOpen) {
        setInfoOpen(false)
      }
      setInfoOpen(true)
    },
    [infoOpen]
  )

  const onLoad = useCallback(map => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        flexGrow: "1",
        height,
      }}
      center={center}
      zoom={location.map_position?.zoom !== undefined && !useZoom ? location.map_position.zoom : zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onMapTypeIdChanged={handleMapTypeChanged}
      mapTypeId={mapType}
    >
      {infoOpen && selected !== undefined && (
        <InfoWindow anchor={(markerMap as any)[`${selected.id}`]} onCloseClick={() => setInfoOpen(false)}>
          <Grid container alignContent="start">
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>{selected.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              {selected.address}
            </Grid>
            <Grid item xs={12}>
              {selected.address_2}
            </Grid>
            <Grid item xs={12}>
              {selected.city}, {selected.state_region} {selected.postal_code}
              {selected.country}
            </Grid>
          </Grid>
        </InfoWindow>
      )}

      <Marker
        position={{
          lat: location.latitude,
          lng: location.longitude,
        }}
        icon={icon({
          path: PushpinIcon,
          fillColor: centerLocation?.id === location.id ? theme.palette.success : theme.palette.secondary,
        })}
        onLoad={marker => markerLoadHandler(marker, `${location.id}`)}
        onClick={() => markerClickHandler(location)}
      />
      {location.map_position?.center !== undefined && (
        <Marker
          position={location.map_position.center}
          icon={icon({
            path: PushpinIcon,
            fillColor: theme.palette.secondary,
          })}
        />
      )}

      <>
        {waConsultants?.map(c => {
          const consultant = c.consultant as IConsultant
          return (
            <Marker
              key={c.id}
              position={{
                lat: consultant.latitude + Math.sin(consultant.id) / 40000,
                lng: consultant.longitude + Math.sin(consultant.id) / 40000,
              }}
              icon={icon({
                path: PersonPushpinIcon,
                fillColor: theme.palette.error,
              })}
            />
          )
        })}
      </>

      {showTravelRadius &&
        waConsultants?.map(c => {
          const consultant = c.consultant as IConsultant
          return (
            <Circle
              key={c.id}
              options={{
                strokeColor: theme.palette.error.main,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: theme.palette.error.light,
                fillOpacity: 0.35,
              }}
              center={{
                lat: consultant.latitude + Math.sin(consultant.id) / 40000,
                lng: consultant.longitude + Math.sin(consultant.id) / 40000,
              }}
              radius={consultant.radius * 1609.344}
            />
          )
        })}

      <>
        {consultants?.map(c => {
          const color = c.id === consultant?.id ? theme.palette.info : theme.palette.warning
          return (
            <Marker
              key={c.id}
              position={{
                lat: c.latitude + Math.sin(c.id) / 40000,
                lng: c.longitude + Math.sin(c.id) / 40000,
              }}
              icon={icon({
                path: PersonPushpinIcon,
                fillColor: color,
              })}
              onLoad={marker => markerLoadHandler(marker, `${c.id}`)}
              onClick={() => markerClickHandler(c)}
            />
          )
        })}
      </>

      {showTravelRadius &&
        consultants?.map(c => {
          const color = c.id === consultant?.id ? theme.palette.info : theme.palette.warning
          return (
            <Circle
              key={c.id}
              options={{
                strokeColor: color.main,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color.light,
                fillOpacity: 0.35,
              }}
              center={{
                lat: c.latitude + Math.sin(c.id) / 40000,
                lng: c.longitude + Math.sin(c.id) / 40000,
              }}
              radius={c.radius * 1609.344}
              onLoad={marker => markerLoadHandler(marker, `${c.id}`)}
              onClick={() => markerClickHandler(c)}
            />
          )
        })}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default WorkAssignmentMap
