import * as React from "react"
import { useEffect } from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import theme from "../themes/default_theme"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import { ISiteMeta } from "../models/ISiteMeta"

interface IProps {
  path?: any
  children?: any
  component?: any
  title?: string
}

/**
 * Use this layout to wrap the login pages.
 *
 * @param {IProps} props See IProps for more details.
 * @returns {React.FunctionComponent<IProps>} the login layout.
 */
const LoginLayout: React.FunctionComponent<IProps> = (props: IProps) => {
  const { children, component: Component, title } = props
  const data: ISiteMeta | undefined = useStaticQuery(
    graphql`
      query LoginQuery {
        site {
          siteMetadata {
            title
            version
            company
          }
        }
      }
    `
  )

  useEffect(() => {
    if (title !== undefined && typeof window !== "undefined" && data?.site.siteMetadata.title !== undefined) {
      window.document.title = `${data?.site.siteMetadata.title} | ${title}`
    }
  }, [title])

  return (
    <>
      <Helmet>
        <title>
          {data?.site.siteMetadata.title} | {title}
        </title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        {Component !== undefined && <Component {...props} data={data} />}
      </ThemeProvider>
    </>
  )
}

export default LoginLayout
