import * as React from "react"
import { useCallback, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"

interface IProps {
  defaultLimit: number | undefined
  title: string
  onChange: (limit: number) => void
}

/**
 * This dialog allows the changing of the results limit.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the limit dialog.
 */
const ChangeLimit: React.FunctionComponent<IProps> = (props: IProps) => {
  const { defaultLimit, title, onChange } = props
  const [limit, setLimit] = useState<number | undefined>(defaultLimit)
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => setOpen(false), [])
  const handleOpen = useCallback(() => setOpen(true), [])

  const handleSave = useCallback(() => {
    setOpen(false)
    if (limit !== undefined) {
      onChange(limit)
    }
  }, [limit])

  return (
    <>
      <Button onClick={handleOpen}>Limit {defaultLimit}</Button>
      <Dialog onClose={handleClose} open={open} maxWidth="xs">
        <DialogTitle>{title} Results Limit</DialogTitle>
        <DialogContent>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField label="Limit" value={limit} fullWidth onChange={e => setLimit(Number(e.target.value))} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeLimit
