import * as React from "react"
import { useCallback, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import DialogControls from "../../../shared/components/DialogControls"
import EditIcon from "@mui/icons-material/Edit"
import { EXPENSE_REPORT_ADMIN_ITEMS_ENDPOINT, IExpenseReportAdminItem } from "../../../shared/models/IExpenseReportAdminItem"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../../shared/models/ICustomer"
import { IListItem } from "../../../shared/models/component/IListItem"
import { ACCOUNT_ENDPOINT, IAccount } from "../../../shared/models/IAccount"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { EXPENSE_REPORT_CATEGORIES_ENDPOINT, IExpenseReportCategory } from "../../../shared/IExpenseReportCategory"
import { IExpenseReport } from "../../../shared/models/IExpenseReport"
import moment from "moment/moment"
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from "../../../config/config"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

interface IProps {
  adminItem: IExpenseReportAdminItem
  expenseReport: IExpenseReport
  onChange: () => void
}

export interface IExpenseReportAdminItemEdit {
  id: number
  expense_report: number
  description: string
  on_date: string | null
  hours: number
  customer: number | undefined | null
  account: number | undefined | null
  expense_report_category: number | undefined | null
}

const customerRepository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const categoryRepository = new RestRepository<IExpenseReportCategory | IListItem>(EXPENSE_REPORT_CATEGORIES_ENDPOINT)

const repository = new RestRepository<IExpenseReportAdminItemEdit>(EXPENSE_REPORT_ADMIN_ITEMS_ENDPOINT)

/**
 * Edit an admin item to the expense report.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the component.
 */
const EditAdminItem: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { adminItem, expenseReport, onChange } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const [customer, setCustomer] = useState<ICustomer | null>(null)
  const [account, setAccount] = useState<IAccount | null>(null)
  const [category, setCategory] = useState<IExpenseReportCategory | null>(null)

  const [description, setDescription] = useState<string>("")
  const [hours, setHours] = useState<string>("0")
  const [date, setDate] = useState<moment.Moment | null>(null)

  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleOpen = useCallback(() => {
    setCustomer(adminItem.customer)
    setAccount(adminItem.account)
    setCategory(adminItem.expense_report_category)
    setDescription(adminItem.description)
    setDate(adminItem.on_date !== null ? moment(adminItem.on_date) : null)
    setHours(`${adminItem.hours}`)
    setOpen(true)
  }, [adminItem])

  const handleClose = useCallback(() => {
    setSavingError(null)
    setCustomer(null)
    setAccount(null)
    setCategory(null)
    setDate(null)
    setDescription("")
    setHours("0")
    setOpen(false)
  }, [])

  const handleCustomer = useCallback((customer1?: IListItem | null) => {
    setCustomer(customer1 !== undefined ? (customer1 as ICustomer) : null)
    setAccount(null)
  }, [])

  const handleAccount = useCallback((account1?: IListItem | null) => {
    setAccount(account1 !== undefined ? (account1 as IAccount) : null)
  }, [])

  const handleCategory = useCallback((category1?: IListItem | null) => {
    setCategory(category1 !== undefined ? (category1 as IExpenseReportCategory) : null)
  }, [])

  const handleDateChange = useCallback((e: moment.Moment | null) => {
    if (e !== null) {
      setDate(moment(e))
    } else {
      setDate(null)
    }
  }, [])

  const handleSave = useCallback(async () => {
    try {
      console.log(date?.isValid())
      setSaving(true)
      const adminItem1: IExpenseReportAdminItemEdit = {
        id: adminItem.id,
        expense_report: expenseReport.id,
        description,
        hours: Number(hours),
        expense_report_category: category?.id,
        on_date: date?.isValid() === true ? moment(date).format(DATE_OUTPUT_FORMAT) : null,
        account: account?.id,
        customer: customer?.id,
      }
      await repository.edit(adminItem1, adminItem.id)
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, expenseReport, adminItem, date, description, hours, customer, account, category])

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Edit Admin Item</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <SelectFilteredSingle
                name="customer"
                label="Customer"
                defaultValue={customer as IListItem}
                repository={customerRepository}
                onChange={handleCustomer}
              />
            </Grid>
            <Grid item xs={4}>
              {customer != null && (
                <SelectFilteredSingle
                  name="account"
                  label="Account"
                  defaultValue={account as IListItem}
                  filters={[
                    {
                      field: "customer",
                      value: customer?.id,
                    },
                  ]}
                  repository={accountRepository}
                  onChange={handleAccount}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <SelectFilteredSingle
                name="category"
                label="Category"
                defaultValue={category as IListItem}
                repository={categoryRepository}
                onChange={handleCategory}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date"
                  onChange={handleDateChange}
                  value={date}
                  inputFormat={DATE_INPUT_FORMAT}
                  renderInput={params => <TextField required={false} fullWidth {...params} InputLabelProps={{ shrink: true }} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <TextField fullWidth label="Hours" name="hours" value={hours} onChange={e => setHours(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} loading={saving} onCancel={handleClose} disabled={description === "" || hours === ""} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditAdminItem
