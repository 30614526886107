import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import TruncateText from "../../../shared/components/TruncateText"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import DialogControls from "../../../shared/components/DialogControls"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IListItem } from "../../../shared/models/component/IListItem"
import { IOccupancy, OCCUPANCY_ENDPOINT } from "../../../shared/models/IOccupancy"
import { LOCATION_ENDPOINT } from "../../../shared/models/ILocation"
import FormatNumber from "../../../shared/components/format/FormatNumber"

const occupancyRepository = new RestRepository<IListItem>(OCCUPANCY_ENDPOINT)
const locationRepository = new RestRepository<ILocationPatch>(LOCATION_ENDPOINT)
const waRepository = new RestRepository<IWaPatch | IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  wa: IWorkAssignment
  onChange?: () => void
}

interface ILocationPatch {
  occupancy: number | null
}

interface IWaPatch {
  obtained_risk_score: number
  max_risk_score: number
}

/**
 * Display and edits the occupancy score and labeling.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the occupancy editor.
 */
const OccupancyEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { wa, onChange } = props
  const [open, setOpen] = useState(false)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const [occupancy, setOccupancy] = useState<IOccupancy | null>(null)
  const [obtainedRiskScore, setObtainedRiskScore] = useState<number>(0)
  const [maxRiskScore, setMaxRiskScore] = useState<number>(0)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [wa.admin_reviewer_consultant])

  const handleObtainedRiskScore = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setObtainedRiskScore(Number(event.target.value))
  }, [])

  const handleMaxRiskScore = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxRiskScore(Number(event.target.value))
  }, [])

  const handleOccupancy = useCallback((occupancy1?: IListItem | null) => {
    setOccupancy(occupancy1 !== undefined ? (occupancy1 as IOccupancy) : null)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setSavingError(undefined)
  }, [])

  const handleSave = useCallback(async () => {
    if (wa.location !== null) {
      setSaving(true)
      setSavingError(undefined)
      try {
        const locationPatch: ILocationPatch = {
          occupancy: occupancy === null ? occupancy : occupancy.id,
        }
        await locationRepository.patch(locationPatch, wa.location.id)

        const waPatch: IWaPatch = {
          max_risk_score: maxRiskScore,
          obtained_risk_score: obtainedRiskScore,
        }
        await waRepository.patch(waPatch, wa.id)
        if (occupancy !== null) {
          await occupancyRepository.action(occupancy.id, "recalculate")
        }
        onChange?.()
        setOpen(false)
        setSaving(false)
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    }
  }, [maxRiskScore, obtainedRiskScore, occupancy, wa])

  useEffect(() => {
    setMaxRiskScore(wa.max_risk_score)
    setObtainedRiskScore(wa.obtained_risk_score)
    if (wa.location?.occupancy?.id !== undefined) {
      setOccupancy({
        ...wa.location.occupancy,
        name: `${wa.location.occupancy.sic_code} - ${wa.location.occupancy.name}`,
      })
    }
  }, [wa])

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <TruncateText
            num={30}
            text={
              wa.location?.occupancy?.name !== undefined
                ? `${wa.location.occupancy.sic_code} - ${wa.location.occupancy.name}`
                : "Occupancy Not Set"
            }
          />
        </Grid>
        <Grid item>
          <IconButton size="small" onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Change Occupancy</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ErrorMessage error={savingError} />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="occupancy"
                label="Location Occupancy"
                defaultValue={occupancy as IListItem}
                repository={occupancyRepository}
                onChange={handleOccupancy}
              />
            </Grid>
            {wa.location?.occupancy !== undefined && wa.location.occupancy !== null && (
              <>
                <Grid item xs>
                  <strong>Obtained</strong>
                </Grid>
                <Grid item>
                  <FormatNumber
                    twoDecimalPlaces
                    value={wa.location.occupancy.current_avg_obtained_risk_score}
                    prefixUnits={false}
                    suffixUnits="%"
                  />
                </Grid>
                <Grid item xs>
                  <strong>Max</strong>
                </Grid>
                <Grid item>
                  <FormatNumber
                    twoDecimalPlaces
                    value={wa.location.occupancy.current_avg_max_risk_score}
                    prefixUnits={false}
                    suffixUnits="%"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Alert severity="warning">
                Warning. Changing the location occupancy will change the occupancy of other work assignments using this location.
              </Alert>
            </Grid>
            <Grid item xs={6}>
              <TextField label="Obtained Risk Score" value={obtainedRiskScore} onChange={handleObtainedRiskScore} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Max Risk Score" value={maxRiskScore} onChange={handleMaxRiskScore} />
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">Note. Changing the scores only affects this work assignment and the global averages.</Alert>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls loading={saving} onCancel={handleClose} onSave={handleSave} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OccupancyEditor
