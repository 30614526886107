import * as React from "react"
import { FormEvent, useCallback, useState } from "react"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { RestRepository } from "../repositories/RestRepository"
import ErrorMessage from "./ErrorMessage"
import DialogControls from "./DialogControls"

interface IProps {
  item: any
  modelId: number
  field: string
  title: string
  label: string
  onChange: (item: any) => void
  value: boolean
  repository: RestRepository<any>
}

/**
 * This component assists in changing a boolean field for a model.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the checkbox dialog.
 */
const ChangeCheckbox: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, item, title, label, value, modelId, onChange, repository } = props

  const [open, setOpen] = useState(false)
  const [newValue, setNewValue] = useState<boolean>(value)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleSave = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      setSaving(true)
      setSavingError(undefined)
      try {
        await repository.patch({ [field]: newValue } as any, modelId)
        onChange({
          ...item,
          [field]: newValue,
        })
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
      setOpen(false)
    },
    [newValue, item, field, modelId]
  )

  return (
    <>
      <Button size="small" onClick={handleOpen}>
        {value ? "Yes" : "No"}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Change {title}</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <form method="post" onSubmit={handleSave}>
            <Box sx={{ mt: 1 }}>
              <FormControlLabel
                label={label}
                control={<Checkbox onChange={e => setNewValue(e.target.checked)} checked={newValue} />}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} loading={saving} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeCheckbox
