import { useCallback } from "react"
import { IPaging } from "../models/IPaging"
import { IMapLocation } from "../models/component/IMapLocation"
import { UseFormGetValues } from "react-hook-form/dist/types/form"
import { LookupsRepository } from "../repositories/LookupsRepository"
import useAuth from "./useAuth"

const lookupsRepository = new LookupsRepository()

interface IProps {
  getValues: UseFormGetValues<any>
}

interface IResponse {
  locateAddress: (alt?: boolean) => Promise<IMapLocation>
}

/**
 * Use a form to get the long and lat for an address.
 *
 * @param {IProps} props See IProps for details.
 * @returns {IResponse} See IResponse for details.
 */
const useFormLocateAddress = (props: IProps): IResponse => {
  const { getValues } = props
  const { currentUser } = useAuth()

  const locateAddress = useCallback(
    async (alt: boolean = false): Promise<IMapLocation> => {
      const addressValue: string | undefined = (getValues(`address${alt ? "_alt" : ""}`) as string)?.replaceAll("&", "and")
      const address2Value: string | undefined = (getValues(`address_2${alt ? "_alt" : ""}`) as string)?.replaceAll("&", "and")
      const address =
        `${addressValue !== undefined ? addressValue : ""}, ${address2Value !== undefined ? address2Value : ""},` +
        (getValues(`city${alt ? "_alt" : ""}`) as string) +
        "," +
        (getValues(`state_region${alt ? "_alt" : ""}`) as string) +
        "," +
        (getValues(`country${alt ? "_alt" : ""}`) as string) +
        "," +
        (getValues(`postal_code${alt ? "_alt" : ""}`) as string)

      const paging: IPaging = {
        filters: [
          {
            field: "address",
            value: address,
          },
        ],
      }

      return await lookupsRepository.lookup<IMapLocation>("locate_address", paging)
    },
    [getValues, currentUser]
  )

  return { locateAddress }
}

export default useFormLocateAddress
