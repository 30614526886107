import * as React from "react"
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import useDebounce from "react-debounced"

import { RestRepository } from "../repositories/RestRepository"
import { IListItem } from "../models/component/IListItem"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { IPaging } from "../models/IPaging"
import { IFilter } from "../models/IFilter"
import ErrorMessage from "./ErrorMessage"

interface IProps {
  name: string
  size?: "small" | "medium"
  autoFocus?: boolean
  label: string | React.ReactElement<any, any>
  defaultValue?: IListItem | null
  filters?: IFilter[]
  repository: RestRepository<IListItem>
  onChange: (listItem: IListItem | null) => void
  onFocus?: () => void
  onBlur?: () => void
}

/**
 * This component provides a single autocomplete form select.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC} the component.
 */
const SelectFilteredSingle: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { name, size = "medium", autoFocus = false, filters = [], defaultValue, label, repository, onChange, onFocus = () => {}, onBlur = () => {} } = props

  const debounce = useDebounce()

  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [listItems, setListItems] = useState<readonly IListItem[]>([])
  const [selectedListItem, setSelectedListItem] = useState<IListItem | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)

  const unmounted = useRef(false)
  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  const handleOpen = useCallback(async () => {
    if (!unmounted.current && selectedListItem !== null) setListItems([selectedListItem])
    if (!unmounted.current) setOpen(true)
    if (!unmounted.current) setLoading(true)
    if (!unmounted.current) setError(null)
    try {
      const paging: IPaging = { filters }
      const results = await repository.list(paging)
      if (!unmounted.current) setListItems(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        if (!unmounted.current) setError(reason.response)
      } else {
        if (!unmounted.current) setError(CONNECTION_ERROR)
      }
    }
    if (!unmounted.current) setLoading(false)
  }, [filters, selectedListItem])

  const handleListItemClick = useCallback((event: SyntheticEvent, item: IListItem | null) => {
    if (!unmounted.current) setSelectedListItem(item)
    if (!unmounted.current) onChange(item)
  }, [])

  // noinspection DuplicatedCode
  const handleSearch = useCallback(
    async (event: SyntheticEvent, search: string) => {
      // noinspection DuplicatedCode
      debounce(async () => {
        if (!unmounted.current) setError(null)
        if (!unmounted.current) setLoading(true)
        try {
          const paging: IPaging = {
            filters: [
              ...filters,
              {
                field: "search",
                value: search,
              },
            ],
          }
          const results = await repository.list(paging)
          if (!unmounted.current) setListItems(results)
        } catch (reason: any) {
          if (reason?.response !== undefined) {
            if (!unmounted.current) setError(reason.response)
          } else {
            if (!unmounted.current) setError(CONNECTION_ERROR)
          }
        }
        if (!unmounted.current) setLoading(false)
      })
    },
    [filters]
  )

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null && !open) {
      const item: IListItem = {
        id: defaultValue.id,
        name: defaultValue.name,
      }
      setSelectedListItem(item)
    }
  }, [defaultValue, open])

  // note: warnings involving isOptionEqualToValue means the names don't match.
  // example: "118 | Wolfe, Thomas and Hamilton => Allianz" !== "Wolfe, Thomas and Hamilton"
  return (
    <>
      <ErrorMessage error={error} />
      <Autocomplete
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        onChange={handleListItemClick}
        value={selectedListItem}
        onInputChange={handleSearch}
        onFocus={onFocus}
        onBlur={onBlur}
        autoHighlight
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={listItems}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            size={size}
            autoFocus={autoFocus}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default SelectFilteredSingle
