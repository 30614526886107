import * as React from "react"
import { useCallback, useState } from "react"
import { RestRepository } from "../repositories/RestRepository"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import ErrorMessage from "./ErrorMessage"
import { CONNECTION_ERROR, type IConnectionError } from "../models/IConnectionError"
import { type IListItem } from "../models/component/IListItem"
import SelectFilteredSingle from "./SelectFilteredSingle"
import DialogControls from "./DialogControls"
import AddContactCheckMessage from "./AddContactCheckMessage"
import AddContact from "./AddContact"
import { CONTACT_ENDPOINT, type IContact } from "../models/IContact"
import ContactCard from "./ContactCard"

const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)

interface IProps {
  modelId: number
  field: string
  title: string
  value: IListItem | null
  repository: RestRepository<any>
  onChange: () => void
}

/**
 * ContactEditor - A React functional component for editing a contact.
 *
 * @param {IProps} props - The component props.
 * @returns {React.FC<IProps>} The rendered component.
 */
const ContactEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { modelId, field, title, value, repository, onChange } = props
  const [open, setOpen] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)
  const [contact, setContact] = useState<IListItem | null>(null)
  const [displayContact, setDisplayContact] = useState<IContact | null>(null)

  const handleOpen = useCallback(async () => {
    await handleSelectionChange(value)
    setOpen(true)
  }, [value])

  const handleClose = useCallback(() => {
    setOpen(false)
    setContact(null)
    setDisplayContact(null)
    setSavingError(null)
  }, [])

  /**
   * Makes call to save contact using a patch. Handles all errors.
   */
  const handleAssign = useCallback(async () => {
    setSaving(true)
    setSavingError(null)
    let contactId = null
    if (contact?.id !== undefined) {
      contactId = contact.id
    }
    try {
      await repository.patch({ [field]: contactId } as any, modelId)
      onChange()
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setSavingError(reason.response as IConnectionError)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
    setOpen(false)
  }, [contact])

  const retrieveDisplayContact = useCallback(async (contactId: string | number) => {
    setSaving(true)
    setSavingError(null)
    try {
      const contact1 = (await contactRepository.read(contactId)) as IContact
      setDisplayContact(contact1)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setSavingError(reason.response as IConnectionError)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
  }, [])

  const handleSelectionChange = useCallback(async (contact1: IListItem | null) => {
    setContact(contact1)
    setDisplayContact(null)
    if (contact1 !== null) {
      await retrieveDisplayContact(contact1.id)
    }
  }, [])

  const handleAddContact = useCallback(async (contact1: IListItem) => {
    setContact(contact2 => contact1 ?? contact2)
    setDisplayContact(null)
    if (contact1 !== null) {
      await retrieveDisplayContact(contact1.id)
    }
  }, [])

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Edit {title}</DialogTitle>
        <DialogContent>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <ErrorMessage error={savingError} />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <SelectFilteredSingle
                    name={field}
                    label={title}
                    defaultValue={contact}
                    repository={contactRepository}
                    onChange={handleSelectionChange}
                  />
                  <AddContactCheckMessage />
                </Grid>
                <Grid item>
                  <Box sx={{ mt: -2 }}>
                    <AddContact onAddContact={handleAddContact} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {displayContact !== null && (
              <Grid item xs={8}>
                <Box sx={{ mt: 3 }}>
                  <ContactCard contact={displayContact} />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls loading={saving} onSave={handleAssign} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
      <Button size="small" onClick={handleOpen}>
        {value !== null ? value.name : "Not Set"}
      </Button>
    </>
  )
}

export default ContactEditor
