import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { IConsultantLocation, isConsultantLocation } from "../models/IConsultantLocation"
import { Circle, GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api"
import { IMapLocation } from "../models/component/IMapLocation"
import { icon, PersonPushpinIcon } from "../utilities/icons_utilities"
import { useTheme } from "@mui/material"
import useMapType from "../hooks/useMapType"
import { DEFAULT_CENTER, GOOGLE_MAPS } from "../../config/config"

interface IProps {
  primaryLocation: IConsultantLocation | null
  additionalLocations?: IConsultantLocation[] | null
}

/**
 * Displays the consultant's location on a map.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} a map.
 */
const ConsultantLocationMap: React.FunctionComponent<IProps> = (props: IProps) => {
  const { primaryLocation, additionalLocations } = props
  const [center, setCenter] = useState<{ lat: number; lng: number }>(DEFAULT_CENTER)

  const theme = useTheme()
  const [selectedLocation, setSelectedLocation] = useState<IMapLocation | null>(null)
  const [infoOpen, setInfoOpen] = useState(false)
  const [markerMap, setMarkerMap] = useState<any | null>(null)
  const [map, setMap] = useState<any>()
  const { mapType, handleMapTypeChanged } = useMapType({ map })
  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS)

  useEffect(() => {
    if (primaryLocation !== null) {
      setCenter({
        lat: primaryLocation.latitude,
        lng: primaryLocation.longitude,
      })
    }
  }, [primaryLocation])

  const markerLoadHandler = useCallback((marker, name) => {
    setMarkerMap((prevState: any | null) => {
      if (prevState === null) {
        return { [name]: marker }
      }
      return {
        ...prevState,
        [name]: marker,
      }
    })
  }, [])

  const markerClickHandler = useCallback(
    (location: IMapLocation) => {
      // Remember which location was clicked
      setSelectedLocation(location)
      // Required so clicking a 2nd marker works as expected
      if (infoOpen) {
        setInfoOpen(false)
      }
      setInfoOpen(true)
    },
    [infoOpen]
  )

  const handleLoad = useCallback(map => {
    setMap(map)
  }, [])

  const handleUnmount = useCallback(() => {
    setMap(null)
  }, [])

  const handleAutoZoom = useCallback(
    (marker: google.maps.Circle) => {
      const center = marker.getCenter()
      if (center !== undefined && center !== null) {
        const loc = {
          lat: center.lat(),
          lng: center.lng(),
        }
        const bounds = marker.getBounds()
        if (bounds !== null) {
          bounds.extend(loc)
          map.fitBounds(bounds)
          map.panToBounds(bounds)
        }
      }
    },
    [map]
  )

  return isLoaded && center.lat !== null ? (
    <GoogleMap
      mapContainerStyle={{
        flexGrow: "1",
        height: "400px",
      }}
      center={center}
      zoom={15}
      onLoad={handleLoad}
      onMapTypeIdChanged={handleMapTypeChanged}
      onUnmount={handleUnmount}
      mapTypeId={mapType}
    >
      {primaryLocation !== null && (
        <Marker
          position={{
            lat: primaryLocation.latitude,
            lng: primaryLocation.longitude,
          }}
          icon={icon({
            path: PersonPushpinIcon,
            fillColor: theme.palette.warning,
          })}
          onLoad={marker => markerLoadHandler(marker, `${primaryLocation.latitude},${primaryLocation.longitude}`)}
          onClick={() => markerClickHandler(primaryLocation)}
        />
      )}

      {primaryLocation !== null && isConsultantLocation(primaryLocation) && (
        <Circle
          center={{
            lat: primaryLocation.latitude,
            lng: primaryLocation.longitude,
          }}
          options={{
            strokeColor: theme.palette.warning.main,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: theme.palette.warning.light,
            fillOpacity: 0.35,
          }}
          onLoad={handleAutoZoom}
          radius={primaryLocation.radius * 1609.344}
        />
      )}

      {additionalLocations?.map((location, i) => (
        <Marker
          key={i}
          position={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          icon={icon({
            path: PersonPushpinIcon,
            fillColor: theme.palette.info,
          })}
          onLoad={marker => markerLoadHandler(marker, `${location.latitude},${location.longitude}`)}
          onClick={() => markerClickHandler(location)}
        />
      ))}

      {additionalLocations?.map((location, i) => (
        <Circle
          key={i}
          options={{
            strokeColor: theme.palette.info.main,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: theme.palette.info.light,
            fillOpacity: 0.35,
          }}
          center={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          radius={location.radius * 1609.344}
        />
      ))}

      {infoOpen && selectedLocation !== null && isConsultantLocation(selectedLocation) && (
        <InfoWindow
          anchor={markerMap[`${selectedLocation.latitude},${selectedLocation.longitude}`]}
          onCloseClick={() => setInfoOpen(false)}
        >
          <div dangerouslySetInnerHTML={{ __html: selectedLocation.comment }} />
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default ConsultantLocationMap
