import * as React from "react"
import { useCallback, useState } from "react"
import Dialog from "@mui/material/Dialog"
import ContactCard from "./ContactCard"
import { type IContact } from "../models/IContact"
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material"
import DialogControls from "./DialogControls"
import MoreIcon from "@mui/icons-material/More"
import HtmlTooltip from "./HtmlToolTip"

interface IProps {
  contacts: IContact[]
}

/**
 * React functional component that displays a dialog with a list of contacts.
 * @param {IProps} props - The component props.
 * @returns {React.FC<IProps>} A React element representing the ContactsDialog component.
 */
const ContactsDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { contacts } = props
  const [open, setOpen] = useState(false)

  // Handler for opening the dialog
  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [])

  // Handler for closing the dialog
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <HtmlTooltip title={<>There are {contacts.length} additional contacts.</>} placement="right">
        <IconButton onClick={handleClickOpen} size="small">
          <MoreIcon />
        </IconButton>
      </HtmlTooltip>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Additional Contacts</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {contacts.map((contact, index) => (
              <Grid key={index} item xs={12} md={6}>
                <Box sx={{ mt: 1.5 }}>
                  <ContactCard contact={contact} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleClose} buttonLabel="Close" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ContactsDialog
