import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { CONSULTANTS_ENDPOINT, IConsultant } from "../../../shared/models/main/IConsultant"
import { truncateString } from "../../../shared/utilities/format_utility"
import {
  CONSULTANT_SPECIALTY_ENDPOINT,
  IConsultantSpecialty,
  IConsultantSpecialtyCreate,
} from "../../../shared/models/IConsultantSpecialty"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { ISpecialty, SPECIALTY_ENDPOINT } from "../../../shared/models/ISpecialty"
import useContentHeight from "../../../shared/hooks/useContentHeight"
import { styles } from "../../../shared/styling/general"
import { useApiList } from "../../../shared/hooks/useApiList"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { LoadingButton } from "@mui/lab"

const specialtyRepository = new RestRepository<ISpecialty>(SPECIALTY_ENDPOINT)
const csRepository = new RestRepository<IConsultantSpecialty | IConsultantSpecialtyCreate>(CONSULTANT_SPECIALTY_ENDPOINT)
const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)

interface IProps {
  consultant: IConsultant
  onChange: () => void
}

/**
 * Manages what the consultant has specialties in.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the specialties editor.
 */
const SpecialtiesEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { consultant, onChange } = props
  const [consultantSpecialty, setConsultantSpecialty] = useState<IConsultantSpecialty | null>(null)
  const { data: specialties, loading } = useApiList<ISpecialty[]>({ apiFunction: specialtyRepository.list })
  const tableHeight = useContentHeight()

  const [filter, setFilter] = useState("")
  const [note, setNote] = useState("")
  const [score, setScore] = useState(5)
  const [error, setError] = useState<IConnectionError | null>(null)
  const [specialtyId, setSpecialtyId] = useState<number | null>(null)
  const [consultantSpecialties, setConsultantSpecialties] = useState<IConsultantSpecialty[] | null>(null)

  const [saving, setSaving] = useState(false)

  const specialtiesUnUsed = useMemo(() => {
    if (specialties !== undefined && consultantSpecialties !== null) {
      return specialties.filter(s => {
        return !consultantSpecialties.some(cs => (cs.specialty as ISpecialty).id === s.id)
      })
    }
    return []
  }, [consultantSpecialties, specialties, specialtyId])

  const selectedSpecialtyId = useMemo(() => {
    return specialtyId !== null ? specialtyId : specialtiesUnUsed.length > 0 ? specialtiesUnUsed[0].id : specialtyId
  }, [specialtyId, specialtiesUnUsed])

  const consultantSpecialtiesFiltered = useMemo(() => {
    if (filter === "") {
      return consultantSpecialties
    }
    return consultantSpecialties?.filter(cs => (cs.specialty as ISpecialty).name.toLowerCase().includes(filter))
  }, [consultantSpecialties, filter])

  const handleConsultantSpecialty = useCallback(
    (cs: IConsultantSpecialty) => () => {
      if (consultantSpecialty?.id === cs.id) {
        setNote("")
        setScore(5)
        setSpecialtyId(null)
        setConsultantSpecialty(null)
      } else {
        setNote(cs.note)
        setScore(cs.score)
        setSpecialtyId((cs.specialty as ISpecialty).id)
        setConsultantSpecialty(cs)
      }
    },
    [consultantSpecialty]
  )

  const handleNote = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value)
  }, [])

  const handleScore = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setScore(Number(e.target.value))
  }, [])

  const handleSpecialtyId = useCallback((e: SelectChangeEvent<number | null>) => {
    setSpecialtyId(Number(e.target.value))
  }, [])

  const handleReset = useCallback(() => {
    setNote("")
    setScore(5)
    if (specialties !== undefined && specialties.length > 0) {
      setSpecialtyId(specialties[0].id)
    }
    setConsultantSpecialty(null)
  }, [specialties])

  const handleSave = useCallback(async () => {
    if (selectedSpecialtyId === null) {
      return null
    }
    setSaving(true)
    try {
      if (consultantSpecialty !== null) {
        const cs: IConsultantSpecialty = {
          id: consultantSpecialty.id,
          specialty: selectedSpecialtyId,
          consultant: consultant.id,
          note,
          score,
        }
        await csRepository.edit(cs, consultantSpecialty.id)
      } else {
        const cs: IConsultantSpecialtyCreate = {
          specialty: selectedSpecialtyId,
          consultant: consultant.id,
          note,
          score,
        }
        await csRepository.add(cs)
        handleReset()
      }
      const consultant1 = await consultantRepository.read(consultant.id)
      onChange()
      setConsultantSpecialties([...consultant1.specialties])
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
  }, [consultantSpecialty, selectedSpecialtyId, note, score, consultant, handleReset])

  const handleDelete = useCallback(async () => {
    if (consultantSpecialty === null) {
      return null
    }
    setSaving(true)
    try {
      await csRepository.delete(consultantSpecialty.id)
      const consultant1 = await consultantRepository.read(consultant.id)
      handleReset()
      setConsultantSpecialties([...consultant1.specialties])
      onChange()
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
  }, [consultantSpecialty, consultant])

  useEffect(() => {
    setConsultantSpecialties([...consultant.specialties])
  }, [consultant.id])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ErrorMessage error={error} />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <TextField label="Filter" fullWidth onChange={e => setFilter(e.target.value)} />
          </Grid>
          <Grid item>
            {consultantSpecialtiesFiltered?.length} of {consultantSpecialties?.length}
          </Grid>
        </Grid>
        <Box
          sx={{
            overflow: "auto",
            height: tableHeight - 180,
          }}
        >
          <List>
            {consultantSpecialtiesFiltered?.map(cs => (
              <ListItemButton key={cs.id} selected={cs.id === consultantSpecialty?.id} onClick={handleConsultantSpecialty(cs)}>
                <ListItemAvatar>
                  <Avatar sx={styles.specialty.avatar(cs.score)}>{cs.score}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={(cs.specialty as ISpecialty).name} secondary={truncateString(cs.note, 60)} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="baseline">
              <Grid item xs>
                <Typography variant="h4">{consultantSpecialty !== null ? "Edit" : "Add"}</Typography>
              </Grid>
              <Grid item>
                {consultantSpecialty !== null && (
                  <LoadingButton loading={saving} color="secondary" size="small" onClick={handleDelete}>
                    Delete
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {loading ? (
              <>Loading...</>
            ) : (
              <FormControl fullWidth disabled={saving}>
                {consultantSpecialty !== null ? (
                  <TextField disabled label="Specialty" value={(consultantSpecialty.specialty as ISpecialty).name} />
                ) : (
                  <>
                    <InputLabel>Specialty</InputLabel>
                    <Select value={selectedSpecialtyId} onChange={handleSpecialtyId} label="Specialty">
                      {specialtiesUnUsed?.map(specialty => (
                        <MenuItem key={specialty.id} value={specialty.id}>
                          {specialty.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField name="specialty_score" disabled={saving} type="number" label="Score" value={score} onChange={handleScore} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth multiline name="specialty_note" disabled={saving} label="Note" value={note} onChange={handleNote} />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                {consultantSpecialty !== null && (
                  <Button onClick={handleReset} disabled={saving}>
                    Cancel
                  </Button>
                )}
              </Grid>
              <Grid item>
                <LoadingButton loading={saving} color="secondary" onClick={handleSave}>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SpecialtiesEditor
