import * as React from "react"
import { useCallback, useState } from "react"
import { IInvoice, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import DialogControls from "../../../shared/components/DialogControls"

interface IProps {
  invoice: IInvoice
  onChange: (invoice: IInvoice) => void
}

interface IPatch {
  amount_overwrite: boolean
  amount: number
}

const repository = new RestRepository<IInvoice | IPatch>(INVOICE_ENDPOINT)

/**
 * Edit the total for the invoice.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const EditTotal: React.FunctionComponent<IProps> = (props: IProps) => {
  const { invoice, onChange } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const [overwrite, setOverwrite] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>("0")
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => {
    setOverwrite(invoice.amount_overwrite)
    if (invoice.amount_overwrite) {
      setAmount(`${invoice.amount}`)
    } else {
      setAmount(`${invoice.total_amount}`)
    }
    setOpen(true)
  }, [invoice.amount_overwrite, invoice.amount, invoice.total_amount])

  const handleClose = useCallback(() => setOpen(false), [])

  const handleEdit = useCallback(async () => {
    try {
      setSaving(true)
      const patch: IPatch = {
        amount_overwrite: overwrite,
        amount: Number(amount),
      }
      const invoice1 = await repository.patch(patch, invoice.id)
      onChange(invoice1 as IInvoice)
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, invoice, overwrite, amount])

  const handleOverwrite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOverwrite(event.target.checked)
  }, [])

  const handleAmount = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value)
  }, [])

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Total</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={overwrite} value={overwrite} onChange={handleOverwrite} />}
                  label="Overwrite Amount"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              {overwrite ? (
                <TextField fullWidth autoFocus label="Amount" name="amount" value={amount} onChange={handleAmount} />
              ) : (
                <>
                  <Typography variant="h4">
                    {invoice.sum_amount === 0 ? "$0" : <FormatNumber value={invoice.sum_amount} twoDecimalPlaces />}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs />
            <Grid item xs={4}>
              {overwrite && <FormatNumber value={Number(amount)} prefixUnits="$" twoDecimalPlaces />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleEdit} loading={saving} onCancel={handleClose} disabled={amount === ""} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditTotal
