import * as React from "react"
import { useCallback, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { CATEGORY_OPTIONS, IInvoiceLineItem, INVOICE_LINE_ITEM_ENDPOINT } from "../../../shared/models/IInvoiceLineItem"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import EditIcon from "@mui/icons-material/Edit"
import DialogControls from "../../../shared/components/DialogControls"

interface IProps {
  lineItem: IInvoiceLineItem
  onChange: () => void
}

interface IPatch {
  description: string
  category: string
  amount: number
}

const repository = new RestRepository<IPatch>(INVOICE_LINE_ITEM_ENDPOINT)

/**
 * This component will edit a line item.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} The edit line item component.
 */
const EditLineItem: React.FunctionComponent<IProps> = (props: IProps) => {
  const { lineItem, onChange } = props
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const [description, setDescription] = useState<string>(lineItem.description)
  const [category, setCategory] = useState<string>(lineItem.category)
  const [amount, setAmount] = useState<string>(`${lineItem.amount}`)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleEdit = useCallback(async () => {
    try {
      setSaving(true)
      const patch: IPatch = {
        description,
        category,
        amount: Number(amount),
      }
      await repository.patch(patch, lineItem.id)
      onChange()
      setSaving(false)
      handleClose()
    } catch (reason: any) {
      setSaving(false)
      if (reason?.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
  }, [onChange, lineItem, description, category, amount])

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Edit Line Item</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <>
                <TextField fullWidth label="Cateogry" select onChange={e => setCategory(e.target.value)} value={category}>
                  {CATEGORY_OPTIONS.map(inspectionType => (
                    <MenuItem key={inspectionType.key} value={inspectionType.key}>
                      {inspectionType.value}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            </Grid>
            <Grid item xs={2}>
              <TextField fullWidth label="Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleEdit} loading={saving} onCancel={handleClose} disabled={description === "" || amount === ""} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditLineItem
