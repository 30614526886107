import * as React from "react"
import { useCallback } from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { CUSTOMERS_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../shared/models/ICustomer"
import CustomerForm from "./components/CustomerForm"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiAdd, { IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import { dateAsIso } from "../../shared/utilities/format_utility"
import moment from "moment"

const repository = new RestRepository<ICustomer>(CUSTOMER_ENDPOINT)
const redirectView = CUSTOMERS_VIEW_URL

/**
 * This displays the add page for a customer.
 *
 * @returns {React.FunctionComponent} the page.
 */
const AddPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setError, formState, setValue, clearErrors } = useForm()

  const props: IUseApiAddProps<ICustomer> = {
    apiFunction: repository.add,
    setError,
    redirectView,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<ICustomer>(props)

  const handleSave = useCallback(async (customer1: ICustomer) => {
    customer1.executive = customer1.executive === undefined ? null : customer1.executive
    customer1.invoice_contact = customer1.invoice_contact === undefined ? null : customer1.invoice_contact
    customer1.technical_admin = customer1.technical_admin === undefined ? null : customer1.technical_admin
    customer1.service_instructions_change_date = dateAsIso(customer1.service_instructions_change_date as moment.Moment)
    await handleAdd(customer1)
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Add Customer" loading={saving} errors={formState.errors} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <CustomerForm control={control} setValue={setValue} clearErrors={clearErrors} />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
