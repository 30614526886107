import * as React from "react"
import { FormEvent, useCallback, useMemo, useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { RestRepository } from "../repositories/RestRepository"
import ErrorMessage from "./ErrorMessage"
import { truncateString } from "../utilities/format_utility"
import { DefaultEditor } from "react-simple-wysiwyg"
import HtmlTooltip from "./HtmlToolTip"
import { OverridableStringUnion } from "@mui/types"
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button"
import NotesIcon from "@mui/icons-material/Notes"
import DialogControls from "./DialogControls"

interface IProps {
  item: any
  modelId: number
  field: string
  title: string
  buttonTitle?: string | null
  onChange: (item: any) => void
  value: string | null
  repository: RestRepository<any>
  size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>
}

/**
 * This component assists in changing a html field for a model.
 *
 * @param {IProps} props See IProps for more details.
 * @returns {React.FunctionComponent<IProps>} the component for changing html text.
 */
const ChangeHtmlField: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, item, title, value, buttonTitle = null, modelId, onChange, repository, size = "small" } = props

  const [open, setOpen] = useState(false)
  const [newValue, setNewValue] = useState<string>(value === null ? "" : value)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | undefined>()

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleClose = useCallback(() => setOpen(false), [])

  const handleSave = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      setSaving(true)
      setSavingError(undefined)
      try {
        await repository.patch({ [field]: newValue } as any, modelId)
        onChange({
          ...item,
          [field]: newValue,
        })
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
      setOpen(false)
    },
    [newValue, item, field, modelId]
  )

  const isValueNotEmpty = useMemo(() => {
    return value !== null && value !== ""
  }, [value])

  return (
    <>
      <HtmlTooltip
        title={isValueNotEmpty ? <Box sx={{ p: 1 }} dangerouslySetInnerHTML={{ __html: value as string }} /> : ""}
        placement="right"
      >
        <Button size={size} onClick={handleOpen} startIcon={<NotesIcon />}>
          <>
            {isValueNotEmpty ? (
              buttonTitle === null ? (
                <Box dangerouslySetInnerHTML={{ __html: truncateString(value) as string }} />
              ) : (
                <>{buttonTitle}</>
              )
            ) : (
              `Add ${title}`
            )}
          </>
        </Button>
      </HtmlTooltip>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Edit {title}</DialogTitle>
        <DialogContent>
          <ErrorMessage error={savingError} />
          <form method="post" onSubmit={handleSave}>
            <Box sx={{ mt: 1 }}>
              <DefaultEditor onChange={e => setNewValue(e.target.value)} value={newValue} />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} loading={saving} onCancel={handleClose} disabled={newValue === value} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeHtmlField
