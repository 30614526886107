import { IFilter } from "../../../shared/models/IFilter"
import { FILTERS } from "../../../config/config"

export interface IWidget {
  id: number
  title: string
  description: string
  order: string
  show: boolean
  position: number
  filters: IFilter[]
}

// todo: need to organize filters and reports. Move these filters to config.
export const widgets: IWidget[] = [
  {
    id: 0,
    title: "Past Consultant Due Date",
    description: "Below are the work assignments with a past consultant due date where you are the executive.",
    order: "consultant_due_date",
    show: true,
    position: 0,
    filters: [
      {
        field: "past_consultant_due_date",
        value: "true",
        display: "true",
        title: "Past Consultant Due Date",
      },
    ],
  },
  {
    id: 1,
    title: "Reports Due to Client",
    description: "Client reports late, due today, or due in 10 days where you are the executive.",
    order: "due_date",
    show: true,
    position: 1,
    filters: [
      {
        field: "late_today_tomorrow",
        value: "true",
        display: "true",
        title: "Reports Due to Client",
      },
    ],
  },
  {
    id: 2,
    title: "Late Assignments",
    description: "Late assignments where you are the executive.",
    order: "consultant_due_date",
    show: true,
    position: 2,
    filters: [
      {
        ...FILTERS.PROGRESS,
        value: "progress_invoice_sent_account",
        display: "Report Sent to Client",
      },
    ],
  },
  {
    id: 3,
    title: "Assignments with Reports Sent",
    description: "Work assignments where you are the executive and reports have been sent.",
    order: "progress_report_sent_account",
    show: true,
    position: 3,
    filters: [
      {
        ...FILTERS.REPORT_SENT_TO_CLIENT,
        value: "true",
        display: "true",
      },
      {
        ...FILTERS.DUE_DATE,
        value: "is_late",
        display: "Is Late",
      },
    ],
  },
  {
    id: 4,
    title: "Site Visit Date Not Scheduled",
    description:
      "Below are surveys where the site visit date has not been scheduled and the consultant accepted the job 5 or more days ago and where you are the executive.",
    order: "due_date",
    show: true,
    position: 4,
    filters: [
      {
        field: "inspections_due_in_10_days",
        value: "true",
        title: "Site Visit Date Not Scheduled",
      },
    ],
  },
  {
    id: 5,
    title: "Assignments Not Visited and Due in 30",
    description: "List of surveys not yet visited or scheduled with due date to client <30 days where you are the executive.",
    order: "due_date",
    show: true,
    position: 5,
    filters: [
      {
        title: "Visit Date",
        field: "visit_date",
        value: "has_not_occurred",
        display: "Has Not Occurred",
      },
      {
        title: "Due Date",
        field: "due_date",
        value: "in_next_30_days",
        display: "In Next 30 Days",
      },
    ],
  },
  {
    id: 6,
    title: "Assignments Not Assigned and Due in 30",
    description: "List of surveys not assigned and due in <30 days where you are the executive.",
    order: "due_date",
    show: true,
    position: 6,
    filters: [
      {
        title: "Progress (Not Set)",
        field: "progress_not_set",
        multiple: true,
        value: "progress_consultant_request_confirmed",
        display: "Confirm Consultant",
      },
      {
        title: "Due Date",
        field: "due_date",
        value: "in_next_30_days",
        display: "In Next 30 Days",
      },
    ],
  },
  {
    id: 7,
    title: "Reports in Admin Review",
    description: "List of surveys in admin review where you are the executive.",
    order: "due_date",
    show: true,
    position: 7,
    filters: [
      {
        field: FILTERS.DAILY.field,
        value: "true",
        display: "true",
      },
      {
        title: "Progress (Next Step)",
        field: "progress",
        value: "progress_report_review_admin_sign_off",
        display: "Report Review by Admin",
      },
    ],
  },
  {
    id: 8,
    title: "Reports in Tech Review",
    description: "List of surveys in tech review where you are the executive.",
    order: "due_date",
    show: true,
    position: 8,
    filters: [
      {
        field: FILTERS.DAILY.field,
        value: "true",
        display: "true",
      },
      {
        title: "Progress (Next Step)",
        field: "progress",
        value: "progress_report_review_tech_sign_off",
        display: "Report Review by Tech Reviewers",
      },
    ],
  },
]
