import * as React from "react"
import { useCallback, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { IWorkAssignmentExtension, WORK_ASSIGNMENT_EXTENSION_ENDPOINT } from "../../../shared/models/IWorkAssignmentExtension"
import Moment from "react-moment"
import { DATE_FORMAT } from "../../../config/config"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { EXTENSION_TYPE_ENDPOINT, IExtensionType } from "../../../shared/models/main/IExtensionType"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { DefaultEditor } from "react-simple-wysiwyg"
import TruncateText from "../../../shared/components/TruncateText"
import DialogControls from "../../../shared/components/DialogControls"
import AddIcon from "@mui/icons-material/Add"
import { IListItem } from "../../../shared/models/component/IListItem"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"

const extensionTypeRepository = new RestRepository<IListItem>(EXTENSION_TYPE_ENDPOINT)
const waExtensionRepository = new RestRepository<IWorkAssignmentExtension>(WORK_ASSIGNMENT_EXTENSION_ENDPOINT)
const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  wa: IWorkAssignment
  onChange: (wa: IWorkAssignment) => void
}

/**
 * Edit extensions for a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the extensions editor component.
 */
const ExtensionsEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wa, onChange } = props

  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [open, setOpen] = useState(false)
  const [notes, setNotes] = useState<string>("")
  const [extensionType, setExtensionType] = useState<IListItem | null>(null)
  const [newConsultantDueDate, setNewConsultantDueDate] = useState<string>("")
  const [newDueDate, setNewDueDate] = useState<string>("")
  const [error, setError] = useState<IConnectionError | undefined>()

  const handleClose = useCallback(() => {
    if (!saving) {
      setOpen(false)
    }
  }, [saving])
  const handleOpen = useCallback(() => setOpen(true), [])

  const handleExtensionType = useCallback((et: IListItem | null) => {
    setExtensionType(et)
  }, [])

  const handleCreate = useCallback(async () => {
    if (extensionType !== null) {
      setSaving(true)
      try {
        const waExtension: IWorkAssignmentExtension = {
          extension_type: extensionType.id as number,
          work_assignment: wa.id,
          notes,
          new_consultant_due_date: newConsultantDueDate !== "" ? newConsultantDueDate : null,
          new_due_date: newDueDate !== "" ? newDueDate : null,
        }
        await waExtensionRepository.add(waExtension)
        const wa1 = await waRepository.read(wa.id)
        onChange(wa1)
        setOpen(false)
        setNotes("")
        setNewDueDate("")
        setNewConsultantDueDate("")
        setExtensionType(null)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    }
  }, [wa, notes, newConsultantDueDate, newDueDate, extensionType])

  const handleSignOff = useCallback(
    waExtensionId => async () => {
      setLoading(true)
      await waExtensionRepository.action(waExtensionId, "sign_off")
      const wa1 = await waRepository.read(wa.id)
      onChange(wa1)
      setLoading(false)
    },
    [wa]
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ErrorMessage error={error} />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button startIcon={<AddIcon />} onClick={handleOpen}>
          Extension
        </Button>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
          <DialogTitle>New Extension</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <SelectFilteredSingle
                  name="extension_type"
                  label="Extension Type"
                  repository={extensionTypeRepository}
                  onChange={handleExtensionType}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  value={newConsultantDueDate}
                  onChange={e => setNewConsultantDueDate(e.target.value)}
                  helperText={newConsultantDueDate !== "" && <Moment format={DATE_FORMAT}>{newConsultantDueDate}</Moment>}
                  InputLabelProps={{ shrink: true }}
                  label="New Consultant Due Date"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  value={newDueDate}
                  onChange={e => setNewDueDate(e.target.value)}
                  helperText={newDueDate !== "" && <Moment format={DATE_FORMAT}>{newDueDate}</Moment>}
                  InputLabelProps={{ shrink: true }}
                  label="New Due Date"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink>Notes</InputLabel>
                <DefaultEditor value={notes} onChange={e => setNotes(e.target.value)} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogControls onSave={handleCreate} onCancel={handleClose} loading={saving} />
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                New Consultant
                <br />
                Due Date
              </TableCell>
              <TableCell>New Due Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Sign Off By</TableCell>
              <TableCell>Sign Off</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(wa.extensions as IWorkAssignmentExtension[]).map(extension => (
              <TableRow key={extension.id}>
                <TableCell>{(extension.extension_type as IExtensionType).name}</TableCell>
                <TableCell>
                  {extension.new_consultant_due_date !== null && <Moment format={DATE_FORMAT}>{extension.new_consultant_due_date}</Moment>}
                </TableCell>
                <TableCell>{extension.new_due_date !== null && <Moment format={DATE_FORMAT}>{extension.new_due_date}</Moment>}</TableCell>
                <TableCell>
                  <TruncateText text={extension.notes} placement="bottom" />
                </TableCell>
                <TableCell>
                  <Moment format={DATE_FORMAT}>{extension.created}</Moment>
                </TableCell>
                <TableCell>{extension.sign_off_by?.name}</TableCell>
                <TableCell>
                  {extension.sign_off !== null ? (
                    <Moment format={DATE_FORMAT}>{extension.sign_off}</Moment>
                  ) : (
                    <Button disabled={loading} size="small" onClick={handleSignOff(extension.id)}>
                      Sign Off
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {(wa.extensions as IWorkAssignmentExtension[]).length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>No extensions.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default ExtensionsEditor
