import { ErrorOption } from "react-hook-form";

/**
 * Check if an object is a connection error.
 *
 * @param {any} obj to check.
 * @returns {boolean} true if it is a connection error object.
 */
export const isConnectionError = (obj: any): obj is IConnectionError => obj?.objType === "IConnectionError"

export type TSetFormError = (name: string, error: ErrorOption, options?: { shouldFocus: boolean }) => void

/**
 * Map the connection errors to form errors.
 *
 * @param {IConnectionError} errors to map to the errors.
 * @param {TSetFormError} setError the form to set the errors to.
 */
export const setFormConnectionErrors = (errors: IConnectionError, setError: TSetFormError): void => {
  Object.keys(errors).forEach(field => {
    const messages = (errors as any)[field]
    let message = ""
    if (Array.isArray(messages)) {
      message += (messages as string[]).join(", ")
    } else {
      message += Object.values(messages).join(", ")
    }
    setError(field, {
      type: "manual",
      message,
    })
  })
}

export const CONNECTION_ERROR = {
  data: { error: ["Connection Error"] },
  objType: "Error",
  code: "0",
}

interface MapType {
  [id: string]: string[]
}

export interface IConnectionError {
  objType?: string
  data: MapType
  code?: string
}
