import * as React from "react"
import { IWorkAssignmentConsultant } from "../models/IWorkAssignmentConsultant"
import { Chip, Link, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { Link as BrowserLink } from "gatsby"
import { CONSULTANTS_VIEW_URL } from "../../config/urls"
import { IConsultant } from "../models/main/IConsultant"
import FormatNumber from "./format/FormatNumber"
import FormatDate from "./format/FormatDate"

interface IProps {
  wacs?: IWorkAssignmentConsultant[]
}

/**
 * List work assignment consultants in a table for display.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} a list of consultants.
 */
const WorkAssignmentConsultantsList: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wacs } = props

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {wacs?.map(wac => (
            <TableRow key={wac.id}>
              <TableCell>
                <Link
                  component={BrowserLink}
                  sx={{
                    ml: 1,
                    whiteSpace: "nowrap",
                  }}
                  to={`${CONSULTANTS_VIEW_URL}/${(wac.consultant as IConsultant).id}`}
                >
                  {(wac.consultant as IConsultant).name}
                </Link>
              </TableCell>
              <TableCell>
                {wac.accepted && <Chip sx={{ ml: 1 }} size="small" color="success" label="Accepted" />}
                {wac.declined && <Chip sx={{ ml: 1 }} size="small" color="error" label="Declined" />}
              </TableCell>
              <TableCell>
                <FormatNumber value={wac.amount} suffixUnits={wac.hourly_rate ? "/hr" : ""} />
              </TableCell>
              <TableCell>
                <FormatDate value={wac.created} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WorkAssignmentConsultantsList
