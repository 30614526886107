import { IWorkAssignment } from "../models/IWorkAssignment"
import * as React from "react"
import { IConsultant } from "../models/main/IConsultant"
import { Box } from "@mui/material"
import WorkAssignmentConsultantsList from "./WorkAssignmentConsultantsList"
import HtmlTooltip from "./HtmlToolTip"

interface IProps {
  wa: IWorkAssignment
}

/**
 * Displays the first consultant found that accepted the work assignment and the number of other consultants.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultant and count.
 */
const WorkAssignmentConsultantName: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wa } = props

  if (wa.work_assignment_consultants === undefined || wa.work_assignment_consultants.length === 0) {
    return <>No Requests</>
  }

  const waAccepted = wa.work_assignment_consultants.filter(c => c.accepted)

  if (waAccepted.length > 0) {
    const waConsultant = waAccepted[0]
    const consultant = waConsultant?.consultant as IConsultant
    return (
      <HtmlTooltip title={<WorkAssignmentConsultantsList wacs={wa.work_assignment_consultants} />} placement="right">
        <Box>
          {consultant.name} ({wa.work_assignment_consultants.length})
        </Box>
      </HtmlTooltip>
    )
  }

  return (
    <HtmlTooltip title={<WorkAssignmentConsultantsList wacs={wa.work_assignment_consultants} />} placement="right">
      <Box>None Accepted ({wa.work_assignment_consultants.length})</Box>
    </HtmlTooltip>
  )
}

export default WorkAssignmentConsultantName
