import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { IFilter } from "../models/IFilter"
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import LinkIcon from "@mui/icons-material/Link"
import CopyIcon from "@mui/icons-material/CopyAll"
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string"
import { useLocation } from "@reach/router"
import { styles } from "../styling/general"
import TabPanel, { useTabPanel } from "./TabPanel"
import { CopyToClipboard } from "react-copy-to-clipboard"
import DialogControls from "./DialogControls"

interface IProps {
  filters: IFilter[] | undefined
  onFilter: (filters: IFilter[]) => void
}

/**
 * Provides a means of sharing filters between users via link.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the filter component.
 */
const FilterSharing: React.FunctionComponent<IProps> = (props: IProps) => {
  const { filters, onFilter } = props
  const [open, setOpen] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const location = useLocation()
  const { tab, handleTabChange } = useTabPanel()
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const [filtersEncoded, setFiltersEncoded] = useState<string>("")
  const [filtersDecoded, setFiltersDecoded] = useState<IFilter[] | null>(null)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleApplyFilters = useCallback(() => {
    if (filtersDecoded !== null) {
      onFilter(filtersDecoded)
      setOpen(false)
    }
  }, [filtersDecoded])

  useEffect(() => {
    if (filters !== undefined && filters.length > 0) {
      const filtersJson = JSON.stringify(filters)
      const b64encoded = compressToEncodedURIComponent(filtersJson)
      setFiltersEncoded(b64encoded)
    }
  }, [filters?.length])

  useEffect(() => {
    if (location.search !== "") {
      const searchParams = new URLSearchParams(location.search)
      const b64encoded = searchParams.get("filters")
      if (b64encoded !== null) {
        const b64decoded = decompressFromEncodedURIComponent(b64encoded)
        setFiltersDecoded(JSON.parse(b64decoded))
        setOpen(true)
        handleTabChange(null, 1)
      }
    }
  }, [location])

  const filterDisplay = useCallback((filter: IFilter) => {
    if (filter?.display !== undefined) {
      return ` | ${filter.display}`
    }
    if (filter?.value !== undefined) {
      return ` | ${filter.value}`
    }
    return ""
  }, [])

  const filterUrl = `${location.origin + location.pathname}?filters=${filtersEncoded}`

  return (
    <>
      <Button startIcon={<LinkIcon />} onClick={handleOpen} size="small">
        Share
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth fullScreen={isSmall} maxWidth="sm">
        <DialogTitle>Filter Sharing</DialogTitle>
        <Box
          sx={{
            ...styles.tabsBox,
            mt: 0,
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Send" />
            <Tab label="Receive" />
          </Tabs>
        </Box>
        <DialogContent>
          <TabPanel value={tab} index={0}>
            {filters !== undefined && filters.length > 0 ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <TextField fullWidth value={filterUrl} label="Link" />
                </Grid>
                <Grid item>
                  <CopyToClipboard text={filterUrl} onCopy={() => setCopied(true)}>
                    <IconButton>
                      <CopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  <Snackbar
                    open={copied}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    autoHideDuration={2000}
                    onClose={() => setCopied(false)}
                  >
                    <Alert severity="info" sx={{ width: "100%" }}>
                      Link copied to clipboard.
                    </Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs>
                  No filters have been selected.
                </Grid>
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {filtersDecoded !== null && filtersDecoded.length > 0 ? (
              <Paper sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <List dense>
                      {filtersDecoded.map((filter, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={`${filter?.title !== undefined ? filter.title : ""}${filterDisplay(filter)}`} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Button onClick={handleApplyFilters}>Load</Button>
                  </Grid>
                </Grid>
              </Paper>
            ) : (
              <Grid container>
                <Grid item xs>
                  No filters found in the url.
                </Grid>
              </Grid>
            )}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <DialogControls buttonLabel="Close" onSave={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FilterSharing
