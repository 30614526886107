import { Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material"
import * as React from "react"
import { useMemo } from "react"

interface IProps {
  loading: boolean
  rows?: number
}

/**
 * Displays a loading indicator for a table.
 *
 * @param {IProps} props is IProps for details.
 * @returns {React.FC<IProps>} the table loading component.
 */
const TableLoadingFull: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { loading, rows = 10 } = props

  const rowIndex = useMemo(() => {
    return Array.from({ length: rows }, (x, i) => i)
  }, [rows])

  return loading ? (
    <Table size="small">
      <TableBody>
        {rowIndex.map(i => (
          <TableRow key={i}>
            <TableCell>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <></>
  )
}

export default TableLoadingFull
