import * as React from "react"
import { useCallback } from "react"
import { Box } from "@mui/material"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { EXPENSE_REPORTS_VIEW_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormActionPanel from "../../shared/components/FormActionPanel"
import { styles } from "../../shared/styling/general"
import HeaderPanel from "../../shared/components/HeaderPanel"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../shared/models/IExpenseReport"
import useApiAdd, { IUseApiAddProps } from "../../shared/hooks/useApiAdd"
import ErrorMessage from "../../shared/components/ErrorMessage"
import ExpenseReportForm from "./components/ExpenseReportForm"
import { dateAsIso } from "../../shared/utilities/format_utility"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)
const redirectView = EXPENSE_REPORTS_VIEW_URL

/**
 * This page will add an expense_report.
 *
 * @returns {React.FunctionComponent} the add expense_report page.
 */
const AddPage: React.FunctionComponent = () => {
  const { control, handleSubmit, setError, formState, setValue } = useForm()

  const props: IUseApiAddProps<IExpenseReport> = {
    apiFunction: repository.add,
    setError,
    redirectView,
  }
  const { saving, handleAdd, connectionError } = useApiAdd<IExpenseReport>(props)

  const handleSave = useCallback(async (expenseReport1: IExpenseReport) => {
    expenseReport1.pay_period = dateAsIso(expenseReport1.pay_period)
    await handleAdd(expenseReport1)
  }, [])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <HeaderPanel title="Add Expense Report" loading={saving} errors={formState.errors} />
        <ErrorMessage error={connectionError} />
        <Box component="form" onSubmit={handleSubmit(handleSave as SubmitHandler<FieldValues>)} autoComplete="off" sx={{ p: 2 }}>
          <ExpenseReportForm control={control} setValue={setValue} />
          <FormActionPanel />
        </Box>
      </Box>
    </WorkflowPage>
  )
}

export default AddPage
