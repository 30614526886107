import React, { useCallback, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogControls from "../../../shared/components/DialogControls"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { IInvoice, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import { IPaging } from "../../../shared/models/IPaging"

const invoiceRepository = new RestRepository<IInvoice>(INVOICE_ENDPOINT)

interface IProps {
  invoice: IInvoice
  onChange: () => void
}

/**
 * EditQuickbooksId is a functional component that allows the user to edit the QuickBooks Customer ID for an invoice.
 * It renders a button that opens a dialog containing an input field for the user to enter the new QuickBooks Customer ID.
 * The component also provides a callback function to handle the saving of the new Customer ID.
 *
 * @param {IProps} props - The component props.
 * @param {IInvoice} props.invoice - The invoice object.
 * @param {Function} props.onChange - The callback function to trigger when the QuickBooks Customer ID is saved.
 * @returns {React.ReactElement} The rendered component.
 */
const EditQuickbooksId: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { invoice, onChange } = props

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [customerId, setCustomerId] = useState<string>("")

  // handle dialog open
  const handleClickOpen = useCallback(() => {
    if (invoice.primary_contact_for_billing.quickbooks_identifier !== null) {
      setCustomerId(invoice.primary_contact_for_billing.quickbooks_identifier)
    } else {
      setCustomerId("")
    }
    setOpen(true)
  }, [invoice])

  // handle dialog close
  const handleClose = useCallback(() => {
    setOpen(false)
    setCustomerId("")
  }, [])

  // handle customer id change
  const handleCustomerIDChange = useCallback(event => {
    setCustomerId(event.target.value)
  }, [])

  // handle customer id saving
  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      const paging: IPaging = { filters: [{ field: "quickbooks_identifier", value: customerId }] }
      await invoiceRepository.action(invoice.id, "set_customer_with_intuit_id", paging)
      onChange()
      setOpen(false)
      setCustomerId("")
    } catch (e: any) {
      setOpen(false)
    }
    setLoading(false)
  }, [invoice, customerId])

  return (
    <>
      <Button fullWidth onClick={handleClickOpen}>Edit QB ID</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter QuickBooks Customer ID</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" label="Customer ID" fullWidth value={customerId} onChange={handleCustomerIDChange} />
        </DialogContent>
        <DialogActions>
          <DialogControls loading={loading} onSave={handleSave} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditQuickbooksId
