import * as React from "react"
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"

import WorkflowPage from "../../shared/components/WorkflowPage"
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material"
import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import ViewLoading from "../../shared/components/ViewLoading"
import { Link as RouterLink, useNavigate, useParams } from "@reach/router"
import { CUSTOMER_ENDPOINT, customerTypeName, type ICustomer } from "../../shared/models/ICustomer"
import ViewProperty from "../../shared/components/ViewProperty"
import Moment from "react-moment"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import { DATE_TIME_FORMAT } from "../../config/config"
import { ACCOUNTS_ADD_URL, ACCOUNTS_VIEW_URL, CUSTOMERS_EDIT_URL } from "../../config/urls"
import { RestRepository } from "../../shared/repositories/RestRepository"
import UserCard from "../../shared/components/UserCard"
import ContactCard from "../../shared/components/ContactCard"
import TableRowSelect from "../../shared/components/TableRowSelect"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../shared/models/IAccount"
import { type IFilter } from "../../shared/models/IFilter"
import { type IPagedResults } from "../../shared/models/IPagedResults"
import { type IPaging } from "../../shared/models/IPaging"
import { CONNECTION_ERROR, type IConnectionError, isConnectionError } from "../../shared/models/IConnectionError"
import TablePaging from "../../shared/components/TablePaging"
import FilesEditor from "../../shared/components/FilesEditor"
import { type IFile } from "../../shared/models/IFile"
import { CUSTOMER_FILE_ENDPOINT } from "../../shared/models/ICustomerFile"
import FormatDate from "../../shared/components/format/FormatDate"
import Goto from "../../shared/components/Goto"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { type DashboardStore } from "../../store"
import type ICustomersViewState from "../../store/settings/models/ICustomersViewState"
import { operations } from "../../store/settings"
import { type IListItem } from "../../shared/models/component/IListItem"
import type IUser from "../../shared/models/IUser"
import TruncateText from "../../shared/components/TruncateText"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import TableLoadingFull from "../../shared/components/TableLoadingFull"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"

const customerRepository = new RestRepository<ICustomer>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const fileRepository = new RestRepository<IFile>(CUSTOMER_FILE_ENDPOINT)

const limit = 10

/**
 * Displays customer and all the accounts associated with it.
 * @returns {React.FC} the view component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: customer, error, loading, call: refreshCustomer } = useApiRead<ICustomer>({ apiFunction: customerRepository.read })

  const { id } = useParams()
  const viewState = useSelector((state: DashboardStore) => state.settings.customersView, shallowEqual)

  const [accounts, setAccounts] = useState<IPagedResults<IAccount> | null>(null)
  const [accountsLoading, setAccountsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [page, setPage] = useState(viewState?.id === id ? viewState?.page : 1)

  const { tab, handleTabChange } = useTabPanel()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const newState: ICustomersViewState = {
      id,
      page,
    }
    dispatch(operations.setCustomersView(newState))
  }, [id, page])

  const handleSelected = useCallback(async (c: ICustomer) => {
    await navigate(`${ACCOUNTS_VIEW_URL}/${c.id}`)
  }, [])

  const handlePaging = useCallback((_e: ChangeEvent<unknown> | null, page1: number) => {
    setPage(page1)
    setAccounts(null)
  }, [])

  const loadAccounts = useCallback(async () => {
    try {
      if (customer !== undefined) {
        setAccountsLoading(true)
        const filters: IFilter[] = [
          {
            field: "customer",
            value: customer.id,
          },
        ]
        const paging: IPaging = {
          filters,
          limit,
          offset: limit * (page - 1),
        }
        const results = await accountRepository.findAll(paging)
        if (!isConnectionError(results)) {
          setAccounts(results as IPagedResults<IAccount>)
        }
        setAccountsLoading(false)
      }
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage(reason.response as IConnectionError)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
  }, [customer, page])

  const handleDataRefresh = useCallback(async () => {
    setAccountsLoading(true)
    try {
      setErrorMessage(null)
      await loadAccounts()
      refreshCustomer()
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage(reason.response as IConnectionError)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
    setAccountsLoading(false)
  }, [loadAccounts])

  const gotoFilters = useMemo(() => {
    if (customer !== undefined) {
      return [
        {
          field: "customer",
          value: customer.id,
        },
      ] as IFilter[]
    }
    return null
  }, [customer])

  useEffect(() => {
    if (customer !== undefined && accounts == null) {
      void (async () => {
        await loadAccounts()
      })()
    }
  }, [customer, accounts, loadAccounts])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading} />
        <ErrorMessage error={error} />
        <ErrorMessage error={errorMessage} />
        {customer !== undefined && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="h4" component="h2" onClick={handleDataRefresh} sx={{ cursor: "pointer" }}>
                    {customer.name}
                    <Box component="small" sx={styles.consultant.identifier}>
                      {customer.identifier}
                    </Box>
                  </Typography>
                  <Typography variant="h6" component="span">
                    Since <Moment format="YYYY">{customer.since}</Moment>
                  </Typography>
                  <Chip label={customerTypeName(customer)} color="secondary" sx={{ ml: 1 }} />
                </Grid>
                <Grid item xs>
                  <ViewLoading loading={loading} message="Updating..." />
                </Grid>
                <Grid item>
                  {customer.service_instructions_url !== "" && (
                    <Button component={Link} href={customer.service_instructions_url} target="_blank">
                      Service Instructions
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button startIcon={<EditIcon />} component={RouterLink} to={`${CUSTOMERS_EDIT_URL}/${customer.id}`}>
                    Customer
                  </Button>
                </Grid>
                <Grid item>
                  <HelpDocsDrawer showButton showButtonLabel={false} pageId={234} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {customer.contacts.map(contact => (
                  <Grid item key={contact.id} lg={3} md={5} xs={12}>
                    <ContactCard contact={contact} />
                  </Grid>
                ))}
                {customer.invoice_contact !== null && (
                  <Grid item lg={3} md={5} xs={12}>
                    <ContactCard contact={customer.invoice_contact} prefixLabel="Invoice" />
                  </Grid>
                )}
                {customer.executive !== undefined && customer.executive !== null && (
                  <Grid item lg={3} md={5} xs={12}>
                    <UserCard user={customer.executive} label="AE" />
                  </Grid>
                )}
                {customer.technical_admin !== undefined && customer.technical_admin !== null && (
                  <Grid item lg={3} md={5} xs={12}>
                    <UserCard user={customer.technical_admin} label="TA" />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.tabsBox}>
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Accounts" />
                  <Tab label="Files" />
                  <Tab label="Additional Info" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} md={4} lg={3}>
                    {gotoFilters !== null && <Goto repository={accountRepository} filters={gotoFilters} url={ACCOUNTS_VIEW_URL} />}
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Button
                      startIcon={<AddIcon />}
                      component={RouterLink}
                      to={ACCOUNTS_ADD_URL}
                      state={{
                        customer: {
                          id: customer.id,
                          name: customer.name,
                        },
                      }}
                    >
                      Account
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>AE</TableCell>
                        <TableCell>TA</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Locations</TableCell>
                        <TableCell>Updated</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accounts !== null &&
                        !accountsLoading &&
                        accounts.count > 0 &&
                        accounts.results.map(account => (
                          <TableRowSelect key={account.id} item={account} onSelected={handleSelected}>
                            <TableCell>{account.identifier}</TableCell>
                            <TableCell>
                              <TruncateText text={account.name} />
                            </TableCell>
                            <TableCell>{(account.executive as IUser)?.name}</TableCell>
                            <TableCell>{(account.technical_admin as IUser)?.name}</TableCell>
                            <TableCell>
                              <ChangeHtmlField
                                modelId={account.id}
                                item={account}
                                field="notes"
                                title="Notes"
                                onChange={handleDataRefresh}
                                value={account.notes}
                                repository={accountRepository}
                              />
                            </TableCell>
                            <TableCell>{account.locations_count}</TableCell>
                            <TableCell>
                              <FormatDate value={account.updated} />
                            </TableCell>
                          </TableRowSelect>
                        ))}
                    </TableBody>
                  </Table>
                  {!accountsLoading && (accounts === undefined || accounts?.results.length === 0) && (
                    <Alert color="info" sx={{ m: 1 }}>
                      No accounts found.
                    </Alert>
                  )}
                  <TableLoadingFull loading={accountsLoading} rows={limit} />
                </TableContainer>
                {accounts != null && (
                  <TablePaging count={Math.ceil(accounts.count / limit)} total={accounts.count} page={page} onPaging={handlePaging} />
                )}
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <FilesEditor parentId={customer.id} fileRepository={fileRepository} fieldName="customer" />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ViewProperty label="Notes" vertical>
                      <Box dangerouslySetInnerHTML={{ __html: customer.notes }}></Box>
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={6}>
                    <ViewProperty label="Consultant Due Date - Days After Visit">
                      {customer.consultant_due_date_days_after_visit}
                    </ViewProperty>
                    <ViewProperty label="Created">
                      <Moment format={DATE_TIME_FORMAT}>{customer.created}</Moment>
                    </ViewProperty>
                    <ViewProperty label="Updated">
                      <Moment format={DATE_TIME_FORMAT}>{customer.updated}</Moment>
                    </ViewProperty>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
