import { IAccount } from "./IAccount"
import { ICustomer } from "./ICustomer"
import { IExpenseReportCategory } from "../IExpenseReportCategory"

export const EXPENSE_REPORT_ADMIN_ITEMS_ENDPOINT = "expense_report_admin_items"

export interface IExpenseReportAdminItem {
  id: number
  customer: ICustomer | null
  account: IAccount | null

  description: string
  hours: number
  on_date: string | null

  expense_report_category: IExpenseReportCategory | null

  created: string
  updated: string
}
