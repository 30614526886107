import { Link } from "@mui/material"
import * as React from "react"

interface IProps {
  latitude: number | null
  longitude: number | null
}

/**
 * A link to open a new Google Maps page showing the location.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the Google Maps link.
 */
const GoogleMapsGeoLink: React.FunctionComponent<IProps> = (props: IProps) => {
  const { latitude, longitude } = props

  return latitude !== null && latitude !== 0 && longitude !== null && longitude !== 0 ? (
    <Link target="_blank" href={`https://www.google.com/maps/place/${latitude},${longitude}`}>
      {latitude.toFixed(5)}, {longitude.toFixed(5)}
    </Link>
  ) : (
    <>Not available</>
  )
}

export default GoogleMapsGeoLink
