import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { IPagedResults } from "../models/IPagedResults"
import { IPaging } from "../models/IPaging"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { IOrdering } from "../models/IOrdering"
import { IFilter } from "../models/IFilter"

interface IUseApiPagedResultsResponse<T> {
  call: (filters?: IFilter[]) => void
  loading: boolean
  count: number
  paging?: IPaging | null
  error: IConnectionError | undefined
  data: IPagedResults<T> | undefined
  handlePaging: (_e: ChangeEvent<unknown> | null, page: number) => void
  handleOrdering: (ordering: IOrdering) => void
  handleLimit: (limit: number) => void
  handleFilter: (filters: IFilter[]) => void
}

export interface IUseApiPagedResultsProps<T> {
  apiFunction: (paging?: IPaging | null) => Promise<IPagedResults<T>>
  dontCallOnMount?: boolean
  initialFilters?: IFilter[]
}

/**
 * This hook will do all the heavy lifting of paging api results.
 *
 * @param {IUseApiPagedResultsProps} props see IUseApiEditProps<T> for details.
 * @returns {IUseApiPagedResultsResponse} edit state
 */
export const useApiPagedLocal = <T>(props: IUseApiPagedResultsProps<T>): IUseApiPagedResultsResponse<T> => {
  const { apiFunction, dontCallOnMount, initialFilters = [] } = props

  const [loading, setLoading] = useState(false)
  const [paging, setPaging] = useState<IPaging>({
    filters: initialFilters,
    page: 1,
    offset: 0,
    limit: 10,
  })
  const [error, setError] = useState<IConnectionError | undefined>()
  const [data, setData] = useState<IPagedResults<T> | undefined>()
  const [didFirstLoad, setDidFirstLoad] = useState<boolean>(false)

  const limit = useMemo(() => (paging?.limit !== undefined ? paging.limit : 5), [paging])
  const count = useMemo(() => (data?.count !== undefined ? Math.ceil(data.count / limit) : 0), [data, limit])

  const handlePaging = useCallback(
    async (_e: ChangeEvent<any> | null, page: number) => {
      setPaging(paging1 => {
        return {
          ...paging1,
          page,
          offset: (page - 1) * limit,
        }
      })
    },
    [limit]
  )

  const handleLimit = useCallback((newLimit: number) => {
    setPaging(paging1 => ({
      ...paging1,
      limit: newLimit,
    }))
  }, [])

  const handleOrdering = useCallback(async (ordering: IOrdering) => {
    setPaging(paging1 => ({
      ...paging1,
      ordering,
    }))
  }, [])

  const handleFilter = useCallback(async (filters: IFilter[]) => {
    setPaging(paging1 => ({
      ...paging1,
      filters,
    }))
  }, [])

  const call = useCallback(() => {
    setLoading(true)
    apiFunction(paging)
      .then(results => {
        setData(results)
        setError(undefined)
      })
      .catch(reason => {
        setData(undefined)
        if (reason?.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      })
      .finally(() => setLoading(false))
  }, [apiFunction, paging])

  useEffect(() => {
    if (!didFirstLoad && !loading && (dontCallOnMount === undefined || !dontCallOnMount)) {
      setDidFirstLoad(true)
      call()
    }
  }, [call, dontCallOnMount, didFirstLoad])

  useEffect(() => {
    setDidFirstLoad(false)
  }, [paging])

  useEffect(() => {
    if (paging?.limit === undefined) {
      handleLimit(10)
    }
    if (paging?.limit !== undefined && paging.limit > 500) {
      handleLimit(500)
    }
  }, [paging?.limit, handleLimit])

  return {
    call,
    loading,
    error,
    data,
    count,
    paging,
    handlePaging,
    handleLimit,
    handleOrdering,
    handleFilter,
  }
}
