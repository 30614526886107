import * as React from "react"
import { Chip } from "@mui/material"
import { styles } from "../../../shared/styling/general"

type ProgressColor = "warning" | "success"

const progressColor: ProgressColor[] = ["warning", "success"]
const progressText = ["In Progress", "Complete"]

interface IProps {
  progress: number
}

/**
 * Tech review request progress.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the tech review request progress.
 */
const ReviewProgress: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { progress } = props

  return (
    <>
      <Chip color={progressColor[progress]} sx={styles.review.chip()} size="small" variant="filled" label={progressText[progress]} />
    </>
  )
}

export default ReviewProgress
