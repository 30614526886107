import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { CONTACT_ENDPOINT, IContact } from "../models/IContact"
import { RestRepository } from "../repositories/RestRepository"
import StarIcon from "@mui/icons-material/Star"
import StarOutlineIcon from "@mui/icons-material/StarOutline"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import ErrorMessage from "./ErrorMessage"
import HtmlTooltip from "./HtmlToolTip"
import { CircularProgress, IconButton } from "@mui/material"

interface IProps {
  contact: IContact
}

interface IPatch {
  primary: boolean
}

const repo = new RestRepository<IContact | IPatch>(CONTACT_ENDPOINT)

/**
 * Button to toggle the contact as primary.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const ContactAsPrimary: React.FunctionComponent<IProps> = (props: IProps) => {
  const { contact } = props
  const [saving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)
  const [localContact, setLocalContact] = useState<IContact | null>(null)

  /**
   * Toggle the verified state of the contact.
   */
  const handleToggleVerified = useCallback(async () => {
    if (localContact !== null) {
      setSaving(true)
      setSavingError(null)
      try {
        const patch: IPatch = { primary: !localContact.primary }
        const contactUpdated = await repo.patch(patch, localContact.id)
        setLocalContact(contactUpdated as IContact)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    }
  }, [localContact])

  /**
   * Set the local contact state if it hasn't been set.
   */
  useEffect(() => {
    if (localContact === null) {
      setLocalContact(contact)
    }
  }, [localContact, contact])

  return (
    <HtmlTooltip
      title={
        <>
          {savingError === null ? "" : <ErrorMessage error={savingError} />}
          {localContact?.primary === true ? "Is Primary" : "Make Primary"}
        </>
      }
      placement="right"
    >
      <IconButton disabled={localContact === null} onClick={handleToggleVerified} size="small">
        {saving ? (
          <CircularProgress size="1rem" />
        ) : (
          <>{localContact?.primary === true ? <StarIcon color="success" /> : <StarOutlineIcon color="warning" />}</>
        )}
      </IconButton>
    </HtmlTooltip>
  )
}

export default ContactAsPrimary
