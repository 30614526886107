import { type IAccount } from "./IAccount"
import { type ILocation } from "./ILocation"
import type IUser from "./IUser"
import { type IMessage } from "./IMessage"
import { type IWorkAssignmentConsultant } from "./IWorkAssignmentConsultant"
import { type ICustomer } from "./ICustomer"
import { type IWorkAssignmentExtension } from "./IWorkAssignmentExtension"
import { type ISelectItem } from "./component/ISelectItem"
import { type IWorkAssignmentFile } from "./IWorkAssignmentFile"
import type * as moment from "moment/moment"
import { type IReportFormat } from "./IReportFormat"
import { type IStorageFile } from "./IStorageFile"
import { type IInvoice } from "./IInvoice"
import { type IConsultant } from "./main/IConsultant"
import { type IContact } from "./IContact"

export const WORK_ASSIGNMENT_ENDPOINT = "work_assignments"
export const WORK_ASSIGNMENT_MAP_ENDPOINT = "work_assignments_map"

export const EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT = "expense_report_work_assignment_tech_reviews"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_ADMIN_REVIEW_ENDPOINT = "expense_report_work_assignment_admin_reviews"

/**
 * Check to see if an object is a work assignment.
 *
 * @param {any} obj to check.
 * @returns {boolean} true if it is a work assignment.
 */
export const isWorkAssignment = (obj: any): obj is IWorkAssignment => true

// These values are duplicated on the server. They shouldn't change much if at all, so duplication should be fine.
// Be sure to reflect any changes on the server.
export const INSPECTION_TYPES = [
  {
    key: "HPRF",
    value: "Highly Protected Risk First",
  },
  {
    key: "HPRR",
    value: "Highly Protected Risk Regular",
  },
  {
    key: "PLF",
    value: "Property Lite First",
  },
  {
    key: "PLR",
    value: "Property Lite Regular",
  },
  {
    key: "VRR",
    value: "VRR",
  },
  {
    key: "VS",
    value: "VS",
  },
  {
    key: "BR",
    value: "Builders Risk",
  },
  {
    key: "PLBRF",
    value: "Property Lite Builders Risk First Survey",
  },
  {
    key: "PLBRR",
    value: "Property Lite Builders Risk Resurvey",
  },
  {
    key: "SPF",
    value: "Special Survey First",
  },
  {
    key: "VSF",
    value: "Virtual Survey First",
  },
  {
    key: "VSR",
    value: "Virtual Survey Regular",
  },
  {
    key: "SPVSF",
    value: "Special Virtual Survey First",
  },
  {
    key: "PR",
    value: "Plan Review",
  },
  {
    key: "CFU",
    value: "Construction Follow Up",
  },
] as ISelectItem[]

export const DEFAULT_INSPECTION_TYPE = INSPECTION_TYPES[0]

export const INSPECTION_CATEGORIES = [
  {
    key: "PL",
    value: "PL",
  },
  {
    key: "HPR",
    value: "HPR",
  },
  {
    key: "PR",
    value: "Plan Review",
  },
] as ISelectItem[]

export const DEFAULT_INSPECTION_CATEGORY = INSPECTION_CATEGORIES[1]

export const PRIORITY_TYPES = [
  {
    key: 0,
    value: "Normal",
  },
  {
    key: 1,
    value: "High",
  },
] as ISelectItem[]

export const DEFAULT_PRIORITY_TYPES = PRIORITY_TYPES[1]

export interface IWorkAssignment {
  id: number
  identifier: string
  name: string
  inspection_category: string
  inspection_type: string
  inspection_type_name: string
  consultant_is_finished: boolean

  external_tracking: string
  priority: number
  priority_name: string
  archive: boolean
  due_date_days_after_visit: number
  consultant_due_date_days_after_visit: number

  due_date: string | moment.Moment | null
  due_date_comment: string[] | null

  visit_date: string | moment.Moment | null
  issued_date: string | moment.Moment | null
  consultant_due_date: string | moment.Moment | null
  consultant_due_date_comment: string[] | null

  total_insured_value?: number
  property_damage?: number
  business_interruption?: number
  rli_getting_paid: number
  rli_getting_paid_amount?: number
  rli_getting_paid_hours?: number
  rli_getting_paid_hourly_rate: boolean
  work_assignment_approved: boolean
  suggested_consultant_amount: number
  report_format: IReportFormat | number | null
  service_instructions_url: string | null

  notes: string
  job_status: string
  notes_consultant: string
  contact_notes: string
  tech_review_notes: string
  max_risk_score: number
  obtained_risk_score: number

  progress_consultant_requests_sent: string
  progress_consultant_requests_sent_by: IUser

  progress_prepare_field_maps: string
  progress_prepare_field_maps_by: IUser

  progress_consultant_request_confirmed: string
  progress_consultant_request_confirmed_by: IUser

  progress_consultant_pre_survey_received: string
  progress_consultant_pre_survey_received_by: IUser

  progress_consultant_docs_ready: string
  progress_consultant_docs_ready_by: IUser

  progress_consultant_visit_complete: string
  progress_consultant_visit_complete_by: IUser

  progress_consultant_reports_submitted: string
  progress_consultant_reports_submitted_by: IUser

  progress_report_review_admin_sign_off: string
  progress_report_review_admin_sign_off_by: IUser

  progress_report_review_tech_sign_off: string
  progress_report_review_tech_sign_off_by: IUser

  progress_report_sent_account: string
  progress_report_sent_account_by: IUser

  progress_invoice_sent_account: string
  progress_invoice_sent_account_by: IUser

  is_report_sent_account_late: boolean
  is_consultant_report_late: boolean

  tech_reviewer_consultant: IConsultant | null
  tech_review_accept: string | null
  tech_review_decline: string | null
  tech_review_hours: number
  tech_review_due_date: string | moment.Moment
  tech_review_sign_off: string | null
  tech_review_needed: boolean
  tech_review_hours_approved_by: IUser | null

  tech_reviewer_2_consultant: IConsultant | null
  tech_review_2_accept: string | null
  tech_review_2_decline: string | null
  tech_review_2_hours: number
  tech_review_2_due_date: string | moment.Moment
  tech_review_2_sign_off: string | null
  tech_review_2_needed: boolean
  tech_review_2_hours_approved_by: IUser | null

  admin_reviewer: IUser | number
  admin_reviewer_consultant: IConsultant | number
  admin_review_hours: number
  admin_review_due_date: string | moment.Moment
  admin_review_sign_off: string | moment.Moment
  admin_review_hours_approved_by: IUser | null

  account: IAccount | number
  customer: ICustomer | number
  extensions: IWorkAssignmentExtension[] | number[]
  location: ILocation | null
  invoice: IInvoice | string
  invoice_contact: IContact | null
  work_assignment_consultants: IWorkAssignmentConsultant[]
  messages: IMessage[]
  files: IWorkAssignmentFile[]
  files_bundle: IStorageFile
  executive: IUser | null
  technical_admin: IUser | null
  assignee: IUser | number | null
}

/**
 * Get the work assignment location as text.
 *
 * @param {IWorkAssignment} wa to convert to string.
 * @returns {string} the location or None
 */
export const workAssignmentLocation = (wa: IWorkAssignment): string => {
  if (wa.location !== null) {
    return `${wa.location.name} - ${wa.location.address} - ${wa.location.city}, ${wa.location.state_region}, ${wa.location.country}`
  }
  return "None"
}

/**
 * Retrieves the name of the consultant assigned to a work assignment
 *
 * @param {IWorkAssignment} wa - The work assignment object
 * @returns {string} - The name of the assigned consultant, or "None" if no consultants are assigned
 */
export const workAssignmentConsultant = (wa: IWorkAssignment) : string => {
  if (wa.work_assignment_consultants.length > 0) {
    const consultant = wa.work_assignment_consultants[0].consultant as IConsultant
      return consultant.name
  }
  return "None"
}

/**
 * Is an admin reviewer needed?
 *
 * @param {IWorkAssignment | null} wa to check.
 * @returns {boolean} true if needed.
 */
export const workAssignmentAdminNotNeeded = (wa: IWorkAssignment | null): boolean => {
  if (wa === null) {
    return false
  }
  return wa.progress_report_review_admin_sign_off !== null && wa.admin_reviewer_consultant === null
}

/**
 * Describes the current progress for the work assignment.
 */
export class Progress {
  total: number
  progress: number
  previousCompletedName?: string
  previousCompletedDate?: string
  field: string
  title: string

  /**
   * Build a work assignment progress.
   *
   * @param {number} total of steps in the progress.
   * @param {number} progress the current percent progress.
   * @param {string} field the progress is currently on.
   * @param {string} title of the current progress.
   * @param {string} previousCompletedName name of the previous completed progress.
   * @param {string} previousCompletedDate date of the previous completed progress.
   */
  constructor(
    total: number,
    progress: number,
    field: string,
    title: string,
    previousCompletedName?: string | undefined,
    previousCompletedDate?: string | undefined
  ) {
    this.total = total
    this.progress = progress
    this.field = field
    this.title = title
    this.previousCompletedName = previousCompletedName
    this.previousCompletedDate = previousCompletedDate
  }

  getCurrentName = (): string => this.title
  getField = (): string => this.field
  getProgress = (): number => 100 * (this.progress / this.total)
  getPercent = (): string => this.getProgress().toFixed(0) + "%"
  isComplete = (): boolean => this.progress === this.total
}

export const workAssignmentSteps = [
  {
    step: 1,
    field: "progress_consultant_requests_sent",
    title: "Request Consultant",
  },
  {
    step: 2,
    field: "progress_prepare_field_maps",
    title: "Prepare Field Maps",
  },
  {
    step: 3,
    field: "progress_consultant_docs_ready",
    title: "Prepare Consultant Documents",
  },
  {
    step: 4,
    field: "progress_consultant_request_confirmed",
    title: "Confirm Consultant",
  },
  {
    step: 5,
    field: "progress_consultant_pre_survey_received",
    title: "Receive Pre-Survey from Consultant",
  },
  {
    step: 6,
    field: "progress_consultant_visit_complete",
    title: "Consultant Visit Complete",
  },
  {
    step: 7,
    field: "progress_consultant_reports_submitted",
    title: "Consultant Submits Report to RLI",
  },
  {
    step: 8,
    field: "progress_report_review_admin_sign_off",
    title: "Report Review by Admin",
  },
  {
    step: 9,
    field: "progress_report_review_tech_sign_off",
    title: "Report Review by Tech Reviewers",
  },
  {
    step: 10,
    field: "progress_report_sent_account",
    title: "Send Report to Account",
  },
  {
    step: 11,
    field: "progress_invoice_sent_account",
    title: "Send Invoice to Account",
  },
]

/**
 * Build the current work assignment progress.
 *
 * @param {IWorkAssignment} wa to build the progress for.
 * @returns {Progress} the work assignment progress.
 */
export const workAssignmentProgress = (wa: IWorkAssignment): Progress => {
  const justSteps = workAssignmentSteps.filter(wa => wa.step > 0)
  const total = justSteps.length

  let progress = 0
  let previousCompletedName
  let previousCompletedDate
  let currentField = ""
  let currentTitle = ""

  for (const step of justSteps) {
    if ((wa as any)[step.field] === null) {
      currentField = step.field
      currentTitle = step.title
      return new Progress(total, progress, currentField, currentTitle, previousCompletedName, previousCompletedDate)
    } else {
      progress++
      previousCompletedName = step.field
      previousCompletedDate = (wa as any)[step.field] as string
    }
  }
  return new Progress(total, progress, currentField, currentTitle, previousCompletedName, previousCompletedDate)
}
