import * as React from "react"
import { useCallback, useRef, useState } from "react"
import { Box, Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper, Popover } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import FormatDate from "../format/FormatDate"
import { LoadingButton } from "@mui/lab"
import BuildIcon from "@mui/icons-material/Build"
import { IStorageFile } from "../../models/IStorageFile"
import { RestRepository } from "../../repositories/RestRepository"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../models/IWorkAssignment"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  waId: number
  bundle: IStorageFile
  onChange: () => void
}

/**
 * This component will zip all the file in a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the file bundler component.
 */
const FileBundler: React.FunctionComponent<IProps> = (props: IProps) => {
  const { waId, bundle, onChange } = props
  const [buildingBundle, setBuildingBundle] = useState(false)
  const bundleAnchorRef = useRef<HTMLDivElement>(null)
  const [openBundleMenu, setOpenBundleMenu] = useState(false)

  const handleBuildBundle = useCallback(async () => {
    setBuildingBundle(true)
    setOpenBundleMenu(false)
    try {
      await waRepository.action(waId, "bundle_files")
    } catch (e) {}
    await onChange()
    setBuildingBundle(false)
  }, [waId])

  return (
    <>
      {bundle?.exists !== false && !buildingBundle ? (
        <>
          <ButtonGroup variant="text" ref={bundleAnchorRef}>
            <Button href={bundle.read_url} target="_blank">
              Download
            </Button>
            <Button size="small" onClick={() => setOpenBundleMenu(o => !o)}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          <Popover
            open={openBundleMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={() => setOpenBundleMenu(false)}
            anchorEl={bundleAnchorRef.current}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenBundleMenu(false)}>
                <MenuList autoFocusItem>
                  <MenuItem onClick={handleBuildBundle}>
                    <Box component="span" sx={{ pr: 1 }}>
                      Rebuild Bundle
                    </Box>
                    <FormatDate value={bundle.exists as string} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popover>
        </>
      ) : (
        <>
          <LoadingButton
            loadingPosition="start"
            loading={buildingBundle}
            onClick={handleBuildBundle}
            startIcon={<BuildIcon />}
            disabled={buildingBundle}
          >
            {buildingBundle ? "Building..." : "Build Bundle"}
          </LoadingButton>
        </>
      )}
    </>
  )
}

export default FileBundler
