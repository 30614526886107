import * as React from "react"
import { useCallback, useState } from "react"
import SelectFilteredSingle from "./SelectFilteredSingle"
import { RestRepository } from "../repositories/RestRepository"
import { IListItem } from "../models/component/IListItem"
import { Grid } from "@mui/material"
import { navigate } from "gatsby"
import { IFilter } from "../models/IFilter"

interface IProps {
  repository: RestRepository<IListItem>
  url: string
  filters?: IFilter[]
  label?: string
  onFocus?: () => void
  onBlur?: () => void
}

/**
 * Use this component to lookup and goto a page based on a list repository.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FunctionComponent<IProps>} the goto component
 */
const Goto: React.FunctionComponent<IProps> = (props: IProps) => {
  const { repository, url, filters, label = "Goto", onFocus, onBlur } = props

  const [focus, setFocus] = useState<boolean>(false)

  const handleChange = useCallback(
    async (item?: IListItem | null) => {
      if (item?.id !== undefined) {
        await navigate(`${url}/${item.id}`)
      }
    },
    [url]
  )

  const handleFocus = useCallback(() => {
    setFocus(true)
    onFocus?.()
  }, [onFocus])

  const handleBlur = useCallback(() => {
    setFocus(false)
    onBlur?.()
  }, [onFocus])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <SelectFilteredSingle
          name="goto"
          size="small"
          label={focus ? label : "Goto"}
          filters={filters}
          onFocus={handleFocus}
          onBlur={handleBlur}
          repository={repository}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default Goto
