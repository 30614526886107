import * as React from "react"
import { useCallback, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import DeleteIcon from "@mui/icons-material/CalendarToday"
import moment from "moment"
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from "../../config/config"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import DialogControls from "./DialogControls"

interface IProps {
  field: string
  onClick: (field: string, date?: string) => void
  disabled: boolean
}

/**
 * Sign off button for work assignment progress.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the sign off button.
 */
const SignOffButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, onClick, disabled } = props

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<moment.Moment>(moment())

  const handleDateChange = useCallback(e => {
    setDate(moment(e))
  }, [])

  const handleSignOff = useCallback(() => {
    onClick(field)
  }, [])

  const handleSignOffWithDate = useCallback(() => {
    onClick(field, moment(date).format(DATE_OUTPUT_FORMAT))
    setDate(moment())
    setOpen(false)
  }, [date])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <ButtonGroup variant="text" size="small">
        <Button disabled={disabled} sx={{ lineHeight: "1em" }} onClick={handleSignOff}>
          Sign Off
        </Button>
        <Button startIcon={<DeleteIcon />} onClick={handleOpen} sx={{ lineHeight: "1em" }} />
      </ButtonGroup>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Sign Off</DialogTitle>
        <DialogContent>
          <DialogContentText>Sign off on step with a date.</DialogContentText>
          <Box sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date"
                onChange={handleDateChange}
                value={date}
                mask="____-__-__"
                inputFormat={DATE_INPUT_FORMAT}
                renderInput={params => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogControls onCancel={handleClose} onSave={handleSignOffWithDate} buttonLabel="Sign Off" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignOffButton
