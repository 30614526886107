import { type IListItem } from "./component/IListItem"
import { type IStorageFile } from "./IStorageFile"
import { type IMinimalFile } from "./IFile"

export const REPORT_FORMAT_ENDPOINT = "report_formats"

export interface IReportFormat extends IListItem, IMinimalFile {
  id: number
  name: string
  ext: string
  description: string
  file: IStorageFile
  admin_review_needed: boolean
}
