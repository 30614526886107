import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography
} from "@mui/material"
import { BASE_URL } from "../../../shared/utilities/request_utility"
import useGlobalEvent from "beautiful-react-hooks/useGlobalEvent"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { PROFILES_ENDPOINT } from "../../../shared/models/main/IProfile"
import useAuth from "../../../shared/hooks/useAuth"
import { LoadingButton } from "@mui/lab"
import { IInvoice, INVOICE_ENDPOINT } from "../../../shared/models/IInvoice"
import DialogControls from "../../../shared/components/DialogControls"
import TruncateText from "../../../shared/components/TruncateText"
import FormatDate from "../../../shared/components/format/FormatDate"
import { DATE_TIME_FORMAT } from "../../../config/config"
import EditQuickbooksId from "./EditQuickbooksId"

const BASE_SERVER_URL = BASE_URL.replace("/api", "")

interface IIntuitLogin {
  logged_in: boolean
  quickbooks_base_url: string
}

interface IProps {
  invoice: IInvoice
  onRefresh: () => void
}

const repository = new RestRepository<IIntuitLogin>(PROFILES_ENDPOINT)
const invoiceRepository = new RestRepository<IInvoice>(INVOICE_ENDPOINT)

/**
 * Renders a button for Intuit login.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.ReactElement} The rendered IntuitAuthentication component.
 */
const IntuitAuthentication: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { invoice, onRefresh } = props
  const [intuitLogin, setIntuitLogin] = useState<IIntuitLogin | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const onGlobalEvent = useGlobalEvent("focus")
  const { currentUser } = useAuth()

  const loadIntuitProfile = useCallback(async () => {
    if (currentUser?.user.profile !== undefined && (intuitLogin === null || !intuitLogin.logged_in)) {
      setLoading(true)
      try {
        const isLoggedIn = await repository.action(currentUser.user.profile, "intuit_logged_in")
        if (isLoggedIn.logged_in) {
          setIntuitLogin(isLoggedIn)
        }
      } catch (reason: any) {
        setOpen(false)
        setIntuitLogin(null)
      }
      setLoading(false)
    }
  }, [currentUser?.user.profile, intuitLogin])

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleSyncCustomerAccount = useCallback(async () => {
    setLoading(true)
    try {
      await invoiceRepository.action(invoice.id, "sync_customer_with_intuit")
      onRefresh()
    } catch (e: any) {
      setOpen(false)
      setIntuitLogin(null)
    }
    setLoading(false)
  }, [invoice])

  const handleSyncInvoice = useCallback(async () => {
    setLoading(true)
    try {
      await invoiceRepository.action(invoice.id, "sync_invoice_with_intuit")
      onRefresh()
    } catch (e: any) {
      setOpen(false)
      setIntuitLogin(null)
    }
    setLoading(false)
  }, [invoice])

  const handleAttachInvoice = useCallback(async () => {
    setLoading(true)
    try {
      await invoiceRepository.action(invoice.id, "attach_invoice_with_intuit")
    } catch (e: any) {
      setOpen(false)
      setIntuitLogin(null)
    }
    setLoading(false)
  }, [invoice])

  const handleOpenDialog = useCallback(async () => {
    if (currentUser?.token.access !== undefined) {
      window.open(`${BASE_SERVER_URL}/intuit/oauth/?token=${currentUser.token.access}`, "Login", "width=600,height=800")
    }
  }, [currentUser])

  const handleRefreshLogin = useCallback(async () => {
    setIntuitLogin(null)
    await loadIntuitProfile()
  }, [])

  onGlobalEvent(() => {
    void (async () => {
      await loadIntuitProfile()
    })()
  })

  useEffect(() => {
    void (async () => {
      await loadIntuitProfile()
    })()
  }, [currentUser?.user.profile, loadIntuitProfile])

  return (
    <>
      {intuitLogin === null || !intuitLogin.logged_in ? (
        <Button onClick={handleOpenDialog} disabled={currentUser?.user.profile === undefined}>
          Intuit Login
        </Button>
      ) : (
        <LoadingButton loading={loading} onClick={handleOpen}>
          Sync with Intuit
        </LoadingButton>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Intuit Sync</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={9}>
              {invoice.primary_contact_for_billing.workflow_type !== null && (
                <>
                  <Typography variant="h6" component="h6" sx={{ textTransform: "capitalize" }}>
                    {invoice.primary_contact_for_billing.workflow_type.replaceAll("_", " ")}
                  </Typography>
                  <strong>
                    {invoice.primary_contact_for_billing.workflow_id} |{" "}
                    <TruncateText text={invoice.primary_contact_for_billing.name} num={40} />
                  </strong>
                  <Grid container>
                    <Grid item xs>
                      <strong>Synced:</strong>{" "}
                      <FormatDate value={invoice.primary_contact_for_billing.quickbooks_synced} format={DATE_TIME_FORMAT} />
                    </Grid>
                    <Grid item xs>
                      {intuitLogin !== null &&
                        invoice.primary_contact_for_billing.quickbooks_identifier !== null &&
                        invoice.primary_contact_for_billing.quickbooks_identifier !== "" && (
                          <Link
                            target="_blank"
                            href={`${intuitLogin.quickbooks_base_url}/app/customerdetail?nameId=${invoice.primary_contact_for_billing.quickbooks_identifier}`}
                          >
                            View in Quickbooks | {invoice.primary_contact_for_billing.quickbooks_identifier}
                          </Link>
                        )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth onClick={handleSyncCustomerAccount}>
                Sync
              </Button>
              <EditQuickbooksId invoice={invoice} onChange={onRefresh} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" component="h6">
                Invoice
              </Typography>
              <strong>{invoice.number}</strong>
              <Grid container>
                <Grid item xs>
                  <em>Synced:</em> <FormatDate value={invoice.quickbooks_synced} format={DATE_TIME_FORMAT} />
                </Grid>
                <Grid item xs>
                  {intuitLogin !== null &&
                    invoice.quickbooks_identifier !== undefined &&
                    invoice.quickbooks_identifier !== null &&
                    invoice.quickbooks_identifier !== "" && (
                      <Link target="_blank" href={`${intuitLogin.quickbooks_base_url}/app/invoice?txnId=${invoice.quickbooks_identifier}`}>
                        View in Quickbooks | {invoice.quickbooks_identifier}
                      </Link>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth onClick={handleSyncInvoice}>
                Sync
              </Button>
            </Grid>
            {invoice?.file?.exists !== undefined && invoice?.file?.exists !== false && (
              <>
                <Grid item xs={9}>
                  <Typography variant="h6" component="h6">
                    Attach Generated Invoice
                  </Typography>
                  <strong>{(invoice.file_final.exists as boolean) ? "Final Invoice" : "Generated Invoice"}</strong>
                </Grid>
                <Grid item xs={3}>
                  <Button fullWidth onClick={handleAttachInvoice}>
                    Attach
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls
            loading={loading}
            buttonLabel="Close"
            onSave={() => setOpen(false)}
            cancelButtonLabel="Refresh Login"
            onCancel={handleRefreshLogin}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default IntuitAuthentication
